import React from 'react';
import StringUtil from '../../utils/string-utils';
import PieChart from './PieChart';
import BarChart from './BarChart';

export default function PollingDetails({ data }) {
  return data.map((poll, index) => {
    const _labels = [];
    let question = poll.question;
    let longestlabel = 0;
    poll.responses.forEach((r) => {
      if (r.selection) {
        if (r.selection.length > longestlabel) {
          longestlabel = r.selection.length;
        }
        _labels.push(r.selection);
      }
    });

    let responded = 0;
    let noResponse = 0;
    poll.responses.forEach((r) => {
      if (r.selection) {
        responded += r.count;
      } else {
        noResponse = r.count;
      }
    });

    const _values = [];
    _labels.forEach((label) => {
      let _label = label.trim();
      const resp = poll.responses.find((r) => r.selection === _label);
      if (resp) {
        _values.push(resp.count);
      } else {
        _values.push(0);
      }
    });
    const _labelMaxChars = Math.min(40 + (longestlabel / 20) * 5, 100);
    for (let i = 0; i < _labels.length; i++) {
      _labels[i] = StringUtil.splitLabelInSections(_labels[i], _labelMaxChars);
    }

    return (
      <div key={`h-barchart-${index}`}>
        <h4 style={{ textAlign: 'center' }}>{question}</h4>
        <div className='chart-area' style={{ marginBottom: 30, alignItems: 'center' }}>
          <PieChart data={[responded, noResponse]} labels={['Responded', 'No Response']} style={{ width: 250, marginRight: 30 }} />
          <BarChart data={_values} displayDataLabels={true} displayLegend={false} labels={_labels} style={{ width: 700 }} horizontal />
        </div>
      </div>
    );
  });
}
