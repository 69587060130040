import React from 'react';
import '../css/ButtonGroup.css';

export default function ButtonGroup({ label, options, value, onChange }) {
  return (
    <div className='button-group'>
      <div className='label'>{label}</div>
      <div className='options'>
        {options.map((opt, index) => {
          const selected = opt.value === value;
          return (
            <div key={`btn-group-${opt.value}-${index}`} className={selected ? 'item selected' : 'item'} onClick={() => onChange && onChange(opt.value)}>
              {selected && opt.selectedIcon ? <img src={opt.selectedIcon} alt='' /> : <img src={opt.icon} alt='' />}
              <div className='label'>{opt.label}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
