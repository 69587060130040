import React from 'react';
import { Bar } from 'react-chartjs-2';
import { getColorScale, getDatasetConfig, getDefaultPluginConfig } from '../../utils/charts-util';

export default function BarChart({ datasets, data, dataLabel, displayDataLabels = true, labels, style, displayLegend, horizontal, title }) {
  const colorScale = getColorScale(labels.length);
  const _datasets = getDatasetConfig(datasets, data, dataLabel, colorScale);

  return (
    <div style={style}>
      <Bar
        data={{
          labels,
          datasets: _datasets,
        }}
        options={{
          indexAxis: horizontal ? 'y' : 'x',
          scales: {
            x: {
              ticks: {
                display: horizontal ? false : true,
              },
              grid: {
                display: false,
                drawBorder: false,
              },
            },
            y: {
              ticks: {
                display: horizontal ? true : false,
              },
              grid: {
                display: false,
                drawBorder: false,
              },
            },
          },
          tooltips: false,
          plugins: {
            ...getDefaultPluginConfig(title, displayLegend),
            datalabels: {
              display: displayDataLabels,
              formatter: (value, context) => {
                if (value === 0) return '';

                const dataset = context.dataset;
                const count = dataset.data.length;
                let total = 0;
                for (let i = 0; i < count; i++) {
                  total += parseInt(dataset.data[i]);
                }
                const percent = Math.round((value * 100) / total);
                return `${percent}% (${value})`;
              },
            },
          },
        }}
      />
    </div>
  );
}
