import axios from 'axios';
import { take, call, all, put, cancel, fork } from 'redux-saga/effects';
import { setToken, post, get } from '../services/api';
import { actions, loginSuccess, loginFailure, checkSession } from '../redux/authRedux';
import { toggleNavBar } from '../redux/uiRedux';

function _deleteHeaders() {
  delete axios.defaults.headers.Authorization;
  delete axios.defaults.headers['x-pando-studio-id'];
  delete axios.defaults.headers['x-pando-is-admin-app'];
}

function* authorize(username, password) {
  try {
    const { data } = yield call(post, 'auth', { username, password });
    if (data.user.disableMfa === true) {
      yield call([localStorage, localStorage.setItem], 'token', data.token);
      yield call(setToken, data.token);
    }
    yield put(loginSuccess(data.user));
    return data;
  } catch (error) {
    yield put(loginFailure(error));
    yield call(_deleteHeaders);
  }
}

function* loginFlow() {
  const token = yield call([localStorage, localStorage.getItem], 'token');
  if (token) {
    yield put(checkSession());
    yield call(setToken, token);
    const navBarCollapsed = yield call([localStorage, localStorage.getItem], 'navBarCollapsed');
    if (navBarCollapsed) {
      yield put(toggleNavBar(navBarCollapsed === 'true'));
    }
    try {
      const data = yield call(get, 'auth');
      yield put(loginSuccess(data.data.user));

      if (window.location.pathname === '/login' && data.status === 200) {
        window.location.pathname = '/app';
      }

      yield take(actions.LOGOUT);
      yield call(get, 'auth/logout');
    } catch (error) {
      yield put(loginFailure('Session expired'));
      yield call(_deleteHeaders);
    } finally {
      yield call([localStorage, localStorage.removeItem], 'token');
      yield call([localStorage, localStorage.removeItem], 'navBarCollapsed');
      yield call([localStorage, localStorage.removeItem], 'activeStudio');
      yield call([localStorage, localStorage.removeItem], 'activeEvent');
    }
  }

  while (true) {
    const {
      payload: { username, password },
    } = yield take(actions.LOGIN_REQUEST);
    const task = yield fork(authorize, username, password);
    const action = yield take([actions.LOGOUT, actions.LOGIN_FAILURE]);
    if (action.type === actions.LOGOUT && task) yield cancel(task);
    yield call(get, 'auth/logout');
    yield call(_deleteHeaders);
    yield call([localStorage, localStorage.removeItem], 'token');
    yield call([localStorage, localStorage.removeItem], 'navBarCollapsed');
    yield call([localStorage, localStorage.removeItem], 'activeStudio');
    yield call([localStorage, localStorage.removeItem], 'activeEvent');
  }
}

export default function* () {
  yield all([loginFlow()]);
}
