import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { selectEvent, getEventsRequest, updateEventRequest, getEventParticipantsRequest, getEventRequest, setActiveEvent } from '../../redux/eventsRedux';
import { getAssetsRequest } from '../../redux/assetsRedux';
import { sendUpdate } from '../../redux/uiRedux';
import { isAdmin } from '../../redux/authRedux';
import { getInstancesRequest } from '../../redux/goLiveRedux';
import SocketClient from '../../utils/socket-client';

import BroadcastDetails from './forms/BroadcastDetails';
import EventList from './EventList';
import EventAssets from './EventAssets';

class Events extends PureComponent {
  componentDidMount() {
    const { activeStudio, getEvents, sendUpdate, getInstances, getEventParticipants, activeEvent, getEvent, setActiveEvent } = this.props;
    if (activeEvent) {
      getEventParticipants(activeEvent._id);
    }
    if (activeStudio) {
      getInstances(activeStudio._id, true);
      this._onAdminAppSync = (msg) => {
        console.log('admin-app-sync', msg);
        if (msg.type === 'endBroadcast') {
          getEvent(msg.payload._id);
          if (activeEvent && activeEvent._id === msg.payload._id) {
            console.log('setting active event');
            setActiveEvent({ ...activeEvent, ...msg.payload });
          }
        } else {
          sendUpdate(msg);
        }
      };
      SocketClient.on('admin-app-sync', this._onAdminAppSync);
      getEvents(activeStudio._id);
    }
  }

  componentDidUpdate(prevProps) {
    const { list, activeEvent, activeStudio, getEvents } = this.props;

    if (activeStudio && prevProps.activeStudio !== activeStudio) {
      getEvents(activeStudio._id);
    }

    if (prevProps.list.length !== list.length) {
      if (activeEvent) {
        const { getAssets, selectEvent, getEvent } = this.props;
        getAssets(activeEvent._id);
        selectEvent(activeEvent._id);
        getEvent(activeEvent._id);
      }
    }
  }

  componentWillUnmount() {
    const { selectEvent, activeStudio } = this.props;
    selectEvent(null);
    if (activeStudio) {
      SocketClient.leaveRoom(`${activeStudio._id}:admin-app-sync`);
    }
    SocketClient.leaveRoom('server-resource-stats');
    SocketClient.removeAllListeners();
    SocketClient.off('admin-app-sync', this._onAdminAppSync);
  }

  render() {
    const { routesEnabled, activeStudio, appMode } = this.props;
    if (!routesEnabled) return <Redirect to='/app/studios' />;

    return (
      <div className='events-container'>
        <div className='events-content'>
          <div className='left-column'>
            {activeStudio !== null ? (
              <EventList
                isAdmin={isAdmin}
                onSelectEvent={(e) => {
                  this._onSelectEvent(e);
                }}
              />
            ) : null}
          </div>

          <div className='right-column'>
            {appMode === 'PANDO' && <EventAssets isAdmin={isAdmin} />}
            {appMode === 'BROADCAST' && <BroadcastDetails />}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  appMode: state.ui.appMode,
  routesEnabled: state.ui.routesEnabled,
  activeStudio: state.studio.active,
  selectedEvent: state.events.selected,
  list: state.events.list,
  activeEvent: state.events.active,
  isAdmin: isAdmin(state),
  liveEvent: state.studio.active ? state.studio.active.liveEvent : null,
});

const mapDispatchToProps = (dispatch) => ({
  selectEvent: (event) => dispatch(selectEvent(event)),
  getEvents: (studio) => dispatch(getEventsRequest(studio)),
  sendUpdate: (update) => dispatch(sendUpdate(update)),
  getAssets: (eventId) => dispatch(getAssetsRequest(eventId)),
  updateEvent: (id, data) => dispatch(updateEventRequest(id, data)),
  getInstances: (studio, monitoring) => dispatch(getInstancesRequest(studio, monitoring)),
  getEventParticipants: (event) => dispatch(getEventParticipantsRequest(event)),
  getEvent: (event) => dispatch(getEventRequest(event)),
  setActiveEvent: (event) => dispatch(setActiveEvent(event)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Events);
