import React from 'react';
import { connect } from 'react-redux';
// import imageIcon from '../../assets/icons/image.svg';
import AssetListItem from './AssetListItem';

const AssetList = ({ list, event }) => {
  return event !== null && list && list.map((l) => <AssetListItem key={l} asset={l} />);
};

const mapStateToProps = (state) => ({
  list: state.assets.list,
  event: state.layouts.event,
});

export default connect(mapStateToProps, null)(AssetList);
