import React from 'react';
import arrowsLeft from '../../assets/icons/double_arrow_left.svg';
import arrowsRight from '../../assets/icons/double_arrow_right.svg';

export default function NavBarButton({ collapsed, onClick }) {
  const arrow = collapsed ? <img src={arrowsRight} alt='right' /> : <img src={arrowsLeft} alt='left' />;
  return (
    <div className='navbar-button' onClick={onClick}>
      {arrow}
    </div>
  );
}
