import React, { useState, useEffect, useCallback, useRef } from 'react';
import moment from 'moment';
import axios from 'axios';
import { useSelector } from 'react-redux';

import { get } from '../../services/api';
import Button from '../../common/Button';

const { REACT_APP_API_BASE_URL } = process.env;

export default function BreakoutRoomsRecordings() {
  const timer = useRef(null);
  const selectedEventId = useSelector((state) => state.events.selected);
  const [recordings, setRecordings] = useState([]);
  const [downloadingIds, setDownloadingIds] = useState([]);

  const getRecordings = useCallback(async () => {
    if (selectedEventId) {
      try {
        const { data } = await get(`/breakoutRoom/recordings?eventId=${selectedEventId}`);
        if (data) {
          setRecordings(data);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [selectedEventId]);

  useEffect(() => {
    if (timer.current) {
      clearInterval(timer.current);
    }
    getRecordings();
    timer.current = setInterval(() => {
      getRecordings();
    }, 15000);
  }, [getRecordings]);

  useEffect(
    () => () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    },
    [],
  );

  const downloadRecording = useCallback((recordingId) => {
    setDownloadingIds((prev) => [...prev, recordingId]);
    axios({
      url: `${REACT_APP_API_BASE_URL}/breakoutRoom/recordings/${recordingId}/download`,
      method: 'GET',
      responseType: 'blob',
      timeout: 0,
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${recordingId}.mp4`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setDownloadingIds((prev) => prev.filter((id) => id !== recordingId));
      });
  }, []);

  if (recordings.length === 0) {
    return null;
  }

  return (
    <div style={{ color: '#fff', display: 'flex', flexDirection: 'column', padding: 15, borderTop: '2px solid #1e272f' }}>
      <h3 style={{ alignSelf: 'flex-start' }}>Breakout Room Recordings</h3>
      {recordings.map((recording) => {
        const isDownloading = downloadingIds.includes(recording._id);
        return (
          <div
            key={recording._id}
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 5, fontSize: 14 }}
          >
            <div>{`Breakout Room #${recording.roomNum}`}</div>
            <div>{recording.createdAt && moment(recording.createdAt).format('MM/DD/YYYY hh:mm A')}</div>
            <div>{recording.status}</div>
            <Button
              type='secondary'
              text={isDownloading ? 'Downloading' : 'Download'}
              onClick={() => downloadRecording(recording._id)}
              containerStyle={{
                fontSize: 12,
                padding: '2px 10px',
                margin: 0,
              }}
              disabled={recording.status !== 'COMPLETED' || isDownloading}
            />
          </div>
        );
      })}
    </div>
  );
}
