import { all, takeLatest, call, put } from 'redux-saga/effects';
import { get, post, put as update, del } from '../services/api';
import {
  actions,
  getEventsSuccess,
  getEventsFailure,
  getAllEventsSuccess,
  getAllEventsFailure,
  updateEventSuccess,
  updateEventFailure,
  deleteEventSuccess,
  deleteEventFailure,
  createEventSuccess,
  createEventFailure,
  duplicateEventSuccess,
  duplicateEventFailure,
  getEventParticipantsSuccess,
  getEventParticipantsFailure,
  updateParticipantStatusFailure,
  updateParticipantStatusSuccess,
  removeEventParticipantsFailure,
  removeEventParticipantsSuccess,
} from '../redux/eventsRedux';
import { setEvent, getLayoutsRequest } from '../redux/layoutsRedux';
import { getAssetsRequest } from '../redux/assetsRedux';
import { disableLiveRoutes } from '../redux/uiRedux';
import { actions as participantActions, updateParticipantsInBulkFailure, updateParticipantsInBulkSuccess } from '../redux/participantRedux';
import { ON_WALL_ROLES, OFF_WALL_ROLES } from '../utils/user-roles';

export function* fetchEvents(action) {
  try {
    const { studio } = action.payload;
    const response = yield call(get, `/event?studio=${studio}`);
    if (response.status === 200) {
      yield put(getEventsSuccess(response.data));
      const storedEvent = localStorage.getItem('selectedEvent');
      if (storedEvent) {
        const event = JSON.parse(storedEvent);
        yield put(setEvent(event));
        yield put(getLayoutsRequest(event._id));
        yield put(getAssetsRequest(event._id));
      }
    } else {
      yield put(disableLiveRoutes());
      yield put(
        getEventsFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(getEventsFailure(error));
  }
}

export function* fetchAllEvents() {
  try {
    const response = yield call(get, '/event/all');
    if (response.status === 200) {
      yield put(getAllEventsSuccess(response.data));
    } else {
      yield put(
        getAllEventsFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(getAllEventsFailure(error));
  }
}

export function* putEvent(action) {
  try {
    const { event, id } = action.payload;
    const response = yield call(update, `/event/${id}`, event);
    if (response.status === 200) {
      yield put(updateEventSuccess(response.data));
    } else {
      yield put(
        updateEventFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(updateEventFailure(error));
  }
}

export function* getEvent(action) {
  try {
    const { id } = action.payload;
    const response = yield call(get, `/event/${id}?getCounts=true`);
    if (response.status === 200) {
      yield put(updateEventSuccess(response.data));
    } else {
      yield put(
        updateEventFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(updateEventFailure(error));
  }
}

export function* postEvent(action) {
  try {
    const { event } = action.payload;
    const response = yield call(post, '/event/', event);
    if (response.status === 200) {
      yield put(createEventSuccess(response.data));
    } else {
      yield put(
        createEventFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(createEventFailure(error));
  }
}

export function* deleteEvent(action) {
  try {
    const { id } = action.payload;
    const response = yield call(del, `/event/${id}`);
    if (response.status === 200) {
      yield put(deleteEventSuccess(id));
    } else {
      yield put(
        deleteEventFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(deleteEventFailure(error.response.data));
  }
}

export function* duplicateEvent(action) {
  try {
    const { id } = action.payload;
    const response = yield call(get, `/event/duplicate/${id}`);
    if (response.status === 200) {
      yield put(duplicateEventSuccess(response.data));
    } else {
      yield put(
        duplicateEventFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(duplicateEventFailure(error));
  }
}

export function* getEventParticipants(action) {
  try {
    const { event, includeOffWallParticipants } = action.payload;
    let roles = ON_WALL_ROLES;
    if (includeOffWallParticipants) {
      roles = roles.concat(OFF_WALL_ROLES);
    }
    const response = yield call(post, `/eventParticipant/list`, { event, roles });
    if (response.status === 200) {
      yield put(getEventParticipantsSuccess(response.data));
    } else {
      yield put(
        getEventParticipantsFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(getEventParticipantsFailure(error));
  }
}

export function* updateParticipantStatus(action) {
  try {
    const { id, data } = action.payload;
    const response = yield call(update, `/eventParticipant/${id}`, data);
    if (response.status === 200) {
      yield put(updateParticipantStatusSuccess(response.data));
    } else {
      yield put(
        updateParticipantStatusFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(updateParticipantStatusFailure(error));
  }
}

export function* removeEventParticipants(action) {
  try {
    const { event } = action.payload;
    const response = yield call(post, `/eventParticipant/clear`, { event });
    if (response.status === 200) {
      yield put(removeEventParticipantsSuccess(response.data));
    } else {
      yield put(
        removeEventParticipantsFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(removeEventParticipantsFailure(error));
  }
}

export function* updateParticipantsInBulk(action) {
  try {
    const { data, event } = action.payload;
    const response = yield call(post, `/eventParticipant/updateParticipantsInBulk`, { data, event });
    if (response.status === 200) {
      yield put(updateParticipantsInBulkSuccess(response.data));
    } else {
      yield put(
        updateParticipantsInBulkFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(updateParticipantsInBulkFailure(error));
  }
}

export default function* () {
  yield all([
    takeLatest([actions.GET_EVENTS_REQUEST], fetchEvents),
    takeLatest([actions.GET_ALL_EVENTS_REQUEST], fetchAllEvents),
    takeLatest([actions.CREATE_EVENT_REQUEST], postEvent),
    takeLatest([actions.UPDATE_EVENT_REQUEST], putEvent),
    takeLatest([actions.DELETE_EVENT_REQUEST], deleteEvent),
    takeLatest([actions.DUPLICATE_EVENT_REQUEST], duplicateEvent),
    takeLatest([actions.GET_EVENT_PARTICIPANTS_REQUEST], getEventParticipants),
    takeLatest([actions.UPDATE_PARTICIPANT_STATUS_REQUEST], updateParticipantStatus),
    takeLatest([actions.REMOVE_EVENT_PARTICIPANTS_REQUEST], removeEventParticipants),
    takeLatest([participantActions.UPDATE_PARTICIPANTS_IN_BULK_REQUEST], updateParticipantsInBulk),
    takeLatest([actions.GET_EVENT_REQUEST], getEvent),
  ]);
}
