import React from 'react';
import swal from 'sweetalert';
import List from '../../common/List';
import PollsListItem from './PollsListItem';
import { post, del } from '../../services/api';
import addIcon from '../../assets/icons/add.svg';
import copyIcon from '../../assets/icons/copy_green.svg';

export default function PollsList({ polls, event, onSelectPoll, onCopyPolls, selected, onAddPoll, onDeletePoll }) {
  const _onAddPoll = async () => {
    try {
      const name = await swal({
        title: 'Add Poll',
        text: 'Enter the name of the poll to add',
        content: 'input',
        buttons: true,
      });
      if (name) {
        const { data } = await post('/poll', { event: event._id, name });
        onAddPoll && onAddPoll(data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const _onCopyPolls = async () => {
    try {
      onCopyPolls();
    } catch (err) {
      console.error(err);
    }
  };

  const _onSelectPoll = (pollData) => {
    onSelectPoll && onSelectPoll(pollData);
  };

  const _onDeletePoll = async (pollData) => {
    try {
      const confirmed = await swal({
        title: 'Delete Poll',
        text: 'Are you sure you want to delete this Poll?',
        buttons: true,
        dangerMode: true,
      });
      if (confirmed) {
        await del(`/poll/${pollData._id}`);
        onDeletePoll && onDeletePoll(pollData);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className='polls-list'>
      <List
        data={polls}
        renderItem={(item) => <PollsListItem data={item} onSelect={_onSelectPoll} onDelete={_onDeletePoll} selected={selected} />}
        keyExtractor={(item) => `${item._id}`}
        containerStyle={{ height: 'calc(100% - 60px)', position: 'relative' }}
      />
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <div className='add-poll' onClick={_onAddPoll}>
          <img src={addIcon} alt='add' style={{ marginLeft: 15 }} />
          Add Poll
        </div>
        <div className='add-poll' onClick={_onCopyPolls}>
          <img src={copyIcon} alt='add' style={{ marginLeft: 15 }} />
          Copy Polls
        </div>
      </div>
    </div>
  );
}
