import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../css/RadioButtons.css';

class RadioButtons extends Component {
  static defaultProps = {
    label: '',
    value: undefined,
  };

  state = {
    selectedOption: null,
  };

  componentDidMount() {
    const { value } = this.props;
    this.setState({ selectedOption: value });
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      this.setState({ selectedOption: value });
    }
  }

  _onChangeHandler = (event) => {
    const { onChange } = this.props;
    const { value } = event.target;
    this.setState({
      selectedOption: value,
    });
    onChange(value);
  };

  _isSelected = (value) => {
    const { selectedOption } = this.state;
    if (typeof value === 'number') {
      return parseInt(selectedOption) === value;
    } else {
      return selectedOption === value;
    }
  };

  render() {
    const { label, options, inline, small } = this.props;
    const { selectedOption } = this.state;

    const inputs = options.map((o, i) => (
      <label key={i} htmlFor={`${o.label}-radio-btn`}>
        <input id={`${o.label}-radio-btn`} type='radio' value={o.value} checked={this._isSelected(o.value)} onChange={this._onChangeHandler} />
        <span className={small ? 'radio-label small' : 'radio-label'}>{o.label}</span>
      </label>
    ));

    if (inline) {
      return <div className={small ? 'inline-radio-buttons small' : 'inline-radio-buttons'}>{inputs}</div>;
    }

    return (
      <div className='form-group'>
        {label && <div className='form-label'>{label}</div>}
        <div className='form-control'>{inputs}</div>
      </div>
    );
  }
}

export default RadioButtons;
