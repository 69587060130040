import { all, takeLatest, call, put } from 'redux-saga/effects';

import { get, del, put as update, post } from '../services/api';
import {
  actions,
  getAssetsSuccess,
  getAssetsFailure,
  deleteAssetSuccess,
  deleteAssetFailure,
  updateAssetSuccess,
  updateAssetFailure,
  createAssetSuccess,
  createAssetFailure,
} from '../redux/assetsRedux';

export function* fetchEventAssets(action) {
  try {
    const { eventId } = action.payload;
    const response = yield call(get, `/asset?event=${eventId}`);
    if (response.status === 200) {
      yield put(getAssetsSuccess(response.data));
    } else {
      yield put(
        getAssetsFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(getAssetsFailure(error));
  }
}

export function* deleteAsset(action) {
  try {
    const { id } = action.payload;
    const response = yield call(del, `/asset/${id}`);
    if (response.status === 200) {
      yield put(deleteAssetSuccess(id));
    } else {
      yield put(
        deleteAssetFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(deleteAssetFailure(error));
  }
}

export function* putAsset(action) {
  try {
    const { asset, id } = action.payload;
    const response = yield call(update, `/asset/${id}`, asset);
    if (response.status === 200) {
      yield put(updateAssetSuccess(response.data));
    } else {
      yield put(
        updateAssetFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(updateAssetFailure(error));
  }
}

export function* postAssets(action) {
  try {
    const { event, assets } = action.payload;
    for (const i in assets) {
      const a = assets[i];
      const payload = {
        name: a.fileName,
        type: a.fileType === 'mp4' ? 'video' : 'image',
        fileUrl: a.url,
        previewUrl: a.url,
        fileKey: a.fileKey,
        event,
        metadata: a.metadata,
      };
      const response = yield call(post, '/asset/', payload);
      if (response.status === 200) {
        yield put(createAssetSuccess(response.data));
      } else {
        yield put(
          createAssetFailure({
            status: response.status,
            message: response.data,
          }),
        );
      }
    }
  } catch (error) {
    yield put(createAssetFailure(error));
  }
}

export default function* () {
  yield all([
    takeLatest([actions.GET_ASSETS_REQUEST], fetchEventAssets),
    takeLatest([actions.CREATE_ASSET_REQUEST], postAssets),
    takeLatest([actions.DELETE_ASSET_REQUEST], deleteAsset),
    takeLatest([actions.UPDATE_ASSET_REQUEST], putAsset),
  ]);
}
