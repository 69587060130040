import React from 'react';
import upIcon from '../../assets/icons/up.svg';
import downIcon from '../../assets/icons/down.svg';

export default function Widget({ title, percentage, total, upload, download, type, style }) {
  return (
    <div className='widget' style={style}>
      <div className='title'>{title}</div>
      <div className='control'>
        {type === 'progress' && (
          <div className='progress-container'>
            <div className='labels'>
              <div className='percentage'>{`${percentage || 0}%`}</div>
              <div className='unit'>{total}</div>
            </div>
            <div className='progress-bar'>
              <div className='progress' style={{ left: `${percentage || 0}%` }} />
            </div>
          </div>
        )}
        {type === 'network' && (
          <div className='network-container'>
            <div className='icon-label'>
              <img src={upIcon} alt='out' />
              <span>{upload}</span>
            </div>
            <div className='icon-label'>
              <img src={downIcon} alt='in' />
              <span>{download}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
