import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import swal from 'sweetalert';
import Switch from '../../common/Switch';
import {
  startRequest,
  stopRequest,
  autoAssignRequest,
  autoAssignFailure,
  autoAssignSuccess,
  getBreakoutRoomConfigRequest,
  updateBreakoutRoomConfigRequest,
  addRoomsRequest,
  setEventParticipants,
  stopSuccess,
  setHostFailure,
  setHostSuccess,
} from '../../redux/breakoutRoomsRedux';
import { getEventParticipantsRequest, getEventParticipantsSuccess } from '../../redux/eventsRedux';
import Button from '../../common/Button';
import Select from '../../common/Select';
import SocketClient from '../../utils/socket-client';
import { post } from '../../services/api';
import RoomsGrid from './RoomsGrid';
import ParticipantList from './ParticipantList';
import { ON_WALL_ROLES } from '../../utils/user-roles';

const { REACT_APP_JANUS_CLUSTER_COUNT } = process.env;

class BreakoutRooms extends PureComponent {
  _maxNumberOfRooms = REACT_APP_JANUS_CLUSTER_COUNT && REACT_APP_JANUS_CLUSTER_COUNT > 0 ? parseInt(REACT_APP_JANUS_CLUSTER_COUNT) : 10;
  _mounted = false;
  _timer = null;

  state = {
    roomsOpts: [],
    numberOfParticipantsListOptions: [],
    timerStr: '',
    fetching: false,
    assignRandomHosts: false,
  };

  async componentDidMount() {
    this._mounted = true;
    const {
      activeStudio,
      activeEvent,
      getBreakoutRoomConfig,
      startTime,
      participantsList,
      setEventParticipants,
      stopSuccess,
      getEventParticipants,
      getEventParticipantsSuccess,
    } = this.props;
    if (activeStudio && activeEvent) {
      this._setRoomOptions();
      getEventParticipants(activeEvent._id);
      getBreakoutRoomConfig(activeEvent._id);
      SocketClient.joinRoom(`${activeStudio._id}:${activeEvent._id}:participant-list`);
      SocketClient.on('participant-list-update', getEventParticipantsSuccess);

      this._onAdminAppSync = (data) => {
        if (data.type === 'stop') {
          stopSuccess();
        }
        getBreakoutRoomConfig(activeEvent._id);
      };
      SocketClient.on('admin-app-sync', this._onAdminAppSync);

      if (participantsList.length !== 0) {
        setEventParticipants(participantsList);
      }
      if (startTime) {
        clearInterval(this._timer);
        this._timer = setInterval(this._startTimer, 1000);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { participantsList, setEventParticipants, startTime, started } = this.props;

    if (prevProps.startTime !== startTime && startTime) {
      clearInterval(this._timer);
      this._timer = setInterval(this._startTimer, 1000);
    }

    if (prevProps.participantsList.length !== participantsList.length) {
      setEventParticipants(participantsList);
    }

    if (prevProps.started !== started) {
      if (started === true) {
        SocketClient.emitAdminAppSync({
          type: 'start',
        });
      } else if (started === false) {
        SocketClient.emitAdminAppSync({
          type: 'stop',
        });
      }
    }
  }

  componentWillUnmount() {
    const { activeStudio, activeEvent } = this.props;
    this._mounted = false;
    clearInterval(this._timer);
    if (activeStudio && activeEvent) {
      SocketClient.leaveRoom(`${activeStudio._id}:${activeEvent._id}:participant-list`);
    }
    SocketClient.removeAllListeners();
    SocketClient.off('admin-app-sync', this._onAdminAppSync);
  }

  _startTimer = () => {
    if (this._mounted) {
      const { startTime } = this.props;
      const now = new Date().getTime();
      const start = new Date(startTime).getTime();
      let diff = Math.round((now - start) / 1000);
      const h = Math.floor(diff / (60 * 60));
      diff -= h * 60 * 60;
      const m = Math.floor(diff / 60);
      diff -= m * 60;
      const s = diff;
      this.setState({
        timerStr: `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}:${s < 10 ? `0${s}` : s}`,
      });
    }
  };

  _setRoomOptions = () => {
    const roomsOpts = [];
    for (let i = 0; i < this._maxNumberOfRooms; i += 1) {
      roomsOpts.push({
        value: i + 1,
        label: i + 1,
      });
    }
    const numberOfParticipantsListOptions = [
      { value: 10, label: 10 },
      { value: 17, label: 17 },
      { value: 26, label: 26 },
    ];
    this.setState({ roomsOpts, numberOfParticipantsListOptions });
  };

  _onNumRoomsSelected = (option) => {
    const { config } = this.props;
    this.props.addRooms(config._id, option.value, this._maxNumberOfRooms);
    SocketClient.emitAdminAppSync({
      type: 'addRooms',
    });
  };

  _onNumParticipantsSelected = (option) => {
    const { activeEvent } = this.props;
    this.props.updateBreakoutRoomConfig(activeEvent._id, { numberOfParticipantsPerRoom: option.value });
    SocketClient.emitAdminAppSync({
      type: 'setNumberOfParticipantsPerRoom',
    });
  };

  _start = async () => {
    const { config, start, rooms, roomList, eventParticipants } = this.props;
    let validBreakoutRooms = true;
    let errorMessage;

    roomList
      .map((roomId) => rooms[roomId])
      .forEach((room) => {
        if (room.index <= this._maxNumberOfRooms) {
          if (room.participants.length === 0) {
            validBreakoutRooms = false;
            errorMessage = 'Every room must have at least 1 participant.';
          } else if (!room.host || room.host === null) {
            validBreakoutRooms = false;
            errorMessage = 'Set a host for every room before start.';
          } else if (!eventParticipants[room.host] || (eventParticipants[room.host] && eventParticipants[room.host].status === 'NOT_CONNECTED')) {
            validBreakoutRooms = false;
            errorMessage = 'All room hosts must connected before starting the breakout rooms';
          }
        }
      });

    if (validBreakoutRooms) {
      start(config._id);
    } else {
      await swal({
        title: 'Error',
        text: errorMessage,
        button: {
          text: 'OK',
          value: true,
          visible: true,
          className: '',
          closeModal: true,
        },
      });
    }
  };

  _stop = () => {
    const { config, stop } = this.props;
    stop(config._id);
    clearInterval(this._timer);
    this.setState({
      timerStr: '',
    });
  };

  _autoAssign = async () => {
    this.setState({ fetching: true });

    const { config, rooms, participantsList, participants, roomList, numberOfParticipantsPerRoom } = this.props;
    const unassignedParticipants = participantsList.map((i) => i.participant).filter((p) => !participants[p._id]);
    const roomParticipantsMap = {};

    const availableRooms = roomList
      .filter((id) => {
        const r = rooms[id];
        return r.index <= this._maxNumberOfRooms && r.participants.length < numberOfParticipantsPerRoom;
      })
      .map((roomId) => {
        roomParticipantsMap[roomId] = [];
        return { id: rooms[roomId]._id, participants: rooms[roomId].participants.length };
      });

    let roomIndex = 0;
    for (const participant of unassignedParticipants) {
      if (roomIndex === availableRooms.length) {
        roomIndex = 0;
      }
      if (availableRooms[roomIndex]) {
        const roomId = availableRooms[roomIndex].id;
        if (!roomParticipantsMap[roomId].includes(participant._id) && availableRooms[roomIndex].participants < numberOfParticipantsPerRoom) {
          roomParticipantsMap[roomId].push(participant._id);
          rooms[roomId].participants.push(participant._id);
          availableRooms[roomIndex].participants += 1;
        }
        roomIndex += 1;
      } else {
        roomIndex += 1;
      }
    }

    await this._autoAssignRequest(config._id, roomParticipantsMap);
    SocketClient.emitAdminAppSync({
      type: 'autoAssign',
    });

    if (this.state.assignRandomHosts === true) {
      for (const [key, value] of Object.entries(rooms)) {
        if (!value.host && value.participants.length > 0) {
          const r = Math.floor(Math.random() * value.participants.length);
          await this._setHostRequest(value.participants[r], key);
          SocketClient.emitAdminAppSync({
            type: 'setHost',
          });
        }
      }
    }

    this.setState({ fetching: false });
  };

  _setHostRequest = async (participant, room) => {
    try {
      const response = await post(`/breakoutRoom/${room}/setHost`, { uuid: participant });
      if (response.status === 200) {
        this.props.setHostSuccess({ participant, room });
      } else {
        this.props.setHostFailure({
          status: response.status,
          message: response.data,
        });
      }
    } catch (error) {
      console.log(error);
      this.props.setHostFailure(error);
    }
  };

  _autoAssignRequest = async (config, rooms) => {
    try {
      const response = await post(`/breakoutRoom/autoAssign`, { config, rooms });
      if (response.status === 200) {
        this.props.autoAssignSuccess({ rooms });
      } else {
        this.props.autoAssignFailure({
          status: response.status,
          message: response.data,
        });
      }
    } catch (error) {
      console.log(error);
      this.props.autoAssignFailure(error);
    }
  };

  render() {
    const { routesEnabled, liveRoutesEnabled, roomList, startTime, numberOfRooms, participantsList, config, numberOfParticipantsPerRoom } = this.props;
    if (!routesEnabled) return <Redirect to='/app/events' />;
    if (!liveRoutesEnabled) return <Redirect to='/app/goLive' />;

    const { roomsOpts, timerStr, fetching, numberOfParticipantsListOptions } = this.state;

    return (
      <div className='breakout-rooms'>
        <ParticipantList data={participantsList} maxRooms={this._maxNumberOfRooms} />
        <div className='content'>
          <div className='configuration'>
            <div className='actions'>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 10 }}>
                <div className='num-rooms-select'>
                  <div className='label'># of Rooms</div>
                  <Select
                    list={roomsOpts}
                    listKey='value'
                    listLabel='label'
                    onChange={this._onNumRoomsSelected}
                    selected={numberOfRooms}
                    currentOption={numberOfRooms}
                    containerStyle={{ width: 80 }}
                    disabled={startTime || !config}
                  />
                </div>
                <div className='num-rooms-select'>
                  <div className='label'>Max per Room</div>
                  <Select
                    list={numberOfParticipantsListOptions}
                    listKey='value'
                    listLabel='label'
                    onChange={this._onNumParticipantsSelected}
                    selected={numberOfParticipantsPerRoom}
                    currentOption={numberOfParticipantsPerRoom}
                    containerStyle={{ width: 80 }}
                    disabled={startTime || !config}
                  />
                </div>

                <Switch
                  label='Assign Random Hosts'
                  onChange={() => this.setState({ assignRandomHosts: !this.state.assignRandomHosts })}
                  checked={this.state.assignRandomHosts}
                  height={16}
                  width={34}
                  containerStyle={{ margin: 0, marginRight: 10, marginLeft: 10 }}
                />
                <Button type='secondary' text='Auto-Assign' onClick={this._autoAssign} containerStyle={{ margin: 0 }} disabled={startTime || fetching} />
              </div>
              {startTime ? (
                <div className='timer-wrapper'>
                  <div className='timer'>{`Time: ${timerStr || '00:00:00'}`}</div>
                  <Button text='Stop' type='danger' onClick={this._stop} containerStyle={{ margin: 0 }} />
                </div>
              ) : (
                <Button text='Start' onClick={this._start} containerStyle={{ margin: 0 }} disabled={roomList.length === 0} />
              )}
            </div>
          </div>
          <RoomsGrid rooms={roomList} roomSize={numberOfParticipantsPerRoom} maxRooms={this._maxNumberOfRooms} startTime={startTime} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { participants } = state.events;
  let participantsList = [];
  if (participants && participants.length) {
    participantsList = participants.filter((p) => ON_WALL_ROLES.includes(p.role) && ['IN_STUDIO_QUEUE', 'ON_WALL', 'ON_AIR'].includes(p.status));
  }
  return {
    config: state.breakoutRooms.config,
    started: state.breakoutRooms.started,
    startTime: state.breakoutRooms.startTime,
    numberOfRooms: state.breakoutRooms.numberOfRooms,
    numberOfParticipantsPerRoom: state.breakoutRooms.numberOfParticipantsPerRoom,
    roomList: state.breakoutRooms.roomList,
    rooms: state.breakoutRooms.rooms,
    participants: state.breakoutRooms.participants,
    eventParticipants: state.breakoutRooms.eventParticipants,
    activeStudio: state.studio.active,
    activeEvent: state.events.active,
    routesEnabled: state.ui.routesEnabled,
    liveRoutesEnabled: state.ui.liveRoutesEnabled,
    participantsList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addRooms: (configId, numRooms, maxRooms) => dispatch(addRoomsRequest(configId, numRooms, maxRooms)),
  start: (config) => dispatch(startRequest(config)),
  stop: (config) => dispatch(stopRequest(config)),
  autoAssign: (config, rooms) => dispatch(autoAssignRequest(config, rooms)),
  autoAssignSuccess: (data) => dispatch(autoAssignSuccess(data)),
  autoAssignFailure: (error) => dispatch(autoAssignFailure(error)),
  setEventParticipants: (participants) => dispatch(setEventParticipants(participants)),
  getEventParticipants: (event) => dispatch(getEventParticipantsRequest(event)),
  getEventParticipantsSuccess: (data) => dispatch(getEventParticipantsSuccess(data)),
  getBreakoutRoomConfig: (event) => dispatch(getBreakoutRoomConfigRequest(event)),
  updateBreakoutRoomConfig: (event, config) => dispatch(updateBreakoutRoomConfigRequest(event, config)),
  stopSuccess: () => dispatch(stopSuccess()),
  setHostSuccess: (data) => dispatch(setHostSuccess(data)),
  setHostFailure: (error) => dispatch(setHostFailure(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BreakoutRooms);
