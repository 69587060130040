import { createActions, handleActions } from 'redux-actions';
import _ from 'lodash';

const initialState = {
  list: [],
  selectedUser: null,
  fetching: false,
  error: null,
  userCreated: null,
  userUpdated: null,
};

export const actions = {
  GET_USERS_REQUEST: 'GET_USERS_REQUEST',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_USERS_FAILURE: 'GET_USERS_FAILURE',
  CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',
  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',
  DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',
  SELECT_USER: 'SELECT_USER',
};

export const {
  getUsersRequest,
  getUsersSuccess,
  getUsersFailure,
  createUserRequest,
  createUserSuccess,
  createUserFailure,
  updateUserRequest,
  updateUserSuccess,
  updateUserFailure,
  deleteUserRequest,
  deleteUserSuccess,
  deleteUserFailure,
  selectUser,
} = createActions({
  [actions.GET_USERS_REQUEST]: () => ({}),
  [actions.GET_USERS_SUCCESS]: (data) => ({ data }),
  [actions.GET_USERS_FAILURE]: (error) => ({ error }),
  [actions.CREATE_USER_REQUEST]: (user) => ({ user }),
  [actions.CREATE_USER_SUCCESS]: (data) => ({ data }),
  [actions.CREATE_USER_FAILURE]: (error) => ({ error }),
  [actions.UPDATE_USER_REQUEST]: (id, user) => ({ id, user }),
  [actions.UPDATE_USER_SUCCESS]: (data) => ({ data }),
  [actions.UPDATE_USER_FAILURE]: (error) => ({ error }),
  [actions.DELETE_USER_REQUEST]: (id) => ({ id }),
  [actions.DELETE_USER_SUCCESS]: (data) => ({ data }),
  [actions.DELETE_USER_FAILURE]: (error) => ({ error }),
  [actions.SELECT_USER]: (user) => ({ user }),
});

const reducer = handleActions(
  {
    [actions.GET_USERS_REQUEST]: (state) => ({
      ...state,
      fetching: true,
      error: null,
    }),
    [actions.GET_USERS_SUCCESS]: (state, action) => {
      let { selectedUser } = state;
      if (selectedUser) {
        selectedUser = action.payload.data.find((u) => u._id === selectedUser._id);
      }
      if (!selectedUser) {
        const [users] = action.payload.data;
        selectedUser = users;
      }
      const list = _.orderBy(action.payload.data, [(u) => u.username.toLowerCase()], ['asc']);
      return {
        ...state,
        selectedUser,
        list,
        fetching: false,
        error: null,
      };
    },
    [actions.GET_USERS_FAILURE]: (state, action) => ({
      ...state,
      fetching: false,
      error: action.payload.error,
    }),
    [actions.CREATE_USER_REQUEST]: (state) => ({
      ...state,
      fetching: true,
      error: null,
      userCreated: null,
    }),
    [actions.CREATE_USER_SUCCESS]: (state, action) => {
      const list = _.orderBy([...state.list, action.payload.data.user], [(u) => u.username.toLowerCase()], ['asc']);
      return {
        ...state,
        list,
        fetching: false,
        error: null,
        userCreated: true,
      };
    },
    [actions.CREATE_USER_FAILURE]: (state, action) => ({
      ...state,
      fetching: false,
      error: action.payload.error,
      userCreated: false,
    }),
    [actions.DELETE_USER_REQUEST]: (state) => ({
      ...state,
      fetching: true,
      error: null,
    }),
    [actions.DELETE_USER_SUCCESS]: (state, action) => {
      const deletedId = action.payload.data;
      const index = state.list.findIndex((u) => u._id === deletedId);
      if (index !== -1) {
        state.list.splice(index, 1);
      }
      return {
        ...state,
        list: [...state.list],
        fetching: false,
        selectedUser: null,
        error: null,
      };
    },
    [actions.DELETE_USER_FAILURE]: (state, action) => ({
      ...state,
      fetching: false,
      error: action.payload.error,
    }),
    [actions.UPDATE_USER_REQUEST]: (state) => ({
      ...state,
      fetching: true,
      error: null,
      userUpdated: null,
    }),
    [actions.UPDATE_USER_SUCCESS]: (state, action) => {
      const { _id } = action.payload.data;
      const usrIndex = state.list.map((l) => l._id).indexOf(_id);
      state.list[usrIndex] = action.payload.data;
      const selectedUser = action.payload.data;
      const list = _.orderBy(state.list, [(u) => u.username.toLowerCase()], ['asc']);
      return {
        ...state,
        list,
        fetching: false,
        error: null,
        userUpdated: true,
        selectedUser,
      };
    },
    [actions.UPDATE_USER_FAILURE]: (state, action) => ({
      ...state,
      fetching: false,
      error: action.payload.error,
      userUpdated: false,
    }),
    [actions.SELECT_USER]: (state, action) => ({
      ...state,
      selectedUser: action.payload.user
    }),
  },
  initialState,
);

export default reducer;
