import io from 'socket.io-client';
import { store } from '../redux';
import Deferred from './deferred';

const { REACT_APP_ADMIN_APP_AUTH_KEY } = process.env;

class SocketClient {
  static _pollErrorCount = 0;
  static listeners = {};
  static joinedRooms = [];
  static connected = new Deferred();

  static connect(url) {
    SocketClient.socket = io.connect(url, { auth: { type: 'ADMIN_APP', authKey: REACT_APP_ADMIN_APP_AUTH_KEY } });

    SocketClient.socket.once('connect', () => {
      SocketClient.connected.resolve();
    });

    SocketClient.socket.once('disconnect', () => {
      setTimeout(() => {
        if (SocketClient.socket.connected === false) {
          SocketClient.socket.removeAllListeners();
          SocketClient.connected = new Deferred();
          SocketClient.connect(url);
        } else {
          SocketClient.socket.removeAllListeners();
          SocketClient.joinAllRooms();
        }
      }, 3000);
    });

    SocketClient.socket.on('error', (error) => {
      console.error('socket on error', error);
    });

    SocketClient.socket.on('connect_error', (error) => {
      console.error('socket on connect_error', error);
    });

    SocketClient.socket.on('connect_timeout', (error) => {
      console.error('socket on connect_timeout', error);
    });

    SocketClient.joinAllRooms();
  }

  static joinAllRooms() {
    SocketClient.joinedRooms.forEach((roomName) => SocketClient.socket.emit('join-room', roomName));
    for (const [type, callbacks] of Object.entries(SocketClient.listeners)) {
      callbacks.forEach((cb) => SocketClient.socket.on(type, cb));
    }
  }

  static joinRoom(roomName) {
    if (SocketClient.socket) {
      SocketClient.socket.emit('join-room', roomName);
      SocketClient.joinedRooms.push(roomName);
    }
  }

  static leaveRoom(roomName) {
    if (SocketClient.socket) {
      SocketClient.socket.emit('leave-room', roomName);
      const roomIndex = SocketClient.joinedRooms.indexOf(roomName);
      if (roomIndex !== -1) {
        SocketClient.joinedRooms.splice(roomIndex, 1);
      }
    }
  }

  static disconnect() {
    if (SocketClient.socket) {
      SocketClient.socket.removeAllListeners();
      SocketClient.socket.disconnect();
    }
  }

  static emit(type, data) {
    if (SocketClient.socket) {
      SocketClient.socket.emit(type, data);
    }
  }

  static emitAdminAppSync(data) {
    const { studio, events } = store.getState();
    if (studio.active && events.active) {
      const roomName = `${studio.active._id}:${events.active._id}:admin-app-sync`;
      SocketClient.socket.emit('admin-app-sync', {
        roomName,
        ...data,
      });
    }
  }

  static on(event, callback) {
    if (SocketClient.socket) {
      SocketClient.socket.on(event, callback);
      if (!SocketClient.listeners[event]) {
        SocketClient.listeners[event] = [];
      }
      SocketClient.listeners[event].push(callback);
    }
  }

  static off(event, callback) {
    if (SocketClient.socket) {
      SocketClient.socket.off(event, callback);
      if (SocketClient.listeners[event]) {
        const index = SocketClient.listeners[event].indexOf(callback);
        if (index !== -1) {
          SocketClient.listeners[event].splice(index, 1);
        }
      }
    }
  }

  static removeAllListeners() {
    if (SocketClient.socket) {
      Object.keys(SocketClient.socket._callbacks).forEach((key) => {
        if (!['$disconnect', '$admin-app-sync', '$direct-connect-available'].includes(key)) {
          const listener = key.replace('$', '');
          delete SocketClient.listeners[listener];
          SocketClient.socket.removeListener(listener);
        }
      });
    }
  }
}

export default SocketClient;
