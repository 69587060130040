import React from 'react';
import downIcon from '../../assets/icons/arrow_down.svg';
import upIcon from '../../assets/icons/arrow_up.svg';

const SelectList = props => {
  const {
    handleOpenOptions,
    openOptions,
    currentOption,
    selectRef,
    small
  } = props;

  const arrow = openOptions ? upIcon : downIcon;
  const customStyle = small ? { fontSize: 13 } : {};

  return (
    <div
      // tabIndex="0"
      role="button"
      onClick={handleOpenOptions}
      onKeyDown={handleOpenOptions}
      aria-pressed={openOptions}
      aria-expanded={openOptions}
      className="select-list"
      ref={selectRef}
      style={customStyle}
    >
      <div className="selected">{currentOption || 'Select...'}</div>
      <img src={arrow} alt="arrow" />
    </div>
  );
};

export default SelectList;
