import React from 'react';
import Input from './Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';

const SearchBox = ({ value, onChange, onClear, showIcon, containerStyle, inputStyle, placeholder, width }) => {
  return (
    <div className='search-box' style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width }}>
      <Input
        name=''
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        type='text'
        containerStyle={{ width: '100%', marginRight: 0, ...containerStyle }}
        inputStyle={inputStyle}
      />
      {showIcon && (
        <FontAwesomeIcon icon={value.length > 0 ? faTimes : faSearch} color='#939394' style={{ cursor: 'pointer', fontSize: 20 }} onClick={onClear} />
      )}
    </div>
  );
};

export default SearchBox;
