import React from 'react';

export default function VideoOverlay(props) {
  // Percentages
  const width = props.innerWidth || 65;
  const height = props.innerHeight || 78;

  const paddingLeft = props.paddingLeft || (100 - width) / 2;
  const paddingRight = props.paddingRight || (100 - width) / 2;
  const paddingTop = props.paddingTop || (100 - height) / 2;
  const paddingBottom = props.paddingBottom || (100 - height) / 2;

  const borderStyle = `${props.borderWidth || 3}px solid ${props.borderColor || '#74bc1f'}`;

  const styles = {
    leftMarker: {
      position: 'absolute',
      top: `${paddingTop}%`,
      left: `${paddingLeft}%`,
      bottom: `${paddingBottom}%`,
      width: 10,
      borderTop: borderStyle,
      borderLeft: borderStyle,
      borderBottom: borderStyle,
    },
    rightMarker: {
      position: 'absolute',
      right: `${paddingRight}%`,
      top: `${paddingTop}%`,
      bottom: `${paddingBottom}%`,
      borderTop: borderStyle,
      borderRight: borderStyle,
      borderBottom: borderStyle,
      width: 10,
    },
  };
  return (
    <>
      <div style={styles.leftMarker} />
      <div style={styles.rightMarker} />
    </>
  );
}
