import React, { PureComponent } from 'react';
import Checkbox from '../../common/Checkbox';
import PropTypes from 'prop-types';

class CheckboxListItem extends PureComponent {
  static propTypes = {
    pollName: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  state = {
    selected: false,
  };

  _handleClick = (value) => {
    const { onChange, item } = this.props;
    this.setState({ selected: value });
    onChange(item, value);
  };

  render() {
    const { pollName } = this.props;
    const { selected } = this.state;
    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
        <Checkbox label='' value={selected} onChange={this._handleClick} />
        <div className='polls-list-item'>
          <div className='poll-name'>{pollName}</div>
        </div>
        {/*<div className={selected && selected._id === data._id ? 'polls-list-item selected' : 'polls-list-item'}*/}
        {/*     onClick={() => onSelect(data)}>*/}
        {/*  <div className='poll-name'>{data.name}</div>*/}
        {/*</div>*/}
      </div>
    );
  }
}

export default CheckboxListItem;
