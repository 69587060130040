/* eslint-disable jsx-a11y/media-has-caption */
import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Popover from 'react-popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faVolumeUp, faVolumeDown, faVolumeMute, faCamera } from '@fortawesome/free-solid-svg-icons';

import talkIcon from '../../assets/icons/talk.svg';
import infoIcon from '../../assets/images/navbar/helpDesk-white.svg';
import videoIcon from '../../assets/icons/video-white.svg';
import imgIcon from '../../assets/icons/image-white.svg';
import defaultLogo from '../../assets/images/default-event.png';
import Select from '../../common/Select';
import Button from '../../common/Button';
import RTCStream from '../../utils/rtc-stream';
import ImageCache from '../../utils/image-cache';
import { compressImage } from '../../utils/file-util';
import SocketClient from '../../utils/socket-client';
import { put, post } from '../../services/api';
import { selectParticipant as selectParticipantAction } from '../../redux/wallRedux';
import Property from '../participants/Property';
import WebRTCDetails from '../participants/WebRTCDetails';

class VideoStream extends Component {
  state = {
    resolutions: [],
    videoInputs: [],
    audioInputs: [],
    audioOutputs: [],
    selectedResolution: undefined,
    selectedVideoIn: undefined,
    selectedAudioIn: undefined,
    selectedAudioOut: undefined,
    fps: 0,
    resolution: null,
    volume: 1,
    showVolume: false,
    isTalking: false,
    isOnAir: false,
    showWebRTCDetails: false,
  };

  componentDidMount() {
    this._loadClient();
    this._checkClient();
    window.onbeforeunload = this.componentWillUnmount.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { client, onAirParticipants } = this.props;
    if (prevProps.client && client && prevProps.client._id !== client._id) {
      this._loadClient();
    }

    if (JSON.stringify(prevProps.onAirParticipants) !== JSON.stringify(onAirParticipants)) {
      this._checkClient();
    }

    const { selectedVideoIn, selectedResolution, selectedAudioIn, selectedAudioOut } = this.state;
    if (prevState.selectedVideoIn !== selectedVideoIn && selectedVideoIn) {
      this._updateParticipantDevices();
    }
    if (prevState.selectedResolution !== selectedResolution && selectedResolution) {
      this._updateParticipantDevices();
    }
    if (prevState.selectedAudioIn !== selectedAudioIn && selectedAudioIn) {
      this._updateParticipantDevices();
    }
    if (prevState.selectedAudioOut !== selectedAudioOut && selectedAudioOut) {
      this._updateParticipantDevices();
    }
  }

  componentWillUnmount() {
    this._closeClientStream();
    window.onbeforeunload = () => {};
  }

  _checkClient = () => {
    const { onAirParticipants, client } = this.props;
    if (client && onAirParticipants) {
      let isOnAir = false;
      Object.values(onAirParticipants).forEach((value) => {
        if (value === client.participant._id) {
          isOnAir = true;
        }
      });
      this.setState({ isOnAir });
    }
  };

  _loadClient = () => {
    const { client } = this.props;
    if (client) {
      const groupedDevices = {};
      const { selectedDevices, availableDevices, supportedResolutions } = client.participant;
      if (availableDevices) {
        availableDevices.forEach((device) => {
          if (!groupedDevices[device.kind]) {
            groupedDevices[device.kind] = [];
          }
          if (device.deviceId !== 'default') {
            groupedDevices[device.kind].push(device);
          }
        });
        let selectedResolution;
        let selectedVideoIn;
        let selectedAudioIn;
        let selectedAudioOut;

        if (groupedDevices.videoinput && selectedDevices.videoInput) {
          selectedVideoIn = groupedDevices.videoinput.find((d) => d.deviceId === selectedDevices.videoInput.deviceId);
          const resolution = `${selectedDevices.videoInput.width}x${selectedDevices.videoInput.height}`;
          selectedResolution = {
            value: resolution,
            label: resolution,
          };
        }
        if (groupedDevices.audioinput && selectedDevices.audioInput) {
          selectedAudioIn = groupedDevices.audioinput.find((d) => d.deviceId === selectedDevices.audioInput.deviceId);
        }
        if (groupedDevices.audiooutput && selectedDevices.audioOutput) {
          selectedAudioOut = groupedDevices.audiooutput.find((d) => d.deviceId === selectedDevices.audioOutput.deviceId);
        }

        this.setState({
          resolutions: supportedResolutions.map((r) => ({ value: r, label: r })) || [],
          videoInputs: groupedDevices.videoinput || [],
          audioInputs: groupedDevices.audioinput || [],
          audioOutputs: groupedDevices.audiooutput || [],
          selectedResolution,
          selectedVideoIn,
          selectedAudioIn,
          selectedAudioOut,
        });
      }
      this._getClientStream(client.participant._id);
    }
  };

  _updateParticipantDevices = async () => {
    try {
      const { client, activeEvent } = this.props;
      if (client) {
        const { participant } = client;
        const { selectedAudioIn, selectedVideoIn, selectedAudioOut, selectedResolution } = this.state;
        let { videoInput, audioInput, audioOutput } = participant.selectedDevices;

        if (selectedVideoIn) {
          if (!videoInput) {
            videoInput = {};
          }
          videoInput.deviceId = selectedVideoIn.deviceId;
          videoInput.label = selectedVideoIn.label;
        }

        if (selectedResolution) {
          const parts = selectedResolution.value.split('x');
          const width = parseInt(parts[0], 10);
          const height = parseInt(parts[1], 10);
          videoInput.width = width;
          videoInput.height = height;
        }

        if (selectedAudioIn) {
          if (!audioInput) {
            audioInput = {};
          }
          audioInput.deviceId = selectedAudioIn.deviceId;
          audioInput.label = selectedAudioIn.label;
        }

        if (selectedAudioOut) {
          if (!audioOutput) {
            audioOutput = {};
          }
          audioOutput.deviceId = selectedAudioOut.deviceId;
          audioOutput.label = selectedAudioOut.label;
        }

        await put(`/participant/${participant._id}`, { event: activeEvent._id, selectedDevices: { videoInput, audioInput, audioOutput } });
      }
    } catch (error) {
      console.error(error);
    }
  };

  _getClientStream = async (uuid) => {
    try {
      this._uuid = uuid;
      this.roomName = `${uuid}-admin-recv`;
      SocketClient.emit('get-client-stream', this._uuid);
      this.rtc = await RTCStream.get(this.roomName, this._video, this._audio, true);

      const { activeStudio, activeEvent } = this.props;
      SocketClient.joinRoom(this._uuid);
      SocketClient.joinRoom(`${activeStudio._id}:${activeEvent._id}:stream-metadata`);

      SocketClient.on('stream-metadata', (data) => {
        if (data[uuid]) {
          const { fps, resolution } = data[uuid];
          this.setState({ fps, resolution });
        }
      });

      SocketClient.on('message', (payload) => {
        if (payload.type === 'update-video-input') {
          this.setState({ selectedVideoIn: payload.data });
        } else if (payload.type === 'update-resoluton') {
          this.setState({ selectedResolution: payload.data });
        } else if (payload.type === 'update-audio-input') {
          this.setState({ selectedAudioIn: payload.data });
        } else if (payload.type === 'update-audio-output') {
          this.setState({ selectedAudioOut: payload.data });
        } else if (payload.type === 'update-resolution-list') {
          const { resolutionsList, selectedResolution } = payload.data;
          this.setState({ resolutions: resolutionsList, selectedResolution });
        } else if (payload.type === 'update-media-devices') {
          const { audioInputs, audioOutputs, videoInputs, selectedAudioInput, selectedAudioOutput, selectedVideoInput } = payload.data;
          this.setState({
            audioInputs,
            audioOutputs,
            videoInputs,
            selectedVideoIn: selectedVideoInput,
            selectedAudioIn: selectedAudioInput,
            selectedAudioOut: selectedAudioOutput,
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  _closeClientStream = () => {
    const { activeStudio, activeEvent } = this.props;
    SocketClient.emit('close-client-stream', this._uuid);
    RTCStream.close(this);
    SocketClient.leaveRoom(this._uuid);
    SocketClient.leaveRoom(`${activeStudio._id}:${activeEvent._id}:stream-metadata`);
    if (SocketClient.socket) {
      SocketClient.socket.removeListener('message');
    }
  };

  _setVideoInput = (option) => {
    this.setState({ selectedVideoIn: option }, () => {
      const {
        client: { participant },
      } = this.props;
      SocketClient.emit('change-input', { uuid: participant._id, type: 'videoinput', data: option });
      this._updateParticipantDevices();
    });
  };

  _setResolution = (option) => {
    this.setState({ selectedResolution: option }, () => {
      const {
        client: { participant },
      } = this.props;
      const { selectedVideoIn } = this.state;
      SocketClient.emit('change-input', {
        uuid: participant._id,
        type: 'resolution',
        data: { resolution: option, deviceId: selectedVideoIn.value },
      });
      this._updateParticipantDevices();
    });
  };

  _setAudioInput = (option) => {
    this.setState({ selectedAudioIn: option }, () => {
      const {
        client: { participant },
      } = this.props;
      SocketClient.emit('change-input', {
        uuid: participant._id,
        type: 'audioinput',
        data: option,
      });
      this._updateParticipantDevices();
    });
  };

  _setAudioOutput = (option) => {
    this.setState({ selectedAudioOut: option }, () => {
      const {
        client: { participant },
      } = this.props;
      SocketClient.emit('change-input', {
        uuid: participant._id,
        type: 'audiooutput',
        data: option,
      });
      this._updateParticipantDevices();
    });
  };

  _toggleTalk = () => {
    const { isTalking: isTalkingCurrentValue } = this.state;
    this.setState({ isTalking: !isTalkingCurrentValue }, () => {
      const { isTalking } = this.state;
      const {
        wallSupportStaffUuid,
        client: { participant },
      } = this.props;
      if (isTalking) {
        SocketClient.emit('wall-support-staff-talking', {
          participantUuid: participant._id,
          wallSupportStaffUuid,
        });
      } else {
        SocketClient.emit('wall-support-staff-muted', {
          participantUuid: participant._id,
        });
      }
    });
  };

  _changeVolume = (event) => {
    const { target } = event;
    this.setState({ volume: target.value }, () => {
      const { volume } = this.state;
      this._audio.volume = volume;
    });
  };

  _toggleWebRTCDetails = () => {
    const { showWebRTCDetails } = this.state;
    this.setState({ showWebRTCDetails: !showWebRTCDetails });
  };

  _onResetPhoto = async () => {
    try {
      const { client, activeEvent } = this.props;
      const { participant } = client;

      let photoData;
      if (activeEvent.logo) {
        photoData = await ImageCache.get(activeEvent.logo);
      } else {
        photoData = defaultLogo;
      }

      const file = await new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
          const compressedFile = compressImage(img, img.width, img.height);
          resolve(compressedFile);
        };
        img.src = photoData;
      });

      const eventId = activeEvent._id;
      const uuid = participant._id;
      const extension = file.name.split('.').pop().toLowerCase();
      const fileName = `${eventId}-${uuid}-${Date.now()}.${extension}`;
      const fileKey = `participants/${fileName}`;
      const fileType = file.type;
      const response = await post('/participant/uploadPhoto', { fileName, fileType, fileKey, uuid, eventId });
      const returnData = response.data;
      const { signedRequest, url } = returnData;
      const options = {
        headers: {
          'Content-Type': fileType,
        },
        timeout: 0,
      };
      const axiosInstance = axios.create({
        headers: {
          'Content-Type': fileType,
        },
      });
      axiosInstance.defaults.timeout = 0;
      axiosInstance.interceptors.request.use((config) => {
        const newConfig = { ...config };
        delete newConfig.headers.Authorization;
        delete newConfig.headers['x-pando-studio-id'];
        delete newConfig.headers['x-pando-is-admin-app'];
        return newConfig;
      });
      await axiosInstance.put(signedRequest, file, options);

      SocketClient.emit('admin-action', {
        uuid,
        action: 'reset-photo',
        photoUrl: url,
      });
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const { client, selectParticipant, onUseVideoImageToggle, onSendToHelpDesk } = this.props;
    const { fps, resolution, volume, showVolume, isTalking, isOnAir, showWebRTCDetails } = this.state;
    let content = null;

    if (client) {
      const { resolutions, videoInputs, audioInputs, audioOutputs, selectedAudioIn, selectedAudioOut, selectedResolution, selectedVideoIn } = this.state;
      const { platform } = client.participant;

      const { osName = '', browserName = '', browserVersion = '', osVersion = '' } = platform || {};

      let volumeIcon = faVolumeMute;
      if (volume > 0.3) {
        volumeIcon = faVolumeUp;
      } else if (volume > 0) {
        volumeIcon = faVolumeDown;
      }

      const participantName = `${client.wallDisplayNum ? `${client.wallDisplayNum}. ` : ''}${client.participant.firstName || ''} ${
        client.participant.lastName || ''
      }`.trim();
      const participantLocation = client.participant.location || client.participant.city;

      content = (
        <>
          <div className='video-stream'>
            <div className='sidebar custom-scrollbar' style={{ width: 300, alignSelf: 'flex-start', margin: 6, overflowY: 'auto' }}>
              <div className='property-list' style={{ width: 280, height: 400, marginRight: 5, marginLeft: 0, textAlign: 'left' }}>
                <Property label='Public IP' value={client.publicIp} />
                <Property label='DNS Name' value={client.dnsName} />
                <Property label='Connect Count' value={client.connectCount} />
                <Property label='Disconnect Count' value={client.disconnectCount} />
                <Property
                  label='Bandwidth'
                  value={
                    client.systemCheck && client.systemCheck.bandwidth !== null && client.systemCheck.bandwidth >= 0
                      ? `${client.systemCheck.bandwidth} Mbps`
                      : '-'
                  }
                />
                <Property label='FPS' value={fps} />
                <Popover
                  isOpen={showWebRTCDetails}
                  body={<WebRTCDetails uploadData={client.uploadConnectionType} downloadData={client.downloadConnectionType} />}
                  onOuterAction={this._toggleWebRTCDetails}
                  tipSize={6}
                  place='right'
                  style={{ fill: '#1e272f', zIndex: 99999 }}
                >
                  <Property label='WebRTC Details' value={showWebRTCDetails ? 'Hide' : 'Show'} onClick={this._toggleWebRTCDetails} />
                </Popover>
              </div>
            </div>
            <div
              style={{ position: 'relative' }}
              onMouseEnter={() => this.setState({ showVolume: true })}
              onMouseLeave={() => this.setState({ showVolume: false })}
            >
              <video
                ref={(c) => {
                  this._video = c;
                }}
                style={{ width: 320, height: 180, background: 'black' }}
                autoPlay
                playsInline
              />
              <audio
                ref={(c) => {
                  this._audio = c;
                }}
                volume={volume}
                autoPlay
              />
              {isOnAir && <span className='status-label on-air'>ON AIR</span>}
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 17,
                  transform: 'rotate(-90deg)',
                  transformOrigin: 'right',
                  transition: 'opacity 0.4s ease-in',
                  opacity: showVolume ? 1 : 0,
                }}
              >
                <input
                  type='range'
                  name='volume'
                  value={volume}
                  onChange={this._changeVolume}
                  min={0}
                  max={1}
                  step={0.1}
                  style={{ background: 'transparent', marginRight: 11 }}
                />
                <FontAwesomeIcon
                  icon={volumeIcon}
                  color='#939394'
                  style={{ position: 'absolute', fontSize: 17, bottom: 14, left: -10, transform: 'rotate(90deg)', width: 20, height: 20 }}
                />
                <FontAwesomeIcon
                  icon={faTimes}
                  color='#939394'
                  style={{ position: 'absolute', cursor: 'pointer', fontSize: 18, top: 13, right: -14 }}
                  onClick={() => selectParticipant(null)}
                />
              </div>
              <div className='client-os-info'>
                <div style={{ textAlign: 'left' }}>
                  <div>{`${participantName}, (${participantLocation})`}</div>
                  <div>{resolution && `${resolution} (${fps} fps)`}</div>
                </div>
                <div style={{ textAlign: 'right' }}>
                  <div>{`${osName} ${osVersion}`}</div>
                  <div>{`${browserName} ${browserVersion}`}</div>
                </div>
              </div>
              <div
                className='actions'
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: '100%', marginTop: 12 }}
              >
                <Button
                  onClick={this._toggleTalk}
                  text=''
                  type={isTalking ? 'primary' : 'secondary'}
                  icon={talkIcon}
                  align='left'
                  s
                  tooltip='Talk'
                  containerStyle={styles.actionButton}
                />
                <Button
                  onClick={() => onUseVideoImageToggle(client)}
                  text=''
                  type='secondary'
                  icon={client.useImage ? videoIcon : imgIcon}
                  align='left'
                  tooltip={client.useImage ? 'Use Video' : 'Use Image'}
                  containerStyle={styles.actionButton}
                />
                <Button
                  onClick={() => this._onResetPhoto()}
                  text=''
                  type='secondary'
                  icon={faCamera}
                  iconType='FontAwesome'
                  iconStyle={{ fontSize: 20, position: 'relative', left: 3 }}
                  align='left'
                  tooltip='Reset Photo'
                  containerStyle={styles.actionButton}
                />
                <Button
                  onClick={onSendToHelpDesk}
                  text=''
                  type='secondary'
                  icon={infoIcon}
                  align='left'
                  tooltip='To Screening Queue'
                  containerStyle={styles.actionButton}
                />
              </div>
            </div>
            <div className='video-stream-opts' style={{ alignSelf: 'flex-start' }}>
              <div className='inline-select'>
                <div className='inline-select-label'>Camera</div>
                <Select
                  list={videoInputs}
                  onChange={this._setVideoInput}
                  listKey='deviceId'
                  listLabel='label'
                  selected={selectedVideoIn && selectedVideoIn.deviceId}
                  currentOption={selectedVideoIn && selectedVideoIn.label}
                  small
                  containerStyle={{ width: 200 }}
                />
              </div>
              <div className='inline-select'>
                <div className='inline-select-label'>Resolution</div>
                <Select
                  list={resolutions}
                  onChange={this._setResolution}
                  listKey='value'
                  listLabel='label'
                  selected={selectedResolution && selectedResolution.value}
                  currentOption={selectedResolution && selectedResolution.label}
                  small
                  containerStyle={{ width: 200 }}
                />
              </div>
              <div className='inline-select'>
                <div className='inline-select-label'>Mic</div>
                <Select
                  list={audioInputs}
                  onChange={this._setAudioInput}
                  listKey='deviceId'
                  listLabel='label'
                  selected={selectedAudioIn && selectedAudioIn.deviceId}
                  currentOption={selectedAudioIn && selectedAudioIn.label}
                  small
                  containerStyle={{ width: 200 }}
                />
              </div>
              <div className='inline-select'>
                <div className='inline-select-label'>Speaker</div>
                <Select
                  list={audioOutputs}
                  onChange={this._setAudioOutput}
                  listKey='deviceId'
                  listLabel='label'
                  selected={selectedAudioOut && selectedAudioOut.deviceId}
                  currentOption={selectedAudioOut && selectedAudioOut.label}
                  small
                  containerStyle={{ width: 200 }}
                  disabled={browserName === 'Safari'}
                />
              </div>
            </div>
          </div>
        </>
      );
    }

    return <div className='videostream-container'>{content}</div>;
  }
}

const styles = {
  actionButton: {
    margin: '0 5px',
    padding: 5,
    width: 36,
    height: 36,
  },
};

const mapStateToProps = (state) => ({
  activeEvent: state.events.active,
  activeStudio: state.studio.active,
});

const mapDispatchToProps = (dispatch) => ({
  selectParticipant: (participant) => dispatch(selectParticipantAction(participant)),
});

VideoStream.propTypes = {
  client: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    participant: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onUseVideoImageToggle: PropTypes.func.isRequired,
  onSendToHelpDesk: PropTypes.func.isRequired,
  wallSupportStaffUuid: PropTypes.string.isRequired,
  onAirParticipants: PropTypes.shape({}).isRequired,
  activeEvent: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }).isRequired,
  activeStudio: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }).isRequired,
  selectParticipant: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoStream);
