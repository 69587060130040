import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Tabs from '../../common/Tabs';
import SocketClient from '../../utils/socket-client';
import { ON_WALL_ROLES } from '../../utils/user-roles';
import { post } from '../../services/api';
import OnAirStream from './OnAirStream';
import { getEventParticipantsSuccess } from '../../redux/eventsRedux';
import ProgramFeed from './ProgramFeed';
import OffWallStream from './OffWallStream';

class Streams extends Component {
  state = {
    onAirParticipants: [],
    streams: {
      1: null,
      2: null,
      3: null,
      4: null,
    },
    connectionReady: {
      1: false,
      2: false,
      3: false,
      4: false,
    },
    maxHeight: 0,
    maxWidth: 0,
    activeTab: null,
  };

  _streamsContainerRef = createRef();

  async componentDidMount() {
    const { activeEvent, activeStudio, appMode } = this.props;
    if (appMode === 'PANDO' && activeEvent && activeStudio) {
      try {
        const response = await post(`/eventParticipant/list`, { event: activeEvent._id, roles: ON_WALL_ROLES });
        if (response.status === 200 && response.data) {
          this._updateOnAirParticipants(response.data);
        }
      } catch (error) {
        console.log(error);
      }
      this._setupSocketClient();
    }
    window.addEventListener('resize', this._updateDimensions);
    window.addEventListener('devtoolschange', this._updateDimensions);
  }

  componentWillUnmount() {
    const { activeStudio, activeEvent, appMode } = this.props;
    if (appMode === 'PANDO' && activeEvent && activeStudio) {
      SocketClient.leaveRoom(`${activeStudio._id}:${activeEvent._id}:participant-list`);
      SocketClient.removeAllListeners();
    }
    window.removeEventListener('resize', this._updateDimensions);
    window.removeEventListener('devtoolschange', this._updateDimensions);
  }

  _updateDimensions = () => {
    if (this._streamsContainerRef.current !== null) {
      const { offsetWidth, offsetHeight } = this._streamsContainerRef.current;
      this.setState({
        maxHeight: offsetHeight,
        maxWidth: offsetWidth,
      });
    }
  };

  _updateOnAirParticipants = async (eventParticipants) => {
    const newOnAirParticipants = eventParticipants.filter((p) => p.status === 'ON_AIR');
    const streams = {
      1: null,
      2: null,
      3: null,
      4: null,
    };
    newOnAirParticipants.forEach((p) => {
      streams[p.onAirNum] = p;
    });
    this.setState({ onAirParticipants: newOnAirParticipants, streams });
  };

  _setupSocketClient = () => {
    const { activeStudio, activeEvent, getEventParticipantsSuccess } = this.props;
    SocketClient.joinRoom(`${activeStudio._id}:${activeEvent._id}:participant-list`);
    SocketClient.on('participant-list-update', (participantList) => {
      getEventParticipantsSuccess(participantList);
      this._updateOnAirParticipants(participantList);
    });
  };

  _onReady = (onAirSlot) => {
    const { connectionReady } = this.state;
    connectionReady[onAirSlot] = true;
    this.setState({ connectionReady });
  };

  _onOnAirStreamUmount = (onAirSlot) => {
    const { connectionReady } = this.state;
    connectionReady[onAirSlot] = false;
    this.setState({ connectionReady });
  };

  render() {
    const { routesEnabled, liveRoutesEnabled, activeStudio, activeEvent, appMode } = this.props;
    const { streams, connectionReady, activeTab } = this.state;

    const isPando = appMode === 'PANDO';

    if (!routesEnabled) return <Redirect to={isPando ? '/app/studios' : '/app/broadcast/studios'} />;
    if (isPando && !liveRoutesEnabled) return <Redirect to={'/app/goLive'} />;

    let streamContainerWidth = 0;
    let streamContainerHeight = 0;

    if (this._streamsContainerRef.current !== null) {
      const { offsetWidth, offsetHeight } = this._streamsContainerRef.current;

      streamContainerWidth = Math.round(offsetWidth / 2);
      streamContainerHeight = Math.round(streamContainerWidth * (9 / 16));

      if (streamContainerHeight * 2 > offsetHeight) {
        streamContainerHeight = Math.round(offsetHeight / 2);
        streamContainerWidth = Math.round(streamContainerHeight * (16 / 9));
      }
    }

    const streamContainerStyle = {
      width: streamContainerWidth,
      height: streamContainerHeight,
    };

    let tabs;
    if (isPando) {
      tabs = [
        { value: 'program-feed', label: 'Program Feed' },
        { value: 'on-air', label: 'On Air' },
        { value: 'off-wall-stream', label: 'Off Wall Stream' },
      ];
    } else {
      tabs = [{ value: 'off-wall-stream', label: 'Broadcast Stream' }];
    }

    return (
      <>
        <Tabs options={tabs} value={activeTab} onChange={(tab) => this.setState({ activeTab: tab })} />
        <div className='streams' ref={this._streamsContainerRef}>
          {activeTab === 'program-feed' && <ProgramFeed />}
          {activeTab === 'on-air' && (
            <div className='on-air-streams'>
              <OnAirStream
                onAirParticipant={streams[1]}
                onAirSlot={1}
                onReady={this._onReady}
                style={streamContainerStyle}
                onUnmount={this._onOnAirStreamUmount}
              />
              {connectionReady[1] && (
                <OnAirStream
                  onAirParticipant={streams[2]}
                  onAirSlot={2}
                  onReady={this._onReady}
                  style={streamContainerStyle}
                  onUnmount={this._onOnAirStreamUmount}
                />
              )}
              {connectionReady[2] && (
                <OnAirStream
                  onAirParticipant={streams[3]}
                  onAirSlot={3}
                  onReady={this._onReady}
                  style={streamContainerStyle}
                  onUnmount={this._onOnAirStreamUmount}
                />
              )}
              {connectionReady[3] && (
                <OnAirStream
                  onAirParticipant={streams[4]}
                  onAirSlot={4}
                  onReady={this._onReady}
                  style={streamContainerStyle}
                  onUnmount={this._onOnAirStreamUmount}
                />
              )}
            </div>
          )}
          {activeTab === 'off-wall-stream' && <OffWallStream url={isPando ? activeStudio.offWallStreamUrl : activeEvent.broadcastStream.playbackUrl} />}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  routesEnabled: state.ui.routesEnabled,
  liveRoutesEnabled: state.ui.liveRoutesEnabled,
  activeEvent: state.events.active,
  activeStudio: state.studio.active,
  participants: state.events.participants,
  appMode: state.ui.appMode,
});

const mapDispatchToProps = (dispatch) => ({
  getEventParticipantsSuccess: (data) => dispatch(getEventParticipantsSuccess(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Streams);
