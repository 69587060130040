import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import calendarIcon from '../assets/icons/calendar.svg';
import '../css/DateTimeInput.css';

class DateTimeInput extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    label: '',
  };

  _onChangeHandler = (date) => {
    const { onChange } = this.props;
    onChange(date);
  };

  render() {
    const { label, value } = this.props;
    return (
      <div className='form-group'>
        <div className='form-label'>{label}</div>
        <div className='form-control'>
          <div style={{ display: 'flex', flexDirection: 'row', position: 'relative' }}>
            <DatePicker
              selected={value}
              onChange={this._onChangeHandler}
              showTimeSelect
              timeFormat='HH:mm'
              timeIntervals={15}
              dateFormat='MMMM d, yyyy h:mm aa'
              timeCaption='Time'
            />
            <img src={calendarIcon} alt='calendar' style={{ marginLeft: 15 }} />
          </div>
        </div>
      </div>
    );
  }
}

export default DateTimeInput;
