import React, { PureComponent, createRef } from 'react';
import { connect } from 'react-redux';
import swal from '@sweetalert/with-react';
import { getEventById, getEventRequest } from '../../redux/eventsRedux';
import { post } from '../../services/api';
import { toBase64 } from '../../utils/file-util';
import Button from '../../common/Button';
import GrantAccessModal from './forms/GrantAccessModal';

const LabelValue = ({ label, value }) => (
  <div style={{ display: 'flex', flexDirection: 'row', fontSize: 14 }}>
    <div style={{ width: 210, textAlign: 'left' }}>{label}</div>
    <div>{value}</div>
  </div>
);

class Reporting extends PureComponent {
  _fileInput = createRef();

  _handleRegistrantsUpload = async () => {
    try {
      const { selectedEvent, getEvent } = this.props;
      const file = this._fileInput.current.files[0];
      const base64Data = await toBase64(file);
      const arr = base64Data.split(',');
      const base64 = arr[1];
      const filename = file.name;
      const filetype = file.type;

      const response = await post('report/uploadRegistrants', { file: base64, filename, filetype, eventId: selectedEvent });
      const { rowsToProcess, imported, registrationsAttended, invalidRows, attendanceCount, esAttendanceCount, mismatched, missing } = response.data;

      const missingAttendeesStr = missing.length ? `\n${missing}\n` : '';
      const mismatchedAttendeesStr = mismatched.length ? `\n${mismatched}\n` : '';

      await swal({
        title: 'Import Result',
        text: `Row to process: ${rowsToProcess}\nImported: ${imported}\nAttended: ${registrationsAttended}\nInvalid rows: ${
          invalidRows.length > 0 ? `${invalidRows.length} ${JSON.stringify(invalidRows)}` : 0
        }\n\nTotal attendees: ${attendanceCount}\nES attendees: ${esAttendanceCount}\nMissing attendees: ${
          missing.length
        }${missingAttendeesStr}\nMismatched attendees: ${mismatched.length}${mismatchedAttendeesStr}`,
      });
      getEvent(selectedEvent);
    } catch (error) {
      console.log(error);
    } finally {
      this._fileInput.current.value = null;
    }
  };

  _openGrantAccessModal = () => {
    const { selectedEvent, activeStudio } = this.props;
    swal({
      buttons: {},
      content: <GrantAccessModal studioId={activeStudio._id} eventId={selectedEvent} onClose={() => swal.close()} />,
    });
  };

  _generateReport = () => {
    const { selectedEvent, activeStudio } = this.props;
    const urlParams = new URLSearchParams();
    urlParams.append('eventId', selectedEvent);
    urlParams.append('studioId', activeStudio._id);
    urlParams.append('isAdminAppUser', true);
    const encodedParams = window.btoa(urlParams.toString());
    window.open(`/reports?${encodedParams}`, 'Report', 'width=1280,height=720,toolbar=0,menubar=0,location=0');
  };

  render() {
    const { selectedEventObj } = this.props;
    const importedRegitrants = selectedEventObj ? selectedEventObj.registrants : 0;

    const buttonStyle = {
      fontSize: 14,
      padding: '5px 15px',
      marginTop: 4,
    };

    if (!selectedEventObj) return null;

    return (
      <>
        <h3 style={{ alignSelf: 'flex-start' }}>Reporting</h3>
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 8 }}>
          <LabelValue label='Attendees' value={selectedEventObj.totalCount} />
          <LabelValue label='On Wall' value={selectedEventObj.onWallCount} />
          <LabelValue label='Off Wall' value={selectedEventObj.offWallCount} />
          <LabelValue label='Screening Completed' value={selectedEventObj.screeningCompletedCount} />
          <LabelValue label='Help Requested' value={selectedEventObj.helpRequestedCount} />
          <LabelValue label='Registrants Imported' value={importedRegitrants} />
        </div>
        <div style={{ alignSelf: 'flex-end', marginBottom: 15, textAlign: 'end' }}>
          {!selectedEventObj.erpAccountId && (
            <Button text='Import Registrants' onClick={() => this._fileInput.current && this._fileInput.current.click()} containerStyle={buttonStyle} />
          )}
          <Button text='Generate Report' onClick={this._generateReport} containerStyle={buttonStyle} disabled={importedRegitrants === 0} />
          <Button text='Grant Access' onClick={this._openGrantAccessModal} containerStyle={buttonStyle} disabled={importedRegitrants === 0} />
          <input ref={this._fileInput} type='file' onChange={this._handleRegistrantsUpload} style={{ display: 'none' }} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedEvent: state.events.selected,
  selectedEventObj: getEventById(state, state.events.selected),
  activeEvent: state.events.active,
  activeStudio: state.studio.active,
});

const mapDispatchToProps = (dispatch) => ({
  getEvent: (id) => dispatch(getEventRequest(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reporting);
