import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const Backdrop = ({ collapsed, children }) => (
  <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.3)',
      padding: collapsed ? '20px 20px 20px 94px' : '20px 20px 20px 204px',
      zIndex: 9999,
    }}
  >
    {children}
  </div>
);

Backdrop.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

const mapStateToProps = state => ({
  collapsed: state.ui.navBarCollapsed,
});

export default connect(
  mapStateToProps,
  null
)(Backdrop);
