import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import { getInstancesRequest, createInstanceRequest, deleteInstanceRequest, updateInstanceRequest } from '../../redux/goLiveRedux';
import List from '../../common/List';
import Modal from '../../common/Modal';
import addIcon from '../../assets/icons/add.svg';

import InstanceListItem from './InstanceListItem';
import InstanceForm from './InstanceForm';

class InstanceList extends PureComponent {
  state = {
    openModal: false,
    model: undefined,
  };

  componentDidMount() {
    const { studio } = this.props;
    this.props.getInstances(studio._id, false);
  }

  componentDidUpdate(prevProps) {
    const { studio, error } = this.props;
    if (prevProps.studio !== studio && studio) {
      this.props.getInstances(studio._id);
    }

    if (prevProps.error !== error && error) {
      swal({
        text: error.data,
      });
    }
  }

  _addInstance = () => {
    this.setState({ openModal: true });
  };

  _onEditInstance = (instanceId) => {
    const { instances } = this.props;
    this.setState({ model: instances[instanceId], openModal: true });
  };

  _onDeleteInstance = async (instanceId) => {
    const willDelete = await swal({
      title: 'Are you sure?',
      text: 'Are you sure that you want to delete this Server?',
      buttons: true,
      dangerMode: true,
    });
    if (willDelete) {
      this.props.deleteInstance(instanceId);
    }
  };

  _onSubmit = (data) => {
    const { model } = this.state;
    if (model) {
      this.props.updateInstance(model._id, { ...data });
    } else {
      this.props.createInstance({ ...data, studioWall: this.props.studio._id });
    }
    this._closeModal(model ? true : false);
  };

  _closeModal = (clear) => {
    if (clear) {
      this.setState({ openModal: false, model: undefined });
    } else {
      this.setState({ openModal: false });
    }
  };

  render() {
    const { awsDefaultAvailabilityZone, awsRegion } = this.props.studio;
    let canAddServers = false;
    if (awsRegion && awsDefaultAvailabilityZone) {
      canAddServers = true;
    }

    const disabledStyle = {
      pointerEvents: 'none',
      opacity: 0.5,
    };

    return (
      <div className='instances-list-container'>
        <List
          data={this.props.data}
          renderItem={(item) => <InstanceListItem item={item} onEdit={this._onEditInstance} onDelete={this._onDeleteInstance} />}
          keyExtractor={(item) => `${item}`}
          containerStyle={{ height: 'calc(100% - 65px)', position: 'relative' }}
        />
        <div className='add-button' onClick={this._addInstance} style={canAddServers ? undefined : disabledStyle}>
          <img src={addIcon} alt='add' style={{ marginLeft: 15 }} />
          Add Server
        </div>
        <Modal show={this.state.openModal} onClose={() => this._closeModal(true)}>
          <InstanceForm studio={this.props.studio} model={this.state.model} edit={this.state.model ? true : false} onSubmit={this._onSubmit} />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.goLive.list,
  instances: state.goLive.instances,
  error: state.goLive.error,
});

const mapDispatchToProps = (dispatch) => ({
  getInstances: (studio, monitoring) => dispatch(getInstancesRequest(studio, monitoring)),
  createInstance: (data) => dispatch(createInstanceRequest(data)),
  deleteInstance: (id) => dispatch(deleteInstanceRequest(id)),
  updateInstance: (id, data) => dispatch(updateInstanceRequest(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InstanceList);
