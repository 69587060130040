import { all, takeLatest, call, put } from 'redux-saga/effects';
import {
  actions,
  getAllLayoutsSuccess,
  getAllLayoutsFailure,
  assignParticipantSuccess,
  assignParticipantFailure,
  removeParticipantFromWallSuccess,
  removeParticipantFromWallFailure,
  updateEventParticipantFailure,
  updateEventParticipantSuccess,
  getLayoutConfigFailure,
  getLayoutConfigSuccess,
} from '../redux/wallRedux';
import { get, post, put as update } from '../services/api';

function* fetchLayouts(action) {
  try {
    const { event, studio } = action.payload;
    const response = yield call(get, `/layout?event=${event}&studio=${studio}`);
    if (response.status === 200) {
      yield put(getAllLayoutsSuccess(response.data));
    } else {
      yield put(
        getAllLayoutsFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(getAllLayoutsFailure(error));
  }
}

function* assignParticipant(action) {
  try {
    const { layoutId, data } = action.payload;
    const response = yield call(post, `/layout/${layoutId}/assignParticipant`, data);
    if (response.status === 200) {
      yield put(assignParticipantSuccess(response.data));
    } else {
      yield put(
        assignParticipantFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(assignParticipantFailure(error));
  }
}

function* removeParticipant(action) {
  try {
    const { layoutId, data } = action.payload;
    const response = yield call(post, `/layout/${layoutId}/removeParticipant`, data);
    if (response.status === 200) {
      yield put(removeParticipantFromWallSuccess(response.data));
    } else {
      yield put(
        removeParticipantFromWallFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(removeParticipantFromWallFailure(error));
  }
}

function* updateEventParticipant(action) {
  try {
    const { eventParticipantId, data } = action.payload;
    const response = yield call(update, `/eventParticipant/${eventParticipantId}`, data);
    if (response.status === 200) {
      yield put(updateEventParticipantSuccess(response.data));
    } else {
      yield put(
        updateEventParticipantFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(updateEventParticipantFailure(error));
  }
}

function* getLayoutConfig(action) {
  try {
    const { layout } = action.payload;
    const response = yield call(get, `/layout/${layout}`);
    if (response.status === 200) {
      yield put(getLayoutConfigSuccess(response.data));
    } else {
      yield put(
        getLayoutConfigFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(getLayoutConfigFailure(error));
  }
}

export default function* () {
  yield all([
    takeLatest([actions.GET_ALL_LAYOUTS_REQUEST], fetchLayouts),
    takeLatest([actions.ASSIGN_PARTICIPANT_REQUEST], assignParticipant),
    takeLatest([actions.REMOVE_PARTICIPANT_FROM_WALL_REQUEST], removeParticipant),
    takeLatest([actions.UPDATE_EVENT_PARTICIPANT_REQUEST], updateEventParticipant),
    takeLatest([actions.GET_LAYOUT_CONFIG_REQUEST], getLayoutConfig),
  ]);
}
