import React from 'react';

const styles = {
  tile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#35495D',
    color: '#fff',
    borderRadius: 8,
    width: 300,
    height: 260,
    position: 'relative',
    margin: '0 15px',
    cursor: 'pointer',
  },
};

export default function Tile({ title, icon, onClick, children }) {
  return (
    <div style={styles.tile} onClick={onClick}>
      <img src={icon} alt='icon' width={150} style={{ marginTop: 20 }} />
      <span style={{ marginTop: -10 }}>{title}</span>
      {children}
    </div>
  );
}
