import React, { PureComponent } from 'react';
import swal from '@sweetalert/with-react';
import uuidV4 from 'uuid';
import moment from 'moment';
import { connect } from 'react-redux';

import { getEventParticipantsRequest, updateParticipantStatusRequest, getEventParticipantsSuccess } from '../../redux/eventsRedux';
import { getInstancesRequest, checkInstancesStatus } from '../../redux/goLiveRedux';
import List from '../../common/List';
import RTC from '../../utils/rtc-common';
import SocketClient from '../../utils/socket-client';
import MediaDeviceUtil from '../../utils/media-device-util';
import VideoOverlay from '../../common/VideoOverlay';
import { get } from '../../services/api';
import { getParticipantExtraData } from '../../utils/participant-util';
import ArrayUtil from '../../utils/array-util';
import { ON_WALL_ROLES, OFF_WALL_ROLES } from '../../utils/user-roles';
import { enableGoLiveData } from '../goLive/GoLiveContent';

import EventParticipant from './EventParticipant';
import ScreeningArea from './ScreeningArea';
import Form from './Form';
import LocalVideo from './LocalVideo';
import ParticipantsListHeader from './ParticipantsListHeader';
import OffWallList from './OffWallList';

class Participants extends PureComponent {
  _videoRef = React.createRef();

  _audioRef = React.createRef();

  _localVideoRef = React.createRef();

  _localVideoCanvasRef = React.createRef();

  state = {
    selected: undefined,
    participantConnected: false,
    metadata: undefined,
    currentTabIndex: 0,
    leftTabIndex: 0,
    leftListSort: { sortBy: 'name', sortDirection: 'asc' },
    rightListSort: { sortBy: 'name', sortDirection: 'asc' },
    selectedParticipants: [],
  };

  componentDidMount() {
    const { activeEvent, activeStudio, getEventParticipants, getEventParticipantsSuccess, getInstances, checkInstancesStatus, includeOffWall, appMode } =
      this.props;

    const isPando = appMode === 'PANDO';

    if (includeOffWall) {
      this.setState({ currentTabIndex: 2, leftTabIndex: 0 });
    }

    if (activeStudio && isPando) {
      this._liveDataTimerId = enableGoLiveData(activeStudio, getInstances, null, checkInstancesStatus);
    }
    if (activeEvent && activeStudio) {
      getEventParticipants(activeEvent._id, true);
      SocketClient.joinRoom(`${activeStudio._id}:${activeEvent._id}:participant-list`);
      SocketClient.on('participant-list-update', getEventParticipantsSuccess);
      SocketClient.on('start-screening-failed', ({ participantUuid, screenerUuid }) => {
        if (this._uuid === screenerUuid && this.state.selected.participant._id === participantUuid) {
          this._toScreeningQueue();
        }
      });

      if (isPando) {
        this._timerId = setInterval(this._setupRTC, 3000);
        this.createLocalMediaStream();
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { inScreening, notConnected, includeOffWall, allServersRunning } = this.props;
    const { selected, participantConnected, selectedParticipants } = this.state;

    if (prevState.participantConnected !== participantConnected) {
      if (participantConnected) {
        this.props.onParticipantConnected();
      } else {
        const inScreeningParticipant = prevProps.inScreening.find((p) => selected && p._id === selected._id);
        if (inScreeningParticipant) {
          this._stopScreening();
        }
        this.props.onParticipantDisconnected();
      }
    }

    if (prevProps.inScreening.length !== inScreening.length) {
      if (selected && !participantConnected) {
        const inScreeningParticipant = inScreening.find((p) => p._id === selected._id);
        if (inScreeningParticipant) {
          this.setState({ selected: null });
        }
      } else if (selectedParticipants.length) {
        const inScreeningParticipantIds = inScreening.map((p) => p._id);
        if (inScreeningParticipantIds.length) {
          this.setState({ selectedParticipants: selectedParticipants.filter((p) => !inScreeningParticipantIds.includes(p._id)) });
        }
      }
    }
    if (prevProps.notConnected.length !== notConnected.length) {
      if (selected) {
        const notConnectedParticipant = notConnected.find((p) => p._id === selected._id);
        if (notConnectedParticipant) {
          if (participantConnected) {
            this._stopScreening();
          }
          this._disconnect();
        }
      } else if (selectedParticipants.length) {
        const notConnectedParticipantIds = notConnected.map((p) => p._id);
        if (notConnectedParticipantIds.length) {
          this.setState({ selectedParticipants: selectedParticipants.filter((p) => !notConnectedParticipantIds.includes(p._id)) });
        }
      }
    }
    if (prevProps.includeOffWall !== includeOffWall) {
      this.setState({ currentTabIndex: includeOffWall ? 2 : 0, leftTabIndex: 0, selected: null });
    }
  }

  async componentWillUnmount() {
    const { activeStudio, activeEvent, updateParticipantStatus } = this.props;
    const { selected, participantConnected } = this.state;
    if (selected && participantConnected) {
      SocketClient.emit('stop-screening', { participantUuid: selected.participant._id, screenerUuid: this._uuid });
      await updateParticipantStatus(selected._id, { status: 'IN_SCREENING_QUEUE' });
    }

    if (activeEvent && activeStudio) {
      SocketClient.leaveRoom(`${activeStudio._id}:${activeEvent._id}:participant-list`);
      SocketClient.removeAllListeners();
    }

    this._cleanup();

    if (this._timerId) {
      clearInterval(this._timerId);
    }
    if (this._liveDataTimerId) {
      clearInterval(this._liveDataTimerId);
    }
    this._unmounted = true;
  }

  _setupRTC = async () => {
    if (this.props.allServersRunning) {
      if (this._timerId) {
        clearInterval(this._timerId);
      }
      if (!this._unmounted) {
        this._startClient();
      }
    }
  };

  createLocalMediaStream = async () => {
    try {
      const savedVideoDevice = localStorage.getItem('current-video-device');
      const savedAudioDevice = localStorage.getItem('current-audio-device');
      const savedAudioOutputDevice = localStorage.getItem('current-audio-output-device');

      const stream = await window.navigator.mediaDevices.getUserMedia({ audio: true, video: true });

      const availableDevices = await MediaDeviceUtil.getAvailableDevices();
      const filteredVideoDevices = availableDevices.filter((d) => d.kind === 'videoinput' && d.deviceId !== 'default');
      const filteredAudioDevices = availableDevices.filter((d) => d.kind === 'audioinput' && d.deviceId !== 'default');
      const filteredAudioOutputDevices = availableDevices.filter((d) => d.kind === 'audiooutput' && d.deviceId !== 'default');

      stream.getTracks().forEach((track) => track.stop());

      let initialVideoInput;
      if (savedVideoDevice) {
        const videoDevice = JSON.parse(savedVideoDevice);
        const validVideoInput = filteredVideoDevices.find((dev) => dev.deviceId === videoDevice.deviceId);
        initialVideoInput = validVideoInput || filteredVideoDevices[0];
      } else {
        initialVideoInput = filteredVideoDevices[0];
      }

      let initialAudioInput;
      if (savedAudioDevice) {
        const audioDevice = JSON.parse(savedAudioDevice);
        const validAudioInput = filteredAudioDevices.find((dev) => dev.deviceId === audioDevice.deviceId);
        initialAudioInput = validAudioInput || filteredAudioDevices[0];
      } else {
        initialAudioInput = filteredAudioDevices[0];
      }

      let initialAudioOutput;
      if (savedAudioOutputDevice) {
        const audioOutputDevice = JSON.parse(savedAudioOutputDevice);
        const validAudioOutput = filteredAudioOutputDevices.find((dev) => dev.deviceId === audioOutputDevice.deviceId);
        initialAudioOutput = validAudioOutput || filteredAudioOutputDevices[0];
      } else {
        initialAudioOutput = filteredAudioOutputDevices[0];
      }

      const videoContraints = {
        video: {
          deviceId: { exact: initialVideoInput.deviceId },
          width: { min: 640, ideal: 960, max: 960 },
          height: { min: 360, ideal: 540, max: 540 },
        },
      };
      const audioContraints = {
        audio: {
          deviceId: { exact: initialAudioInput.deviceId },
          channelCount: 1,
          sampleSize: { exact: 16 },
          autoGainControl: false,
          volume: 0.25,
          latency: 0,
        },
      };

      if (this._audioRef.current) {
        this._audioRef.current.setSinkId(initialAudioOutput.deviceId);
      }

      const [localVideoStream, localAudioStream] = await Promise.all([
        navigator.mediaDevices.getUserMedia(videoContraints),
        navigator.mediaDevices.getUserMedia(audioContraints),
      ]);
      if (!this._unmounted) {
        this.localVideo = localVideoStream;
        this.localAudio = localAudioStream;

        this._localVideoRef.current.muted = true;
        this._localVideoRef.current.srcObject = this.localVideo;
      } else {
        localVideoStream.getTracks().forEach((track) => track.stop());
        localAudioStream.getTracks().forEach((track) => track.stop());
      }
    } catch (error) {
      console.error(error);
    }
  };

  _cleanup = () => {
    if (this._clientRtc) {
      this._clientRtc.stop();
    }
    if (this._serverRtc) {
      this._serverRtc.stop();
    }

    if (this.localVideo) {
      this.localVideo.getTracks().forEach((track) => track.stop());
    }

    if (this.localAudio) {
      this.localAudio.getTracks().forEach((track) => track.stop());
    }

    if (this._localMediaStream) {
      this._localMediaStream.getTracks().forEach((track) => track.stop());
    }
  };

  _startClient = async () => {
    try {
      this._uuid = uuidV4();
      this._clientRtc = new RTC(`${this._uuid}-client-send`);

      this._localMediaStream = new MediaStream();
      // this.localVideo.getTracks().forEach((t) => this._localMediaStream.addTrack(t));
      this._localVideoCanvasRef.current
        .captureStream()
        .getTracks()
        .forEach((track) => {
          this._localMediaStream.addTrack(track);
        });

      while (!this.localAudio) {
        await new Promise((resolve) => setTimeout(resolve, 250));
      }
      this.localAudio.getTracks().forEach((t) => this._localMediaStream.addTrack(t));

      this._clientPc = await this._clientRtc.setup();
      this._clientPc.addStream(this._localMediaStream);

      await new Promise((resolve, reject) => {
        let attemptCount = 0;
        this._rtcTimerId = setInterval(async () => {
          if (attemptCount++ < 5) {
            this._clientRtc.sendMessage({ type: 'state', value: 'CONFIG_COMPLETED' });

            console.log('Waiting for READY_FOR_OFFER');
            const { type, value } = await this._clientRtc.getMessage('state');
            if (type === 'state' && value === 'READY_FOR_OFFER') {
              clearInterval(this._rtcTimerId);
              resolve();
            }
          } else {
            clearInterval(this._rtcTimerId);
            if (!this._unmounted) {
              reject('Unable to get a response from the RTC Server.');
              swal({
                title: 'Unable to get a response from the RTC Server.',
                text: 'Please contact support',
              });
            }
          }
        }, 1000);
      });

      await this._clientRtc.startClient();

      this._startServer();
    } catch (error) {
      console.error(error);
    }
  };

  _startServer = async () => {
    const rtc = new RTC(`${this._uuid}-client-recv`);
    const pc = await rtc.setup();

    pc.ontrack = ({ track }) => {
      console.log(`Received ${track.kind} MediaStreamTrack with ID ${track.id}`);
      if (track.kind === 'video') {
        const videoStream = new MediaStream();
        videoStream.addTrack(track);
        this._videoRef.current.muted = true;
        this._videoRef.current.srcObject = videoStream;
      } else if (track.kind === 'audio') {
        const audioStream = new MediaStream();
        audioStream.addTrack(track);
        this._audioRef.current.srcObject = audioStream;
      }
    };

    await new Promise(async (resolve) => {
      const { type, value } = await rtc.getMessage('state');
      if (type === 'state' && value === 'CONFIG_COMPLETED') {
        resolve();
      }
    });

    console.log('Sending READY_FOR_OFFER');
    rtc.sendMessage({
      type: 'state',
      value: 'READY_FOR_OFFER',
    });

    rtc.startServer();

    this._serverRtc = rtc;
  };

  _selectParticipant = async (event, selectedParticipant) => {
    try {
      const { selected, participantConnected, selectedParticipants } = this.state;
      if (selected && selectedParticipant.participant._id !== selected.participant._id) {
        if (participantConnected) {
          this._disconnect();
        }
      }

      const index = selectedParticipants.findIndex((p) => p._id === selectedParticipant._id);
      if (event.metaKey || event.ctrlKey) {
        if (index === -1) {
          this.setState({ selected: null, selectedParticipants: [...selectedParticipants, selectedParticipant] });
        } else {
          this.setState({ selected: null, selectedParticipants: selectedParticipants.filter((i) => i._id !== selectedParticipant._id) });
        }
      } else {
        const extraData = await getParticipantExtraData(selectedParticipant._id);
        selectedParticipant = { ...selectedParticipant, ...extraData };
        this.setState({ selected: null }, () => {
          this.setState({
            selected: selectedParticipant,
            selectedParticipants: selectedParticipants[index] && selectedParticipants[index]._id === selectedParticipant._id ? [] : [selectedParticipant],
          });
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  _disconnect = () => {
    clearInterval(this._timer);
    this.setState({ selected: undefined, participantConnected: false, metadata: undefined });
  };

  _onConnect = async () => {
    if (!this._clientPc || (this._clientPc && this._clientPc.connectionState !== 'connected')) {
      this._clientRtc.showConnectionErrorModal();
      return;
    }

    this._timer = setInterval(this._getMetadata, 1000);
    const { selected } = this.state;
    this.setState({ participantConnected: true }, async () => {
      const response = await get(`/eventParticipant/${selected._id}/screen`);
      if (response.data && response.data.success === true) {
        SocketClient.emit('start-screening', { participantUuid: selected.participant._id, screenerUuid: this._uuid });
      } else {
        this._disconnect();
      }
    });
  };

  _getMetadata = async () => {
    try {
      const ignoredTypes = [
        'candidate-pair',
        'certificate',
        'local-candidate',
        'stream',
        'transport',
        'peer-connection',
        'remote-inbound-rtp',
        'remote-candidate',
      ];

      const result = {};
      const codecs = {};

      const _extractMetadata = (rtcStats) => {
        for (const value of rtcStats.values()) {
          const {
            id,
            type,
            kind,
            jitterBufferDelay,
            jitterBufferEmittedCount,
            framesReceived,
            remoteSource,
            codecId,
            bytesReceived,
            bytesSent,
            mimeType,
            width,
            height,
          } = value;
          if (!ignoredTypes.includes(type)) {
            if (type === 'track' && remoteSource) {
              if (kind === 'audio') {
                result.audioDelay = (jitterBufferDelay / jitterBufferEmittedCount) * 1000;
              } else if (kind === 'video') {
                result.videoDelay = (jitterBufferDelay / jitterBufferEmittedCount) * 1000;
                result.framesReceived = framesReceived;
              }
            } else if (type === 'inbound-rtp') {
              if (kind === 'video') {
                result.videoCodecId = codecId;
                result.videoBytesReceived = bytesReceived;
              } else if (kind === 'audio') {
                result.audioCodecId = codecId;
                result.audioBytesReceived = bytesReceived;
              }
            } else if (type === 'outbound-rtp') {
              if (kind === 'video') {
                result.videoBytesSent = bytesSent;
              } else if (kind === 'audio') {
                result.audioBytesSent = bytesSent;
              } else if (type === 'codec') {
                codecs[id] = mimeType;
              } else if (type === 'media-source') {
                // console.log(JSON.stringify(value));
                if (kind === 'video') {
                  result.frameWidth = width;
                  result.frameHeight = height;
                }
              }
            }
          }
        }
      };

      if (this._clientRtc && this._serverRtc) {
        const clientRtcStats = await this._clientRtc.pc.getStats();
        const serverRtcStats = await this._serverRtc.pc.getStats();
        _extractMetadata(clientRtcStats);
        _extractMetadata(serverRtcStats);

        result.videoCodec = codecs[result.videoCodecId];
        result.audioCodec = codecs[result.audioCodecId];

        this.setState({ metadata: result });
      }
    } catch (error) {
      console.error(error);
    }
  };

  _stopScreening = () => {
    const { selected } = this.state;
    if (this._clientRtc) {
      clearInterval(this._timer);
    }
    SocketClient.emit('stop-screening', { participantUuid: selected.participant._id, screenerUuid: this._uuid });
  };

  _toStudioQueue = () => {
    const { selected } = this.state;
    const { updateParticipantStatus } = this.props;

    if (selected) {
      this._stopScreening();
      this.setState({ selected: undefined, participantConnected: false, metadata: undefined }, () => {
        updateParticipantStatus(selected._id, { status: 'IN_STUDIO_QUEUE', helpRequested: false });
      });
    }
  };

  _toScreeningQueue = () => {
    const { selected } = this.state;
    const { updateParticipantStatus } = this.props;
    if (selected) {
      this._stopScreening();
      this.setState({ selected: undefined, metadata: undefined, participantConnected: false }, () => {
        updateParticipantStatus(selected._id, { status: 'IN_SCREENING_QUEUE' });
      });
    }
  };

  _sendToOnboarding = () => {
    const { selected } = this.state;
    const { updateParticipantStatus } = this.props;
    if (selected) {
      this._stopScreening();
      this.setState({ selected: undefined, metadata: undefined, participantConnected: false }, () => {
        updateParticipantStatus(selected._id, { status: 'ONBOARDING', systemCheck: null, screeningCompleted: false, helpRequested: false, paused: false });
      });
    }
  };

  _archiveParticipant = () => {
    const { selected } = this.state;
    const { updateParticipantStatus } = this.props;
    if (selected) {
      this._stopScreening();
      this.setState({ selected: undefined, metadata: undefined, participantConnected: false }, () => {
        updateParticipantStatus(selected._id, { status: 'ARCHIVED' });
      });
    }
  };

  _removeParticipant = () => {
    const { selected } = this.state;
    const { updateParticipantStatus } = this.props;
    if (selected) {
      this._stopScreening();
      this.setState({ selected: undefined, metadata: undefined, participantConnected: false }, () => {
        updateParticipantStatus(selected._id, { status: 'REMOVED' });
      });
    }
  };

  _toggleLocalAudio = async (value) => {
    if (this.localAudio) {
      this.localAudio.getAudioTracks()[0].enabled = value;
    }
  };

  _changeDevices = async (deviceId, type) => {
    let newTrack;
    if (type === 'video') {
      this.localVideo = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: { exact: deviceId }, width: { exact: 640 }, height: { exact: 360 } },
      });

      this._localVideoRef.current.srcObject = this.localVideo;
      newTrack = this.localVideo.getVideoTracks()[0];
    } else if (type === 'audio') {
      this.localAudio = await navigator.mediaDevices.getUserMedia({
        audio: {
          channelCount: 1,
          sampleSize: { exact: 16 },
          autoGainControl: false,
          volume: 0.25,
          latency: 0,
        },
      });
      newTrack = this.localAudio.getAudioTracks()[0];
    } else if (type === 'audio-output' && this._audioRef) {
      this._audioRef.current.setSinkId(deviceId);
      return;
    }

    if (this._clientPc) {
      const senders = this._clientPc.getSenders();
      if (senders) {
        for (const sender of senders) {
          if (sender.track && sender.track.kind === type) {
            await sender.replaceTrack(newTrack);
          }
        }
      }
    }
  };

  _refresh = async () => {
    const { selected } = this.state;
    const extraData = await getParticipantExtraData(selected._id);
    const selectedParticipant = { ...extraData };

    this.setState({ selected: selectedParticipant });
  };

  render() {
    const { inQueue, inStudio, onWall, archivedOrRemoved, inScreening, includeOffWall, onboarding, notConnected, style, appMode, activeEvent } = this.props;
    const { selected, participantConnected, rightListSort, leftListSort, selectedParticipants } = this.state;

    const connectedParticipantsCount = inQueue.length + inStudio.length;

    let listData = null;
    if (this.state.currentTabIndex === 0) {
      listData = inStudio;
    } else if (this.state.currentTabIndex === 1) {
      listData = onWall;
    } else if (this.state.currentTabIndex === 2) {
      listData = archivedOrRemoved;
    }
    listData = ArrayUtil.sort(listData, rightListSort.sortBy, rightListSort.sortDirection);

    let leftListData = null;
    if (this.state.leftTabIndex === 0) {
      leftListData = includeOffWall ? [...inQueue, ...inStudio] : inQueue;
    } else if (this.state.leftTabIndex === 1) {
      leftListData = inScreening;
    } else if (this.state.leftTabIndex === 2) {
      leftListData = onboarding;
    }
    leftListData = ArrayUtil.sort(leftListData, leftListSort.sortBy, leftListSort.sortDirection);

    const disabledStyle = {
      pointerEvents: participantConnected ? 'none' : 'all',
      opacity: participantConnected ? 0.5 : 1,
    };

    const isAutomatedOnboardingEnabled = !includeOffWall
      ? activeEvent && activeEvent.enableAutomatedOnboarding
      : activeEvent && activeEvent.enableOffWallAutomatedOnboarding;

    return (
      <div className='content' style={style}>
        <div className='participants-queue' style={disabledStyle}>
          <div className='list-header' style={{ padding: '0' }}>
            {appMode === 'PANDO' && (
              <div className={this.state.leftTabIndex === 2 ? 'tab active' : 'tab'} onClick={() => this.setState({ leftTabIndex: 2 })}>
                {`Onboarding (${onboarding.length})`}
              </div>
            )}
            <div className={this.state.leftTabIndex === 0 ? 'tab active' : 'tab'} onClick={() => this.setState({ leftTabIndex: 0 })}>
              {includeOffWall
                ? `Participants (${connectedParticipantsCount - notConnected.length}/${connectedParticipantsCount})`
                : `Screening Queue (${inQueue.length})`}
            </div>
            {!includeOffWall && (
              <div className={this.state.leftTabIndex === 1 ? 'tab active' : 'tab'} onClick={() => this.setState({ leftTabIndex: 1 })}>
                {`In Screening (${inScreening.length})`}
              </div>
            )}
          </div>
          {!includeOffWall && (
            <>
              <ParticipantsListHeader onChangeSort={(sort) => this.setState({ leftListSort: sort })} />
              <List
                data={leftListData}
                renderItem={(item) => (
                  <EventParticipant
                    item={item}
                    onSelect={this._selectParticipant}
                    selected={selected && selected._id === item._id}
                    selectedParticipants={selectedParticipants}
                    onContextMenuAction={() => this.setState({ selectedParticipants: [] })}
                    isAutomatedOnboardingEnabled={isAutomatedOnboardingEnabled}
                  />
                )}
                keyExtractor={(item) => `${item._id}`}
                containerStyle={{ flex: 1, position: 'relative' }}
              />
            </>
          )}
          {includeOffWall && leftListData && (
            <OffWallList
              items={leftListData}
              onSelect={this._selectParticipant}
              selectedParticipant={selected}
              selectedParticipants={selectedParticipants}
              onContextMenuAction={() => this.setState({ selectedParticipants: [] })}
              appMode={appMode}
            />
          )}
        </div>
        <div className='main'>
          <div className='media-container' style={appMode !== 'PANDO' ? { minHeight: 0, height: 'auto' } : undefined}>
            <ScreeningArea
              participant={selected}
              metadata={this.state.metadata}
              connected={participantConnected}
              toStudioQueue={this._toStudioQueue}
              toScreeningQueue={this._toScreeningQueue}
              onArchive={this._archiveParticipant}
              onRemove={this._removeParticipant}
              onConnect={this._onConnect}
              includeOffWall={includeOffWall}
              onRefresh={this._refresh}
              onSendToOnboarding={this._sendToOnboarding}
              isAutomatedOnboardingEnabled={isAutomatedOnboardingEnabled}
            >
              <>
                {participantConnected && <VideoOverlay />}
                <audio ref={this._audioRef} autoPlay />
                <video ref={this._videoRef} autoPlay muted />
              </>
            </ScreeningArea>
            <LocalVideo
              ref={{ videoRef: this._localVideoRef, canvasRef: this._localVideoCanvasRef }}
              onToggleAudio={this._toggleLocalAudio}
              onChangeDevices={this._changeDevices}
              participantConnected={participantConnected}
              hide={includeOffWall}
            />
          </div>
          <div className='options-container'>
            {selected ? (
              <>
                <Form data={selected} participantConnected={participantConnected} />
              </>
            ) : null}
          </div>
        </div>
        <div className='connected-participants' style={disabledStyle}>
          <div className='list-header' style={{ padding: '0' }}>
            {!includeOffWall && (
              <>
                <div className={this.state.currentTabIndex === 0 ? 'tab active' : 'tab'} onClick={() => this.setState({ currentTabIndex: 0 })}>
                  {`Studio Queue (${inStudio.length})`}
                </div>
                <div className={this.state.currentTabIndex === 1 ? 'tab active' : 'tab'} onClick={() => this.setState({ currentTabIndex: 1 })}>
                  {`On Wall (${onWall.length})`}
                </div>
              </>
            )}
            <div className={this.state.currentTabIndex === 2 ? 'tab active' : 'tab'} onClick={() => this.setState({ currentTabIndex: 2 })}>
              {!includeOffWall ? `Other (${archivedOrRemoved.length})` : `Archived/Removed (${archivedOrRemoved.length})`}
            </div>
          </div>
          <ParticipantsListHeader onChangeSort={(sort) => this.setState({ rightListSort: sort })} hideDuration />
          <List
            data={listData}
            renderItem={(item) => (
              <EventParticipant
                item={item}
                onSelect={this._selectParticipant}
                selected={selected && selected._id === item._id}
                selectedParticipants={selectedParticipants}
                onContextMenuAction={() => this.setState({ selectedParticipants: [] })}
                isAutomatedOnboardingEnabled={isAutomatedOnboardingEnabled}
              />
            )}
            keyExtractor={(item) => `${item._id}`}
            containerStyle={{ flex: 1, position: 'relative' }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { participants } = state.events;
  let inQueue = [];
  let onboarding = [];
  let inScreening = [];
  let inStudio = [];
  let archivedOrRemoved = [];
  let onWall = [];
  let notConnected = [];

  const roles = ownProps.includeOffWall === true ? OFF_WALL_ROLES : ON_WALL_ROLES;
  if (participants && participants.length) {
    const filteredParticipants = participants.filter((p) => roles.includes(p.role));

    inQueue = filteredParticipants
      .filter((p) => p.status === 'IN_SCREENING_QUEUE' && p.hasWebRTCConnection)
      .sort((a, b) => {
        if (moment(a.inScreeningQueueSince).isAfter(moment(b.inScreeningQueueSince))) {
          return 1;
        }
        return -1;
      })
      .concat(filteredParticipants.filter((p) => p.status === 'IN_SCREENING_QUEUE' && !p.hasWebRTCConnection))
      .concat(filteredParticipants.filter((p) => p.status === 'NOT_CONNECTED' && !p.screeningCompleted));

    inScreening = filteredParticipants
      .filter((p) => p.status === 'IN_SCREENING')
      .sort((a, b) => {
        if (moment(a.inScreeningQueueSince).isAfter(moment(b.inScreeningQueueSince))) {
          return 1;
        }
        return -1;
      });
    inStudio = filteredParticipants
      .filter((p) => p.status === 'IN_STUDIO_QUEUE')
      .concat(filteredParticipants.filter((p) => p.status === 'NOT_CONNECTED' && p.screeningCompleted === true));

    onWall = filteredParticipants.filter((p) => p.status === 'ON_WALL' || p.status === 'ON_AIR');
    archivedOrRemoved = filteredParticipants.filter((p) => p.status === 'ARCHIVED' || p.status === 'REMOVED');
    notConnected = filteredParticipants.filter((p) => p.status === 'NOT_CONNECTED');
    onboarding = filteredParticipants.filter((p) => p.status === 'ONBOARDING');
  }

  return {
    activeStudio: state.studio.active,
    activeEvent: state.events.active,
    allServersRunning: state.goLive.allServersRunning,
    appMode: state.ui.appMode,
    inQueue,
    inStudio,
    archivedOrRemoved,
    onWall,
    inScreening,
    notConnected,
    onboarding,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getEventParticipants: (event, includeOffWall) => dispatch(getEventParticipantsRequest(event, includeOffWall)),
  updateParticipantStatus: (id, data) => dispatch(updateParticipantStatusRequest(id, data)),
  getEventParticipantsSuccess: (data) => dispatch(getEventParticipantsSuccess(data)),
  getInstances: (studio, monitoring) => dispatch(getInstancesRequest(studio, monitoring)),
  checkInstancesStatus: (data) => dispatch(checkInstancesStatus(data)),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Participants);
