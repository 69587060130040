function hash32(str, asString, seed) {
  /* jshint bitwise:false */
  let i;
  let l;
  let hval = seed === undefined ? 0x811c9dc5 : seed;

  for (i = 0, l = str.length; i < l; i++) {
    hval ^= str.charCodeAt(i);
    hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
  }
  if (asString) {
    // Convert to 8 digit hex string
    return `0000000${(hval >>> 0).toString(16)}`.substr(-8);
  }
  return hval >>> 0;
}

function hash64(str) {
  const h1 = hash32(str); // returns 32 bit (as 8 byte hex string)
  return h1 + hash32(h1 + str); // 64 bit (as 16 byte hex string)
}

function generatePassword() {
  return Math.random().toString(36).slice(-10);
}

function formatSeconds(seconds) {
  let days = Math.floor(seconds / 60 / 60 / 24);
  let hrs = Math.floor(seconds / 3600) % 24;
  let min = Math.floor(seconds / 60) % 60;
  let sec = Math.floor(seconds % 60);
  return `${days < 10 ? '0' + days : days}:${hrs < 10 ? '0' + hrs : hrs}:${min < 10 ? '0' + min : min}:${sec < 10 ? '0' + sec : sec}`;
}

function validateUrl(url) {
  const regex = /^(https?:\/\/)[\w-]+\.[\w.-]+(?:\/[\w.,@?^=%&:/~+#-]*)?$/;
  return regex.test(url.trim().toLowerCase());
}

function formatFileSize(sizeInBytes) {
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  let size = sizeInBytes;
  let unitIndex = 0;

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }

  return size.toFixed(2) + ' ' + units[unitIndex];
}

function splitLabelInSections(str, maxWidth) {
  let sections = [];
  let words = str.split(' ');
  let temp = '';
  const textMaxWidth = maxWidth - 20;
  words.forEach((item, index) => {
    if (temp.length > 0) {
      let concat = `${temp} ${item}`;
      if (concat.length > textMaxWidth) {
        sections.push(temp + '     ');
        temp = '';
      } else {
        if (index === words.length - 1) {
          sections.push(concat + '     ');
          return;
        } else {
          temp = concat;
          return;
        }
      }
    }
    if (index === words.length - 1) {
      sections.push(item + '     ');
      return;
    }
    if (item.length < textMaxWidth) {
      temp = item;
    } else {
      sections.push(item + '     ');
    }
  });

  return sections;
}

export default { hash64, generatePassword, formatSeconds, validateUrl, formatFileSize, splitLabelInSections };
