import React from 'react';
import { connect } from 'react-redux';
import Image from '../../common/Image';
import landscapeImg from '../../assets/images/feed_landscape.png';
import BackgroundAssetLabel from '../../common/BackgroundAssetLabel';
import { getPopulatedList } from '../../redux/assetsRedux';

function BackgroundAssetPreview({ selectedLayout, assetsList }) {
  let backgroundAssetName;
  let backgroundAssetType;
  let imageFileKey;
  if (selectedLayout && assetsList.length) {
    const { backgroundVideoUrl, backgroundImageUrl } = selectedLayout;
    if (backgroundVideoUrl) {
      const asset = assetsList.find((a) => a.fileUrl === backgroundVideoUrl);
      backgroundAssetName = asset ? asset.name : '';
      backgroundAssetType = 'video';
    } else if (backgroundImageUrl) {
      const asset = assetsList.find((a) => a.fileUrl === backgroundImageUrl);
      backgroundAssetName = asset ? asset.name : '';
      imageFileKey = new URL(backgroundImageUrl).pathname.substring(1);
      backgroundAssetType = 'image';
    }
  }

  return backgroundAssetName ? (
    <div className='background-asset-preview'>
      <div className='asset' style={{ position: 'relative', margin: 0 }} title={backgroundAssetName}>
        {backgroundAssetType === 'image' ? (
          <Image source={imageFileKey} alt='background image' responsive maxWidth={160} maxHeight={90} style={{ border: '2px solid #287737' }} />
        ) : (
          <img src={landscapeImg} alt='background video' width='100%' style={{ border: '2px solid #287737' }} />
        )}
        <BackgroundAssetLabel label='BG' color='rgb(40,119,55)' />
        <div className='asset-name' style={{ fontSize: 13 }}>
          {backgroundAssetName}
        </div>
      </div>
    </div>
  ) : null;
}

const mapStateToProps = (state) => ({
  assetsList: getPopulatedList(state),
});

export default connect(mapStateToProps)(BackgroundAssetPreview);
