import React, { PureComponent } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DropDown from './DropDown';
import settingsIcon from '../assets/icons/settings-alt.svg';
import usersIcon from '../assets/icons/users.svg';
import logoutIcon from '../assets/icons/logout.svg';
import apiKeysIcon from '../assets/icons/api-keys.svg';
import refreshIcon from '../assets/icons/refresh.svg';
import systemEvents from '../assets/icons/system-events.svg';
import disconnectedIcon from '../assets/icons/disconnected.svg';
import { logout } from '../redux/authRedux';
import { setActiveStudio, setLiveStreams } from '../redux/studioRedux';
import { setActiveEvent, setLiveLayout } from '../redux/eventsRedux';
import { goLiveSuccess, endLiveSuccess } from '../redux/goLiveRedux';
import { disableRoutes, disableLiveRoutes, applyUpdates, loadTopBar, enableLiveRoutes, setSocketServerStatus } from '../redux/uiRedux';
import Button from './Button';
import studioIcon from '../assets/images/navbar/studio.svg';
import eventsIcon from '../assets/images/navbar/events.svg';
import liveLabel from '../assets/icons/live_label.png';
import SocketClient from '../utils/socket-client';
import { get } from '../services/api';

class TopBar extends PureComponent {
  componentDidMount() {
    if (this.props.appMode === 'BROADCAST') {
      this._getStreamsTimerId = setInterval(this._getStreams, 5000);
    }

    this.props.loadTopBar();

    const _checkSocketConnection = () => {
      const connected = SocketClient.socket && SocketClient.socket.connected;
      if (connected !== this.props.socketConnected) {
        this.props.setSocketServerStatus(connected);
      }
    };

    _checkSocketConnection();
    this._timerId = setInterval(_checkSocketConnection, 5000);
  }

  _setupSocketEvents = () => {
    const { activeStudio, setActiveStudio, enableLiveRoutes, disableLiveRoutes, goLiveSuccess, endLiveSuccess, activeEvent, setLiveLayout } = this.props;
    if (activeStudio && activeEvent) {
      SocketClient.joinRoom(`${activeStudio._id}:${activeEvent._id}:admin-app-sync`);
      this._onAdminAppSync = (data) => {
        if (data.type === 'GO_LIVE') {
          setActiveStudio({
            ...activeStudio,
            liveEvent: data.payload.eventId,
          });
          setLiveLayout(null);
          enableLiveRoutes();
          goLiveSuccess();
        } else if (data.type === 'END_LIVE') {
          setActiveStudio({
            ...activeStudio,
            liveEvent: null,
          });
          setLiveLayout(null);
          disableLiveRoutes();
          endLiveSuccess();
        }
      };
      this._onDirectConnectAvailable = (isAvailable) => {
        if (!isAvailable) {
          swal({
            title: 'Direct Connect Unavailable',
            text: 'Please be aware that the studio gateway is connected to AWS via the public internet. Please expect processes to auto-restart if they were running.',
          });
        } else {
          swal({
            title: 'Direct Connect Available',
            text: 'The studio gateway is connected to AWS via direct connect.',
          });
        }
      };
      SocketClient.on('admin-app-sync', this._onAdminAppSync);
      SocketClient.on('direct-connect-available', this._onDirectConnectAvailable);
    }
  };

  _getStreams = async () => {
    try {
      const { data } = await get('/studio/listStreams');
      if (data && data.streams) {
        this.props.setLiveStreams(data.streams);
      }
    } catch (e) {
      console.log(e);
    }
  };

  async componentDidUpdate(prevProps) {
    const { activeStudio, activeEvent, pageTitle, socketConnected } = this.props;

    if (!prevProps.socketConnected && socketConnected === true && activeStudio && activeEvent) {
      this._setupSocketEvents();
    } else if (
      ((!prevProps.activeStudio && activeStudio) ||
        (!prevProps.activeEvent && activeEvent) ||
        (prevProps.activeStudio && activeStudio && prevProps.activeStudio._id !== activeStudio._id) ||
        (prevProps.activeEvent && activeEvent && prevProps.activeEvent._id !== activeEvent._id)) &&
      socketConnected === true
    ) {
      if (prevProps.activeStudio && prevProps.activeEvent) {
        SocketClient.leaveRoom(`${prevProps.activeStudio._id}:${prevProps.activeEvent._id}:admin-app-sync`);
        SocketClient.removeAllListeners();
        SocketClient.off('admin-app-sync', this._onAdminAppSync);
        SocketClient.off('direct-connect-available', this._onDirectConnectAvailable);
      }
      this._setupSocketEvents();
    }
    window.document.title = pageTitle ? `Pando Admin - ${pageTitle}` : 'Pando Admin';
  }

  componentWillUnmount() {
    clearInterval(this._timerId);
    clearInterval(this._getStreamsTimerId);

    const { activeStudio, activeEvent } = this.props;
    if (activeStudio && activeEvent) {
      SocketClient.leaveRoom(`${activeStudio._id}:${activeEvent._id}:admin-app-sync`);
    }
    SocketClient.removeAllListeners();
    SocketClient.off('admin-app-sync', this._onAdminAppSync);
    SocketClient.off('direct-connect-available', this._onDirectConnectAvailable);
  }

  _logout = () => {
    this.props.logout();
    this.props.setActiveEvent(null);
    this.props.setActiveStudio(null);
  };

  _applyPendingUpdates = () => {
    if (this.props.updates.length) {
      this.props.applyUpdates(this.props.updates);
    }
  };

  _switchModes = () => {
    this.props.setActiveEvent(null);
    this.props.setActiveStudio(null);
    delete axios.defaults.headers['x-pando-studio-id'];
    localStorage.removeItem('activeStudio');
    localStorage.removeItem('activeEvent');
    window.location = '/app';
  };

  render() {
    const { appMode, showSettings, showSystemEvents, activeStudio, pageTitle, activeEvent, updates, socketConnected, user } = this.props;

    let settingsMenu = null;
    settingsMenu = (
      <DropDown label='Settings' icon={settingsIcon} containerStyle={{ width: 100, marginLeft: 35 }}>
        <Link to='/app/users' style={{ textDecoration: 'none', width: '100%' }}>
          <div className='settings-menu-item'>
            <img src={usersIcon} alt='icon' width='20' height='20' />
            <span>Users</span>
          </div>
        </Link>
        {showSettings && (
          <Link to='/app/apiKeys' style={{ textDecoration: 'none', width: '100%' }}>
            <div className='settings-menu-item'>
              <img src={apiKeysIcon} alt='icon' width='20' height='20' />
              <span>API Keys</span>
            </div>
          </Link>
        )}
        {showSystemEvents && (
          <Link to='/app/systemEvents' style={{ textDecoration: 'none', width: '100%' }}>
            <div className='settings-menu-item'>
              <img src={systemEvents} alt='icon' width='20' height='20' />
              <span>System Events</span>
            </div>
          </Link>
        )}
      </DropDown>
    );

    let isLive = false;
    if (appMode === 'PANDO') {
      isLive = activeStudio && activeEvent && activeStudio.liveEvent && activeStudio.liveEvent === activeEvent._id;
    } else if (appMode === 'BROADCAST' && activeEvent) {
      isLive =
        activeEvent.broadcastStream &&
        this.props.liveStreams &&
        this.props.liveStreams.find((s) => s.channelArn === activeEvent.broadcastStream.channelArn) &&
        SocketClient.socket.connected &&
        !activeEvent.endTime;
    }

    return (
      <div className='top-bar'>
        <div className='breadcrumb'>
          {activeStudio ? (
            <span className='breadcrumb-item'>
              <img src={studioIcon} alt='studio' />
              <span>{activeStudio.name}</span>
            </span>
          ) : null}
          {activeEvent ? (
            <>
              <span>&rsaquo;</span>
              <span className='breadcrumb-item'>
                <img src={eventsIcon} alt='event' />
                <span>{activeEvent.name}</span>
                {isLive ? <img src={liveLabel} alt='live' style={{ width: 'auto', marginLeft: 10 }} /> : null}
              </span>
            </>
          ) : null}
        </div>
        <div className='header'>{pageTitle}</div>
        <div className='admin-settings'>
          <div className='socket-connection-status'>
            {!socketConnected && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={disconnectedIcon} alt='Socket server disconnected' width='20' height='20' style={{ marginTop: 3 }} />
                <span style={{ marginLeft: 8, fontSize: 15, color: '#e9070f' }}>Disconnected</span>
              </div>
            )}
          </div>
          {updates.length ? <Button onClick={this._applyPendingUpdates} text='Refresh' type='link' icon={refreshIcon} align='left' /> : null}
          {user && user.broadcastEnabled && (
            <div className='logout' onClick={this._switchModes}>
              <span style={{ marginLeft: 5 }}>Switch Modes</span>
            </div>
          )}
          {appMode === 'PANDO' && settingsMenu}
          <div className='logout' onClick={this._logout}>
            <img src={logoutIcon} alt='Logout' width='20' height='20' />
            <span style={{ marginLeft: 5 }}>Logout</span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  appMode: state.ui.appMode,
  activeStudio: state.studio.active,
  activeEvent: state.events.active,
  updates: state.ui.updates,
  socketConnected: state.ui.socketConnected,
  liveStreams: state.studio.liveStreams,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  setActiveStudio: (studio) => dispatch(setActiveStudio(studio)),
  setActiveEvent: (event) => dispatch(setActiveEvent(event)),
  disableRoutes: () => dispatch(disableRoutes()),
  disableLiveRoutes: () => dispatch(disableLiveRoutes()),
  applyUpdates: (updates) => dispatch(applyUpdates(updates)),
  loadTopBar: () => dispatch(loadTopBar()),
  enableLiveRoutes: () => dispatch(enableLiveRoutes()),
  goLiveSuccess: () => dispatch(goLiveSuccess()),
  endLiveSuccess: () => dispatch(endLiveSuccess()),
  setLiveLayout: (id) => dispatch(setLiveLayout(id)),
  setSocketServerStatus: (status) => dispatch(setSocketServerStatus(status)),
  setLiveStreams: (streams) => dispatch(setLiveStreams(streams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
