// Primary colors
const BLUE = 'rgb(0, 77, 113)';
const PURPLE = 'rgb(141, 14, 87)';
const ORANGE = 'rgb(255, 131, 0)';
// Secondary colors
const GREEN = 'rgb(0,157,79)';
const LIGHT_GREEN = 'rgb(117,188, 67)';
const RED = 'rgb(215,40,47)';
const YELLOW = 'rgb(246, 206, 62)';

export { BLUE, PURPLE, ORANGE, GREEN, LIGHT_GREEN, RED, YELLOW };

export const pieScoreColors = ['#3FB4B3', '#FD8D32', '#FC4871'];

export const engagementChartColors = [
  'rgba(253, 141, 50, 0.7)',
  'rgba(63, 180, 179, 0.7)',
  'rgba(252, 72, 113, 0.7)',
  'rgba(45, 143, 230, 0.7)',
  'rgba(134, 71, 255, 0.7)',
  'rgba(254, 196, 69, 0.7)',
  'rgba(189, 191, 196, 0.7)',
];

export const defaultColors = [
  'rgb(45, 143, 230)',
  'rgb(252, 72, 113)',
  'rgb(63, 180, 179)',
  'rgb(253, 141, 50)',
  'rgb(134, 71, 255)',
  'rgb(254, 196, 69)',
  'rgb(189, 191, 196)',
];

export const defaultColorsAlpha = [
  'rgba(45, 143, 230, 0.7)',
  'rgba(252, 72, 113, 0.7)',
  'rgba(63, 180, 179, 0.7)',
  'rgba(253, 141, 50, 0.7)',
  'rgba(134, 71, 255, 0.7)',
  'rgba(254, 196, 69, 0.7)',
  'rgba(189, 191, 196, 0.7)',
];

export function getColorScale(numColors) {
  return defaultColors.slice(0, Math.min(defaultColors.length, numColors));
}

export function getDatasetConfig(datasets, data, dataLabel, colorScale) {
  let _datasets = [];
  if (datasets) {
    _datasets = datasets.map((dataset, index) => ({
      ...dataset,
      backgroundColor: colorScale ? colorScale[index] : defaultColorsAlpha[index],
      borderColor: colorScale[index] ? colorScale[index].replace('0.7', '1') : defaultColors[index],
      borderWidth: 1,
    }));
  } else {
    _datasets = [
      {
        data,
        label: dataLabel,
        backgroundColor: colorScale,
        borderColor: colorScale,
        borderWidth: 1,
      },
    ];
  }
  return _datasets;
}

export function getDefaultPluginConfig(title, displayLegend = true) {
  return {
    legend: {
      position: 'bottom',
      display: displayLegend,
    },
    title: {
      display: title && title.length,
      text: title,
      font: {
        size: 14,
        weight: 400,
      },
    },
  };
}
