import React from 'react';

const ActivateIcon = ({ width, height, color }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={`${width}px`} height={`${height}px`} viewBox='0 0 32 32' fill='none'>
    <path
      style={{ fill: color || '#4D637A' }}
      d='M0.7,17.1l3.8-3.8c0.4-0.4,1-0.4,1.4,0l4.6,4.6c0.4,0.4,1,0.4,1.4,0L26.1,3.7c0.4-0.4,1-0.4,1.4,0l3.8,3.8
	c0.4,0.4,0.4,1,0,1.4L11.9,28.3c-0.4,0.4-1,0.4-1.4,0l-9.8-9.8C0.3,18.1,0.3,17.5,0.7,17.1z'
    />
  </svg>
);

export default ActivateIcon;
