import React, { PureComponent } from 'react';

class Draggable extends PureComponent {
  state = {
    isDragging: false,
  };

  _targetRef = React.createRef();

  _onDragStart = (event) => {
    const { dataTransfer, onDragStart } = this.props;
    event.dataTransfer.setData('text/plain', JSON.stringify(dataTransfer));
    event.dataTransfer.effectAllowed = 'move';

    this.setState({
      isDragging: true,
    });
    onDragStart && onDragStart();
  };

  _onDragEnd = () => {
    const { onDragEnd } = this.props;
    this.setState({
      isDragging: false,
    });
    this._targetRef.current.style.backgroundColor = '';
    onDragEnd && onDragEnd();
  };

  _onDrag = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  render() {
    const { children } = this.props;
    return React.cloneElement(React.Children.only(children), {
      ref: this._targetRef,
      draggable: true,
      onDragStart: this._onDragStart,
      onDragEnd: this._onDragEnd,
      onDrag: this._onDrag,
    });
  }
}

export default Draggable;
