import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setLayoutAreas, selectArea } from '../../redux/layoutsRedux';
import LayoutArea from './LayoutArea';
import LayoutAreaSelection from './LayoutAreaSelection';
import { findEventParticipantById } from '../../utils/find-event-participant';

class LayoutAreas extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedArea: null, selectedAreas: [] };
  }

  _onDrop = async (areaIndex, { data, sourceType }) => {
    const { areas, setLayoutAreas } = this.props;
    const area = areas[areaIndex];
    area.sourceType = sourceType;
    if (sourceType === 'Participant') {
      if (data.wallDisplayNum) {
        area.data = { wallDisplayNum: data.wallDisplayNum };
      } else {
        const ep = findEventParticipantById(data._id);
        if (ep) {
          area.data = { wallDisplayNum: ep.wallDisplayNum };
        }
      }
    } else {
      area.data = data || {};
      area.data.areaIndex = areaIndex;
    }

    setLayoutAreas(areas);
  };

  _assignParticipant = (areaIndex, num) => {
    const { areas, setLayoutAreas } = this.props;
    const prevAssignmentIndex = areas.findIndex((a) => a.data && a.data.wallDisplayNum === num);
    if (prevAssignmentIndex !== -1) {
      areas[prevAssignmentIndex].sourceType = 'WallParticipant';
      areas[prevAssignmentIndex].data = null;
    }
    const area = areas[areaIndex];
    area.sourceType = 'Participant';
    area.data = {};
    area.data.wallDisplayNum = num;
    setLayoutAreas(areas);
  };

  _unassignParticipant = (areaIndex) => {
    const { setLayoutAreas, areas } = this.props;
    if (this.state.selectedAreas.length > 0) {
      const sortedSelectedAreas = this.state.selectedAreas.sort((a, b) => a - b);
      for (const area of sortedSelectedAreas) {
        const currentArea = areas[area];
        currentArea.sourceType = 'WallParticipant';
        currentArea.data = null;
      }
      this.setState({ selectedAreas: [] });
    } else {
      const area = areas[areaIndex];
      area.sourceType = 'WallParticipant';
      area.data = null;
    }
    setLayoutAreas(areas);
  };

  _removeArea = (areas, areaIndex) => {
    const area = areas[areaIndex];
    area.sourceType = null;
    area.data = null;
    delete area.padding;

    const { gridColumnStart, gridColumnEnd, gridRowStart, gridRowEnd } = area;
    const numTvColumns = gridColumnEnd - gridColumnStart;
    const numTvRows = gridRowEnd - gridRowStart;

    const halves = area.width === 1 / 2 && area.height === 1;
    const thirds = area.width === 1 / 3 && area.height === 1;
    const quadrant = area.width === 1 / 2 && area.height === 1 / 2;
    const sextuplet = area.width === 1 / 3 && area.height === 1 / 2;

    // If the source is larger than 1 TV
    if (numTvColumns > 6 || numTvRows > 2) {
      areas.splice(areaIndex, 1);
      for (let row = 2; row <= numTvRows; row += 2) {
        for (let col = 6; col <= numTvColumns; col += 6) {
          areas.splice(areaIndex, 0, {
            sourceType: null,
            data: null,
            width: 1,
            height: 1,
            gridColumnStart: gridColumnStart + col - 6,
            gridColumnEnd: gridColumnStart + col,
            gridRowStart: gridRowStart + row - 2,
            gridRowEnd: gridRowStart + row,
          });
        }
      }
    } else if (halves || thirds || quadrant || sextuplet) {
      const beginTvColumn = Math.ceil(gridColumnStart / 6);
      const endTvColumn = beginTvColumn + 1;

      const beginTvRow = Math.ceil(gridRowStart / 2);
      const endTvRow = beginTvRow + 1;

      const emptyTvAreas = [];
      areas.forEach((area, index) => {
        const currentColumnTv = Math.ceil(area.gridColumnStart / 6);
        const currentRowTv = Math.ceil(area.gridRowStart / 2);
        if (!area.sourceType && currentColumnTv >= beginTvColumn && currentColumnTv < endTvColumn) {
          if (currentRowTv >= beginTvRow && currentRowTv < endTvRow) {
            emptyTvAreas.push(index);
          }
        }
      });

      let removeEmptyTvAreas = false;

      if (emptyTvAreas.length === 2 && halves) {
        removeEmptyTvAreas = true;
      } else if (emptyTvAreas.length === 3 && thirds) {
        removeEmptyTvAreas = true;
      } else if (emptyTvAreas.length === 4 && quadrant) {
        removeEmptyTvAreas = true;
      } else if (emptyTvAreas.length === 6 && sextuplet) {
        removeEmptyTvAreas = true;
      }

      if (removeEmptyTvAreas) {
        const newAreaData = {
          sourceType: null,
          data: null,
          width: 1,
          height: 1,
          gridColumnStart: (beginTvColumn - 1) * 6 + 1,
          gridColumnEnd: (endTvColumn - 1) * 6 + 1,
          gridRowStart: (beginTvRow - 1) * 2 + 1,
          gridRowEnd: (endTvRow - 1) * 2 + 1,
        };
        areas = areas.filter((_, index) => {
          return emptyTvAreas.indexOf(index) === -1;
        });
        areas.splice(emptyTvAreas[0], 0, newAreaData);
      }
    }
    return areas;
  };

  _removeSource = (areaIndex) => {
    const { setLayoutAreas, areas } = this.props;
    let _areas = [...areas];
    if (this.state.selectedAreas.length > 0) {
      const sortedSelectedAreas = this.state.selectedAreas.sort((a, b) => a - b);
      let offset = 0;
      let originalLength = _areas.length;
      for (const area of sortedSelectedAreas) {
        _areas = this._removeArea(_areas, area + offset);
        const newLength = _areas.length;
        if (originalLength !== newLength) {
          const lengthDiff = originalLength - newLength;
          if (lengthDiff < 0) {
            offset += Math.abs(lengthDiff);
          } else {
            offset -= lengthDiff;
          }
          originalLength = newLength;
        }
      }
      this.setState({ selectedAreas: [] });
    } else {
      _areas = this._removeArea(_areas, areaIndex);
    }
    setLayoutAreas(_areas);
  };

  _cropArea = (areas, areaIndex, width, height, crop) => {
    const area = areas[areaIndex];
    area.crop = crop;
    area.width = width;
    area.height = height;
    area.gridColumnEnd = area.gridColumnStart + (area.gridColumnEnd - area.gridColumnStart) * width;
    area.gridRowEnd = area.gridRowStart + (area.gridRowEnd - area.gridRowStart) * height;

    const { gridColumnEnd, gridRowStart, gridRowEnd } = area;

    const halves = area.width === 1 / 2 && area.height === 1;
    const thirds = area.width === 1 / 3 && area.height === 1;
    const quadrant = area.width === 1 / 2 && area.height === 1 / 2;
    const sextuplet = area.width === 1 / 3 && area.height === 1 / 2;

    if (halves) {
      areas.splice(areaIndex + 1, 0, { width, height, crop, gridColumnStart: gridColumnEnd, gridColumnEnd: gridColumnEnd + 3, gridRowStart, gridRowEnd });
    } else if (thirds) {
      areas.splice(
        areaIndex + 1,
        0,
        { width, height, crop, gridColumnStart: gridColumnEnd, gridColumnEnd: gridColumnEnd + 2, gridRowStart, gridRowEnd },
        { width, height, crop, gridColumnStart: gridColumnEnd + 2, gridColumnEnd: gridColumnEnd + 4, gridRowStart, gridRowEnd },
      );
    } else if (quadrant) {
      areas.splice(
        areaIndex + 1,
        0,
        { width, height, crop, gridColumnStart: gridColumnEnd, gridColumnEnd: gridColumnEnd + 3, gridRowStart, gridRowEnd },
        { width, height, crop, gridColumnStart: area.gridColumnStart, gridColumnEnd, gridRowStart: gridRowStart + 1, gridRowEnd: gridRowEnd + 1 },
        { width, height, crop, gridColumnStart: gridColumnEnd, gridColumnEnd: gridColumnEnd + 3, gridRowStart: gridRowStart + 1, gridRowEnd: gridRowEnd + 1 },
      );
    } else if (sextuplet) {
      areas.splice(
        areaIndex + 1,
        0,
        { width, height, crop, gridColumnStart: gridColumnEnd, gridColumnEnd: gridColumnEnd + 2, gridRowStart, gridRowEnd },
        { width, height, crop, gridColumnStart: gridColumnEnd + 2, gridColumnEnd: gridColumnEnd + 4, gridRowStart, gridRowEnd },
        { width, height, crop, gridColumnStart: area.gridColumnStart, gridColumnEnd, gridRowStart: gridRowStart + 1, gridRowEnd: gridRowEnd + 1 },
        { width, height, crop, gridColumnStart: gridColumnEnd, gridColumnEnd: gridColumnEnd + 2, gridRowStart: gridRowStart + 1, gridRowEnd: gridRowEnd + 1 },
        {
          width,
          height,
          crop,
          gridColumnStart: gridColumnEnd + 2,
          gridColumnEnd: gridColumnEnd + 4,
          gridRowStart: gridRowStart + 1,
          gridRowEnd: gridRowEnd + 1,
        },
      );
    }
    return areas;
  };

  _onAreaDivision = (areaIndex, width, height, crop) => {
    const { setLayoutAreas, areas } = this.props;
    let _areas = [...areas];
    if (this.state.selectedAreas.length > 0) {
      const sortedSelectedAreas = this.state.selectedAreas.sort((a, b) => a - b);
      const halves = width === 1 / 2 && height === 1;
      const thirds = width === 1 / 3 && height === 1;
      const quadrant = width === 1 / 2 && height === 1 / 2;
      const sextuplet = width === 1 / 3 && height === 1 / 2;
      let offset = 0;
      for (const area of sortedSelectedAreas) {
        const currentArea = areas[area];
        if (currentArea.width === 1 && currentArea.height === 1) {
          _areas = this._cropArea(_areas, area + offset, width, height, crop);
          if (halves) {
            offset += 1;
          } else if (thirds) {
            offset += 2;
          } else if (quadrant) {
            offset += 3;
          } else if (sextuplet) {
            offset += 5;
          }
        }
      }
      this.setState({ selectedAreas: [] });
    } else {
      _areas = this._cropArea(_areas, areaIndex, width, height, crop);
    }
    setLayoutAreas(_areas);
  };

  _onEdit = (areaIndex, { top, left, width, height }) => {
    const { selectArea } = this.props;
    selectArea({ areaIndex, top, left, width, height });
  };

  _updateSelection = ({ top, left, width, height }) => {
    const { selectedArea } = this.props;
    this.props.selectArea({ ...selectedArea, top, left, width, height });
  };

  _onAreaClick = (event, areaIndex) => {
    const { areas } = this.props;
    const { selectedAreas } = this.state;

    if (areas[areaIndex].sourceType) {
      const index = selectedAreas.indexOf(areaIndex);
      if (event.metaKey || event.ctrlKey) {
        if (index === -1) {
          this.setState({ selectedAreas: [...selectedAreas, areaIndex] });
        } else {
          this.setState({ selectedAreas: selectedAreas.filter((_, i) => i !== index) });
        }
      } else {
        this.setState({ selectedAreas: areaIndex === selectedAreas[index] ? [] : [areaIndex] });
      }
    } else {
      this.setState({ selectedAreas: [] });
    }
  };

  render() {
    const { areas, rows, columns, width, height, selectedArea } = this.props;
    const { selectedAreas } = this.state;
    const tvWidth = width / columns;
    const tvHeight = height / rows;

    const layoutAreas = [];

    areas.forEach((area, areaIndex) => {
      const { sourceType, crop, data, gridRowStart, gridRowEnd, gridColumnStart, gridColumnEnd, padding, width, height } = area;
      layoutAreas.push(
        <LayoutArea
          key={`area-${areaIndex}`}
          areaIndex={areaIndex}
          style={{
            gridRowStart,
            gridRowEnd,
            gridColumnStart,
            gridColumnEnd,
            height: 'auto',
            position: 'relative',
          }}
          rows={rows}
          columns={columns}
          onDrop={this._onDrop}
          onAreaDivision={this._onAreaDivision}
          onEdit={this._onEdit}
          onClick={this._onAreaClick}
          isSelected={selectedAreas.includes(areaIndex)}
          isMultiSelected={selectedAreas.length > 1}
          removeSource={this._removeSource}
          assignParticipant={this._assignParticipant}
          unassignParticipant={this._unassignParticipant}
          tvWidth={tvWidth}
          tvHeight={tvHeight}
          sourceType={sourceType}
          crop={crop}
          data={data}
          padding={padding}
          width={width}
          height={height}
        />,
      );
    });

    return (
      <div
        ref={this._gridRef}
        id='layout-areas'
        className='grid'
        style={{
          width: '100%',
          height: '100%',
          gridTemplateColumns: `repeat(${columns * 6}, 1fr)`,
          gridTemplateRows: `repeat(${rows * 2}, 1fr)`,
        }}
      >
        {layoutAreas}
        {selectedArea && (
          <LayoutAreaSelection
            area={selectedArea}
            maxWidth={width}
            maxHeight={height}
            onStopScaling={this._updateSelection}
            onDragEnd={this._updateSelection}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  areas: state.layouts.areas,
  selectedArea: state.layouts.selectedArea,
  currentLayoutId: state.layouts.current,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ setLayoutAreas, selectArea }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LayoutAreas);
