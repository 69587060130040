import _ from 'lodash';
import { createActions, handleActions } from 'redux-actions';
import StringUtils from '../utils/string-utils';

const initialState = {
  event: null,
  entities: {},
  studioLayouts: [],
  eventLayouts: [],
  current: null,
  grid: {},
  backgroundVideoUrl: null,
  backgroundImageUrl: null,
  areas: [],
  participantCount: 0,
  fetching: false,
  error: null,
  selected: null,
  editing: false,
  newLayoutId: undefined,
  selectedArea: null,
  areaSelection: null,
  horizontalTvLines: [],
  verticalTvLines: [],
  snapIntoGrid: true,
  updated: null,
  newHashCode: null,
};

export const actions = {
  CLEAR_LAYOUT: 'CLEAR_LAYOUT',
  SET_GRID_COLOR: 'SET_GRID_COLOR',
  SET_EVENT: 'SET_EVENT',
  GET_LAYOUTS_REQUEST: 'GET_LAYOUTS_REQUEST',
  GET_LAYOUTS_SUCCESS: 'GET_LAYOUTS_SUCCESS',
  GET_LAYOUTS_FAILURE: 'GET_LAYOUTS_FAILURE',
  SET_LAYOUT: 'SET_LAYOUT',
  GET_LAYOUT: 'GET_LAYOUT',
  CREATE_LAYOUT_REQUEST: 'CREATE_LAYOUT_REQUEST',
  CREATE_LAYOUT_SUCCESS: 'CREATE_LAYOUT_SUCCESS',
  CREATE_LAYOUT_FAILURE: 'CREATE_LAYOUT_FAILURE',
  UPDATE_LAYOUT_REQUEST: 'UPDATE_LAYOUT_REQUEST',
  UPDATE_LAYOUT_SUCCESS: 'UPDATE_LAYOUT_SUCCESS',
  UPDATE_LAYOUT_FAILURE: 'UPDATE_LAYOUT_FAILURE',
  DELETE_LAYOUT_REQUEST: 'DELETE_LAYOUT_REQUEST',
  DELETE_LAYOUT_SUCCESS: 'DELETE_LAYOUT_SUCCESS',
  DELETE_LAYOUT_FAILURE: 'DELETE_LAYOUT_FAILURE',
  SET_SELECTED: 'SET_SELECTED',
  ORDER_LAYOUTS: 'ORDER_LAYOUTS',
  UPDATE_LAYOUTS_ORDER_REQUEST: 'UPDATE_LAYOUTS_ORDER_REQUEST',
  UPDATE_LAYOUTS_ORDER_SUCCESS: 'UPDATE_LAYOUTS_ORDER_SUCCESS',
  UPDATE_LAYOUTS_ORDER_FAILURE: 'UPDATE_LAYOUTS_ORDER_FAILURE',
  RESET_LAYOUT: 'RESET_LAYOUT',
  LOAD_AREAS: 'LOAD_AREAS',
  LOAD_LAYOUT_SUCCESS: 'LOAD_LAYOUT_SUCCESS',
  LOAD_LAYOUT_FAILURE: 'LOAD_LAYOUT_FAILURE',
  SET_LAYOUT_AREAS: 'SET_LAYOUT_AREAS',
  SELECT_AREA: 'SELECT_AREA',
  APPLY_SELECTION: 'APPLY_SELECTION',
  UPDATE_SELECTION: 'UPDATE_SELECTION',
  SET_TV_LINES: 'SET_TV_LINES',
  SET_SNAP_INTO_GRID: 'SET_SNAP_INTO_GRID',
  UPDATE_LAYOUT_AREA_DATA: 'UPDATE_LAYOUT_AREA_DATA',
  DUPLICATE_LAYOUT_REQUEST: 'DUPLICATE_LAYOUT_REQUEST',
  DUPLICATE_LAYOUT_SUCCESS: 'DUPLICATE_LAYOUT_SUCCESS',
  DUPLICATE_LAYOUT_FAILURE: 'DUPLICATE_LAYOUT_FAILURE',
  SET_BACKGROUND_VIDEO: 'SET_BACKGROUND_VIDEO',
  SET_BACKGROUND_IMAGE_URL: 'SET_BACKGROUND_IMAGE_URL',
  SET_UPDATED_LAYOUT_CONFIG: 'SET_UPDATED_LAYOUT_CONFIG',
};

export const getLayoutById = (state, id) => {
  const { entities } = state.layouts;
  if (id) {
    return entities[id];
  }
  return undefined;
};

export const {
  clearLayout,
  setGridColor,
  setEvent,
  getLayoutsRequest,
  getLayoutsSuccess,
  getLayoutsFailure,
  setLayout,
  getLayout,
  createLayoutRequest,
  createLayoutSuccess,
  createLayoutFailure,
  updateLayoutRequest,
  updateLayoutSuccess,
  updateLayoutFailure,
  deleteLayoutRequest,
  deleteLayoutSuccess,
  deleteLayoutFailure,
  setSelected,
  orderLayouts,
  updateLayoutsOrderRequest,
  updateLayoutsOrderSuccess,
  updateLayoutsOrderFailure,
  resetLayout,
  loadAreas,
  loadLayoutSuccess,
  loadLayoutFailure,
  setLayoutAreas,
  selectArea,
  applySelection,
  updateSelection,
  setTvLines,
  setSnapIntoGrid,
  updateLayoutAreaData,
  duplicateLayoutRequest,
  duplicateLayoutSuccess,
  duplicateLayoutFailure,
  setBackgroundVideo,
  setBackgroundImageUrl,
  setUpdatedLayoutConfig,
} = createActions({
  [actions.CLEAR_LAYOUT]: (id, rows, columns) => ({ id, rows, columns }),
  [actions.SET_GRID_COLOR]: (color) => ({ color }),
  [actions.SET_EVENT]: (id) => ({ id }),
  [actions.GET_LAYOUTS_REQUEST]: (studioId, eventId) => ({ studioId, eventId }),
  [actions.GET_LAYOUTS_SUCCESS]: (data) => ({ data }),
  [actions.GET_LAYOUTS_FAILURE]: (error) => ({ error }),
  [actions.SET_LAYOUT]: (layout, rows, columns) => ({ layout, rows, columns }),
  [actions.GET_LAYOUT]: (layout) => ({ layout }),
  [actions.CREATE_LAYOUT_REQUEST]: (layout) => ({ layout }),
  [actions.CREATE_LAYOUT_SUCCESS]: (data) => ({ data }),
  [actions.CREATE_LAYOUT_FAILURE]: (error) => ({ error }),
  [actions.UPDATE_LAYOUT_REQUEST]: (id, data) => ({ id, data }),
  [actions.UPDATE_LAYOUT_SUCCESS]: (data) => ({ data }),
  [actions.UPDATE_LAYOUT_FAILURE]: (error) => ({ error }),
  [actions.DELETE_LAYOUT_REQUEST]: (id) => ({ id }),
  [actions.DELETE_LAYOUT_SUCCESS]: (data) => ({ data }),
  [actions.DELETE_LAYOUT_FAILURE]: (error) => ({ error }),
  [actions.SET_SELECTED]: (id) => ({ id }),
  [actions.ORDER_LAYOUTS]: (oldIndex, newIndex, layoutId) => ({ oldIndex, newIndex, layoutId }),
  [actions.UPDATE_LAYOUTS_ORDER_REQUEST]: (list) => ({ list }),
  [actions.UPDATE_LAYOUTS_ORDER_SUCCESS]: (data) => ({ data }),
  [actions.UPDATE_LAYOUTS_ORDER_FAILURE]: (error) => ({ error }),
  [actions.RESET_LAYOUT]: () => ({}),
  [actions.LOAD_AREAS]: (data) => ({ data }),
  [actions.LOAD_LAYOUT_SUCCESS]: (data) => ({ data }),
  [actions.LOAD_LAYOUT_FAILURE]: () => ({}),
  [actions.SET_LAYOUT_AREAS]: (areas) => ({ areas }),
  [actions.SELECT_AREA]: (area) => ({ area }),
  [actions.APPLY_SELECTION]: (data) => ({ data }),
  [actions.UPDATE_SELECTION]: (data) => ({ data }),
  [actions.SET_TV_LINES]: (data) => ({ data }),
  [actions.SET_SNAP_INTO_GRID]: (data) => ({ data }),
  [actions.UPDATE_LAYOUT_AREA_DATA]: (areaIndex, data) => ({ areaIndex, data }),
  [actions.DUPLICATE_LAYOUT_REQUEST]: (layout, event) => ({ layout, event }),
  [actions.DUPLICATE_LAYOUT_SUCCESS]: (data) => ({ data }),
  [actions.DUPLICATE_LAYOUT_FAILURE]: (error) => ({ error }),
  [actions.SET_BACKGROUND_VIDEO]: (url, metadata) => ({ url, metadata }),
  [actions.SET_BACKGROUND_IMAGE_URL]: (url) => ({ url }),
  [actions.SET_UPDATED_LAYOUT_CONFIG]: (data) => ({ data }),
});

const reducer = handleActions(
  {
    [actions.SET_GRID_COLOR]: (state, action) => {
      const { grid } = state;
      const { color } = action.payload;
      grid.color = color;
      return {
        ...state,
        grid: { ...grid },
      };
    },
    [actions.SET_EVENT]: (state, action) => ({
      ...state,
      event: action.payload.id,
    }),
    [actions.CLEAR_LAYOUT]: (state, action) => {
      const { entities, backgroundVideoUrl, backgroundImageUrl } = state;
      const { id, rows, columns } = action.payload;
      const areas = [];
      for (let y = 0; y < rows * 2; y += 2) {
        for (let x = 0; x < columns * 6; x += 6) {
          areas.push({
            width: 1,
            height: 1,
            sourceType: null,
            gridRowStart: y + 1,
            gridRowEnd: y + 3,
            gridColumnStart: x + 1,
            gridColumnEnd: x + 7,
          });
        }
      }
      const newHashCode = StringUtils.hash64(JSON.stringify({ areas, backgroundVideoUrl, backgroundImageUrl }));
      entities[id].areas = areas;
      return {
        ...state,
        entities,
        areas,
        participantCount: 0,
        newHashCode,
      };
    },

    [actions.UPDATE_LAYOUT_AREA_DATA]: (state, action) => {
      const { areas, entities, current } = state;
      const { areaIndex, data } = action.payload;
      areas[areaIndex].data = data;
      entities[current].areas = areas;
      return {
        ...state,
        areas,
        entities,
      };
    },

    [actions.SET_LAYOUT_AREAS]: (state, action) => {
      const { backgroundVideoUrl, backgroundImageUrl } = state;
      const { areas } = action.payload;
      const participantCount = areas.filter((a) => a.sourceType === 'WallParticipant' || a.sourceType === 'Participant').length;
      areas
        .sort((a, b) => a.gridRowStart - b.gridRowStart || a.gridColumnStart - b.gridColumnStart)
        .forEach((area, index) => {
          if (!area.data) {
            area.data = {};
          }
          area.data.areaIndex = index;
        });
      const newHashCode = StringUtils.hash64(JSON.stringify({ areas, backgroundVideoUrl, backgroundImageUrl }));
      return {
        ...state,
        areas: [...areas],
        participantCount,
        newHashCode,
      };
    },

    [actions.SET_SNAP_INTO_GRID]: (state, action) => ({
      ...state,
      snapIntoGrid: action.payload.data,
    }),

    [actions.SET_TV_LINES]: (state, action) => {
      const { verticalTvLines, horizontalTvLines } = action.payload.data;
      return {
        ...state,
        horizontalTvLines,
        verticalTvLines,
      };
    },

    [actions.UPDATE_SELECTION]: (state, action) => {
      return {
        ...state,
        areaSelection: action.payload.data,
      };
    },

    [actions.APPLY_SELECTION]: (state, action) => {
      const { rows, columns } = action.payload.data;
      const { selectedArea, areas, backgroundImageUrl, backgroundVideoUrl } = state;

      const { sourceType, gridColumnStart, gridColumnEnd, gridRowStart, gridRowEnd, data } = areas[selectedArea.areaIndex];
      const layoutAreasRect = document.getElementById('layout-areas').getBoundingClientRect();
      const wallWidth = layoutAreasRect.width;
      const wallHeight = layoutAreasRect.height;

      const tvWidth = wallWidth / columns;
      const tvHeight = wallHeight / rows;

      const beginTvColumn = Math.floor(selectedArea.left / tvWidth);
      const beginTvRow = Math.floor(selectedArea.top / tvHeight);

      const numTvColumns = Math.ceil((selectedArea.left - beginTvColumn * tvWidth + selectedArea.width) / tvWidth);
      const numTvRows = Math.ceil((selectedArea.top - beginTvRow * tvHeight + selectedArea.height) / tvHeight);

      // console.log(selectedArea);
      // console.log('wall', wallWidth, wallHeight);
      // console.log('tv', tvWidth, tvHeight);
      // console.log('start', selectedArea.left / tvWidth, selectedArea.top / tvHeight);
      // console.log('end', selectedArea.left / tvWidth + numTvColumns, selectedArea.top / tvHeight + numTvRows);
      // console.log('num', numTvColumns, numTvRows);
      // console.log('grid', beginTvColumn, beginTvRow, beginTvColumn + numTvColumns, beginTvRow + numTvRows);

      // If we are scaling down to less number of TVs
      if ((gridRowEnd - gridRowStart) / 2 > numTvRows || (gridColumnEnd - gridColumnStart) / 6 > numTvColumns) {
        for (let row = 2; row <= gridRowEnd - gridRowStart; row += 2) {
          for (let col = 6; col <= gridColumnEnd - gridColumnStart; col += 6) {
            areas.splice(selectedArea.areaIndex + 1, 0, {
              width: 1,
              height: 1,
              gridColumnStart: gridColumnStart + col - 6,
              gridColumnEnd: gridColumnStart + col,
              gridRowStart: gridRowStart + row - 2,
              gridRowEnd: gridRowStart + row,
            });
          }
        }
      }

      const scaledAreaLeftPx = beginTvColumn * tvWidth;
      const scaledAreaTopPx = beginTvRow * tvHeight;
      const scaledAreaWidthPx = selectedArea.width < tvWidth ? tvWidth : numTvColumns * tvWidth;
      const scaledAreaHeightPx = selectedArea.height < tvHeight ? tvHeight : numTvRows * tvHeight;

      const top = Math.floor(selectedArea.top - scaledAreaTopPx);
      const left = Math.floor(selectedArea.left - scaledAreaLeftPx);
      const right = Math.floor(scaledAreaWidthPx - (left + selectedArea.width));
      const bottom = Math.floor(scaledAreaHeightPx - (top + selectedArea.height));

      const areaPadding = {
        top: top === 1 ? 0 : top / tvHeight,
        right: right === 1 ? 0 : right / tvWidth,
        bottom: bottom === 1 ? 0 : bottom / tvHeight,
        left: left === 1 ? 0 : left / tvWidth,
      };

      areas.forEach((area) => {
        const currentColumnTv = (area.gridColumnStart - 1) / 6;
        const currentRowTv = (area.gridRowStart - 1) / 2;
        if (currentColumnTv >= beginTvColumn && currentColumnTv < beginTvColumn + numTvColumns) {
          if (currentRowTv >= beginTvRow && currentRowTv < beginTvRow + numTvRows) {
            area.delete = true;
          }
        }
      });

      let area;
      let selectionInserted = false;
      for (let i = 0; i < areas.length; i++) {
        area = areas[i];
        if (area.delete) {
          if (!selectionInserted) {
            if (area.areaIndex !== selectedArea.areaIndex) {
              areas[selectedArea.areaIndex].delete = true;
            }
            area.sourceType = sourceType;
            area.width = numTvColumns;
            area.height = numTvRows;
            area.gridColumnStart = beginTvColumn * 6 + 1;
            area.gridRowStart = beginTvRow * 2 + 1;
            area.gridColumnEnd = area.gridColumnStart + numTvColumns * 6;
            area.gridRowEnd = area.gridRowStart + numTvRows * 2;
            area.padding = areaPadding;
            area.data = data;
            delete area.delete;
            selectionInserted = true;
            break;
          }
        }
      }

      let participantCount = 0;
      const filteredAreas = areas.filter((a) => !a.delete).sort((a, b) => a.gridRowStart - b.gridRowStart || a.gridColumnStart - b.gridColumnStart);
      filteredAreas.forEach((area, index) => {
        if (!area.data) {
          area.data = {};
        }
        if (area.sourceType === 'WallParticipant' || area.sourceType === 'Participant') {
          participantCount++;
        }
        area.data.areaIndex = index;
      });

      const newHashCode = StringUtils.hash64(JSON.stringify({ areas: filteredAreas, backgroundVideoUrl, backgroundImageUrl }));

      return {
        ...state,
        selectedArea: null,
        areas: filteredAreas,
        participantCount,
        newHashCode,
      };
    },

    [actions.SELECT_AREA]: (state, action) => {
      const { area } = action.payload;
      return {
        ...state,
        selectedArea: area,
      };
    },

    [actions.RESET_LAYOUT]: (state, action) => {
      return {
        ...state,
        areas: [],
        participantCount: 0,
      };
    },

    [actions.LOAD_AREAS]: (state, action) => {
      const { layout, rows, columns } = action.payload.data;
      const { entities } = state;
      const selectedLayout = entities[layout];

      let areas = [];
      let participantCount = 0;

      if (!selectedLayout.areas.length) {
        for (let y = 0; y < rows * 2; y += 2) {
          for (let x = 0; x < columns * 6; x += 6) {
            areas.push({
              width: 1,
              height: 1,
              sourceType: null,
              gridRowStart: y + 1,
              gridRowEnd: y + 3,
              gridColumnStart: x + 1,
              gridColumnEnd: x + 7,
            });
          }
        }
      } else {
        areas = selectedLayout.areas.map((area, index) => {
          const { _id, width, height, source, crop, gridRowStart, gridRowEnd, gridColumnStart, gridColumnEnd, padding, data } = area;

          let wallDisplayNum = area.wallDisplayNum;
          let sourceType = area.sourceType;

          if (typeof wallDisplayNum === 'undefined' && sourceType === 'Participant') {
            sourceType = 'WallParticipant';
            wallDisplayNum = null;
          }

          return {
            _id,
            width,
            height,
            sourceType,
            crop,
            gridRowStart,
            gridRowEnd,
            gridColumnStart,
            gridColumnEnd,
            padding,
            data: data ? data : sourceType === 'Participant' ? { areaIndex: index, wallDisplayNum } : { areaIndex: index, ...source },
          };
        });
        participantCount = selectedLayout.areas.filter((a) => a.sourceType === 'WallParticipant' || a.sourceType === 'Participant').length;
      }

      if (!selectedLayout.hashCode) {
        const { backgroundVideoUrl, backgroundImageUrl } = selectedLayout;
        entities[layout].hashCode = StringUtils.hash64(JSON.stringify({ areas, backgroundVideoUrl, backgroundImageUrl })).toString();
      }
      const newHashCode = entities[layout].hashCode;

      return {
        ...state,
        entities,
        areas: [...areas],
        grid: { ...selectedLayout.grid },
        backgroundVideoUrl: selectedLayout.backgroundVideoUrl,
        backgroundImageUrl: selectedLayout.backgroundImageUrl,
        participantCount,
        updated: null,
        newHashCode,
      };
    },
    [actions.LOAD_LAYOUT_SUCCESS]: (state, action) => ({
      ...state,
      current: action.payload.data,
    }),
    [actions.LOAD_LAYOUT_FAILURE]: (state) => ({
      ...state,
      current: null,
      grid: {},
      areas: [],
      participantCount: 0,
    }),
    [actions.GET_LAYOUTS_REQUEST]: (state) => ({
      ...state,
      fetching: true,
      error: null,
    }),
    [actions.GET_LAYOUTS_SUCCESS]: (state, action) => {
      const entities = {};
      const studioLayouts = [];
      const eventLayouts = [];

      const list = _.orderBy(action.payload.data, ['index', 'name'], ['asc', 'asc']).map((s) => s._id);
      list.forEach((id) => {
        const layout = action.payload.data.find((l) => l._id === id);
        if (layout.studioWall) {
          studioLayouts.push(id);
        } else {
          eventLayouts.push(id);
        }
        entities[id] = { ...layout };
      });
      return {
        ...state,
        entities,
        studioLayouts,
        eventLayouts,
        fetching: false,
        error: null,
      };
    },
    [actions.GET_LAYOUTS_FAILURE]: (state, action) => ({
      ...state,
      fetching: false,
      error: action.payload.error,
    }),
    [actions.CREATE_LAYOUT_REQUEST]: (state) => ({
      ...state,
      fetching: true,
      error: null,
      newLayoutId: undefined,
    }),
    [actions.CREATE_LAYOUT_SUCCESS]: (state, action) => {
      const { studioLayouts, eventLayouts, entities } = state;
      const { data } = action.payload;

      if (!data.studioWall) {
        eventLayouts.push(data._id);
      } else {
        studioLayouts.push(data._id);
      }
      entities[data._id] = { ...data };

      return {
        ...state,
        studioLayouts,
        eventLayouts,
        entities,
        newLayoutId: data._id,
        fetching: false,
        error: null,
      };
    },
    [actions.CREATE_LAYOUT_FAILURE]: (state, action) => ({
      ...state,
      fetching: false,
      error: action.payload.error,
      newLayoutId: undefined,
    }),
    [actions.UPDATE_LAYOUT_REQUEST]: (state) => ({
      ...state,
      fetching: true,
      error: null,
      updated: null,
    }),
    [actions.UPDATE_LAYOUT_SUCCESS]: (state, action) => {
      const { entities, studioLayouts, eventLayouts } = state;
      const updatedLayoutData = action.payload.data;
      const prevLayoutData = entities[updatedLayoutData._id];

      if (prevLayoutData && prevLayoutData.studioWall && !updatedLayoutData.studioWall) {
        studioLayouts.splice(studioLayouts.indexOf(updatedLayoutData._id), 1);
        eventLayouts.push(updatedLayoutData._id);
      } else if (prevLayoutData && !prevLayoutData.studioWall && updatedLayoutData.studioWall) {
        eventLayouts.splice(eventLayouts.indexOf(updatedLayoutData._id), 1);
        studioLayouts.push(updatedLayoutData._id);
      }

      let areas = [];
      areas = updatedLayoutData.areas.map((area, index) => {
        const { _id, width, height, sourceType, source, crop, gridRowStart, gridRowEnd, gridColumnStart, gridColumnEnd, padding, data, wallDisplayNum } = area;
        return {
          _id,
          width,
          height,
          sourceType,
          crop,
          gridRowStart,
          gridRowEnd,
          gridColumnStart,
          gridColumnEnd,
          padding,
          data: data ? data : sourceType === 'Participant' ? { areaIndex: index, wallDisplayNum } : { areaIndex: index, ...source },
        };
      });
      entities[updatedLayoutData._id] = updatedLayoutData;
      return {
        ...state,
        entities,
        areas,
        eventLayouts: [...eventLayouts],
        studioLayouts: [...studioLayouts],
        fetching: false,
        error: null,
        updated: true,
      };
    },
    [actions.UPDATE_LAYOUT_FAILURE]: (state, action) => ({
      ...state,
      fetching: false,
      updated: false,
      error: action.payload.error,
    }),
    [actions.DELETE_LAYOUT_REQUEST]: (state) => ({
      ...state,
      fetching: true,
      error: null,
    }),
    [actions.DELETE_LAYOUT_SUCCESS]: (state, action) => {
      const { id } = action.payload.data;
      const { entities, studioLayouts, eventLayouts } = state;
      if (!entities[id].studioWall) {
        eventLayouts.splice(eventLayouts.indexOf(id), 1);
      } else {
        studioLayouts.splice(studioLayouts.indexOf(id), 1);
      }
      delete entities[id];

      return {
        ...state,
        entities,
        eventLayouts: [...eventLayouts],
        studioLayouts: [...studioLayouts],
        current: id === state.current ? null : state.current,
        fetching: false,
        error: null,
      };
    },
    [actions.DELETE_LAYOUT_FAILURE]: (state, action) => ({
      ...state,
      fetching: false,
      error: action.payload.error,
    }),
    [actions.ORDER_LAYOUTS]: (state, action) => {
      const { oldIndex, newIndex, layoutId } = action.payload;
      const { entities, eventLayouts, studioLayouts } = state;
      const layoutData = entities[layoutId];
      if (!layoutData.studioWall) {
        eventLayouts.splice(newIndex < 0 ? eventLayouts.length + newIndex : newIndex, 0, eventLayouts.splice(oldIndex, 1)[0]);
        return {
          ...state,
          eventLayouts: [...eventLayouts],
        };
      } else {
        studioLayouts.splice(newIndex < 0 ? studioLayouts.length + newIndex : newIndex, 0, studioLayouts.splice(oldIndex, 1)[0]);
        return {
          ...state,
          studioLayouts: [...studioLayouts],
        };
      }
    },
    [actions.DUPLICATE_LAYOUT_REQUEST]: (state) => ({
      ...state,
      fetching: true,
      error: null,
      newLayoutId: undefined,
    }),
    [actions.DUPLICATE_LAYOUT_SUCCESS]: (state, action) => {
      const { entities, studioLayouts, eventLayouts } = state;
      const { data } = action.payload;
      if (data.studioWall) {
        studioLayouts.push(data._id);
      } else {
        eventLayouts.push(data._id);
      }
      entities[data._id] = { ...data };
      return {
        ...state,
        entities,
        studioLayouts,
        eventLayouts,
        newLayoutId: data._id,
        fetching: false,
        error: null,
      };
    },
    [actions.DUPLICATE_LAYOUT_FAILURE]: (state, action) => ({
      ...state,
      fetching: false,
      error: action.payload.error,
      newLayoutId: undefined,
    }),
    [actions.SET_BACKGROUND_VIDEO]: (state, action) => {
      const { areas } = state;
      const newHashCode = StringUtils.hash64(
        JSON.stringify({ areas, backgroundVideoUrl: action.payload.url, backgroundVideoMetadata: action.payload.metadata, backgroundImageUrl: null }),
      );
      return { ...state, backgroundVideoUrl: action.payload.url, backgroundVideoMetadata: action.payload.metadata, backgroundImageUrl: null, newHashCode };
    },
    [actions.SET_BACKGROUND_IMAGE_URL]: (state, action) => {
      const { areas } = state;
      const newHashCode = StringUtils.hash64(JSON.stringify({ areas, backgroundImageUrl: action.payload.url, backgroundVideoUrl: null }));
      return { ...state, backgroundImageUrl: action.payload.url, backgroundVideoUrl: null, newHashCode };
    },
    [actions.SET_UPDATED_LAYOUT_CONFIG]: (state, action) => {
      const { data } = action.payload;
      const { entities, current } = state;

      const mappedAreas = data.areas.map((area, index) => {
        const { _id, width, height, sourceType, source, crop, gridRowStart, gridRowEnd, gridColumnStart, gridColumnEnd, padding, wallDisplayNum } = area;
        return {
          _id,
          width,
          height,
          sourceType,
          crop,
          gridRowStart,
          gridRowEnd,
          gridColumnStart,
          gridColumnEnd,
          padding,
          data: sourceType === 'Participant' ? { areaIndex: index, wallDisplayNum } : { areaIndex: index, ...source },
        };
      });
      entities[data._id] = data;

      let newState = { ...state, fetching: false, error: null, entities };
      if (current === data._id) {
        newState.areas = mappedAreas;
        newState.grid = data.grid;
        newState.newHashCode = data.hashCode;
      }
      return newState;
    },
  },
  initialState,
);

export default reducer;
