import React from 'react';
import PropTypes from 'prop-types';
import '../../css/CustomRadio.css';

function CustomRadio({ label, name, value, checked, onChange, style }) {
  return (
    <label className='cr' style={style}>
      <span>{label}</span>
      <input type='radio' name={name} value={value} checked={checked} onChange={onChange} />
      <div className='cr-input' />
    </label>
  );
}

CustomRadio.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default CustomRadio;
