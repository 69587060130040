import { createActions, handleActions } from 'redux-actions';
import _ from 'lodash';

const initialState = {
  entities: {},
  list: [],
  selected: null,
  active: null,
  fetching: false,
  successfulRequest: false,
  newStudioId: undefined,
  error: null,
  socketServerUrl: null,
  liveStreams: [],
};

export const getFullList = (state) => {
  const { list, entities } = state.studio;
  if (list.length) {
    return list.map((id) => entities[id]);
  }
  return [];
};

export const getStudioById = (state, id) => {
  const { entities } = state.studio;
  if (id) {
    return entities[id];
  }
  return undefined;
};

export const getSelectedStudioRows = (state) => {
  const { selected, entities } = state.studio;
  if (selected !== null && entities[selected]) {
    return entities[selected].rows;
  }
  return undefined;
};

export const getSelectedStudioColumns = (state) => {
  const { selected, entities } = state.studio;
  if (selected !== null && entities[selected]) {
    return entities[selected].columns;
  }
  return undefined;
};

export const canUpdate = (state) => {
  const { selected, entities } = state.studio;
  if (selected !== null && entities[selected]) {
    // return entities[selected].__v === 0;
    return true;
  }
  return false;
};

export const getActiveStudio = (state) => {
  const { active, entities } = state.studio;
  if (active !== null && entities[active]) {
    return entities[active];
  }
  return undefined;
};

export const actions = {
  UPDATE_REQUEST: 'UPDATE_CONFIG_REQUEST',
  UPDATE_SUCCESS: 'UPDATE_CONFIG_SUCCESS',
  UPDATE_FAILURE: 'UPDATE_CONFIG_FAILURE',
  CREATE_REQUEST: 'CREATE_CONFIG_REQUEST',
  CREATE_SUCCESS: 'CREATE_CONFIG_SUCCESS',
  CREATE_FAILURE: 'CREATE_CONFIG_FAILURE',
  GET_REQUEST: 'GET_STUDIOS_REQUEST',
  GET_SUCCESS: 'GET_STUDIOS_SUCCESS',
  GET_FAILURE: 'GET_STUDIOS_FAILURE',
  SET_STUDIO_CONFIG: 'SET_STUDIO_CONFIG',
  SELECT_STUDIO: 'SELECT_STUDIO',
  DELETE_REQUEST: 'DELETE_STUDIO_REQUEST',
  DELETE_SUCCESS: 'DELETE_STUDIO_SUCCESS',
  DELETE_FAILURE: 'DELETE_STUDIO_FAILURE',
  SET_ACTIVE_STUDIO: 'SET_ACTIVE_STUDIO',
  SET_SOCKET_SERVER_URL: 'SET_SOCKET_SERVER_URL',
  SET_JANUS_SERVERS_READY: 'SET_JANUS_SERVERS_READY',
  SET_LIVE_STREAMS: 'SET_LIVE_STREAMS',
};

export const {
  updateConfigRequest,
  updateConfigSuccess,
  updateConfigFailure,
  createConfigRequest,
  createConfigSuccess,
  createConfigFailure,
  getStudiosRequest,
  getStudiosSuccess,
  getStudiosFailure,
  setStudioConfig,
  selectStudio,
  deleteStudioRequest,
  deleteStudioSuccess,
  deleteStudioFailure,
  updateWallLayout,
  setActiveStudio,
  setSocketServerUrl,
  setLiveStreams,
} = createActions({
  [actions.UPDATE_REQUEST]: (id, configuration) => ({ id, configuration }),
  [actions.UPDATE_SUCCESS]: (updated) => ({ updated }),
  [actions.UPDATE_FAILURE]: (error) => ({ error }),
  [actions.CREATE_REQUEST]: (configuration) => ({ configuration }),
  [actions.CREATE_SUCCESS]: (studio) => ({ studio }),
  [actions.CREATE_FAILURE]: (error) => ({ error }),
  [actions.GET_REQUEST]: () => ({}),
  [actions.GET_SUCCESS]: (studios) => ({ studios }),
  [actions.GET_FAILURE]: (error) => ({ error }),
  [actions.SET_STUDIO_CONFIG]: (rows, columns) => ({ rows, columns }),
  [actions.SELECT_STUDIO]: (studio) => ({ studio }),
  [actions.DELETE_REQUEST]: (id) => ({ id }),
  [actions.DELETE_SUCCESS]: (deleted) => ({ deleted }),
  [actions.DELETE_FAILURE]: (error) => ({ error }),
  [actions.SET_ACTIVE_STUDIO]: (studio) => ({ studio }),
  [actions.SET_SOCKET_SERVER_URL]: (url) => ({ url }),
  [actions.SET_LIVE_STREAMS]: (streams) => ({ streams }),
});

const reducer = handleActions(
  {
    [actions.UPDATE_REQUEST]: (state) => ({
      ...state,
      fetching: true,
      error: null,
      successfulRequest: false,
    }),
    [actions.UPDATE_SUCCESS]: (state, action) => {
      const { updated } = action.payload;
      return { ...state, fetching: false, error: null, successfulRequest: true, entities: { ...state.entities, [updated._id]: updated } };
    },
    [actions.UPDATE_FAILURE]: (state, action) => ({
      ...state,
      fetching: false,
      error: action.payload.error,
      successfulRequest: false,
    }),
    [actions.CREATE_REQUEST]: (state) => ({
      ...state,
      fetching: true,
      error: null,
      successfulRequest: false,
      newStudioId: undefined,
    }),
    [actions.CREATE_SUCCESS]: (state, action) => {
      const { studio } = action.payload;
      return {
        ...state,
        fetching: false,
        error: null,
        successfulRequest: true,
        list: [...state.list, studio._id],
        entities: { ...state.entities, [studio._id]: studio },
        newStudioId: studio._id,
      };
    },
    [actions.CREATE_FAILURE]: (state, action) => ({
      ...state,
      fetching: false,
      error: action.payload.error,
      successfulRequest: false,
      newStudioId: undefined,
    }),
    [actions.GET_REQUEST]: (state) => ({
      ...state,
      fetching: true,
      error: null,
      successfulRequest: false,
    }),
    [actions.GET_SUCCESS]: (state, action) => {
      const { studios } = action.payload;
      const entities = {};
      const list = _.orderBy(
        studios.filter((s) => s.dbName !== 'broadcast'),
        ['name'],
        ['asc'],
      ).map((s) => s._id);
      list.forEach((id) => {
        const studio = studios.find((s) => s._id === id);
        entities[id] = { ...studio };
      });
      return {
        ...state,
        fetching: false,
        error: null,
        successfulRequest: true,
        list,
        entities,
      };
    },
    [actions.GET_FAILURE]: (state, action) => ({
      ...state,
      fetching: false,
      error: action.payload.error,
      successfulRequest: false,
    }),
    [actions.SET_STUDIO_CONFIG]: (state, action) => {
      const { selected, entities } = state;
      const { rows, columns } = action.payload;
      const studio = { ...entities[selected] };
      studio.rows = rows;
      studio.columns = columns;
      return {
        ...state,
        entities: { ...state.entities, [selected]: studio },
      };
    },
    [actions.SELECT_STUDIO]: (state, action) => ({
      ...state,
      fetching: false,
      error: action.error,
      selected: action.payload.studio,
    }),
    [actions.DELETE_REQUEST]: (state) => ({
      ...state,
      fetching: true,
      error: null,
      successfulRequest: false,
    }),
    [actions.DELETE_SUCCESS]: (state, action) => {
      const { deleted } = action.payload;
      const { entities, list } = state;
      delete entities[deleted._id];
      list.splice(list.indexOf(deleted._id), 1);
      return {
        ...state,
        entities: { ...entities },
        list: [...list],
        selected: null,
        fetching: false,
        successfulRequest: true,
        error: null,
      };
    },
    [actions.DELETE_FAILURE]: (state, action) => ({
      ...state,
      fetching: false,
      error: action.payload.error,
      successfulRequest: false,
    }),
    [actions.SET_ACTIVE_STUDIO]: (state, action) => ({
      ...state,
      active: action.payload.studio,
      newStudioId: undefined,
    }),
    [actions.SET_SOCKET_SERVER_URL]: (state, action) => ({
      ...state,
      socketServerUrl: action.payload.url,
    }),
    [actions.SET_LIVE_STREAMS]: (state, action) => ({
      ...state,
      liveStreams: action.payload.streams,
    }),
  },
  initialState,
);

export default reducer;
