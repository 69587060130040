import React from 'react';
import '../css/Spinner.css';

export default function Spinner({ style, mode }) {
  let className = 'lds-spinner';
  if (mode === 'dark') {
    className = 'lds-spinner dark';
  }
  return (
    <div className={className} style={style}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
