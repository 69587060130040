import React, { useState } from 'react';
import { connect } from 'react-redux';
import { SortableHandle } from 'react-sortable-hoc';
import { getLayoutById } from '../../redux/layoutsRedux';
import moreIcon from '../../assets/icons/more.svg';
import deleteIcon from '../../assets/icons/delete.svg';
import editIcon from '../../assets/icons/edit.svg';
import liveLabel from '../../assets/icons/live_label.png';
import copyIcon from '../../assets/icons/copy.svg';

const DragHandle = SortableHandle(() => (
  <div className='drag-handle'>
    <img src={moreIcon} alt='more' />
  </div>
));

const LayoutListItem = ({ data, selected, onSelected, layout, onDelete, onEdit, liveLayout, onDuplicate }) => {
  const [showHandle, setShowHandle] = useState(false);

  const _onClickHandler = () => {
    onSelected && onSelected(layout);
  };

  const _onMouseOver = () => {
    setShowHandle(true);
  };

  const _onMouseOut = () => {
    setShowHandle(false);
  };

  const _onDelete = (event) => {
    event.stopPropagation();
    onDelete && onDelete(data);
  };

  const _onEdit = (event) => {
    event.stopPropagation();
    onEdit && onEdit(data);
  };

  const _onDuplicate = (event) => {
    event.stopPropagation();
    onDuplicate && onDuplicate(data);
  };

  const listItemClass = selected ? 'layout-list-item active' : 'layout-list-item';
  return data ? (
    <div className={listItemClass} onClick={_onClickHandler} onMouseEnter={_onMouseOver} onMouseLeave={_onMouseOut}>
      {showHandle && !selected ? <DragHandle /> : null}
      <div className='item-label'>
        {liveLayout && liveLayout === data._id ? (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
            <span>{data.name}</span>
            <img src={liveLabel} alt='live' style={{ width: 40, marginLeft: 10 }} />
          </div>
        ) : (
          data.name
        )}
      </div>
      {showHandle && (
        <div className='layout-options'>
          <div className='option' role='button' onClick={_onDuplicate} tabIndex='-1' onKeyUp={() => {}}>
            <img src={copyIcon} alt='duplicate' />
          </div>
          <div className='option' role='button' onClick={_onEdit} tabIndex='-1' onKeyUp={() => {}}>
            <img src={editIcon} alt='edit' />
          </div>
          {!liveLayout || (liveLayout && liveLayout !== data._id) ? (
            <div className='option' role='button' onClick={_onDelete} tabIndex='-1' onKeyUp={() => {}}>
              <img src={deleteIcon} alt='delete' />
            </div>
          ) : null}
        </div>
      )}
    </div>
  ) : null;
};

const mapStateToProps = (state, ownProps) => ({
  data: getLayoutById(state, ownProps.layout),
});

export default connect(mapStateToProps, null)(LayoutListItem);
