import React, { useState, useRef } from 'react';
import { uploadToS3 } from '../../../services/api';
import ProgressBar from '../../../common/FileUploader/ProgressBar';
import Button from '../../../common/Button';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 15,
    backgroundColor: 'rgb(32, 39, 47)',
    color: 'rgb(169, 170, 171)',
    fontSize: 13,
    padding: 10,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },
  fileInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  fileName: {
    marginLeft: 10,
  },
  button: {
    padding: '0 12px',
    fontSize: 13,
    margin: 0,
  },
};

const S3FileUploader = ({ s3Folder, onUploadSuccess }) => {
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploadButtonDisabled, setUploadButtonDisabled] = useState(false);

  const _handleFileSelect = () => {
    setUploadButtonDisabled(false);
    fileInputRef.current.click();
  };

  const _handleFileUpload = async (event) => {
    setFile(event.target.files[0]);
  };

  const _handleUpload = async () => {
    if (!file) {
      return;
    }
    setUploadButtonDisabled(true);

    const fileName = file.name;
    const fileType = file.type;
    const fileKey = `${s3Folder}/${fileName}`;

    const onUploadProgress = (progressEvent) => {
      const { loaded, total } = progressEvent;
      setProgress(Math.round((loaded / total) * 100));
    };

    try {
      const upload = await uploadToS3(file, fileName, fileType, null, fileKey, onUploadProgress);
      onUploadSuccess && onUploadSuccess(upload);
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        setProgress(0);
        setFile(null);
        fileInputRef.current.value = '';
        setUploadButtonDisabled(false);
      }, 250);
    }
  };

  const isUploading = progress > 0;

  return (
    <div style={styles.container}>
      <input ref={fileInputRef} type='file' onChange={_handleFileUpload} style={{ display: 'none' }} />
      <div style={{ ...styles.fileInputContainer, marginBottom: isUploading ? 12 : 0 }}>
        <div style={styles.flexRow}>
          <Button type='secondary' text='Choose File' onClick={_handleFileSelect} disabled={isUploading} containerStyle={styles.button} />
          <div onClick={_handleFileSelect} style={styles.fileName}>
            {file ? file.name : 'No file selected'}
          </div>
        </div>
        {file && (
          <Button
            type='primary'
            text={isUploading ? `Uploading... ${parseInt(progress)}%` : 'Upload'}
            onClick={_handleUpload}
            disabled={uploadButtonDisabled}
            containerStyle={styles.button}
          />
        )}
      </div>
      {isUploading && <ProgressBar percentage={progress} />}
    </div>
  );
};

export default S3FileUploader;
