import React, { useState, useEffect } from 'react';
import Button from '../../common/Button';
import timeIcon from '../../assets/icons/icon-time.svg';
import timeSetIcon from '../../assets/icons/icon-time-set.svg';
import Input from '../../common/Input';
import RadioButtons from '../../common/RadioButtons';
import { get } from '../../services/api';
import Popover from 'react-popover';
import DatePicker from 'react-datepicker';

function CustomDatePicker({ value, onSetDateTime, activeEvent }) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [ampm, setAmPm] = useState('am');

  const _setTimeInputs = (date) => {
    const d = new Date(date);
    let h = d.getHours();
    setAmPm(h >= 12 ? 'pm' : 'am');
    h = h % 12;
    h = h ? h : 12;
    let m = d.getMinutes();
    setHours(h < 10 ? `0${h}` : h);
    setMinutes(m < 10 ? `0${m}` : m);
  };

  useEffect(() => {
    (async () => {
      try {
        const { data } = await get(`/event/${activeEvent._id}`);
        const d = data.scheduledStartTime ? new Date(data.scheduledStartTime) : new Date();
        setSelectedDate(d);
        _setTimeInputs(d);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  const _onChange = ({ target }) => {
    if (isNaN(target.value)) return;

    const value = parseInt(target.value);

    if (target.name === 'hours') {
      if (value >= 1 && value <= 12) {
        setHours(value < 10 ? `0${value}` : value);
      }
    } else {
      if (value >= 0 && value <= 59) {
        setMinutes(value < 10 ? `0${value}` : value);
      }
    }
  };

  const _onSetDateTime = () => {
    if (selectedDate && hours && minutes) {
      if (ampm === 'pm') {
        selectedDate.setHours(parseInt(hours) + 12);
      } else {
        selectedDate.setHours(parseInt(hours));
      }
      selectedDate.setMinutes(parseInt(minutes));
      onSetDateTime(selectedDate);
    }
  };

  const _onSetToNow = () => {
    const now = new Date();
    setSelectedDate(null);
    setTimeout(() => {
      setSelectedDate(now);
    }, 250);
    _setTimeInputs(now);
  };

  return (
    <div className='custom-date-picker-container' style={{ backgroundColor: '#1e272f', padding: 5 }}>
      <div>
        <DatePicker className='inline-datepicker' inline selected={selectedDate} onChange={(date) => setSelectedDate(date)} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 5 }}>
        <Input
          name='hours'
          onChange={_onChange}
          value={hours}
          type='text'
          containerStyle={{ margin: 0 }}
          inputStyle={{ margin: 0, width: 26, padding: 0, fontSize: 12 }}
        />
        <span style={{ color: 'white' }}>:</span>
        <Input
          name='minutes'
          onChange={_onChange}
          value={minutes}
          type='text'
          containerStyle={{ margin: 0 }}
          inputStyle={{ margin: 0, width: 26, padding: 0, fontSize: 12 }}
        />
        <RadioButtons
          inline
          onChange={(v) => setAmPm(v)}
          options={[
            {
              label: 'AM',
              value: 'am',
            },
            {
              label: 'PM',
              value: 'pm',
            },
          ]}
          value={ampm}
          small
        />
        <Button
          type='link'
          text='Now'
          onClick={_onSetToNow}
          containerStyle={{
            margin: 0,
            padding: '2px 12px',
            fontSize: 12,
            fontWeight: 300,
          }}
        />
      </div>
      <div style={{ padding: 5 }}>
        <Button
          type='primary'
          text='Set'
          onClick={_onSetDateTime}
          containerStyle={{
            margin: 0,
            padding: '2px 12px',
            fontSize: 12,
            fontWeight: 300,
            width: '100%',
          }}
        />
      </div>
    </div>
  );
}

function CustomDateTimeInput({ value, customStyle, onChange, activeEvent }) {
  const [showDatePicker, setShowDatePicker] = useState(false);

  const _openDatePicker = () => {
    setShowDatePicker(true);
  };

  const _closeDatePicker = () => {
    setShowDatePicker(false);
  };

  const _onSetDateTime = (date) => {
    setShowDatePicker(false);
    onChange(date);
  };

  return (
    <Popover
      isOpen={showDatePicker}
      body={<CustomDatePicker value={value} onSetDateTime={_onSetDateTime} activeEvent={activeEvent} />}
      onOuterAction={_closeDatePicker}
      tipSize={6}
      place='left'
      style={{ fill: '#1e272f', zIndex: 99999 }}
    >
      <Button
        type='secondary'
        text='Set Meeting Start Time'
        onClick={_openDatePicker}
        containerStyle={customStyle}
        icon={value ? timeSetIcon : timeIcon}
        iconStyle={{ width: 20, height: 20 }}
      />
    </Popover>
  );
}

export default CustomDateTimeInput;
