import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Button from '../../common/Button';
import TextInput from '../../common/TextInput';
import TextArea from '../../common/TextArea';
import { put } from '../../services/api';
import SocketClient from '../../utils/socket-client';

import WallMetadataFormFields from './WallMetadataFormFields';

class Form extends PureComponent {
  state = {
    name: '',
    phone: '',
    notes: '',
    metadata: {},
  };

  componentWillMount() {
    const { participant, metadata } = this.props.data;
    const initialState = {
      name: `${participant.firstName} ${participant.lastName || ''}`.trim(),
      city: participant.city || '',
      state: participant.state || '',
      phone: participant.phone || '',
      notes: participant.notes || '',
      metadata: metadata || {},
    };
    this.setState(initialState);
  }

  _onChangeHandler = (event) => {
    const { target } = event;
    this.setState({ [target.name]: target.value });
  };

  _onChangeMetadataHandler = (metadata) => {
    this.setState({ metadata });
  };

  _onUpdate = async () => {
    try {
      const { data, activeEvent } = this.props;
      const { participant } = data;
      const { name, phone, city, state, notes, metadata } = this.state;
      const nameParts = name.trim().split(' ');
      const firstName = nameParts[0];
      const lastName = name.trim().replace(firstName, '').trim();
      await put(`/participant/${participant._id}`, { event: activeEvent._id, firstName, lastName, city, state, phone, notes });
      await put(`/eventParticipant/${data._id}`, { metadata });
      SocketClient.emit('change-input', { uuid: participant._id, type: 'participantInformation', data: { name, city, state, phone, notes } });
      SocketClient.emit('set-client-metadata', { uuid: participant._id, firstName, lastName, city, state, phone, ...metadata });
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const { name, phone, city, state, notes, metadata } = this.state;
    return (
      <>
        <div className='form-container custom-scrollbar'>
          <form autoComplete='off' style={{ margin: 5, width: '65%' }}>
            <h4>Day-of Contact</h4>
            <TextInput name='name' label='Name' onChange={this._onChangeHandler} value={name} />
            <TextInput name='city' label='City' onChange={this._onChangeHandler} value={city} />
            <TextInput name='state' label='State' onChange={this._onChangeHandler} value={state} />
            <TextInput name='phone' label='Phone' onChange={this._onChangeHandler} value={phone} />
            <TextArea name='notes' label='Notes' onChange={this._onChangeHandler} value={notes} />
            <WallMetadataFormFields data={metadata} onChange={this._onChangeMetadataHandler} />
            <Button type='primary' text='Update' onClick={this._onUpdate} containerStyle={{ marginTop: 30 }} />
          </form>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { participants, active } = state.events;
  const participantId = ownProps.data.participant._id;
  const eventParticipant = participants.find((p) => p.participant && p.participant._id === participantId);
  const status = eventParticipant ? eventParticipant.status : null;
  return { status, activeEvent: active };
};

export default connect(mapStateToProps, null)(Form);
