import React from 'react';
import '../css/TextArea.css';

export default function TextArea({ label, name, value, onChange, hasError, errorMessage, containerStyle }) {
  return (
    <div className='form-group'>
      <div className='form-label'>{label}</div>
      <div className={hasError ? 'form-control error' : 'form-control'} style={containerStyle}>
        <div style={{ display: 'flex' }}>
          <textarea name={name} value={value} onChange={onChange} autoComplete='off' rows={4} />
        </div>
        {hasError ? <div className='error-message'>{errorMessage}</div> : null}
      </div>
    </div>
  );
}
