import React, { useState } from 'react';
import Copy from '../assets/icons/copy.svg';

export default function CopyToClipboard({ text }) {
  const [copySuccess, setCopySuccess] = useState(false);

  function _copyToClipboard() {
    navigator.clipboard.writeText(text);
    setCopySuccess(true);
  }

  return (
    <div
      className='icon'
      style={{ cursor: 'pointer', marginLeft: 7, position: 'relative' }}
      onClick={_copyToClipboard}
      onMouseLeave={() => setCopySuccess(false)}
    >
      <img src={Copy} alt='Copy' width='15' height='15' />
      <span className={'tooltip top'} style={{ background: copySuccess ? '#287737' : undefined }}>
        {copySuccess ? 'Copied!' : 'Copy'}
      </span>
    </div>
  );
}
