import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ContextMenu from '../../common/ContextMenu';
import Draggable from '../../common/Draggable';
import { findEventParticipantByDisplayNum, findEventParticipantById } from '../../utils/find-event-participant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAppleAlt } from '@fortawesome/free-solid-svg-icons';

class WallParticipant extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hovered: false,
      showContextMenu: false,
      contextMenuX: 0,
      contextMenuY: 0,
    };
  }

  _dragEnterCount = 0;

  _onDragEnter = () => {
    this._dragEnterCount += 1;
    this.setState({ hovered: true });
  };

  _onDragOver = (event) => {
    event.persist();
    event.preventDefault();
    event.stopPropagation();
  };

  _onDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this._dragEnterCount -= 1;
    if (this._dragEnterCount === 0) {
      this.setState({ hovered: false });
    }
  };

  _onDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this._dragEnterCount = 0;
    this.setState({ hovered: false });
    const { sourceType, onDrop, areaId, data } = this.props;
    const { dataTransfer } = event;
    const dropData = JSON.parse(dataTransfer.getData('text'));
    if (sourceType === 'WallParticipant') {
      if (dropData.sourceType === 'Participant') {
        let participantId;
        if (dropData.data.wallDisplayNum) {
          const ep = findEventParticipantByDisplayNum(dropData.data.wallDisplayNum, ['ON_WALL', 'ON_AIR', 'IN_STUDIO_QUEUE']);
          participantId = ep.participant._id;
        } else {
          participantId = dropData.data._id;
        }
        onDrop && onDrop({ participantId, areaId });
      }
    } else if (sourceType === 'Participant') {
      const ep = findEventParticipantById(dropData.data._id, ['IN_STUDIO_QUEUE']);
      if (ep && data) {
        const isWallDisplayNumMatch = data.wallDisplayNum === ep.wallDisplayNum;
        const participantId = dropData.data._id;
        if (isWallDisplayNumMatch || !data.wallDisplayNum) {
          onDrop && onDrop({ participantId, areaId });
        }
      }
    }
  };

  _onContextMenuHandler = (e) => {
    e.preventDefault();
    const { enableContextMenu } = this.props;
    if (enableContextMenu) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({
        showContextMenu: true,
        contextMenuPosition: { x: e.pageX, y: e.pageY },
      });
    }
  };

  _hideContextMenu = () => {
    this.setState({
      showContextMenu: false,
      contextMenuPosition: { x: 0, y: 0 },
    });
  };

  _onClickHandler = (e) => {
    e.stopPropagation();
    e.persist();
    const { sourceType, data, onSelectParticipant, areaId } = this.props;
    if (sourceType === 'Participant' && data && Object.keys(data).length > 0) {
      let eventParticipant = findEventParticipantByDisplayNum(data.wallDisplayNum, ['ON_WALL', 'ON_AIR']);
      if (eventParticipant) {
        onSelectParticipant && onSelectParticipant(e, { ...eventParticipant, areaId });
      } else {
        onSelectParticipant && onSelectParticipant(e, { ...data, areaId });
      }
    } else {
      onSelectParticipant && onSelectParticipant(e, null);
    }
  };

  render() {
    const {
      sourceType,
      areaId,
      contextMenuItems,
      contextMenuItemSelectionHandler,
      data,
      selectedParticipant,
      style,
      highlightedParticipantIndex,
      draggingParticipant,
      selectedParticipants,
    } = this.props;
    const { contextMenuPosition, showContextMenu, hovered } = this.state;
    let borderStyle = { border: 'none' };
    if (sourceType === 'WallParticipant' || sourceType === 'Participant') {
      const isPartOfBatchSelection =
        selectedParticipants.length > 0 && data && selectedParticipants.map((ep) => ep.wallDisplayNum).includes(data.wallDisplayNum);
      if ((selectedParticipant && data && selectedParticipant.wallDisplayNum === data.wallDisplayNum) || isPartOfBatchSelection) {
        borderStyle = { border: '2px solid #43b77a' };
      } else {
        borderStyle = { border: `1px solid #20b1ee` };
      }
    }

    let content;
    let eventParticipant;
    if (sourceType === 'Participant' && data && Object.keys(data).length > 0) {
      eventParticipant = findEventParticipantByDisplayNum(data.wallDisplayNum, ['ON_WALL', 'ON_AIR']);
      if (eventParticipant) {
        let className = '';
        if (highlightedParticipantIndex && highlightedParticipantIndex === eventParticipant.wallDisplayNum) {
          className = 'highlight';
          borderStyle = { border: '2px solid #c6103a' };
        } else if (eventParticipant.status === 'ON_AIR') {
          borderStyle = { border: '2px solid #c6103a' };
        }
        content = (
          <Draggable dataTransfer={{ data, oldArea: areaId, sourceType: 'Participant' }}>
            <div
              className={className}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                ...borderStyle,
              }}
              onClick={this._onClickHandler}
            >
              {content}
              {eventParticipant && eventParticipant.status === 'ON_AIR' && (
                <div
                  className='num'
                  style={{ position: 'absolute', backgroundColor: 'rgba(32, 32, 32, 0.85)', fontWeight: 600, fontSize: 12, color: '#e81d32', padding: 4 }}
                >
                  {`#${eventParticipant.onAirNum}`}
                </div>
              )}
            </div>
          </Draggable>
        );
      } else {
        content = (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              ...borderStyle,
            }}
            onClick={this._onClickHandler}
          >
            {data.wallDisplayNum && (
              <div
                className='num'
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  backgroundColor: '#4e6379',
                  fontWeight: 600,
                  fontSize: 12,
                  color: '#fff',
                  padding: 4,
                }}
              >
                {`#${data.wallDisplayNum}`}
              </div>
            )}
          </div>
        );
      }
    } else {
      content = (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            ...borderStyle,
          }}
          onClick={this._onClickHandler}
        />
      );
    }

    return (
      <div
        onDragEnter={this._onDragEnter}
        onDragOver={this._onDragOver}
        onDragLeave={this._onDragLeave}
        onDrop={this._onDrop}
        onContextMenu={this._onContextMenuHandler}
        style={{ ...style }}
      >
        {content}
        {eventParticipant && eventParticipant.participant.platform && eventParticipant.participant.platform.osName === 'iOS' && (
          <div
            style={{
              position: 'absolute',
              right: 2,
              top: 2,
              backgroundColor: 'rgba(32, 32, 32, 0.85)',
              fontWeight: 600,
              fontSize: 12,
              color: '#e81d32',
              padding: '3px 6px',
            }}
          >
            <FontAwesomeIcon icon={faAppleAlt} color='#fff' style={{ fontSize: 14 }} />
          </div>
        )}
        {showContextMenu ? (
          <ContextMenu
            position={contextMenuPosition}
            items={contextMenuItems}
            onClose={this._hideContextMenu}
            onItemSelected={contextMenuItemSelectionHandler}
          />
        ) : null}
        <div
          style={{
            position: 'absolute',
            top: 0,
            display: 'flex',
            width: '100%',
            height: '100%',
            backgroundColor: hovered
              ? sourceType !== 'WallParticipant'
                ? draggingParticipant && data && (draggingParticipant.wallDisplayNum === data.wallDisplayNum || !data.wallDisplayNum)
                  ? '#20b1ee'
                  : '#c6103a'
                : '#20b1ee'
              : 'transparent',
            opacity: 0.4,
            pointerEvents: 'none',
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedParticipant: state.wall.selectedParticipant,
  highlightedParticipantIndex: state.wall.highlightedParticipantIndex,
  participants: state.events.participants,
  draggingParticipant: state.wall.draggingParticipant,
});

export default connect(mapStateToProps, null)(WallParticipant);
