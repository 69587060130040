import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

const styles = {
  selectContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 35,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 15px',
    fontSize: 13,
    background: '#486179',
    zIndex: 1,
  },
  select: {
    position: 'absolute',
    left: 10,
    right: 10,
    height: 35,
    opacity: 0,
    cursor: 'pointer',
  },
};

export default function StudiosDropDown({ studios, selectedStudio, onChange }) {
  const selectRef = useRef(null);

  return (
    <div
      style={styles.selectContainer}
      onClick={(e) => {
        e.stopPropagation();
        selectRef.current.click();
      }}
    >
      <span>{selectedStudio ? selectedStudio.name : 'Select Studio'}</span>
      <FontAwesomeIcon icon={faCaretDown} color='#fff' fontSize={20} />
      <select
        ref={selectRef}
        style={styles.select}
        onChange={(e) => {
          const studio = studios.find((s) => s._id === e.target.value);
          onChange(studio);
        }}
      >
        <option value=''>Select Studio</option>
        {studios.map((studio) => {
          return (
            <option key={studio._id} value={studio._id}>
              {studio.name}
            </option>
          );
        })}
      </select>
    </div>
  );
}
