import React from 'react';
import deleteIcon from '../../assets/icons/delete.svg';

export default function PollsListItem({ data, onSelect, onDelete, selected }) {
  const _onDelete = (event) => {
    event.stopPropagation();
    onDelete && onDelete(data);
  };

  return (
    <div className={selected && selected._id === data._id ? 'polls-list-item selected' : 'polls-list-item'} onClick={() => onSelect(data)}>
      <div className='poll-name'>{data.name}</div>
      <button onClick={_onDelete}>
        <img src={deleteIcon} alt='delete poll' style={{ width: 22, height: 22 }} />
      </button>
    </div>
  );
}
