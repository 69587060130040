import React, { PureComponent } from 'react';

import Button from '../../common/Button';
import DateTimeInput from '../../common/DateTimeInput';
import TextInput from '../../common/TextInput';

class ApiKeyDetail extends PureComponent {
  state = {
    name: '',
    expiresAt: new Date(),
  };

  componentWillMount() {
    const { data } = this.props;
    if (data) {
      const { name, expiresAt } = data;
      this.setState({
        name,
        expiresAt: new Date(expiresAt),
      });
    }
  }

  _onChangeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  _onDateChangeHandler = (date) => {
    this.setState({ expiresAt: date });
  };

  _onSave = () => {
    const { onSave } = this.props;
    const { name, expiresAt } = this.state;
    if (!expiresAt) {
      alert('Expiration date is required');
      return false;
    }
    if (name === '') {
      alert("Name can't be empty");
      return false;
    }
    onSave && onSave(this.state);
  };

  _onDelete = () => {
    const { onDelete } = this.props;
    onDelete && onDelete();
  };

  render() {
    const { name, expiresAt } = this.state;
    return (
      <div className='user-detail'>
        <div className='form'>
          <TextInput name='name' label='Name' value={name} onChange={this._onChangeHandler} />
          <DateTimeInput name='expiresAt' label='Expiration Date' onChange={this._onDateChangeHandler} value={expiresAt} />
          <TextInput label='Key' value={this.props.data.key} onChange={() => {}} />
          <div className='user-actions'>
            <Button type='primary' text='Save' onClick={this._onSave} containerStyle={{ margin: 0 }} />
            <Button type='danger' text='Delete' onClick={this._onDelete} containerStyle={{ marginLeft: 30 }} />
          </div>
        </div>
      </div>
    );
  }
}

export default ApiKeyDetail;
