import { all } from 'redux-saga/effects';

import studioSaga from './studioSaga';
import layoutsSaga from './layoutsSaga';
import goLiveSaga from './goLiveSaga';
import eventsSaga from './eventsSaga';
import assetsSaga from './assetsSaga';
import uploaderSaga from './uploaderSaga';
import wallSaga from './wallSaga';
import authSaga from './authSaga';
import usersSaga from './usersSaga';
import breakoutRoomsSaga from './breakoutRoomsSaga';
import uiSaga from './uiSaga';
import apiKeySaga from './apiKeySaga';

export default function* () {
  yield all([
    authSaga(),
    studioSaga(),
    layoutsSaga(),
    goLiveSaga(),
    eventsSaga(),
    assetsSaga(),
    uploaderSaga(),
    wallSaga(),
    usersSaga(),
    breakoutRoomsSaga(),
    uiSaga(),
    apiKeySaga(),
  ]);
}
