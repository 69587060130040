import React, { useState, useEffect } from 'react';
import Select from '../../../common/Select';

export default function DropDownFormField({ name, list, value, onChange, containerStyle }) {
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (value) {
      const type = list.find((it) => it.value === value);
      if (type) {
        setSelected(type);
      }
    } else {
      setSelected(null);
    }
  }, [value]);

  const _onChange = async (type) => {
    setSelected(type);
    onChange(name, type);
  };

  return (
    <div className={'instance-type stopped'} style={containerStyle}>
        <div className='types-list'>
          <Select
            list={list}
            listKey='value'
            listLabel='label'
            onChange={_onChange}
            selected={selected && selected.value}
            currentOption={selected && selected.label}
            containerStyle
            small
          />
        </div>
    </div>
  );
}
