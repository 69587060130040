import React from 'react';
import { NavLink } from 'react-router-dom';

export default function NavBarItem({ icon, label, link, activeBorderColor, collapsed, disabled, hide }) {
  const collapsedClass = collapsed ? 'label collapsed' : 'label';

  return disabled ? (
    <div key={label} className='link disabled' style={{ overflow: 'hidden', height: hide ? 0 : 70 }}>
      <img className='icon' src={icon} alt={label} />
      <span className={collapsedClass}>{label}</span>
    </div>
  ) : (
    <NavLink
      to={link}
      className='link'
      activeClassName='active'
      activeStyle={{
        borderLeft: `2px solid ${activeBorderColor}`,
      }}
      style={{ overflow: 'hidden', transition: 'height 0.1s linear', height: hide ? 0 : 70 }}
    >
      <img className='icon' src={icon} alt={label} />
      <span className={collapsedClass}>{label}</span>
    </NavLink>
  );
}
