import { createActions, handleActions } from 'redux-actions';

const initialState = {
  loading: true,
  navBarCollapsed: true,
  routesEnabled: true,
  liveRoutesEnabled: false,
  goLiveEnabled: false,
  eventSelected: false,
  updates: [],
  socketConnected: false,
  appMode: null,
};

export const actions = {
  TOGGLE_NAV_BAR: 'TOGGLE_NAV_BAR',
  DISABLE_ROUTES: 'DISABLE_ROUTES',
  ENABLE_ROUTES: 'ENABLE_ROUTES',
  DISABLE_LIVE_ROUTES: 'DISABLE_LIVE_ROUTES',
  ENABLE_LIVE_ROUTES: 'ENABLE_LIVE_ROUTES',
  DISABLE_REFRESH: 'DISABLE_REFRESH',
  APPLY_UPDATES: 'APPLY_UPDATES',
  SEND_UPDATE: 'SEND_UPDATE',
  LOAD_TOP_BAR: 'LOAD_TOP_BAR',
  ENABLE_GO_LIVE_ROUTE: 'ENABLE_GO_LIVE_ROUTE',
  DISABLE_GO_LIVE_ROUTE: 'DISABLE_GO_LIVE_ROUTE',
  ENABLE_LAYOUTS_ROUTE: 'ENABLE_LAYOUTS_ROUTE',
  DISABLE_LAYOUTS_ROUTE: 'DISABLE_LAYOUTS_ROUTE',
  UPDATE_ROUTES: 'UPDATE_ROUTES',
  SET_LOADING: 'SET_LOADING',
  SET_SOCKET_SERVER_STATUS: 'SET_SOCKET_SERVER_STATUS',
  SET_APP_MODE: 'SET_APP_MODE',
};

export const {
  toggleNavBar,
  disableRoutes,
  enableRoutes,
  disableRefresh,
  applyUpdates,
  disableLiveRoutes,
  enableLiveRoutes,
  sendUpdate,
  loadTopBar,
  disableGoLiveRoute,
  enableGoLiveRoute,
  disableLayoutsRoute,
  enableLayoutsRoute,
  updateRoutes,
  setLoading,
  setSocketServerStatus,
  setAppMode,
} = createActions({
  [actions.TOGGLE_NAV_BAR]: (collapsed) => ({ collapsed }),
  [actions.DISABLE_ROUTES]: () => ({}),
  [actions.ENABLE_ROUTES]: () => ({}),
  [actions.DISABLE_REFRESH]: () => ({}),
  [actions.APPLY_UPDATES]: (updates) => ({ updates }),
  [actions.DISABLE_LIVE_ROUTES]: () => ({}),
  [actions.ENABLE_LIVE_ROUTES]: () => ({}),
  [actions.SEND_UPDATE]: (update) => ({ update }),
  [actions.LOAD_TOP_BAR]: () => ({}),
  [actions.DISABLE_GO_LIVE_ROUTE]: () => ({}),
  [actions.ENABLE_GO_LIVE_ROUTE]: () => ({}),
  [actions.DISABLE_LAYOUTS_ROUTE]: () => ({}),
  [actions.ENABLE_LAYOUTS_ROUTE]: () => ({}),
  [actions.UPDATE_ROUTES]: () => ({}),
  [actions.SET_LOADING]: (value) => ({ value }),
  [actions.SET_SOCKET_SERVER_STATUS]: (status) => ({ status }),
  [actions.SET_APP_MODE]: (mode) => ({ mode }),
});

const reducer = handleActions(
  {
    [actions.TOGGLE_NAV_BAR]: (state, action) => ({
      ...state,
      navBarCollapsed: action.payload.collapsed,
    }),
    [actions.ENABLE_ROUTES]: (state) => ({
      ...state,
      routesEnabled: true,
    }),
    [actions.DISABLE_ROUTES]: (state) => ({
      ...state,
      routesEnabled: false,
    }),
    [actions.ENABLE_LIVE_ROUTES]: (state) => ({
      ...state,
      liveRoutesEnabled: true,
    }),
    [actions.DISABLE_LIVE_ROUTES]: (state) => ({
      ...state,
      liveRoutesEnabled: false,
    }),
    [actions.DISABLE_REFRESH]: (state) => ({
      ...state,
      updates: [],
    }),
    [actions.SEND_UPDATE]: (state, action) => ({
      ...state,
      updates: [...state.updates, action.payload.update],
    }),
    [actions.ENABLE_GO_LIVE_ROUTE]: (state) => ({
      ...state,
      goLiveEnabled: true,
    }),
    [actions.DISABLE_GO_LIVE_ROUTE]: (state) => ({
      ...state,
      goLiveEnabled: false,
    }),
    [actions.ENABLE_LAYOUTS_ROUTE]: (state) => ({
      ...state,
      eventSelected: true,
    }),
    [actions.DISABLE_LAYOUTS_ROUTE]: (state) => ({
      ...state,
      eventSelected: false,
    }),
    [actions.SET_LOADING]: (state, action) => ({
      ...state,
      loading: action.payload.value,
    }),
    [actions.SET_SOCKET_SERVER_STATUS]: (state, action) => ({
      ...state,
      socketConnected: action.payload.status,
    }),
    [actions.SET_APP_MODE]: (state, action) => ({
      ...state,
      appMode: action.payload.mode,
    }),
  },
  initialState,
);

export default reducer;
