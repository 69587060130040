import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { getEventById, updateEventRequest } from '../../../redux/eventsRedux';
import { post } from '../../../services/api';
import TimeUtil from '../../../utils/time-util';
import CopyToClipboard from '../../../common/CopyToClipboard';
import SocketClient from '../../../utils/socket-client';
import Reporting from '../Reporting';
import PreviewButton from '../PreviewButton';
import ArchiveDetails from './ArchiveDetails';
import '../../../css/Button.css';

const styles = {
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  mainContainer: {
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '0 15px',
  },
  sectionHeader: {
    paddingBottom: 0,
    paddingTop: 0,
    marginTop: 30,
    marginBottom: -10,
  },
  formValue: {
    fontSize: 12,
    fontFamily: 'inherit',
    display: 'flex',
    flexDirection: 'row',
  },
  tableHeader: {
    padding: 10,
    textAlign: 'center',
  },
  tableCell: {
    verticalAlign: 'top',
    padding: 10,
    textAlign: 'center',
  },
};

class BroadcastDetails extends PureComponent {
  state = {
    streamKey: '',
    ingestServer: '',
    streamData: {
      health: '',
      viewerCount: '',
      state: '',
      startTime: '',
    },
    sessionEvents: [],
    fetched: false,
  };

  async componentDidMount() {
    this._fetchStreamData();
    this._timerId = setInterval(this._fetchStreamData, 3000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.event !== this.props.event) {
      clearInterval(this._timerId);
      this._fetchStreamData();
      this._timerId = setInterval(this._fetchStreamData, 3000);
    }
  }

  componentWillUnmount() {
    clearInterval(this._timerId);
  }

  _fetchStreamData = async () => {
    const { event } = this.props;
    if (event) {
      const { streamKey, ingestServer, channelArn } = event.broadcastStream;
      const { data } = await post('/event/getStreamData', { channelArn });
      this.setState({
        streamKey,
        ingestServer,
        streamData: data ? data.stream : null,
        sessionEvents: data && data.streamSession ? data.streamSession.truncatedEvents : null,
        fetched: true,
      });
    }
  };

  render() {
    const { streamKey, ingestServer, streamData, sessionEvents, fetched } = this.state;
    const { event } = this.props;

    let duration = '0:00';
    if (streamData && streamData.startTime) {
      const startTime = moment(streamData.startTime);
      const now = moment();
      duration = TimeUtil.dateDifference(startTime, now);
    }

    let streamState = streamData && streamData.state ? streamData.state : 'OFFLINE';
    let streamStateColor = streamState === 'LIVE' ? '#860b25' : '#555';
    if (streamState === 'LIVE' && (!SocketClient.socket.connected || (event && event.endTime))) {
      streamState = 'STREAMING';
      streamStateColor = '#5F4723';
    }

    return (
      <div className='modal-form' style={styles.flexColumn}>
        <div className='modal-form-header' style={{ padding: '0 15px', marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {event && (
            <div style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <h3 style={{ textAlign: 'center' }}>{event.name}</h3>
              <PreviewButton
                event={event}
                style={{
                  fontSize: 14,
                  padding: '3px 15px',
                  margin: '0 15px 0 0',
                }}
              />
            </div>
          )}
          {fetched && (
            <span
              style={{
                color: 'white',
                background: streamStateColor,
                borderRadius: 15,
                padding: '3px 15px',
                textAlign: 'center',
                minWidth: 70,
                fontSize: 14,
              }}
            >
              {streamState}
            </span>
          )}
        </div>
        {fetched && (
          <>
            <div className='custom-scrollbar' style={styles.mainContainer}>
              {streamState !== 'OFFLINE' && (
                <>
                  <div className='form-control' style={styles.flexRow}>
                    <div className='form-label'>Health</div>
                    <div className='form-value'>{streamData && streamData.health}</div>
                  </div>
                  <div className='form-control' style={styles.flexRow}>
                    <div className='form-label'>Duration</div>
                    <div className='form-value'>{duration}</div>
                  </div>
                  <div className='form-control' style={styles.flexRow}>
                    <div className='form-label'>Viewer Count</div>
                    <div className='form-value'>{(streamData && streamData.viewerCount) || '0'}</div>
                  </div>
                </>
              )}

              <div className='modal-form-header' style={styles.sectionHeader}>
                <h4>Stream Settings</h4>
              </div>
              <div className='form-control' style={styles.flexRow}>
                <div className='form-label'>Ingest Server</div>
                <div className='form-value' style={styles.formValue}>
                  {ingestServer}
                  <CopyToClipboard text={ingestServer} />
                </div>
              </div>
              <div className='form-control' style={styles.flexRow}>
                <div className='form-label'>Stream Key</div>
                <div className='form-value' style={styles.formValue}>
                  {streamKey}
                  <CopyToClipboard text={streamKey} />
                </div>
              </div>

              {sessionEvents && (
                <>
                  <div className='modal-form-header' style={styles.sectionHeader}>
                    <h4>Session Events</h4>
                  </div>
                  <table style={{ padding: 0, paddingTop: 0, width: '100%' }}>
                    <thead style={{ backgroundColor: 'rgb(45, 64, 81)', color: 'rgb(169, 170, 171)', margin: 0 }}>
                      <tr style={{ textAlign: 'left', fontSize: 12 }}>
                        <th style={styles.tableHeader}>EVENT TIME</th>
                        <th style={styles.tableHeader}>NAME</th>
                        <th style={styles.tableHeader}>TYPE</th>
                      </tr>
                    </thead>
                    <tbody style={{ margin: 0 }}>
                      {sessionEvents.map((e, index) => {
                        return (
                          <tr key={`e-${index}`} style={{ backgroundColor: 'rgb(32, 39, 47)', color: 'rgb(169, 170, 171)', fontSize: 13 }}>
                            <td style={styles.tableCell}>{moment(e.eventTime).format('MM/DD/YYYY hh:mm:ss A')}</td>
                            <td style={styles.tableCell}>{e.name}</td>
                            <td style={styles.tableCell}>{e.type}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              )}

              {event && <ArchiveDetails event={event} />}
            </div>
            <div className='event-assets-footer'>
              <Reporting />
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  event: getEventById(state, state.events ? state.events.selected : null),
  activeStudio: state.studio.active,
});

const mapDispatchToProps = (dispatch) => ({
  updateEvent: (id, data) => dispatch(updateEventRequest(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BroadcastDetails);
