import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ParticipantPhoto from '../../common/ParticipantPhoto';

function ParticipantRow({ value, data, animationDelay }) {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const newPercentage = Math.round((value.count / data.totalQuestions) * 100);
    setTimeout(() => setPercentage(newPercentage), (animationDelay + 1) * 600);
  }, [value, data, animationDelay]);

  const { participant, rank } = value;
  let placeSuffix = 'th';
  let className = 'participant-row';
  if (rank === 1) {
    className += ' first-place';
    placeSuffix = 'st';
  } else if (rank === 2) {
    className += ' second-place';
    placeSuffix = 'nd';
  } else if (rank === 3) {
    className += ' third-place';
    placeSuffix = 'rd';
  }
  return (
    <div
      className='slide-in'
      style={{
        animationDelay: `${animationDelay}s`,
      }}
    >
      <div className={className}>
        <div className='participant-photo'>
          <ParticipantPhoto photoUrl={participant.photoUrl} width='80px' height='auto' />
        </div>
        <div className='participant'>
          <div className='place'>{`${rank}${placeSuffix}.`}</div>
          <div className='name'>{`${participant.firstName} ${participant.lastName}`}</div>
        </div>
        <div style={{ flex: 1 }} />
        <div className='progress-element'>
          <span className='progress-label' data-progress={percentage}>
            {`Answered ${value.count} out of ${data.totalQuestions} correctly`}
          </span>
          <div className='progress-container'>
            <div className='progress' style={{ width: `${percentage}%` }} />
          </div>
        </div>
      </div>
    </div>
  );
}

ParticipantRow.propTypes = {
  value: PropTypes.shape({
    participant: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      photoUrl: PropTypes.string.isRequired,
    }).isRequired,
    count: PropTypes.number.isRequired,
    rank: PropTypes.number.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    totalQuestions: PropTypes.number.isRequired,
  }).isRequired,
  animationDelay: PropTypes.number.isRequired,
};

export default ParticipantRow;
