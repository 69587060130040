import { createActions, handleActions } from 'redux-actions';
import _ from 'lodash';

const initialState = {
  layouts: {},
  list: [],
  selectedLayout: null,
  fetching: false,
  error: null,
  editedArea: undefined,
  selectedParticipant: null,
  highlightedParticipantIndex: null,
  draggingParticipant: null,
  activatedLayout: null,
};

export const actions = {
  GET_ALL_LAYOUTS_REQUEST: 'GET_ALL_LAYOUTS_REQUEST',
  GET_ALL_LAYOUTS_SUCCESS: 'GET_ALL_LAYOUTS_SUCCESS',
  GET_ALL_LAYOUTS_FAILURE: 'GET_ALL_LAYOUTS_FAILURE',
  SELECT_LAYOUT: 'SELECT_LAYOUT',
  ADD_METADATA: 'ADD_METADATA',
  REMOVE_METADATA: 'REMOVE_METADATA',
  CLEAR_WALL: 'CLEAR_WALL',
  SEND_WALL_CONFIG: 'SEND_WALL_CONFIG',
  ADD_METADATA_IN_BULK: 'ADD_METADATA_IN_BULK',
  REMOVE_METADATA_IN_BULK: 'REMOVE_METADATA_IN_BULK',
  SELECT_PARTICIPANT: 'SELECT_PARTICIPANT',
  ASSIGN_PARTICIPANT_REQUEST: 'ASSIGN_PARTICIPANT_REQUEST',
  ASSIGN_PARTICIPANT_SUCCESS: 'ASSIGN_PARTICIPANT_SUCCESS',
  ASSIGN_PARTICIPANT_FAILURE: 'ASSIGN_PARTICIPANT_FAILURE',
  REMOVE_PARTICIPANT_FROM_WALL_REQUEST: 'REMOVE_PARTICIPANT_FROM_WALL_REQUEST',
  REMOVE_PARTICIPANT_FROM_WALL_SUCCESS: 'REMOVE_PARTICIPANT_FROM_WALL_SUCCESS',
  REMOVE_PARTICIPANT_FROM_WALL_FAILURE: 'REMOVE_PARTICIPANT_FROM_WALL_FAILURE',
  UPDATE_EVENT_PARTICIPANT_REQUEST: 'UPDATE_EVENT_PARTICIPANT_REQUEST',
  UPDATE_EVENT_PARTICIPANT_SUCCESS: 'UPDATE_EVENT_PARTICIPANT_SUCCESS',
  UPDATE_EVENT_PARTICIPANT_FAILURE: 'UPDATE_EVENT_PARTICIPANT_FAILURE',
  GET_LAYOUT_CONFIG_REQUEST: 'GET_LAYOUT_CONFIG_REQUEST',
  GET_LAYOUT_CONFIG_SUCCESS: 'GET_LAYOUT_CONFIG_SUCCESS',
  GET_LAYOUT_CONFIG_FAILURE: 'GET_LAYOUT_CONFIG_FAILURE',
  SET_HIGHTLIGHTED_PARTICIPANT_INDEX: 'SET_HIGHTLIGHTED_PARTICIPANT_INDEX',
  SET_DRAGGING_PARTICIPANT: 'SET_DRAGGING_PARTICIPANT',
  SET_ACTIVATED_LAYOUT: 'SET_ACTIVATED_LAYOUT',
};

export const getLayoutById = (state, id) => {
  const { layouts } = state.wall;
  if (id) {
    return layouts[id];
  }
  return undefined;
};

export const getLayoutsList = (state) => {
  const { list, layouts } = state.wall;
  if (list.length) {
    return list.map((id) => layouts[id]);
  }
  return [];
};

export const {
  getAllLayoutsRequest,
  getAllLayoutsSuccess,
  getAllLayoutsFailure,
  selectLayout,
  addMetadata,
  removeMetadata,
  clearWall,
  addMetadataInBulk,
  removeMetadataInBulk,
  selectParticipant,
  assignParticipantRequest,
  assignParticipantSuccess,
  assignParticipantFailure,
  removeParticipantFromWallRequest,
  removeParticipantFromWallSuccess,
  removeParticipantFromWallFailure,
  updateEventParticipantRequest,
  updateEventParticipantSuccess,
  updateEventParticipantFailure,
  getLayoutConfigRequest,
  getLayoutConfigSuccess,
  getLayoutConfigFailure,
  setHightlightedParticipantIndex,
  setDraggingParticipant,
  setActivatedLayout,
} = createActions({
  [actions.GET_ALL_LAYOUTS_REQUEST]: (studio, event) => ({ studio, event }),
  [actions.GET_ALL_LAYOUTS_SUCCESS]: (data) => ({ data }),
  [actions.GET_ALL_LAYOUTS_FAILURE]: (error) => ({ error }),
  [actions.SELECT_LAYOUT]: (layout) => ({ layout }),
  [actions.ADD_METADATA]: (layout, area, metadata) => ({ layout, area, metadata }),
  [actions.REMOVE_METADATA]: (layout, area) => ({ layout, area }),
  [actions.CLEAR_WALL]: (layout) => ({ layout }),
  [actions.ADD_METADATA_IN_BULK]: (layout, data) => ({ layout, data }),
  [actions.REMOVE_METADATA_IN_BULK]: (layout, data) => ({ layout, data }),
  [actions.SELECT_PARTICIPANT]: (participant) => ({ participant }),
  [actions.ASSIGN_PARTICIPANT_REQUEST]: (layoutId, data) => ({ layoutId, data }),
  [actions.ASSIGN_PARTICIPANT_SUCCESS]: (data) => ({ data }),
  [actions.ASSIGN_PARTICIPANT_FAILURE]: (error) => ({ error }),
  [actions.REMOVE_PARTICIPANT_FROM_WALL_REQUEST]: (layoutId, data) => ({ layoutId, data }),
  [actions.REMOVE_PARTICIPANT_FROM_WALL_SUCCESS]: (data) => ({ data }),
  [actions.REMOVE_PARTICIPANT_FROM_WALL_FAILURE]: (error) => ({ error }),
  [actions.UPDATE_EVENT_PARTICIPANT_REQUEST]: (eventParticipantId, data) => ({ eventParticipantId, data }),
  [actions.UPDATE_EVENT_PARTICIPANT_SUCCESS]: (data) => ({ data }),
  [actions.UPDATE_EVENT_PARTICIPANT_FAILURE]: (error) => ({ error }),
  [actions.GET_LAYOUT_CONFIG_REQUEST]: (layout) => ({ layout }),
  [actions.GET_LAYOUT_CONFIG_SUCCESS]: (data) => ({ data }),
  [actions.GET_LAYOUT_CONFIG_FAILURE]: (error) => ({ error }),
  [actions.SET_HIGHTLIGHTED_PARTICIPANT_INDEX]: (participantIndex) => ({ participantIndex }),
  [actions.SET_DRAGGING_PARTICIPANT]: (data) => ({ data }),
  [actions.SET_ACTIVATED_LAYOUT]: (data) => ({ data }),
});

const reducer = handleActions(
  {
    [actions.GET_ALL_LAYOUTS_REQUEST]: (state) => ({
      ...state,
      fetching: true,
      error: null,
    }),
    [actions.GET_ALL_LAYOUTS_SUCCESS]: (state, action) => {
      const { data } = action.payload;
      const layouts = {};

      const filteredList = data.filter((layout) => layout.version && layout.version === 2 && !layout.studioWall);
      const list = _.orderBy(filteredList, ['index', 'name'], ['asc', 'asc']).map((s) => s._id);

      list.forEach((id) => {
        const layout = data.find((s) => s._id === id);
        const areas = layout.areas.map((area) => {
          const {
            _id,
            width,
            height,
            sourceType,
            source,
            crop,
            gridRowStart,
            gridRowEnd,
            gridColumnStart,
            gridColumnEnd,
            padding,
            participant,
            wallDisplayNum,
          } = area;
          return {
            _id,
            width,
            height,
            sourceType,
            crop,
            gridRowStart,
            gridRowEnd,
            gridColumnStart,
            gridColumnEnd,
            padding,
            data: sourceType === 'Participant' ? { ...participant, wallDisplayNum } : { ...source },
          };
        });
        layouts[id] = { ...layout, areas };
      });
      return {
        ...state,
        fetching: false,
        error: null,
        list,
        layouts,
      };
    },
    [actions.GET_ALL_LAYOUTS_FAILURE]: (state, action) => ({
      ...state,
      fetching: false,
      error: action.payload.error,
    }),
    [actions.SELECT_LAYOUT]: (state, action) => ({
      ...state,
      selectedLayout: action.payload.layout,
    }),
    [actions.ADD_METADATA]: (state, action) => {
      const { layouts } = state;
      const { layout, area, metadata } = action.payload;
      layouts[layout].areas.forEach((a) => {
        if (a._id === area) {
          a.sourceType = 'Participant';
          a.data = { ...metadata };
        }
      });
      return {
        ...state,
        layouts: { ...layouts },
        editedArea: Date.now(),
      };
    },
    [actions.REMOVE_METADATA]: (state, action) => {
      const { layouts } = state;
      const { layout, area } = action.payload;
      layouts[layout].areas.forEach((a) => {
        if (a._id === area) {
          a.sourceType = 'WallParticipant';
          a.data = {};
        }
      });
      return {
        ...state,
        layouts: { ...layouts },
        editedArea: undefined,
      };
    },
    [actions.CLEAR_WALL]: (state, action) => {
      const { layouts } = state;
      const { layout } = action.payload;
      layouts[layout].areas.forEach((area) => {
        if (area.sourceType === 'Participant') {
          area.sourceType = 'WallParticipant';
          area.data = {};
        }
      });
      return {
        ...state,
        layouts: { ...layouts },
        editedArea: 'all',
      };
    },
    [actions.ADD_METADATA_IN_BULK]: (state, action) => {
      const { layouts } = state;
      const { layout, data } = action.payload;

      const layoutAreasIds = layouts[layout].areas.map((a) => a._id);
      for (let item of data) {
        const areaIndex = layoutAreasIds.indexOf(item.area);
        layouts[layout].areas[areaIndex].sourceType = 'Participant';
        layouts[layout].areas[areaIndex].data = { ...item.metadata };
      }

      return {
        ...state,
        layouts: { ...layouts },
        editedArea: Date.now(),
      };
    },
    [actions.REMOVE_METADATA_IN_BULK]: (state, action) => {
      const { layouts } = state;
      const { layout, data } = action.payload;

      const layoutAreasIds = layouts[layout].areas.map((a) => a._id);

      for (let item of data) {
        const areaIndex = layoutAreasIds.indexOf(item.area);
        layouts[layout].areas[areaIndex].sourceType = 'WallParticipant';
        layouts[layout].areas[areaIndex].data = {};
      }

      return {
        ...state,
        layouts: { ...layouts },
        editedArea: undefined,
      };
    },
    [actions.SELECT_PARTICIPANT]: (state, action) => ({
      ...state,
      selectedParticipant: action.payload.participant,
    }),
    [actions.GET_LAYOUT_CONFIG_REQUEST]: (state) => ({
      ...state,
      fetching: true,
      error: null,
    }),
    [actions.GET_LAYOUT_CONFIG_SUCCESS]: (state, action) => {
      const { data } = action.payload;
      const { layouts, list } = state;

      const areas = data.areas.map((area) => {
        const { _id, width, height, sourceType, source, crop, gridRowStart, gridRowEnd, gridColumnStart, gridColumnEnd, padding, wallDisplayNum } = area;
        return {
          _id,
          width,
          height,
          sourceType,
          crop,
          gridRowStart,
          gridRowEnd,
          gridColumnStart,
          gridColumnEnd,
          padding,
          data: sourceType === 'Participant' ? { wallDisplayNum } : { ...source },
        };
      });
      layouts[data._id] = { ...data, areas };

      if (!list.includes(data._id)) {
        list.push(data._id);
      }

      return {
        ...state,
        fetching: false,
        error: null,
        layouts,
        list,
      };
    },
    [actions.GET_LAYOUT_CONFIG_FAILURE]: (state, action) => ({
      ...state,
      fetching: false,
      error: action.payload.error,
    }),
    [actions.SET_HIGHTLIGHTED_PARTICIPANT_INDEX]: (state, action) => ({
      ...state,
      highlightedParticipantIndex: action.payload.participantIndex,
    }),
    [actions.SET_DRAGGING_PARTICIPANT]: (state, action) => ({
      ...state,
      draggingParticipant: action.payload.data,
    }),
    [actions.SET_ACTIVATED_LAYOUT]: (state, action) => ({
      ...state,
      activatedLayout: action.payload.data,
    }),
  },
  initialState,
);

export default reducer;
