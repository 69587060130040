import React, { useRef, useEffect } from 'react';
import uuidV4 from 'uuid';
import { Line } from 'react-chartjs-2';
import { getColorScale, getDatasetConfig, getDefaultPluginConfig } from '../../utils/charts-util';
import htmlLegendPlugin from './HtmlLegendPlugin';

export default function LineChart({ title, datasets, data, dataLabel, labels, style, colors, displayLegend, fill = true }) {
  const htmlLegendContainerId = useRef(uuidV4());

  useEffect(() => {
    if (document.getElementById(htmlLegendContainerId.current)) {
      setTimeout(() => {
        document.getElementById(htmlLegendContainerId.current).firstChild.firstChild.click();
      }, 100);
    }
  }, []);

  const colorScale = colors || getColorScale(labels.length);
  const _datasets = getDatasetConfig(datasets, data, dataLabel, colorScale);

  return (
    <div style={style}>
      <Line
        data={{
          labels,
          datasets: _datasets.map((dataset) => ({
            ...dataset,
            fill,
          })),
        }}
        options={{
          elements: {
            point: {
              radius: 0,
            },
          },
          scales: {
            x: {
              ticks: {
                display: true,
              },
              grid: {
                display: false,
              },
            },
            y: {
              ticks: {
                display: true,
              },
              grid: {
                display: false,
              },
            },
          },
          plugins: {
            ...getDefaultPluginConfig(title, displayLegend),
            htmlLegend: {
              containerID: displayLegend ? `${htmlLegendContainerId.current}` : null,
            },
            legend: {
              display: false,
            },
            datalabels: {
              display: false,
            },
            filler: {
              propagate: true,
            },
          },
        }}
        plugins={displayLegend ? [htmlLegendPlugin] : []}
      />
      {displayLegend && <div id={`${htmlLegendContainerId.current}`} />}
    </div>
  );
}
