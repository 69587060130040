import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import swal from 'sweetalert';
import {
  createLayoutRequest,
  setLayout,
  updateLayoutRequest,
  deleteLayoutRequest,
  orderLayouts,
  updateLayoutsOrderRequest,
  duplicateLayoutRequest,
} from '../../redux/layoutsRedux';
import { setLiveLayout } from '../../redux/eventsRedux';
import addIcon from '../../assets/icons/add.svg';
import SortableList from '../../common/SortableList';
import LayoutListItem from './LayoutListItem';
import Modal from '../../common/Modal';
import { get } from '../../services/api';
import LayoutForm from './LayoutForm';
import StringUtils from '../../utils/string-utils';

class LayoutList extends PureComponent {
  state = {
    openModal: false,
    currentTabIndex: 0,
    selected: null,
    editing: false,
  };

  async componentDidMount() {
    const { setLiveLayout, current, layouts } = this.props;
    if (current) {
      let currentTabIndex = layouts[current].studioWall ? 0 : 1;
      this.setState({ currentTabIndex });
    }
    const liveLayout = await this._getLiveLayout();
    if (liveLayout) {
      setLiveLayout(liveLayout);
    }
  }

  async componentDidUpdate(prevProps) {
    const { newLayoutId, setLayout, studioLayouts, eventLayouts, activeStudio, layouts } = this.props;
    if (
      (prevProps.studioLayouts && studioLayouts && prevProps.studioLayouts.length !== studioLayouts.length) ||
      (prevProps.eventLayouts && eventLayouts && prevProps.eventLayouts.length !== eventLayouts.length)
    ) {
      const storedLayout = localStorage.getItem('selectedLayout');
      if (storedLayout) {
        const layoutExists = await this._layoutExists(storedLayout);
        if (layoutExists === true && layouts[storedLayout] && layouts[storedLayout].version && layouts[storedLayout].version === 2) {
          let currentTabIndex = layouts[storedLayout].studioWall ? 0 : 1;
          this.setState({ currentTabIndex }, () => {
            this._selectLayout(storedLayout);
          });
        } else {
          setLayout(null);
        }
      } else {
        setLayout(null);
      }
    }

    if (prevProps.newLayoutId !== newLayoutId && newLayoutId) {
      const { rows, columns } = activeStudio;
      let currentTabIndex = layouts[newLayoutId].studioWall ? 0 : 1;
      this.setState({ currentTabIndex }, () => {
        localStorage.setItem('selectedLayout', newLayoutId);
        setLayout(newLayoutId, rows, columns);
      });
    }
  }

  _getLiveLayout = async () => {
    try {
      const { activeEvent } = this.props;
      const response = await get(`/event/${activeEvent._id}`);
      const { liveLayout } = response.data;
      if (liveLayout) {
        const exists = await this._layoutExists(liveLayout);
        return exists ? liveLayout : null;
      }
      return null;
    } catch (error) {
      console.log(error);
    }
  };

  _layoutExists = async (layoutId) => {
    try {
      const layoutResponse = await get(`/layout/${layoutId.toString()}`);
      return layoutResponse.status === 200 && layoutResponse.data ? true : false;
    } catch (error) {
      console.log(error);
    }
  };

  _updateItem = (item) => {
    this.setState({ openModal: true, editing: true, selected: item });
  };

  _deleteItem = async (item) => {
    const willDelete = await swal({
      title: 'Are you sure?',
      text: 'Are you sure that you want to delete this layout?',
      buttons: true,
      dangerMode: true,
    });
    if (willDelete) {
      const { deleteLayoutRequest, current, setLayout } = this.props;
      deleteLayoutRequest(item._id);
      if (item._id === current) {
        setLayout(null);
      }
    }
  };

  _addHandler = () => {
    this.setState({ openModal: true, editing: false });
  };

  _selectLayout = async (selected) => {
    try {
      const { setLayout, activeStudio, layouts, enableSave, current } = this.props;
      const { rows, columns } = activeStudio;
      if (layouts[selected] && layouts[selected].version && layouts[selected].version === 2) {
        if (enableSave === true) {
          const result = await swal({
            text: `You have unsaved changes on ${layouts[current].name}. Would you like to discard them?`,
            buttons: [
              {
                text: 'Discard Changes',
                value: true,
                visible: true,
                className: 'swal-custom-button--red',
                closeModal: true,
              },
              {
                text: 'Cancel',
                value: false,
                visible: true,
                className: 'swal-custom-button--grey',
                closeModal: true,
              },
            ],
            dangerMode: true,
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
          if (result) {
            setLayout(selected, rows, columns);
            localStorage.setItem('selectedLayout', selected);
          }
        } else {
          setLayout(selected, rows, columns);
          localStorage.setItem('selectedLayout', selected);
        }
      } else {
        swal({
          title: 'Incompatible Layout',
          text: 'This is an older layout which is no longer supported. Please delete and recreate it.',
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  _onSortEnd = ({ oldIndex, newIndex }) => {
    const { currentTabIndex } = this.state;
    const { orderLayouts, studioLayouts, eventLayouts, updateLayoutsOrderRequest } = this.props;

    let layoutId;
    let list;
    if (currentTabIndex === 0) {
      layoutId = studioLayouts[oldIndex];
      list = studioLayouts;
    } else {
      layoutId = eventLayouts[oldIndex];
      list = eventLayouts;
    }

    orderLayouts(oldIndex, newIndex, layoutId);
    updateLayoutsOrderRequest(list);
  };

  _duplicateItem = (item) => {
    const { duplicateLayoutRequest, activeEvent } = this.props;
    duplicateLayoutRequest(item._id, activeEvent._id);
  };

  _closeModal = () => {
    this.setState({ openModal: false, editing: false, selected: null });
  };

  _onFormSubmit = ({ name, type }) => {
    const { editing, selected } = this.state;
    const { createLayoutRequest, updateLayoutRequest, event, activeStudio } = this.props;
    if (!editing) {
      const hashCode = StringUtils.hash64(JSON.stringify({ areas: [], backgroundVideoUrl: null, backgroundVideoMetadata: null, backgroundImageUrl: null }));
      createLayoutRequest({
        studioWall: type.value === 1 ? activeStudio._id : undefined,
        event: type.value === 2 ? event._id : undefined,
        name: name || 'New Layout',
        grid: {
          enabled: false,
          size: {
            columns: 1,
            rows: 1,
          },
          color: '#2B763A',
        },
        areas: [],
        version: 2,
        hashCode,
      });
    } else {
      const data = { name };
      if (type.value === 1) {
        data.studioWall = activeStudio._id;
        data.event = null;
      } else {
        data.event = event._id;
        data.studioWall = null;
      }
      updateLayoutRequest(selected._id, data);
    }
    this._closeModal();
  };

  render() {
    const { currentTabIndex, selected, editing, openModal } = this.state;
    const { studioLayouts, eventLayouts, current, liveLayout } = this.props;
    const layoutsListData = currentTabIndex === 0 ? studioLayouts : eventLayouts;
    return (
      <>
        <div className='layout-list-tabs'>
          <div className={currentTabIndex === 0 ? 'tab active' : 'tab'} onClick={() => this.setState({ currentTabIndex: 0 })}>
            Templates
          </div>
          <div className={currentTabIndex === 1 ? 'tab active' : 'tab'} onClick={() => this.setState({ currentTabIndex: 1 })}>
            Event Layouts
          </div>
        </div>
        {layoutsListData.length > 0 && (
          <SortableList
            data={layoutsListData}
            renderItem={(item) => (
              <LayoutListItem
                layout={item}
                liveLayout={liveLayout}
                onSelected={this._selectLayout}
                selected={current === item}
                onEdit={this._updateItem}
                onDelete={this._deleteItem}
                onDuplicate={this._duplicateItem}
              />
            )}
            keyExtractor={(item) => `${item}`}
            containerStyle={{ height: 'calc(100% - 120px)', position: 'relative' }}
            onSortEnd={this._onSortEnd}
          />
        )}
        <div className='add-layout' onClick={this._addHandler}>
          <img src={addIcon} alt='add' style={{ marginLeft: 15 }} />
          Add Layout
        </div>
        {openModal && (
          <Modal show={true} onClose={() => this._closeModal()}>
            <LayoutForm model={selected} onSubmit={this._onFormSubmit} edit={editing === true} />
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  activeStudio: state.studio.active,
  activeEvent: state.events.active,
  liveLayout: state.events.active ? state.events.active.liveLayout : null,
  studioLayouts: state.layouts.studioLayouts,
  eventLayouts: state.layouts.eventLayouts,
  current: state.layouts.current,
  event: state.layouts.event,
  layouts: state.layouts.entities,
  newLayoutId: state.layouts.newLayoutId,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createLayoutRequest,
      setLayout,
      updateLayoutRequest,
      deleteLayoutRequest,
      orderLayouts,
      updateLayoutsOrderRequest,
      duplicateLayoutRequest,
      setLiveLayout,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(LayoutList);
