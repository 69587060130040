import { createActions, handleActions } from 'redux-actions';

const initialState = {
  entities: {},
  list: [],
  selected: null,
  fetching: false,
  error: null,
  eventCreated: false,
  newEventId: undefined,
  active: undefined,
  participants: undefined,
  archived: [],
};

export const actions = {
  GET_ALL_EVENTS_REQUEST: 'GET_ALL_EVENTS_REQUEST',
  GET_ALL_EVENTS_SUCCESS: 'GET_ALL_EVENTS_SUCCESS',
  GET_ALL_EVENTS_FAILURE: 'GET_ALL_EVENTS_FAILURE',
  GET_EVENTS_REQUEST: 'GET_EVENTS_REQUEST',
  GET_EVENTS_SUCCESS: 'GET_EVENTS_SUCCESS',
  GET_EVENTS_FAILURE: 'GET_EVENTS_FAILURE',
  CREATE_EVENT_REQUEST: 'CREATE_EVENT_REQUEST',
  CREATE_EVENT_SUCCESS: 'CREATE_EVENT_SUCCESS',
  CREATE_EVENT_FAILURE: 'CREATE_EVENT_FAILURE',
  UPDATE_EVENT_REQUEST: 'UPDATE_EVENT_REQUEST',
  UPDATE_EVENT_SUCCESS: 'UPDATE_EVENT_SUCCESS',
  UPDATE_EVENT_FAILURE: 'UPDATE_EVENT_FAILURE',
  DELETE_EVENT_REQUEST: 'DELETE_EVENT_REQUEST',
  DELETE_EVENT_SUCCESS: 'DELETE_EVENT_SUCCESS',
  DELETE_EVENT_FAILURE: 'DELETE_EVENT_FAILURE',
  DUPLICATE_EVENT_REQUEST: 'DUPLICATE_EVENT_REQUEST',
  DUPLICATE_EVENT_SUCCESS: 'DUPLICATE_EVENT_SUCCESS',
  DUPLICATE_EVENT_FAILURE: 'DUPLICATE_EVENT_FAILURE',
  SELECT_EVENT: 'SELECT_EVENT',
  DESELECT_EVENT: 'DESELECT_EVENT',
  SET_ACTIVE_EVENT: 'SET_ACTIVE_EVENT',
  GET_EVENT_PARTICIPANTS_REQUEST: 'GET_EVENT_PARTICIPANTS_REQUEST',
  GET_EVENT_PARTICIPANTS_SUCCESS: 'GET_EVENT_PARTICIPANTS_SUCCESS',
  GET_EVENT_PARTICIPANTS_FAILURE: 'GET_EVENT_PARTICIPANTS_FAILURE',
  UPDATE_PARTICIPANT_STATUS_REQUEST: 'UPDATE_PARTICIPANT_STATUS_REQUEST',
  UPDATE_PARTICIPANT_STATUS_SUCCESS: 'UPDATE_PARTICIPANT_STATUS_SUCCESS',
  UPDATE_PARTICIPANT_STATUS_FAILURE: 'UPDATE_PARTICIPANT_STATUS_FAILURE',
  REMOVE_EVENT_PARTICIPANTS_REQUEST: 'REMOVE_EVENT_PARTICIPANTS_REQUEST',
  REMOVE_EVENT_PARTICIPANTS_SUCCESS: 'REMOVE_EVENT_PARTICIPANTS_SUCCESS',
  REMOVE_EVENT_PARTICIPANTS_FAILURE: 'REMOVE_EVENT_PARTICIPANTS_FAILURE',
  SET_LIVE_LAYOUT: 'SET_LIVE_LAYOUT',
  GET_EVENT_REQUEST: 'GET_EVENT_REQUEST',
};

export const getEventById = (state, id) => {
  const { entities } = state.events;
  if (id) {
    return entities[id];
  }
  return null;
};

export const getFullList = (state) => {
  const { list, entities } = state.events;
  if (list.length) {
    return list.map((id) => entities[id]);
  }
  return [];
};

const updateEventListAndEntities = (state, data) => {
  const { entities, list, archived } = state;
  entities[data._id] = data;

  const eventList = list.concat(archived);
  if (!eventList.includes(data._id)) {
    eventList.push(data._id);
  }

  const sortedEvents = eventList.map((id) => entities[id]).sort((a, b) => new Date(b.scheduledStartTime) - new Date(a.scheduledStartTime));

  const availableEventList = sortedEvents.filter((e) => !e.isArchived).map((evt) => evt._id);
  const archivedEventList = sortedEvents.filter((e) => e.isArchived === true).map((evt) => evt._id);
  return [entities, availableEventList, archivedEventList];
};

export const {
  getAllEventsRequest,
  getAllEventsSuccess,
  getAllEventsFailure,
  getEventsRequest,
  getEventsSuccess,
  getEventsFailure,
  createEventRequest,
  createEventSuccess,
  createEventFailure,
  updateEventRequest,
  updateEventSuccess,
  updateEventFailure,
  deleteEventRequest,
  deleteEventSuccess,
  deleteEventFailure,
  duplicateEventRequest,
  duplicateEventSuccess,
  duplicateEventFailure,
  selectEvent,
  deselectEvent,
  setActiveEvent,
  getEventParticipantsRequest,
  getEventParticipantsSuccess,
  getEventParticipantsFailure,
  updateParticipantStatusRequest,
  updateParticipantStatusSuccess,
  updateParticipantStatusFailure,
  removeEventParticipantsRequest,
  removeEventParticipantsSuccess,
  removeEventParticipantsFailure,
  setLiveLayout,
  getEventRequest,
} = createActions({
  [actions.GET_ALL_EVENTS_REQUEST]: () => ({}),
  [actions.GET_ALL_EVENTS_SUCCESS]: (data) => ({ data }),
  [actions.GET_ALL_EVENTS_FAILURE]: (error) => ({ error }),
  [actions.GET_EVENTS_REQUEST]: (studio) => ({ studio }),
  [actions.GET_EVENTS_SUCCESS]: (data) => ({ data }),
  [actions.GET_EVENTS_FAILURE]: (error) => ({ error }),
  [actions.CREATE_EVENT_REQUEST]: (event) => ({ event }),
  [actions.CREATE_EVENT_SUCCESS]: (data) => ({ data }),
  [actions.CREATE_EVENT_FAILURE]: (error) => ({ error }),
  [actions.UPDATE_EVENT_REQUEST]: (id, event) => ({ id, event }),
  [actions.UPDATE_EVENT_SUCCESS]: (data) => ({ data }),
  [actions.UPDATE_EVENT_FAILURE]: (error) => ({ error }),
  [actions.DELETE_EVENT_REQUEST]: (id) => ({ id }),
  [actions.DELETE_EVENT_SUCCESS]: (data) => ({ data }),
  [actions.DELETE_EVENT_FAILURE]: (error) => ({ error }),
  [actions.DUPLICATE_EVENT_REQUEST]: (id) => ({ id }),
  [actions.DUPLICATE_EVENT_SUCCESS]: (data) => ({ data }),
  [actions.DUPLICATE_EVENT_FAILURE]: (error) => ({ error }),
  [actions.SELECT_EVENT]: (event) => ({ event }),
  [actions.DESELECT_EVENT]: () => ({}),
  [actions.SET_ACTIVE_EVENT]: (event) => ({ event }),
  [actions.GET_EVENT_PARTICIPANTS_REQUEST]: (event, includeOffWallParticipants) => ({ event, includeOffWallParticipants }),
  [actions.GET_EVENT_PARTICIPANTS_SUCCESS]: (data) => ({ data }),
  [actions.GET_EVENT_PARTICIPANTS_FAILURE]: (error) => ({ error }),
  [actions.UPDATE_PARTICIPANT_STATUS_REQUEST]: (id, data) => ({
    id,
    data,
  }),
  [actions.UPDATE_PARTICIPANT_STATUS_SUCCESS]: (data) => ({ data }),
  [actions.UPDATE_PARTICIPANT_STATUS_FAILURE]: (error) => ({ error }),
  [actions.REMOVE_EVENT_PARTICIPANTS_REQUEST]: (event) => ({ event }),
  [actions.REMOVE_EVENT_PARTICIPANTS_SUCCESS]: (data) => ({ data }),
  [actions.REMOVE_EVENT_PARTICIPANTS_FAILURE]: (error) => ({ error }),
  [actions.SET_LIVE_LAYOUT]: (data) => ({ data }),
  [actions.GET_EVENT_REQUEST]: (id) => ({ id }),
});

const reducer = handleActions(
  {
    [actions.GET_ALL_EVENTS_REQUEST]: (state) => ({
      ...state,
      fetching: true,
      error: null,
    }),
    [actions.GET_ALL_EVENTS_SUCCESS]: (state, action) => {
      const entities = {};
      const archived = [];
      const list = [];
      action.payload.data
        .map((evt) => evt._id)
        .forEach((id) => {
          const e = action.payload.data.find((evt) => evt._id === id);
          entities[id] = { ...e };
          if (e.isArchived === true) {
            archived.push(id);
          } else {
            list.push(id);
          }
        });
      return {
        ...state,
        entities,
        list,
        archived,
        fetching: false,
        error: null,
      };
    },
    [actions.GET_ALL_EVENTS_FAILURE]: (state, action) => ({
      ...state,
      fetching: false,
      error: action.payload.error,
    }),
    [actions.GET_EVENTS_REQUEST]: (state) => ({
      ...state,
      fetching: true,
      error: null,
    }),
    [actions.GET_EVENTS_SUCCESS]: (state, action) => {
      const sortedList = action.payload.data.sort((a, b) => new Date(b.scheduledStartTime) - new Date(a.scheduledStartTime)).map((evt) => evt._id);
      const entities = {};
      const archived = [];
      const list = [];
      let updatedActiveEvent = state.active;
      sortedList.forEach((id) => {
        const e = action.payload.data.find((evt) => evt._id === id);
        if (state.active && id === state.active._id) {
          updatedActiveEvent = { ...e };
        }
        entities[id] = { ...e };
        if (e.isArchived === true) {
          archived.push(id);
        } else {
          list.push(id);
        }
      });
      return {
        ...state,
        entities,
        list,
        archived,
        active: updatedActiveEvent,
        fetching: false,
        error: null,
      };
    },
    [actions.GET_EVENTS_FAILURE]: (state, action) => ({
      ...state,
      fetching: false,
      error: action.payload.error,
    }),
    [actions.CREATE_EVENT_REQUEST]: (state) => ({
      ...state,
      fetching: true,
      error: null,
      eventCreated: false,
      newEventId: undefined,
    }),
    [actions.CREATE_EVENT_SUCCESS]: (state, action) => {
      const [entities, list, archived] = updateEventListAndEntities(state, action.payload.data);
      return {
        ...state,
        list,
        entities,
        archived,
        fetching: false,
        eventCreated: true,
        error: null,
        newEventId: action.payload.data._id,
      };
    },
    [actions.CREATE_EVENT_FAILURE]: (state, action) => ({
      ...state,
      fetching: false,
      error: action.payload.error,
      eventCreated: false,
      newEventId: undefined,
    }),
    [actions.UPDATE_EVENT_REQUEST]: (state) => ({
      ...state,
      fetching: true,
      error: null,
    }),
    [actions.UPDATE_EVENT_SUCCESS]: (state, action) => {
      const [entities, list, archived] = updateEventListAndEntities(state, action.payload.data);
      return {
        ...state,
        list,
        archived,
        entities,
        fetching: false,
        error: null,
      };
    },
    [actions.UPDATE_EVENT_FAILURE]: (state, action) => ({
      ...state,
      fetching: false,
      error: action.payload.error,
    }),
    [actions.DELETE_EVENT_REQUEST]: (state) => ({
      ...state,
      fetching: true,
      error: null,
    }),
    [actions.DELETE_EVENT_SUCCESS]: (state, action) => {
      const deletedId = action.payload.data;
      const { entities, list, archived } = state;

      const event = entities[deletedId];
      if (event.isArchived) {
        archived.splice(archived.indexOf(deletedId), 1);
      } else {
        list.splice(list.indexOf(deletedId), 1);
      }
      delete entities[deletedId];

      return {
        ...state,
        entities: { ...entities },
        list: [...list],
        archived: [...archived],
        selected: null,
        fetching: false,
        error: null,
      };
    },
    [actions.DELETE_EVENT_FAILURE]: (state, action) => ({
      ...state,
      fetching: false,
      error: action.payload.error,
    }),
    [actions.DUPLICATE_EVENT_REQUEST]: (state) => ({
      ...state,
      duplicated: false,
      fetching: true,
      error: null,
    }),
    [actions.DUPLICATE_EVENT_SUCCESS]: (state, action) => {
      const [entities, list, archived] = updateEventListAndEntities(state, action.payload.data);
      return {
        ...state,
        list,
        archived,
        entities,
        fetching: false,
        error: null,
      };
    },
    [actions.DUPLICATE_EVENT_FAILURE]: (state, action) => ({
      ...state,
      duplicated: false,
      fetching: false,
      error: action.payload.error,
    }),
    [actions.SELECT_EVENT]: (state, action) => ({
      ...state,
      selected: action.payload.event,
    }),
    [actions.DESELECT_EVENT]: (state) => ({
      ...state,
      selected: null,
    }),
    [actions.SET_ACTIVE_EVENT]: (state, action) => ({
      ...state,
      active: action.payload.event,
    }),
    [actions.GET_EVENT_PARTICIPANTS_REQUEST]: (state) => ({
      ...state,
      fetching: true,
      error: null,
    }),
    [actions.GET_EVENT_PARTICIPANTS_SUCCESS]: (state, action) => {
      return {
        ...state,
        participants: action.payload.data,
        fetching: false,
        error: null,
      };
    },
    [actions.GET_EVENT_PARTICIPANTS_FAILURE]: (state, action) => ({
      ...state,
      fetching: false,
      participants: [],
      error: action.payload.error,
    }),
    [actions.SET_LIVE_LAYOUT]: (state, action) => {
      const { active } = state;
      active.liveLayout = action.payload.data;
      return {
        ...state,
        active,
      };
    },
  },
  initialState,
);

export default reducer;
