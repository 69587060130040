import React from 'react';

export default function Counter({ label, value, style }) {
  return (
    <div style={{ margin: 5, display: 'flex', justifyContent: 'flex-start' }}>
      <div style={{ flex: 1, display: 'flex', textAlign: 'left', flexDirection: 'row', justifyContent: 'space-between', margin: '0 10px' }}>
        <div style={{ color: '#43586f' }}>{label}</div>
        <div style={style}>{value}</div>
      </div>
    </div>
  );
}
