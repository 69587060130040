import React from 'react';

export default function TvGridLine({ direction, thin, color, position, dashed, borderWidth }) {
  const _borderWidth = borderWidth ? borderWidth : thin ? 0.5 : 1;
  const _borderType = dashed ? 'dashed' : 'solid';

  let positionStyle = {};
  if (direction === 'horizontal') {
    positionStyle = {
      left: 0,
      right: 0,
      top: `${position}%`,
    };
  } else {
    positionStyle = {
      top: 0,
      bottom: 0,
      left: `${position}%`,
      zIndex: 250,
    };
  }

  return (
    <div
      className='grid-line'
      style={{
        outline: `${_borderWidth}px ${_borderType} ${color}`,
        ...positionStyle,
      }}
    />
  );
}
