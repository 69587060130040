import React from 'react';
import PropTypes from 'prop-types';

const WallLayout = ({
  rows,
  columns,
  width,
  height,
  children,
}) => {
  if (width !== 0 && height !== 0) {
    let gridStyle = {};
    let gridItemWidth = 0;

    const itemWidth = Math.floor(width / columns);
    const itemHeight = Math.floor(itemWidth * 9 / 16);

    if (itemHeight * rows > height) {
      const h = Math.floor(height / rows);
      gridItemWidth = Math.floor(h * 16 / 9);
    } else {
      gridItemWidth = itemWidth;
    }

    if (rows && columns) {
      gridStyle = {
        gridTemplateColumns: `repeat(${columns}, ${gridItemWidth}px)`,
        gridTemplateRows: `repeat(${rows}, 1fr)`
      };

      const items = [];
      for (let i = 0; i < rows * columns; i += 1) {
        items.push(<div key={i} className='grid-item' />);
      }
      return (
        <div className='grid' style={gridStyle}>
          {items}
          {children}
        </div>
      );
    }
  }
  return null;
};

WallLayout.propTypes = {
  rows: PropTypes.number.isRequired,
  columns: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default WallLayout;
