import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { getAwsAvailabilityZonesRequest } from '../../redux/goLiveRedux';
import PropTypes from 'prop-types';
import Input from '../../common/Input';
import Button from '../../common/Button';
import Select from '../../common/Select';
import TextArea from '../../common/TextArea';

class StudioForm extends PureComponent {
  static propTypes = {
    defaultRows: PropTypes.number,
    defaultColumns: PropTypes.number,
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    canUpdate: PropTypes.bool.isRequired,
  };

  state = {
    columns: 0,
    rows: 0,
    awsRegion: '',
    awsDefaultAvailabilityZone: '',
    role: '',
    pptControllerURL: '',
    offWallStreamUrl: '',
    janusServers: '',
  };

  componentDidMount() {
    const { data, user } = this.props;
    if (data.awsRegion) {
      this.props.getAwsAvailabilityZones(data.awsRegion);
    }

    this.setState({
      rows: data.rows,
      columns: data.columns,
      awsRegion: data.awsRegion || '',
      awsDefaultAvailabilityZone: data.awsDefaultAvailabilityZone || '',
      role: user.role,
      pptControllerURL: data.pptControllerURL || '',
      offWallStreamUrl: data.offWallStreamUrl || '',
      janusServers: JSON.stringify(data.janusServers) || '',
    });
  }

  componentDidUpdate(prevProps) {
    const { data, user } = this.props;
    if (prevProps.data !== data && data) {
      this.setState({
        rows: data.rows,
        columns: data.columns,
        awsRegion: data.awsRegion || this.state.awsRegion,
        awsDefaultAvailabilityZone: data.awsDefaultAvailabilityZone || this.state.awsDefaultAvailabilityZone,
        role: user.role,
        pptControllerURL: data.pptControllerURL || this.state.pptControllerURL,
        offWallStreamUrl: data.offWallStreamUrl || this.state.offWallStreamUrl,
        janusServers: JSON.stringify(data.janusServers) || JSON.stringify(this.state.janusServers),
      });
    }
  }

  _onChangeHandler = (event) => {
    const { name, value } = event.target;
    if (name === 'pptControllerURL') {
      this.setState({ pptControllerURL: value });
    } else if (name === 'offWallStreamUrl') {
      this.setState({ offWallStreamUrl: value });
    } else if (name === 'janusServers') {
      this.setState({ janusServers: value });
    } else {
      this.setState({ [name]: parseInt(value, 10) }, () => {
        const { onChange } = this.props;
        onChange && onChange(this.state);
      });
    }
  };

  _setRegion = ({ RegionName }) => {
    this.setState({ awsRegion: RegionName }, () => {
      this.props.getAwsAvailabilityZones(this.state.awsRegion);
    });
  };

  _setDefaultAZ = ({ ZoneName }) => {
    this.setState({ awsDefaultAvailabilityZone: ZoneName });
  };

  _onSubmitHandler = () => {
    const { onSubmit } = this.props;
    if (onSubmit) {
      onSubmit(this.state);
    }
  };

  render() {
    const { rows, columns, awsRegion, awsDefaultAvailabilityZone, role } = this.state;
    const { canUpdate } = this.props;

    return (
      <div className='form' style={{ height: '100%', flex: 1 }}>
        <div style={{ width: 500, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <div className='form-group'>
            <div className='form-label'>TV Rows</div>
            <div className='form-control'>
              <Input
                type='number'
                name='rows'
                value={rows.toString()}
                onChange={this._onChangeHandler}
                min='0'
                max='99'
                step='1'
                disabled={canUpdate === false || role !== 'SUPER_ADMIN'}
                containerStyle={{ display: 'flex', flex: 1, margin: 0 }}
                inputStyle={{ margin: 0 }}
              />
            </div>
          </div>

          <div className='form-group'>
            <div className='form-label'>TV Columns</div>
            <div className='form-control'>
              <Input
                type='number'
                name='columns'
                value={columns.toString()}
                onChange={this._onChangeHandler}
                min='0'
                max='99'
                step='1'
                disabled={canUpdate === false || role !== 'SUPER_ADMIN'}
                containerStyle={{ display: 'flex', flex: 1, margin: 0 }}
                inputStyle={{ margin: 0 }}
              />
            </div>
          </div>
          <div className='form-group'>
            <div className='form-label'>AWS Region</div>
            <div className='form-control'>
              <Select
                list={this.props.awsRegions}
                listKey='RegionName'
                listLabel='RegionName'
                onChange={this._setRegion}
                selected={awsRegion}
                currentOption={awsRegion}
                disabled={canUpdate === false || role !== 'SUPER_ADMIN'}
                containerStyle={{ width: 110 }}
                small
              />
            </div>
          </div>
          {this.props.availabilityZones.length > 0 && (
            <div className='form-group'>
              <div className='form-label'>Default AWS AZ</div>
              <div className='form-control'>
                <Select
                  list={this.props.availabilityZones}
                  listKey='ZoneName'
                  listLabel='ZoneName'
                  onChange={this._setDefaultAZ}
                  selected={awsDefaultAvailabilityZone}
                  currentOption={awsDefaultAvailabilityZone}
                  containerStyle={{ width: 110 }}
                  disabled={canUpdate === false || role !== 'SUPER_ADMIN'}
                  small
                />
              </div>
            </div>
          )}
          <div className='form-group'>
            <div className='form-label'>PPT Controller URL</div>
            <div className='form-control'>
              <Input
                type='text'
                name='pptControllerURL'
                value={this.state.pptControllerURL}
                onChange={this._onChangeHandler}
                disabled={role !== 'SUPER_ADMIN'}
                containerStyle={{ margin: 0, width: '100%' }}
                inputStyle={{ margin: 0 }}
              />
            </div>
          </div>

          <div className='form-group'>
            <div className='form-label'>Off-Wall Stream URL</div>
            <div className='form-control'>
              <Input
                type='text'
                name='offWallStreamUrl'
                value={this.state.offWallStreamUrl}
                onChange={this._onChangeHandler}
                disabled={role !== 'SUPER_ADMIN'}
                containerStyle={{ margin: 0, width: '100%' }}
                inputStyle={{ margin: 0 }}
              />
            </div>
          </div>
          <TextArea
            name='janusServers'
            label='Breakouts Servers'
            onChange={this._onChangeHandler}
            containerStyle={{ paddingLeft: 0, paddingRight: 0, height: 100 }}
            value={this.state.janusServers}
          />
        </div>
        {canUpdate && role === 'SUPER_ADMIN' && <div style={{ marginTop: 20 }}>{<Button text='Save' onClick={this._onSubmitHandler} />}</div>}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  awsRegions: state.goLive.regions,
  availabilityZones: state.goLive.availabilityZones,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  getAwsAvailabilityZones: (regionId) => dispatch(getAwsAvailabilityZonesRequest(regionId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudioForm);
