import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

export default function ContextMenuItem({ item, onSelectItem }) {
  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (hover) {
      const menuItemOpts = document.querySelectorAll('.context-menu-item-options');
      if (menuItemOpts.length === 0) {
        return;
      }
      const rect = menuItemOpts[0].getBoundingClientRect();
      if (rect.right > window.innerWidth) {
        menuItemOpts[0].style.left = '-100%';
      }
      if (rect.bottom > window.innerHeight) {
        menuItemOpts[0].style.top = `-${rect.bottom - window.innerHeight + 42}px`;
      }
    }
  }, [hover]);

  const { danger, disabled, fixed } = item;
  let classes = 'context-menu-item';
  if (danger) {
    classes += ' danger';
  }
  if (disabled) {
    classes += ' disabled';
  }
  if (fixed) {
    classes += ' fixed';
  }

  return (
    <div className={classes} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={(e) => onSelectItem(e, item)}>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        {item.icon}
        <span style={{ marginLeft: item.icon ? 10 : 0 }}>{item.label}</span>
      </span>
      {item.options && <FontAwesomeIcon icon={faChevronRight} color='#c1c1c1' />}
      {item.options && hover && (
        <div className='context-menu-item-options' style={{ top: 0, left: '100%', maxHeight: 420, overflowY: 'auto' }}>
          {item.options.map((opt, index) => {
            let _classes = 'context-menu-item';
            if (opt.danger) {
              _classes += ' danger';
            }
            if (opt.disabled) {
              _classes += ' disabled';
            }
            if (opt.fixed) {
              _classes += ' fixed';
            }
            return (
              <div className={_classes} key={`menu-item-option-${item.value}-${index}`} onClick={(e) => onSelectItem(e, item, opt)}>
                {opt.label}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
