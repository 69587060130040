import React, { PureComponent } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import '../css/List.css';

const SortableItem = SortableElement(({ item, index, renderItem }) => <li className='list-item'>{renderItem && renderItem(item, index)}</li>);

const SortableList = SortableContainer(({ children }) => <ul className='list'>{children}</ul>);

class SortableListContainer extends PureComponent {
  render() {
    const { containerStyle, data, keyExtractor, onSortEnd } = this.props;
    return (
      <div className='list-root' style={containerStyle}>
        <div className='list-wrapper'>
          <SortableList helperClass='sortable-list-item' useDragHandle onSortEnd={onSortEnd}>
            {data.map((item, index) => {
              const _key = (keyExtractor && keyExtractor(item, index)) || `list-item-${index}`;
              return <SortableItem key={_key} {...this.props} item={item} index={index} />;
            })}
          </SortableList>
        </div>
      </div>
    );
  }
}

export default SortableListContainer;
