import React from 'react';
import { connect } from 'react-redux';
import participant_placeholder from '../../assets/images/participant_placeholder.png';
import participant_in_place from '../../assets/images/participant_in_place.png';
import WallAsset from '../../common/WallAsset';
import ParticipantPhoto from '../../common/ParticipantPhoto';
import ParticipantMetadata from '../layouts/ParticipantMetadata';
import { findEventParticipantByDisplayNum } from '../../utils/find-event-participant';
import landscapeImg from '../../assets/images/feed_landscape.png';
import StudioFeed from '../layouts/config/StudioFeed';

const WallArea = ({ style, sourceType, width, height, tvWidth, tvHeight, data, padding, grid }) => {
  let areaWidth = tvWidth;
  let areaHeight = tvHeight;

  let paddingStr = '';
  if ((padding && (padding.top > 0 || padding.left > 0 || padding.right > 0 || padding.bottom > 0)) || width > 1 || height > 1) {
    const top = padding.top * tvHeight;
    const left = padding.left * tvWidth;
    const right = padding.right * tvWidth;
    const bottom = padding.bottom * tvHeight;
    paddingStr = `${top}px ${right}px ${bottom}px ${left}px`;

    const { gridRowStart, gridRowEnd, gridColumnStart, gridColumnEnd } = style;
    const gridColumnCount = gridColumnEnd - gridColumnStart;
    const gridRowCount = gridRowEnd - gridRowStart;
    const tvWidthCount = gridColumnCount / 6;
    const tvHeightCount = gridRowCount / 2;
    areaWidth = tvWidth * tvWidthCount - left - right;
    areaHeight = tvHeight * tvHeightCount - top - bottom;
  } else if (width === 1 / 2 && height === 1 / 2) {
    areaWidth = tvWidth / 2;
    areaHeight = tvHeight / 2;
  } else if (width === 1 / 3 && height === 1 / 2) {
    areaWidth = 'auto';
    areaHeight = tvHeight / 2;
  }

  if (sourceType === 'Asset') {
    const { metadata } = data;
    if (metadata) {
      if (metadata.width === metadata.height) {
        areaWidth = areaHeight;
      } else {
        const ratio = Math.min(areaWidth / metadata.width, areaHeight / metadata.height);
        areaWidth = metadata.width * ratio;
        areaHeight = metadata.height * ratio;
      }
    }
  }

  let image;
  let ep;
  if (sourceType === 'Asset') {
    image =
      data.type === 'image' ? (
        <WallAsset url={data && (data.previewFileKey || data.fileKey)} width={areaWidth} height={areaHeight} />
      ) : (
        <img width='100%' src={landscapeImg} alt='video asset' />
      );
  } else if (sourceType === 'WallParticipant') {
    image = <img style={{ width: areaWidth, height: areaHeight }} src={participant_placeholder} alt='wall participant' />;
  } else if (sourceType === 'Participant') {
    ep = findEventParticipantByDisplayNum(data.wallDisplayNum, ['ON_WALL', 'ON_AIR', 'IN_STUDIO_QUEUE']);
    if (ep && ep.participant && ep.participant.photoUrl) {
      if (ep.participant && ep.participant.photoUrl) {
        image = <ParticipantPhoto photoUrl={ep.participant.photoUrl} width={areaWidth} height={areaHeight} />;
      } else {
        image = <img style={{ width: areaWidth, height: areaHeight }} src={participant_in_place} alt='participant' />;
      }
    } else {
      image = <img style={{ width: areaWidth, height: areaHeight }} src={participant_in_place} alt='participant' />;
    }
  } else if (sourceType === 'StudioFeed') {
    image = <StudioFeed />;
  }

  return (
    <div style={{ ...style, borderWidth: 1, borderColor: grid.color || 'rgb(43, 118, 58)', borderStyle: 'dotted' }}>
      {sourceType && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            display: 'flex',
            width: '100%',
            height: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: sourceType === 'Asset' ? 'flex-start' : 'center',
              alignItems: sourceType === 'Asset' ? 'flex-start' : 'center',
              overflow: 'hidden',
              padding: paddingStr,
            }}
          >
            {image}
            {sourceType === 'Participant' && <ParticipantMetadata wallDisplayNum={data.wallDisplayNum} />}
            {!ep && data.wallDisplayNum && (
              <div
                className='num'
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  backgroundColor: '#4e6379',
                  fontWeight: 600,
                  fontSize: 12,
                  color: '#fff',
                  padding: 4,
                }}
              >
                {`#${data.wallDisplayNum}`}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  grid: state.layouts.grid,
});

export default connect(mapStateToProps)(WallArea);
