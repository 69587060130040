import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import isValidFileType from '../utils/validate-file-type';
import imageIcon from '../assets/icons/image.svg';
import '../css/FileInput.css';

class FileInput extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    label: '',
    name: '',
  };

  state = {
    fileName: 'Select File',
    preview: null,
  };

  _fileInput = React.createRef();

  componentDidMount() {
    if (this.props.filename) this.setState({ fileName: this.props.filename });
  }

  componentDidUpdate(prevProps) {
    const { filename } = this.props;
    if (prevProps.filename !== filename) {
      this.setState({ fileName: filename });
    }
  }

  _onChangeHandler = async (event) => {
    const { onChange } = this.props;
    const { files } = event.target;

    if (files.length !== 0) {
      const file = files[0];
      if (isValidFileType(file)) {
        onChange(event);
        let preview = null;
        if (file.type.includes('image')) {
          try {
            preview = await new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(files[0]);
              reader.onload = () => resolve(reader.result.toString() || '');
              reader.onerror = (error) => reject(error);
            });
          } catch (err) {
            console.error(err);
          }
        }
        this.setState({ fileName: files[0].name, preview });
      } else {
        await swal({
          title: 'Error',
          text: 'Invalid file',
        });
        this._fileInput.current.value = '';
        this.setState({ fileName: 'Select File' });
      }
    }
  };

  render() {
    const { label, name, controlStyle, showPreview, filenameLabelStyle } = this.props;
    const { fileName, preview } = this.state;
    return (
      <div className='form-group'>
        {label && <div className='form-label'>{label}</div>}
        <div className='form-control' style={controlStyle}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {showPreview && <div className='file-preview'>{preview && <img src={preview} alt='' width={80} height={45} style={{ marginRight: 10 }} />}</div>}
            <div className='file-input'>
              <input ref={this._fileInput} type='file' name={name} onChange={this._onChangeHandler} />
              <img src={imageIcon} alt='icon' />
              <span className='file-name' style={filenameLabelStyle}>
                {fileName}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FileInput;
