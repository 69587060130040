import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Tabs from '../../common/Tabs';

import Participants from './Participants';
import RoleChanges from './RoleChanges';
import VenueParticipants from './VenueParticipants';

class Onboarding extends PureComponent {
  state = {
    activeTab: null,
    disabled: false,
  };

  render() {
    const { routesEnabled, appMode } = this.props;
    const { activeTab, disabled } = this.state;
    const isBroadcast = appMode === 'BROADCAST';

    if (!routesEnabled) return <Redirect to='/app/studios' />;

    return (
      <>
        <div className='help-desk'>
          {appMode === 'PANDO' && (
            <Tabs
              options={[
                { value: 'on-wall', label: 'ON WALL' },
                { value: 'off-wall', label: 'OFF WALL' },
                { value: 'venue', label: 'VENUE' },
                { value: 'role-changes', label: 'ROLE CHANGES' },
              ]}
              value={activeTab}
              onChange={(tab) => this.setState({ activeTab: tab })}
              disabled={disabled}
            />
          )}

          {(isBroadcast || (activeTab && activeTab === 'on-wall') || activeTab === 'off-wall') && (
            <Participants
              includeOffWall={isBroadcast || activeTab === 'off-wall'}
              onParticipantConnected={() => this.setState({ disabled: true })}
              onParticipantDisconnected={() => this.setState({ disabled: false })}
              style={{ height: isBroadcast ? 'calc(100% - 10px)' : null }}
            />
          )}
          {activeTab === 'role-changes' && <RoleChanges />}
          {activeTab === 'venue' && <VenueParticipants />}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  appMode: state.ui.appMode,
  routesEnabled: state.ui.routesEnabled,
  activeStudio: state.studio.active,
  activeEvent: state.events.active,
});

Onboarding.propTypes = {
  appMode: PropTypes.string.isRequired,
  routesEnabled: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, null)(Onboarding);
