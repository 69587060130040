import RTC from './rtc-common';

export default class RTCStream {
  static async get(roomName, video, audio, ignoreConnectionError) {
    const rtc = new RTC(roomName);
    const pc = await rtc.setup(ignoreConnectionError);

    pc.ontrack = ({ track }) => {
      console.debug(`Received ${track.kind} MediaStreamTrack with ID ${track.id}`);
      if (track.kind === 'video') {
        video.muted = true; // Chrome will not auto-play unmuted video without user interaction
        const mediaStream = new MediaStream();
        mediaStream.addTrack(track);
        video.srcObject = mediaStream;
      } else if (audio && track.kind === 'audio') {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(track);
        audio.srcObject = mediaStream;
      }
    };

    await new Promise(async (resolve) => {
      const { type, value } = await rtc.getMessage('state');
      if (type === 'state' && value === 'CONFIG_COMPLETED') {
        resolve();
      }
    });

    console.debug('Sending READY_FOR_OFFER');
    rtc.sendMessage({
      type: 'state',
      value: 'READY_FOR_OFFER',
    });

    await rtc.startServer();
    return rtc;
  }

  static close(context) {
    if (context.rtc && context.rtc.pc) {
      context.rtc.pc.getRemoteStreams().forEach((stream) => {
        stream.getTracks().forEach((track) => track.stop());
      });
      context.rtc.stop();
      context.rtc = null;
    }
  }
}
