import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import './css/Forms.css';
import './css/Grid.css';
import './css/Events.css';
import './css/Layouts.css';
import './css/Assets.css';
import './css/Wall.css';
import './css/Studio.css';
import './css/BreakoutRooms.css';
import './css/Participants.css';
import './css/GoLive.css';
import './css/Streams.css';
import './css/Polls.css';

import { selectStudio, setSocketServerUrl } from './redux/studioRedux';
import { setAppMode } from './redux/uiRedux';
import { get, setStudioIdHeader } from './services/api';
import SocketClient from './utils/socket-client';
import Layout from './Layout';
import StudiosDropDown from './common/StudiosDropDown';
import Tile from './common/Tile';
import PandoWall from './assets/icons/pando_wall.svg';
import Broadcast from './assets/icons/broadcast.svg';

const { REACT_APP_SOCKET_SERVER_DOMAIN } = process.env;

const styles = {
  tiles: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

class Main extends PureComponent {
  state = {
    ready: false,
    userStudios: [],
    selectedStudio: null,
    selectedTile: null,
    broadcastStudio: null,
  };

  async componentDidMount() {
    try {
      const storedStudio = localStorage.getItem('activeStudio');

      const userStudios = await get('/studio/');
      const broadcastStudio = userStudios.data.find((s) => s.dbName === 'broadcast');

      let studio = null;
      if (storedStudio) {
        try {
          const parsedStudio = JSON.parse(storedStudio);
          const { status, data } = await get(`/studio/${parsedStudio._id}`);
          if (status === 200 && data && userStudios.data.find((s) => s._id === data._id)) {
            studio = data;
          }
        } catch (err) {
          console.error(err);
        }
      }

      if (userStudios.data.length === 1 && !studio) {
        studio = userStudios.data[0];
      }

      if (studio) {
        this._selectStudio(studio);
      }

      this.setState({
        numStudios: userStudios.data.length,
        selectedStudio: studio,
        userStudios: userStudios.data.filter((s) => s.dbName !== 'broadcast'),
        ready: true,
        broadcastStudio,
      });
    } catch (error) {
      console.log(error);
    }
  }

  _selectStudio = (studio, isButtonPress = false) => {
    const { selectStudio, setAppMode, history, location } = this.props;

    selectStudio(studio);
    localStorage.setItem('activeStudio', JSON.stringify(studio));
    setStudioIdHeader(studio._id);
    if (!this._connectingSocketClient) {
      this._connectSocketClient(studio);
    }

    let selectedTile;
    if (studio.dbName === 'broadcast') {
      selectedTile = 'broadcast';
      if (!location.pathname.includes('broadcast') || isButtonPress) {
        history.replace('/app/broadcast/events');
      }
    } else {
      selectedTile = 'pando';
      if (location.pathname.includes('broadcast') || isButtonPress) {
        history.replace('/app/events');
      }
    }

    setAppMode(selectedTile.toUpperCase());
    this.setState({ selectedTile });
  };

  _connectSocketClient = async (studio) => {
    this._connectingSocketClient = true;
    const instancesResponse = await get(`/instance?studio=${studio._id}`);
    const socketServer = instancesResponse.data.find((i) => i.serverType === 'SOCKET_SERVER');
    if (socketServer && socketServer.subDomain && socketServer.subDomain.length > 0) {
      const socketServerUrl = `https://${socketServer.subDomain}.${REACT_APP_SOCKET_SERVER_DOMAIN}`;
      if (SocketClient.socketServerUrl !== socketServerUrl) {
        SocketClient.connect(socketServerUrl);
        this.props.setSocketServerUrl(socketServerUrl);
      }
    }
  };

  render() {
    const { ready, selectedTile, userStudios, selectedStudio, broadcastStudio, numStudios } = this.state;

    if (ready === true && !selectedTile) {
      return (
        <div style={styles.tiles}>
          <Tile title='Pando' icon={PandoWall}>
            <StudiosDropDown
              studios={userStudios}
              selectedStudio={selectedStudio}
              onChange={(studio) => {
                this.setState({ selectedStudio: studio });
                if (studio) {
                  this._selectStudio(studio, true);
                }
              }}
            />
          </Tile>
          {this.props.user.broadcastEnabled && (
            <Tile
              title='Broadcast'
              icon={Broadcast}
              onClick={() => {
                this._selectStudio(broadcastStudio, true);
              }}
            />
          )}
        </div>
      );
    }
    return selectedTile ? <Layout numStudios={numStudios} {...this.props} selectedTile={selectedTile} /> : null;
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  collapsed: state.ui.navBarCollapsed,
  loading: state.ui.loading,
  appMode: state.ui.appMode,
});

const mapDispatchToProps = (dispatch) => ({
  setSocketServerUrl: (url) => dispatch(setSocketServerUrl(url)),
  selectStudio: (studio) => dispatch(selectStudio(studio)),
  setAppMode: (value) => dispatch(setAppMode(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
