import React from 'react';
import Property from './Property';

export default function WebRTCDetails({ uploadData, downloadData }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        fontSize: 11,
        color: '#fff',
        backgroundColor: '#181f26',
        width: 310,
        border: '2px solid #1e272f',
        boxShadow: '0px 0px 7px 1px #0a081259',
      }}
    >
      <div className='property-list' style={{ width: 290 }}>
        <div style={{ fontWeight: 600, fontSize: 13 }}>Upload</div>
        <div style={{ marginTop: 10, fontWeight: 600, fontSize: 12 }}>Local</div>
        <Property label='Type' value={uploadData && uploadData.local.candidateType} />
        <Property label='Protocol' value={uploadData && uploadData.local.protocol} />
        <Property label='IP' value={uploadData && uploadData.local.ip} />
        <Property label='URL' value={uploadData && uploadData.local.url} />

        <div style={{ marginTop: 10, fontWeight: 600, fontSize: 12 }}>Remote</div>
        <Property label='Type' value={uploadData && uploadData.remote.candidateType} />
        <Property label='Protocol' value={uploadData && uploadData.remote.protocol} />
        <Property label='IP' value={uploadData && uploadData.remote.ip} />
        {uploadData && uploadData.remote.url && <Property label='URL' value={uploadData.remote.url} />}

        <div style={{ marginTop: 20, fontWeight: 600, fontSize: 13 }}>Download</div>
        <div style={{ marginTop: 10, fontWeight: 600, fontSize: 12 }}>Local</div>
        <Property label='Type' value={downloadData && downloadData.local.candidateType} />
        <Property label='Protocol' value={downloadData && downloadData.local.protocol} />
        <Property label='IP' value={downloadData && downloadData.local.ip} />
        <Property label='URL' value={downloadData && downloadData.local.url} />

        <div style={{ marginTop: 10, fontWeight: 600, fontSize: 12 }}>Remote</div>
        <Property label='Type' value={downloadData && downloadData.remote.candidateType} />
        <Property label='Protocol' value={downloadData && downloadData.remote.protocol} />
        <Property label='IP' value={downloadData && downloadData.remote.ip} />
        {downloadData && downloadData.remote.url && <Property label='URL' value={downloadData.remote.url} />}
      </div>
    </div>
  );
}
