import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAllLayoutsRequest, selectLayout, getLayoutById, getLayoutsList } from '../../redux/wallRedux';
import Select from '../../common/Select';
import Loader from '../../common/Loader';
import { get } from '../../services/api';

class LayoutsDropDown extends Component {
  componentDidMount() {
    const { getAllLayoutsRequest, activeEvent, activeStudio, layouts } = this.props;
    getAllLayoutsRequest(activeStudio._id, activeEvent._id);
    if (layouts.length) {
      this._selectLiveLayout();
    }
  }

  async componentDidUpdate(prevProps) {
    const { layouts, } = this.props;
    if (prevProps.layouts.length !== layouts.length) {
      this._selectLiveLayout();
    }
  }

  _selectLiveLayout = async () => {
    const { selectLayout, onActivateLayout } = this.props;
    const liveLayout = await this._getLiveLayout();
    if (liveLayout) {
      selectLayout(liveLayout);
      onActivateLayout(liveLayout);
    }
  }

  _getLiveLayout = async () => {
    try {
      const { activeEvent } = this.props;
      const response = await get(`/event/${activeEvent._id}`);
      const { liveLayout } = response.data;
      if (liveLayout) {
        const exists = await this._layoutExists(liveLayout);
        return exists ? liveLayout : null;
      }
      return null;
    } catch (error) {
      console.log(error);
    }
  };

  _layoutExists = async (layoutId) => {
    try {
      const response = await get(`/layout/${layoutId}`);
      if (response.status === 200 && response.data) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  _onChangeLayout = (data) => {
    const { onChange, selectLayout } = this.props;
    const layoutId = data._id;
    selectLayout(layoutId);
    onChange(layoutId);
  };

  render() {
    const { layouts, selectedLayout, fetching, activatedLayout } = this.props;
    let dropDrown = fetching ? <Loader /> : null;
    if (layouts) {
      dropDrown = (
        <div className='layout-dropdown'>
          <Select
            list={layouts}
            listKey='_id'
            listLabel='name'
            onChange={this._onChangeLayout}
            selected={selectedLayout && selectedLayout._id}
            currentOption={selectedLayout && selectedLayout.name}
            hideOption={activatedLayout}
          />
        </div>
      );
    }
    return dropDrown;
  }
}

const mapStateToProps = (state) => ({
  activeStudio: state.studio.active,
  activeEvent: state.events.active,
  layouts: getLayoutsList(state),
  fetching: state.wall.fetching,
  selectedLayout: getLayoutById(state, state.wall.selectedLayout),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ getAllLayoutsRequest, selectLayout }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LayoutsDropDown);
