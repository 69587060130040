import React, { useEffect, useState } from 'react';
import ImageCache from '../utils/image-cache';

const WallAsset = ({ url, width, height }) => {
  const [base64Img, setBase64Img] = useState(null);
  useEffect(() => {
    if (url) {
      (async () => {
        const data = await ImageCache.get(url);
        setBase64Img(data);
      })();
    }
  }, [url]);
  return base64Img && <img style={{ width, height }} src={base64Img} alt='wall asset' />;
};
export default WallAsset;
