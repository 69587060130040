import React, { PureComponent, createRef } from 'react';
import uuidV4 from 'uuid';
import RTC from '../../utils/rtc-common';
import SocketClient from '../../utils/socket-client';

export default class ProgramFeed extends PureComponent {
  constructor(props) {
    super(props);
    this._videoRef = createRef();
    this._audioRef = createRef();
    this._rtc = null;
  }

  componentDidMount() {
    this._getProgramFeedStream();
    window.onbeforeunload = this.componentWillUnmount.bind(this);
  }

  componentWillUnmount() {
    this._closeProgramFeedStream();
    window.onbeforeunload = () => {};
  }

  _getProgramFeedStream = async () => {
    const uuid = uuidV4();
    const roomName = `${uuid}-admin-recv`;

    const rtc = new RTC(roomName);
    const pc = await rtc.setup();

    SocketClient.emit('get-program-feed', { uuid });

    pc.ontrack = ({ track }) => {
      console.debug(`Received ${track.kind} MediaStreamTrack with ID ${track.id}`);
      if (track.kind === 'video') {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(track);
        if (this._videoRef.current) {
          this._videoRef.current.muted = true;
          this._videoRef.current.srcObject = mediaStream;
        }
      } else if (track.kind === 'audio') {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(track);
        if (this._audioRef.current) {
          this._audioRef.current.autoPlay = true;
          this._audioRef.current.srcObject = mediaStream;
        }
      }
    };

    await new Promise(async (resolve) => {
      const { type, value } = await rtc.getMessage('state');
      if (type === 'state' && value === 'CONFIG_COMPLETED') {
        resolve();
      }
    });

    console.debug('Sending READY_FOR_OFFER');
    rtc.sendMessage({
      type: 'state',
      value: 'READY_FOR_OFFER',
    });

    await rtc.startServer();
    this._rtc = rtc;
  };

  _closeProgramFeedStream = () => {
    if (this._rtc) {
      this._rtc.pc.getRemoteStreams().forEach((stream) => {
        stream.getTracks().forEach((track) => track.stop());
      });
      this._rtc.stop();
    }
  };

  render() {
    return (
      <div className='program-feed'>
        <video ref={this._videoRef} autoPlay muted />
        <audio ref={this._audioRef} autoPlay volume={1} />
      </div>
    );
  }
}
