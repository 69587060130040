import { all, takeLatest, call, put } from 'redux-saga/effects';

import { get, post, del, put as update } from '../services/api';
import {
  actions,
  getApiKeysFailure,
  getApiKeysSuccess,
  createApiKeyFailure,
  createApiKeySuccess,
  updateApiKeyFailure,
  updateApiKeySuccess,
  deleteApiKeyFailure,
  deleteApiKeySuccess
} from '../redux/apiKeyRedux';

export function* getApiKeys() {
  try {
    const response = yield call(get, '/apiKey');
    if (response.status === 200) {
      yield put(getApiKeysSuccess(response.data));
    } else {
      yield put(getApiKeysFailure({ status: response.status, message: response.data }));
    }
  } catch (error) {
    yield put(getApiKeysFailure(error));
  }
}

export function* createApiKey(action) {
  try {
    const { data } = action.payload;
    const response = yield call(post, '/apiKey', data);
    if (response.status === 200) {
      yield put(createApiKeySuccess(response.data));
    } else {
      yield put(createApiKeyFailure({ status: response.status, message: response.data }));
    }
  } catch (error) {
    yield put(createApiKeyFailure(error));
  }
}

export function* updateApiKey(action) {
  try {
    const { id, data } = action.payload;
    const response = yield call(update, `/apiKey/${id}`, data);
    if (response.status === 200) {
      yield put(updateApiKeySuccess(response.data));
    } else {
      yield put(updateApiKeyFailure({ status: response.status, message: response.data }));
    }
  } catch (error) {
    yield put(updateApiKeyFailure(error));
  }
}

export function* deleteApiKey(action) {
  try {
    const { id } = action.payload;
    const response = yield call(del, `/apiKey/${id}`);
    if (response.status === 200) {
      yield put(deleteApiKeySuccess(response.data));
    } else {
      yield put(deleteApiKeyFailure({ status: response.status, message: response.data }));
    }
  } catch (error) {
    yield put(deleteApiKeyFailure(error));
  }
}

export default function*() {
  yield all([
    takeLatest([actions.GET_API_KEYS_REQUEST], getApiKeys),
    takeLatest([actions.CREATE_API_KEY_REQUEST], createApiKey),
    takeLatest([actions.UPDATE_API_KEY_REQUEST], updateApiKey),
    takeLatest([actions.DELETE_API_KEY_REQUEST], deleteApiKey)
  ]);
}
