import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import EventParticipant from './EventParticipant';
import ParticipantsListHeader from './ParticipantsListHeader';
import SearchBox from '../../common/SearchBox';
import ArrayUtil from '../../utils/array-util';

export default function OffWallList({ items, onSelect, selectedParticipant, selectedParticipants, onContextMenuAction, appMode }) {
  const [hasMore, setHasMore] = useState(true);
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [search, setSearch] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const [sort, setSort] = useState({ sortBy: 'name', sortDirection: 'asc' });

  useEffect(() => {
    _loadMore(0);
  }, []);

  useEffect(() => {
    setPageNumber(0);
    setHasMore(true);
    _loadMore(0);
  }, [search]);

  useEffect(() => {
    _loadMore(pageNumber);
  }, [items, pageNumber, sort]);

  function _loadMore(pageNumber) {
    let _paginatedItems;
    if (search) {
      _paginatedItems = items.filter((item) => {
        const { firstName, lastName } = item.participant;
        const name = `${firstName} ${lastName || '\u00a0'}`.trim().toLowerCase();
        return name.includes(search.toLowerCase());
      });
    } else {
      _paginatedItems = [...items];
    }

    _paginatedItems = ArrayUtil.sort(_paginatedItems, sort.sortBy, sort.sortDirection);

    const prevLength = _paginatedItems.length;
    _paginatedItems = _paginatedItems.slice(0, (pageNumber + 1) * 50);

    if (_paginatedItems.length === prevLength) {
      setHasMore(false);
    }
    setPaginatedItems(_paginatedItems);
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', margin: '6px 8px 7px', alignItems: 'center' }}>
        <SearchBox
          value={search}
          onChange={({ target }) => setSearch(target.value)}
          onClear={() => setSearch('')}
          showIcon={true}
          containerStyle={{ margin: '0 15px 0 0' }}
          inputStyle={{ marginLeft: 0 }}
        />
      </div>
      <ParticipantsListHeader onChangeSort={(sort) => setSort(sort)} hideDuration={true} />
      <div className='custom-scrollbar' style={{ overflowY: 'scroll', flex: 1, color: '#fff' }}>
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          loadMore={() => setPageNumber((oldPageNumber) => oldPageNumber + 1)}
          hasMore={hasMore}
          useWindow={false}
        >
          {paginatedItems.map((i, index) => {
            return (
              <EventParticipant
                key={index}
                item={i}
                selected={selectedParticipant && selectedParticipant._id === i._id}
                selectedParticipants={selectedParticipants}
                onSelect={onSelect}
                onContextMenuAction={onContextMenuAction}
                includeOffWall={true}
              />
            );
          })}
        </InfiniteScroll>
      </div>
    </>
  );
}
