import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

import Main from './Main';
import Login from './Login';
import PollResults from './routes/pollResults';
import Leaderboard from './routes/pollResults/Leaderboard';
import Reports from './routes/reports';
import QRCode from './routes/events/QRCode';

import './css/App.css';

function App({ auth }) {
  if (auth.checking === true) return null;

  return (
    <Router>
      <Switch>
        <Route path='/login' component={Login} />
        <Route path='/reports' component={Reports} />
        <PrivateRoute path='/qrCode' component={QRCode} auth={auth} />
        <PrivateRoute path='/pollResults' component={PollResults} auth={auth} />
        <PrivateRoute path='/leaderboard' component={Leaderboard} auth={auth} />
        <PrivateRoute path='/app' component={Main} auth={auth} />
        <Redirect path='/' to='/app' />
      </Switch>
    </Router>
  );
}

const mapStateToProps = ({ auth }) => ({ auth });

export default connect(mapStateToProps, null)(App);
