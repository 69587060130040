import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
  getInstancesRequest,
  setLiveInstanceData as setLiveInstanceDataAction,
  checkInstancesStatus as checkInstancesStatusAction,
} from '../../redux/goLiveRedux';
import studioGatewayIcon from '../../assets/icons/studio_gateway.svg';
import audioIcon from '../../assets/icons/gateway_icons_audio.svg';
import ndiIcon from '../../assets/icons/gateway_icons_NDI.svg';
import wallIcon from '../../assets/icons/gateway_icons_wall.svg';
import SocketClient from '../../utils/socket-client';
import StringUtil from '../../utils/string-utils';

import Process from './Process';
import Widget from './Widget';
import { enableGoLiveData } from './GoLiveContent';

class Gateway extends PureComponent {
  state = {
    studioGateway: null,
    audio: null,
    ndi: null,
    wall: null,
    lastResourceStatsTimestamp: Date.now(),
    directConnectAvailable: true,
  };

  componentDidMount() {
    const { activeStudio, getInstances, setLiveInstanceData, checkInstancesStatus } = this.props;

    this._liveDataTimerId = enableGoLiveData(activeStudio, getInstances, setLiveInstanceData, checkInstancesStatus);
    SocketClient.joinRoom('gateway-resource-stats');
    SocketClient.on('gateway-resource-stats', (stats) => {
      const { gpuStats, osStats, processStats, directConnectAvailable, serverTimestamp } = stats;
      const { audio, ndi, wall } = processStats;
      this.setState({
        studioGateway: { ...osStats, ...gpuStats },
        audio,
        ndi,
        wall,
        lastResourceStatsTimestamp: Date.now(),
        directConnectAvailable,
        serverTimestamp,
      });
    });
  }

  componentWillUnmount() {
    SocketClient.leaveRoom('gateway-resource-stats');
    SocketClient.leaveRoom('server-resource-stats');
    SocketClient.removeAllListeners();
    clearInterval(this._liveDataTimerId);
  }

  render() {
    const { goLiveEnabled, compositorServersReady, socketServersReady, decoderServerReady } = this.props;
    if (!goLiveEnabled) return <Redirect to='/app/studios' />;

    const { studioGateway, audio, ndi, wall, lastResourceStatsTimestamp, directConnectAvailable, serverTimestamp } = this.state;
    let diff = Date.now() - lastResourceStatsTimestamp;
    diff /= 1000;
    const disableButtons = diff > 2 || !socketServersReady || !compositorServersReady || !decoderServerReady;

    return (
      <div className='gateway'>
        <div className='content'>
          <div className='instances'>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div className='studio-gateway'>
                <div style={{ color: '#fff', width: 160, marginRight: 15 }}>
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      marginBottom: 20,
                    }}
                  >
                    Studio Gateway
                  </div>
                  <img src={studioGatewayIcon} alt='' style={{ width: 100 }} />
                  <div style={{ padding: 5 }}>
                    <div className='control'>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', fontSize: 14, color: '#f3f3f3' }}>
                        {directConnectAvailable === true ? 'Primary Network' : 'Backup Network'}
                      </div>
                    </div>
                  </div>
                  <div style={{ fontSize: 14, color: '#f3f3f3' }}>{studioGateway ? StringUtil.formatSeconds(studioGateway.osUptime) : '00:00:00:00'}</div>
                </div>
                <div style={{ width: 300 }}>
                  <Widget
                    title='CPU'
                    type='progress'
                    percentage={studioGateway ? Math.round(studioGateway.cpuUsage) : 0}
                    total={studioGateway ? `${studioGateway.cpuCount} CPU${studioGateway.cpuCount > 1 ? 's' : ''}`.trim() : ''}
                    style={{ border: 'none' }}
                  />
                  <Widget
                    title='Memory'
                    type='progress'
                    percentage={studioGateway ? Math.round((studioGateway.usedMemMb * 100) / studioGateway.totalMemMb) : 0}
                    total={studioGateway ? `${Math.round(studioGateway.totalMemMb / 1024)} GB` : ''}
                    style={{ border: 'none' }}
                  />
                  <Widget
                    title='GPU'
                    type='progress'
                    percentage={studioGateway && studioGateway.gpuData ? Math.round(studioGateway.gpuData.util.replace('%', '')) : 0}
                    total={studioGateway ? `${studioGateway.numGpus} GPU${studioGateway.numGpus > 1 ? 's' : ''}`.trim() : ''}
                    style={{ border: 'none' }}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Process
                  name='Wall'
                  image={wallIcon}
                  data={wall}
                  onStart={() => SocketClient.emit('gateway-command', { action: 'start-process', processName: 'wall' })}
                  onStop={() => SocketClient.emit('gateway-command', { action: 'stop-process', processName: 'wall' })}
                  disableButtons={disableButtons}
                  serverTimestamp={serverTimestamp}
                />
                <Process
                  name='NDI'
                  image={ndiIcon}
                  data={ndi}
                  onStart={() => SocketClient.emit('gateway-command', { action: 'start-process', processName: 'ndi' })}
                  onStop={() => SocketClient.emit('gateway-command', { action: 'stop-process', processName: 'ndi' })}
                  disableButtons={disableButtons}
                  serverTimestamp={serverTimestamp}
                />
                <Process
                  name='Audio'
                  image={audioIcon}
                  data={audio}
                  onStart={() => SocketClient.emit('gateway-command', { action: 'start-process', processName: 'audio' })}
                  onStop={() => SocketClient.emit('gateway-command', { action: 'stop-process', processName: 'audio' })}
                  disableButtons={disableButtons}
                  serverTimestamp={serverTimestamp}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeStudio: state.studio.active,
  goLiveEnabled: state.ui.goLiveEnabled,
  compositorServersReady: state.goLive.compositorServersReady,
  socketServersReady: state.goLive.socketServersReady,
  decoderServerReady: state.goLive.decoderServerReady,
});

const mapDispatchToProps = (dispatch) => ({
  getInstances: (studio, monitoring) => dispatch(getInstancesRequest(studio, monitoring)),
  setLiveInstanceData: (data) => dispatch(setLiveInstanceDataAction(data)),
  checkInstancesStatus: (data) => dispatch(checkInstancesStatusAction(data)),
});

Gateway.propTypes = {
  activeStudio: PropTypes.shape({}).isRequired,
  goLiveEnabled: PropTypes.bool.isRequired,
  compositorServersReady: PropTypes.bool.isRequired,
  socketServersReady: PropTypes.bool.isRequired,
  decoderServerReady: PropTypes.bool.isRequired,
  getInstances: PropTypes.func.isRequired,
  setLiveInstanceData: PropTypes.func.isRequired,
  checkInstancesStatus: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Gateway);
