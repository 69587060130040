import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

import { removeParticipantRequest, addParticipantRequest, moveParticipantRequest } from '../../redux/breakoutRoomsRedux';
import List from '../../common/List';

import ParticipantListItem from './ParticipantListItem';
import SocketClient from '../../utils/socket-client';

const ParticipantList = (props) => {
  const { data } = props;

  const [list, setList] = useState([]);
  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    const sortedList = _getSortedList(data);
    setList([...sortedList]);
  }, [data]);

  useEffect(() => {
    const sortedList = _getSortedList(list);
    setList([...sortedList]);
  }, [sortBy, sortDirection]);

  const _onDropHandler = (event) => {
    const { removeParticipant, config } = props;
    const { dataTransfer } = event;
    const dt = JSON.parse(dataTransfer.getData('text'));
    const { participant, roomId } = dt;
    if (roomId) {
      removeParticipant(roomId, participant._id, config._id);
      SocketClient.emitAdminAppSync({
        type: 'removeParticipant',
      });
    }
  };

  const _onDragOverHandler = (event) => event.preventDefault();

  const _getSortedList = (_list) => {
    let sorted;
    if (sortBy === 'name') {
      sorted = _list.sort((a, b) => {
        const aName = `${a.participant.firstName} ${a.participant.lastName || ''}`.trim().toLowerCase();
        const bName = `${b.participant.firstName} ${b.participant.lastName || ''}`.trim().toLowerCase();
        if (aName < bName) {
          return -1;
        }
        if (aName > bName) {
          return 1;
        }
        return 0;
      });
    } else {
      const mappedlist = _list.map((l) => {
        const room = props.participants[l.participant._id] ? props.participants[l.participant._id].room : -1;
        return { ...l, room };
      });
      sorted = mappedlist.sort((a, b) => {
        if (a.room < b.room) {
          return -1;
        }
        if (a.room > b.room) {
          return 1;
        }
        return 0;
      });
    }
    return sortDirection === 'asc' ? sorted : sorted.reverse();
  };

  const sortList = (column) => {
    setSortBy(column);
    if (sortBy === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortDirection('asc');
    }
  };

  const _getContextMenuItemSelectionHandler = (item) => async (option) => {
    const { activeEvent } = props;
    const ts1Index = props.roomList[props.roomList.length - 2];
    const ts2Index = props.roomList[props.roomList.length - 1];

    const ts1 = props.rooms[ts1Index];
    const ts2 = props.rooms[ts2Index];

    let oldRoomId;
    if (props.participants[item.participant._id]) {
      oldRoomId = props.participants[item.participant._id].roomId;
    }

    switch (option.value) {
      case 1:
        if (oldRoomId) {
          props.moveParticipant(oldRoomId, ts1._id, item.participant._id, props.config._id, false, activeEvent._id);
          SocketClient.emitAdminAppSync({
            type: 'moveParticipant',
          });
        } else {
          props.addParticipant(ts1._id, item.participant._id, props.config._id, undefined, false);
          SocketClient.emitAdminAppSync({
            type: 'addParticipant',
          });
        }
        break;
      case 2:
        if (oldRoomId) {
          props.moveParticipant(oldRoomId, ts2._id, item.participant._id, props.config._id, false, activeEvent._id);
          SocketClient.emitAdminAppSync({
            type: 'moveParticipant',
          });
        } else {
          props.addParticipant(ts2._id, item.participant._id, props.config._id, undefined, false);
          SocketClient.emitAdminAppSync({
            type: 'addParticipant',
          });
        }
        break;
      default:
        break;
    }
  };

  const _getContextMenuItems = (item) => {
    if (Object.keys(props.rooms).length === 0) return [];

    if (props.participants[item.participant._id] && props.participants[item.participant._id].room > 10) {
      return [];
    } else {
      return [
        {
          value: 1,
          label: 'Send to TS 1',
        },
        {
          value: 2,
          label: 'Send to TS 2',
        },
      ];
    }
  };

  return (
    <div className='participant-list'>
      <div className='list-header'>
        <div className='column' onClick={() => sortList('name')}>
          <span>Name</span>
          {sortBy === 'name' ? <FontAwesomeIcon icon={sortDirection === 'desc' ? faSortDown : faSortUp} color='#d9e8ff' size='sm' /> : null}
        </div>
        <div className='column' onClick={() => sortList('room')}>
          <span>Room</span>
          {sortBy === 'room' ? <FontAwesomeIcon icon={sortDirection === 'desc' ? faSortDown : faSortUp} color='#d9e8ff' size='sm' /> : null}
        </div>
      </div>
      <div className='participants' onDrop={_onDropHandler} onDragOver={_onDragOverHandler}>
        <List
          data={list}
          renderItem={(item) => (
            <ParticipantListItem
              item={item}
              contextMenuItems={_getContextMenuItems(item)}
              enableContextMenu={true}
              contextMenuItemSelectionHandler={_getContextMenuItemSelectionHandler(item)}
              maxRooms={props.maxRooms}
            />
          )}
          keyExtractor={(item) => `${item._id}`}
          containerStyle={{ flex: 1, position: 'relative' }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeEvent: state.events.active,
  config: state.breakoutRooms.config,
  participants: state.breakoutRooms.participants,
  rooms: state.breakoutRooms.rooms,
  roomList: state.breakoutRooms.roomList,
});

const mapDispatchToProps = (dispatch) => ({
  removeParticipant: (roomId, participantId, configId) => dispatch(removeParticipantRequest(roomId, participantId, configId)),
  addParticipant: (roomId, participantId, configId, old, removeSubStatus) =>
    dispatch(addParticipantRequest(roomId, participantId, configId, old, removeSubStatus)),
  moveParticipant: (from, to, participantId, configId, removeSubStatus, eventId) =>
    dispatch(moveParticipantRequest(from, to, participantId, configId, removeSubStatus, eventId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantList);
