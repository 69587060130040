import { createActions, handleActions } from 'redux-actions';

const initialState = {
  list: [],
  successful: false,
  fetching: false,
  error: null
};

export const actions = {
  GET_API_KEYS_REQUEST: 'GET_API_KEYS_REQUEST',
  GET_API_KEYS_SUCCESS: 'GET_API_KEYS_SUCCESS',
  GET_API_KEYS_FAILURE: 'GET_API_KEYS_FAILURE',
  CREATE_API_KEY_REQUEST: 'CREATE_API_KEY_REQUEST',
  CREATE_API_KEY_SUCCESS: 'CREATE_API_KEY_SUCCESS',
  CREATE_API_KEY_FAILURE: 'CREATE_API_KEY_FAILURE',
  UPDATE_API_KEY_REQUEST: 'UPDATE_API_KEY_REQUEST',
  UPDATE_API_KEY_SUCCESS: 'UPDATE_API_KEY_SUCCESS',
  UPDATE_API_KEY_FAILURE: 'UPDATE_API_KEY_FAILURE',
  DELETE_API_KEY_REQUEST: 'DELETE_API_KEY_REQUEST',
  DELETE_API_KEY_SUCCESS: 'DELETE_API_KEY_SUCCESS',
  DELETE_API_KEY_FAILURE: 'DELETE_API_KEY_FAILURE'
};

export const {
  getApiKeysRequest,
  getApiKeysSuccess,
  getApiKeysFailure,
  createApiKeyRequest,
  createApiKeySuccess,
  createApiKeyFailure,
  updateApiKeyRequest,
  updateApiKeySuccess,
  updateApiKeyFailure,
  deleteApiKeyRequest,
  deleteApiKeySuccess,
  deleteApiKeyFailure
} = createActions({
  [actions.GET_API_KEYS_REQUEST]: () => ({}),
  [actions.GET_API_KEYS_SUCCESS]: data => ({ data }),
  [actions.GET_API_KEYS_FAILURE]: error => ({ error }),
  [actions.CREATE_API_KEY_REQUEST]: data => ({ data }),
  [actions.CREATE_API_KEY_SUCCESS]: data => ({ data }),
  [actions.CREATE_API_KEY_FAILURE]: error => ({ error }),
  [actions.UPDATE_API_KEY_REQUEST]: (id, data) => ({ id, data }),
  [actions.UPDATE_API_KEY_SUCCESS]: data => ({ data }),
  [actions.UPDATE_API_KEY_FAILURE]: error => ({ error }),
  [actions.DELETE_API_KEY_REQUEST]: id => ({ id }),
  [actions.DELETE_API_KEY_SUCCESS]: data => ({ data }),
  [actions.DELETE_API_KEY_FAILURE]: error => ({ error })
});

const reducer = handleActions(
  {
    [actions.GET_API_KEYS_REQUEST]: state => ({
      ...state,
      list: [],
      fetching: true,
      error: null
    }),
    [actions.GET_API_KEYS_SUCCESS]: (state, action) => ({
      ...state,
      list: action.payload.data,
      fetching: false,
      error: null
    }),
    [actions.GET_API_KEYS_FAILURE]: (state, action) => ({
      ...state,
      list: [],
      fetching: false,
      error: action.payload.error
    }),
    [actions.CREATE_API_KEY_REQUEST]: state => ({
      ...state,
      successful: false,
      fetching: true,
      error: null
    }),
    [actions.CREATE_API_KEY_SUCCESS]: (state, action) => ({
      ...state,
      successful: true,
      fetching: false,
      error: null
    }),
    [actions.CREATE_API_KEY_FAILURE]: (state, action) => ({
      ...state,
      successful: false,
      fetching: false,
      error: action.payload.error
    }),
    [actions.UPDATE_API_KEY_REQUEST]: state => ({
      ...state,
      successful: false,
      fetching: true,
      error: null
    }),
    [actions.UPDATE_API_KEY_SUCCESS]: (state, action) => ({
      ...state,
      successful: true,
      fetching: false,
      error: null
    }),
    [actions.UPDATE_API_KEY_FAILURE]: (state, action) => ({
      ...state,
      successful: false,
      fetching: false,
      error: action.payload.error
    }),
    [actions.DELETE_API_KEY_REQUEST]: state => ({
      ...state,
      successful: false,
      fetching: true,
      error: null
    }),
    [actions.DELETE_API_KEY_SUCCESS]: (state, action) => ({
      ...state,
      successful: true,
      fetching: false,
      error: null
    }),
    [actions.DELETE_API_KEY_FAILURE]: (state, action) => ({
      ...state,
      successful: false,
      fetching: false,
      error: action.payload.error
    })
  },
  initialState
);

export default reducer;
