import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../../common/Button';
import stopIcon from '../../assets/icons/stop.svg';
import startIcon from '../../assets/icons/start.svg';

import Widget from './Widget';

function Process({ name, image, data, onStart, onStop, disableButtons, serverTimestamp }) {
  const [disableStartButton, setDisableStartButton] = useState(false);
  const [disableStopButton, setDisableStopButton] = useState(false);

  const _formatMilliseconds = (ms) => {
    const s = ms / 1000;
    const days = Math.floor(s / 60 / 60 / 24);
    const hrs = Math.floor(s / 3600) % 24;
    const min = Math.floor(s / 60) % 60;
    const sec = Math.floor(s % 60);
    return `${days < 10 ? `0${days}` : days}:${hrs < 10 ? `0${hrs}` : hrs}:${min < 10 ? `0${min}` : min}:${sec < 10 ? `0${sec}` : sec}`;
  };

  const _onStartHandler = () => {
    setDisableStartButton(true);
    setTimeout(() => {
      setDisableStartButton(false);
    }, 7000);
    onStart();
  };

  const _onStopHandler = () => {
    setDisableStopButton(true);
    setTimeout(() => {
      setDisableStopButton(false);
    }, 7000);
    onStop();
  };

  let statusColor;
  let statusIndicatorColor;
  let processCpuUsage = 0;
  let usedMemPct = 0;
  let osUptime = 0;
  let isRunning = false;

  if (data) {
    let diff = serverTimestamp - data.timestamp;
    diff /= 1000;
    isRunning = diff < 5;

    processCpuUsage = data.cpuUsage;
    usedMemPct = data.memoryUsage;
    osUptime = data.uptime;
  }

  if (isRunning) {
    statusColor = '#244B42';
    statusIndicatorColor = '#36AF6D';
  } else {
    statusColor = '#461C2B';
    statusIndicatorColor = '#FF062B';
  }

  return (
    <div className='instance-container' style={{ minHeight: 0 }}>
      <div className='header'>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginBottom: 10 }}>
          <div className='name'>{name}</div>
          <div className='status' style={{ backgroundColor: statusColor }}>
            <span className='indicator' style={{ backgroundColor: statusIndicatorColor }} />
            <span>{isRunning ? 'running' : 'stopped'}</span>
          </div>
        </div>
        <div style={{ color: '#43586f', fontFamily: 'Open Sans', fontSize: 14, marginRight: 14 }}>{osUptime ? _formatMilliseconds(osUptime) : '\u00A0'}</div>
      </div>

      <div className='server-type'>
        <img src={image} alt='' style={{ width: 100 }} />
      </div>

      <div style={{ padding: 5, marginTop: -22, marginBottom: 22 }}>
        <div className='control'>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', fontSize: 14, color: '#f3f3f3' }}>
            {(data && data.connectionType) || ''}
          </div>
        </div>
      </div>

      <Widget title='CPU' type='progress' percentage={processCpuUsage} total='' />
      <Widget title='Memory' type='progress' percentage={usedMemPct} total='' />

      <div className='actions'>
        <Button type='primary' icon={startIcon} text='Start' onClick={_onStartHandler} disabled={isRunning || disableStartButton || disableButtons} />
        <Button type='danger' icon={stopIcon} text='Stop' onClick={_onStopHandler} disabled={!isRunning || disableStopButton || disableButtons} />
      </div>
    </div>
  );
}

Process.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  data: PropTypes.shape({}).isRequired,
  onStart: PropTypes.func.isRequired,
  onStop: PropTypes.func.isRequired,
  disableButtons: PropTypes.bool.isRequired,
  serverTimestamp: PropTypes.number.isRequired,
};

export default Process;
