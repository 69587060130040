import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ProgressBar from './ProgressBar';

class FileProgress extends PureComponent {
  state = {
    collapsed: true,
  }

  _toggleDetail = () => {
    const { collapsed } = this.state;
    this.setState({ collapsed: !collapsed });
  }

  _renderTotalProgress = (uploadMessage, percentage) => {
    const { status } = this.props;
    return (
      <>
        <div className='upload-info'>
          {status === 'uploading' ? <div className='upload-percentage'>{`${percentage || 0}%`}</div> : null}
          <div className='upload-message'>{uploadMessage}</div>          
          {status === 'uploading' ? <div className='upload-details' onClick={this._toggleDetail}>+</div> : null}
        </div>
        <ProgressBar percentage={percentage} />
      </>
    );
  }

  _renderDetail = (uploadMessage, filesPercentage) => {
    const { files, status } = this.props;
    const bars = [];
    for (let i in files) {
      bars.push(
        <div key={`progress-${i}`} >
          <div className='file-info'>
            <div>{files[i].name}</div>
            <div className='upload-percentage'>{`${filesPercentage[i] || 0}%`}</div>
          </div>
          <ProgressBar percentage={filesPercentage[i] || 0} />
        </div>
      )
    }

    let detail = (
      <div className='progress-detail'>
        <div className='upload-info'>
          <div className='upload-message'>{uploadMessage}</div>
          {status === 'uploading' ? <div className='upload-details' onClick={this._toggleDetail}>-</div> : null}
        </div>
        {status === 'uploading' ? bars : null}
      </div>
    );
    
    return detail;
  }

  render() {
    const { files, status, progress, totalSize, totalFiles } = this.props;
    let uploadMessage = '';
    let percentage = 0;
    let filesPercentage = {};

    if (status === 'uploading') {
      uploadMessage = `Uploading ${totalFiles} ${totalFiles > 1 ? 'files' : 'file'}`;      
      let loaded = 0;
      for (let i in progress) {
        filesPercentage[i] = Math.round((progress[i] * 100) / files[i].size);
        loaded += progress[i];
      }
      percentage = Math.round((loaded * 100) / totalSize);
    } else if (status === 'completed') {
      uploadMessage = `Uploaded ${totalFiles} ${totalFiles > 1 ? 'files' : 'file'}`;
    }

    let p = null;

    if (this.state.collapsed === true) {
      p = this._renderTotalProgress(uploadMessage, percentage);
    } else {
      p = this._renderDetail(uploadMessage, filesPercentage);
    }

    const content = (
      <div className='file-progress'>
        {p}
      </div>
    );

    return status === 'waiting' ? null : content;
  }
}

const mapStateToProps = state => ({
  status: state.uploader.status,
  progress: state.uploader.progress,
  files: state.uploader.files,
  responses:  state.uploader.responses,
  totalSize: state.uploader.totalSize,
  totalFiles: state.uploader.totalFiles,
});

export default connect(
  mapStateToProps,
  null
)(FileProgress);