import React from 'react';

const ProgressBar = ({ percentage }) => {
  return (
    <div className='progress-bar'>
      {percentage === 0 ? null : <div className='progress' style={{ width: `${percentage}%` }} />}
    </div>
  );
}

export default ProgressBar;
