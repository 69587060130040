import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  getLayoutById,
  selectParticipant as selectParticipantAction,
  updateEventParticipantRequest,
  removeMetadata,
  setDraggingParticipant as setDraggingParticipantAction,
} from '../../redux/wallRedux';
import { updateParticipantStatusRequest } from '../../redux/eventsRedux';
import ParticipantList from '../../common/ParticipantList/ParticipantList';
import SocketClient from '../../utils/socket-client';
import { getParticipantExtraData } from '../../utils/participant-util';
import { findEventParticipantByDisplayNum } from '../../utils/find-event-participant';

import OnAirSlotAllocator from './OnAirSlotAllocator';
import VideoStream from './VideoStream';
import Tools from './Tools';

class AttendeeListContainer extends PureComponent {
  constructor(props) {
    super(props);
    this._rtc = null;
    this.state = {
      hideEventSupportUsers: false,
    };
  }

  _onUseVideoImageToggle = async (eventParticipant) => {
    const { updateEventParticipant, selectParticipant } = this.props;
    const useImage = !eventParticipant.useImage;
    selectParticipant({ ...eventParticipant, useImage });
    updateEventParticipant(eventParticipant._id, { useImage });

    SocketClient.emit('admin-action', {
      uuid: eventParticipant.participant._id,
      action: useImage ? 'use-image' : 'use-video',
      photoUrl: eventParticipant.participant.photoUrl,
    });
  };

  _onSendToHelpDesk = () => {
    const { selectedParticipant, updateParticipantStatus, selectParticipant } = this.props;
    if (selectedParticipant) {
      updateParticipantStatus(selectedParticipant._id, { status: 'IN_SCREENING_QUEUE' });
      selectParticipant(null);
    }
  };

  _setClient = async (data) => {
    try {
      const { selectParticipant } = this.props;
      const extraData = await getParticipantExtraData(data._id);
      selectParticipant({ ...extraData });
    } catch (error) {
      console.error(error);
    }
  };

  _onDragStart = (data) => {
    const { setDraggingParticipant } = this.props;
    setDraggingParticipant(data);
  };

  _onDragEnd = () => {
    const { setDraggingParticipant } = this.props;
    setDraggingParticipant(null);
  };

  _onToggleEventSupportUsers = () => {
    const { hideEventSupportUsers } = this.state;
    this.setState({ hideEventSupportUsers: !hideEventSupportUsers });
  };

  render() {
    const { hideEventSupportUsers } = this.state;
    const { selectedParticipant, layout, onAirParticipants, showPreview, wallSupportStaffUuid, selectedLayout } = this.props;
    let assignedParticipantsNums = [];
    if (selectedLayout && selectedLayout.areas) {
      assignedParticipantsNums = selectedLayout.areas
        .filter((area) => {
          const participant = area.data && area.data.wallDisplayNum ? findEventParticipantByDisplayNum(area.data.wallDisplayNum, ['ON_WALL', 'ON_AIR']) : null;
          return area.sourceType === 'Participant' && participant;
        })
        .map((area) => area.data.wallDisplayNum);
    }
    return (
      <div style={{ width: '100%', display: showPreview ? 'none' : 'inherit' }}>
        <ParticipantList
          allowedStatus={['IN_STUDIO_QUEUE', 'ON_WALL', 'ON_AIR']}
          selectedParticipant={selectedParticipant}
          onItemSelect={this._setClient}
          onDragStart={this._onDragStart}
          onDragEnd={this._onDragEnd}
          hideEventSupportUsers={hideEventSupportUsers}
          assignedParticipants={assignedParticipantsNums}
          showClearResponseOption
        />
        <div style={{ flex: 1, borderLeft: '2px solid #1e272f' }}>
          <OnAirSlotAllocator layout={layout} onAirParticipants={onAirParticipants} />
          {selectedParticipant && (
            <VideoStream
              key={selectedParticipant.participant._id}
              client={selectedParticipant}
              onUseVideoImageToggle={this._onUseVideoImageToggle}
              onSendToHelpDesk={this._onSendToHelpDesk}
              wallSupportStaffUuid={wallSupportStaffUuid}
              onAirParticipants={onAirParticipants}
            />
          )}
        </div>
        <Tools onToggleEventSupportUsers={this._onToggleEventSupportUsers} hideEventSupportUsersValue={hideEventSupportUsers} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedLayout: getLayoutById(state, state.wall.selectedLayout),
  editedArea: state.wall.editedArea,
  selectedParticipant: state.wall.selectedParticipant,
  activeEvent: state.events.active,
  // we need this to force re-render when the participants are updated
  participants: state.events.participants,
});

const mapDispatchToProps = (dispatch) => ({
  updateParticipantStatus: (id, data) => dispatch(updateParticipantStatusRequest(id, data)),
  selectParticipant: (participant) => dispatch(selectParticipantAction(participant)),
  updateEventParticipant: (eventParticipantId, data) => dispatch(updateEventParticipantRequest(eventParticipantId, data)),
  removeMetadata: (layout, area) => dispatch(removeMetadata(layout, area)),
  setDraggingParticipant: (data) => dispatch(setDraggingParticipantAction(data)),
});

AttendeeListContainer.propTypes = {
  selectedParticipant: PropTypes.shape({}),
  updateParticipantStatus: PropTypes.func.isRequired,
  selectParticipant: PropTypes.func.isRequired,
  layout: PropTypes.string,
  onAirParticipants: PropTypes.shape({}),
  showPreview: PropTypes.bool,
  wallSupportStaffUuid: PropTypes.string,
  selectedLayout: PropTypes.shape({}),
  setDraggingParticipant: PropTypes.func.isRequired,
  updateEventParticipant: PropTypes.func.isRequired,
};

AttendeeListContainer.defaultProps = {
  selectedParticipant: null,
  layout: null,
  onAirParticipants: null,
  showPreview: false,
  wallSupportStaffUuid: null,
  selectedLayout: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(AttendeeListContainer);
