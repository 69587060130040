import { all, takeLatest, call, put } from 'redux-saga/effects';

import { get, del, put as update, post, setStudioIdHeader } from '../services/api';
import {
  actions,
  getAwsInstancesSuccess,
  getAwsInstancesFailure,
  getInstancesFailure,
  getInstancesSuccess,
  createInstanceFailure,
  createInstanceSuccess,
  updateInstanceFailure,
  updateInstanceSuccess,
  deleteInstanceFailure,
  deleteInstanceSuccess,
  startInstanceFailure,
  startInstanceSuccess,
  stopInstanceFailure,
  stopInstanceSuccess,
  restartInstanceFailure,
  restartInstanceSuccess,
  getAwsRegionsFailure,
  getAwsRegionsSuccess,
  goLiveFailure,
  goLiveSuccess,
  endLiveFailure,
  endLiveSuccess,
  checkInstancesStatus,
  getAwsImagesSuccess,
  getAwsImagesFailure,
  launchFromImageSuccess,
  launchFromImageFailure,
  getAwsAvailabilityZonesFailure,
  getAwsAvailabilityZonesSuccess,
  clearLiveInstanceData,
} from '../redux/goLiveRedux';
import { selectLayout } from '../redux/wallRedux';

function* getAwsRegions() {
  try {
    const response = yield call(get, '/aws/regions');
    if (response.status === 200) {
      yield put(getAwsRegionsSuccess(response.data));
    } else {
      yield put(getAwsRegionsFailure({ status: response.status, message: response.data }));
    }
  } catch (error) {
    yield put(getAwsRegionsFailure(error));
  }
}

function* getAwsAvailabilityZones(action) {
  try {
    const { region } = action.payload;
    const response = yield call(get, `/aws/availabilityZones?region=${region}`);
    if (response.status === 200) {
      yield put(getAwsAvailabilityZonesSuccess(response.data));
    } else {
      yield put(getAwsAvailabilityZonesFailure({ status: response.status, message: response.data }));
    }
  } catch (error) {
    yield put(getAwsAvailabilityZonesFailure(error));
  }
}

function* getAwsInstances(action) {
  try {
    const { regionId } = action.payload;
    const response = yield call(get, `/aws/instances?region=${regionId}`);
    if (response.status === 200) {
      yield put(getAwsInstancesSuccess(response.data));
    } else {
      yield put(getAwsInstancesFailure({ status: response.status, message: response.data }));
    }
  } catch (error) {
    yield put(getAwsInstancesFailure(error));
  }
}

function* getInstances(action) {
  try {
    const { studio, monitoring } = action.payload;
    yield call(setStudioIdHeader, studio);
    const response = yield call(get, `/instance?studio=${studio}&monitoring=${monitoring}`);
    if (response.status === 200) {
      yield put(getInstancesSuccess(response.data));
      yield put(checkInstancesStatus());
      for (const instance of response.data) {
        if (instance.status === 'stopped' || instance.status === 'pending') {
          yield put(clearLiveInstanceData(instance.instanceId));
        }
      }
    } else {
      yield put(getInstancesFailure({ status: response.status, message: response.data }));
    }
  } catch (error) {
    yield put(getInstancesFailure(error));
  }
}

function* createInstance(action) {
  try {
    const { data } = action.payload;
    const response = yield call(post, '/instance', data);
    if (response.status === 200) {
      yield put(createInstanceSuccess(response.data));
    } else {
      yield put(createInstanceFailure({ status: response.status, message: response.data }));
    }
  } catch (error) {
    yield put(createInstanceFailure(error.response));
  }
}

function* updateInstance(action) {
  try {
    const { id, data } = action.payload;
    const response = yield call(update, `/instance/${id}`, data);
    if (response.status === 200) {
      yield put(updateInstanceSuccess(response.data));
    } else {
      yield put(updateInstanceFailure({ status: response.status, message: response.data }));
    }
  } catch (error) {
    yield put(updateInstanceFailure(error.response));
  }
}

function* deleteInstance(action) {
  try {
    const { id } = action.payload;
    const response = yield call(del, `/instance/${id}`);
    if (response.status === 200) {
      yield put(deleteInstanceSuccess(id));
    } else {
      yield put(deleteInstanceFailure({ status: response.status, message: response.data }));
    }
  } catch (error) {
    yield put(deleteInstanceFailure(error));
  }
}

function* startInstance(action) {
  try {
    const { id } = action.payload;
    const response = yield call(post, `/instance/${id}/start`);
    if (response.status === 200) {
      yield put(startInstanceSuccess(response.data));
      yield put(checkInstancesStatus());
    } else {
      yield put(startInstanceFailure({ status: response.status, message: response.data }));
    }
  } catch (error) {
    yield put(startInstanceFailure(error));
  }
}

function* stopInstance(action) {
  try {
    const { id } = action.payload;
    const response = yield call(post, `/instance/${id}/stop`);
    if (response.status === 200) {
      yield put(stopInstanceSuccess(response.data));
      yield put(checkInstancesStatus());
    } else {
      yield put(stopInstanceFailure({ status: response.status, message: response.data }));
    }
  } catch (error) {
    yield put(stopInstanceFailure(error));
  }
}

function* restartInstance(action) {
  try {
    const { id } = action.payload;
    const response = yield call(post, `/instance/${id}/restart`);
    if (response.status === 200) {
      yield put(restartInstanceSuccess(response.data));
      yield put(checkInstancesStatus());
    } else {
      yield put(restartInstanceFailure({ status: response.status, message: response.data }));
    }
  } catch (error) {
    yield put(restartInstanceFailure(error));
  }
}

function* goLive(action) {
  try {
    const { studioId, eventId } = action.payload;
    const response = yield call(post, `/studio/${studioId}/goLive`, { eventId });
    if (response.status === 200) {
      yield put(goLiveSuccess(response.data));
    } else {
      yield put(goLiveFailure({ status: response.status, message: response.data }));
    }
  } catch (error) {
    yield put(goLiveFailure(error));
  }
}

function* endLive(action) {
  try {
    const { studioId } = action.payload;
    const response = yield call(post, `/studio/${studioId}/endLive`);
    if (response.status === 200) {
      yield put(endLiveSuccess(response.data));
      yield put(selectLayout(null));
    } else {
      yield put(endLiveFailure({ status: response.status, message: response.data }));
    }
  } catch (error) {
    yield put(endLiveFailure(error));
  }
}

function* getAwsImages(action) {
  try {
    const { regionId } = action.payload;
    const response = yield call(get, `/aws/images?region=${regionId}`);
    if (response.status === 200) {
      yield put(getAwsImagesSuccess(response.data));
    } else {
      yield put(getAwsImagesFailure({ status: response.status, message: response.data }));
    }
  } catch (error) {
    yield put(getAwsImagesFailure(error));
  }
}

function* launchFromImage(action) {
  try {
    const { id } = action.payload;
    const response = yield call(post, `/instance/${id}/launchFromImage`);
    if (response.status === 200) {
      yield put(launchFromImageSuccess(id));
    } else {
      yield put(launchFromImageFailure({ status: response.status, message: response.data }));
    }
  } catch (error) {
    yield put(launchFromImageFailure(error));
  }
}

export default function* () {
  yield all([
    takeLatest([actions.GET_AWS_REGIONS_REQUEST], getAwsRegions),
    takeLatest([actions.GET_AWS_INSTANCES_REQUEST], getAwsInstances),
    takeLatest([actions.GET_INSTANCES_REQUEST], getInstances),
    takeLatest([actions.CREATE_INSTANCE_REQUEST], createInstance),
    takeLatest([actions.UPDATE_INSTANCE_REQUEST], updateInstance),
    takeLatest([actions.DELETE_INSTANCE_REQUEST], deleteInstance),
    takeLatest([actions.START_INSTANCE_REQUEST], startInstance),
    takeLatest([actions.STOP_INSTANCE_REQUEST], stopInstance),
    takeLatest([actions.RESTART_INSTANCE_REQUEST], restartInstance),
    takeLatest([actions.GO_LIVE_REQUEST], goLive),
    takeLatest([actions.END_LIVE_REQUEST], endLive),
    takeLatest([actions.GET_AWS_IMAGES_REQUEST], getAwsImages),
    takeLatest([actions.LAUNCH_FROM_IMAGE_REQUEST], launchFromImage),
    takeLatest([actions.GET_AWS_AVAILABILITY_ZONES_REQUEST], getAwsAvailabilityZones),
  ]);
}
