import React, { PureComponent } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import { get } from '../../services/api';

import PaginatedItems from './PaginatedItems';

class SystemEvents extends PureComponent {
  state = {
    systemEvents: [],
  };

  async componentDidMount() {
    const { activeStudio } = this.props;

    if (activeStudio) {
      const response = await get('/user/systemEvents');
      this.setState({ systemEvents: response.data });
    }
  }

  render() {
    const { systemEvents } = this.state;

    let displayData;
    if (systemEvents) {
      displayData = systemEvents.map(({ _id, createdAt, adminAppUser, studio, event, systemEventType, data }) => {
        return {
          _id,
          timestamp: moment(createdAt).format('MM/DD/YYYY hh:mm:ss A'),
          adminAppUser,
          studio,
          event,
          action: systemEventType,
          extraInfo: data ? data.name : '',
        };
      });
    }

    return (
      <div style={{ padding: 25, height: '90%', overflowY: 'auto' }}>
        <table style={{ padding: 25, margin: '0 auto' }}>
          <thead style={{ backgroundColor: 'rgb(45, 64, 81)', color: 'rgb(169, 170, 171)', margin: 0 }}>
            <tr style={{ textAlign: 'left', fontSize: 13 }}>
              <th style={{ padding: 10, textAlign: 'center' }}>TIMESTAMP</th>
              <th style={{ padding: 10, textAlign: 'center' }}>ADMIN USER</th>
              <th style={{ padding: 10, textAlign: 'center' }}>STUDIO</th>
              <th style={{ padding: 10, textAlign: 'center' }}>EVENT</th>
              <th style={{ padding: 10, textAlign: 'center' }}>ACTION</th>
              <th style={{ padding: 10, textAlign: 'center' }}>EXTRA INFO</th>
            </tr>
          </thead>
          {displayData && <tbody style={{ margin: 0 }}>{displayData.length > 0 && <PaginatedItems itemsPerPage={15} items={displayData} />}</tbody>}
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeStudio: state.studio.active,
    activeEvent: state.events.active,
  };
};

export default connect(mapStateToProps)(SystemEvents);
