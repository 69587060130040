import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import WallLayout from './WallLayout';

class WallLayoutContainer extends PureComponent {
  static propTypes = {
    rows: PropTypes.number.isRequired,
    columns: PropTypes.number.isRequired,
    maxWidth: PropTypes.number,
    maxHeight: PropTypes.number.isRequired,
    onSizeUpdate: PropTypes.func,
  };

  static defaultProps = {
    maxWidth: undefined,
    onSizeUpdate: undefined,
  };

  state = {
    maxWidth: 0,
    maxHeight: 0,
  };

  _containerRef = React.createRef();

  componentDidMount() {
    this._updateSize();
    window.addEventListener('resize', this._updateSize, true);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._updateSize, true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.maxHeight !== this.props.maxHeight || prevProps.maxWidth !== this.props.maxWidth) {
      this._updateSize();
    }
  }

  _updateSize = () => {
    if (this._containerRef.current !== null) {
      const { offsetWidth, offsetHeight } = this._containerRef.current;
      const maxWidth = this.props.maxWidth || offsetWidth || 0;
      const maxHeight = this.props.maxHeight || offsetHeight || 0;
      this.setState(
        {
          maxWidth,
          maxHeight,
        },
        () => {
          const { maxWidth, maxHeight } = this.state;
          const { onSizeUpdate } = this.props;
          onSizeUpdate && onSizeUpdate({ maxWidth, maxHeight });
        },
      );
    }
  };

  render() {
    const { maxWidth, maxHeight } = this.state;
    return (
      <div ref={this._containerRef} className='grid-container'>
        <WallLayout {...this.props} width={maxWidth} height={maxHeight} />
      </div>
    );
  }
}

export default WallLayoutContainer;
