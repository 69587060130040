import React, { useEffect, useState } from 'react';
import HorizontalBar from './HorizontalBar';
import { pieScoreColors } from '../../utils/charts-util';
import SearchBox from '../../common/SearchBox';

export default function HtmlBarChart({ data, style, title }) {
  const [filteredData, setFilteredData] = useState([]);
  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    if (data) {
      setFilteredData(data);
    }
  }, [data]);

  const _filterData = (e) => {
    const value = e.target.value;
    setSearchString(value);
    if (value && value.length > 0) {
      const filteredData = data.filter((d) => {
        const name = `${d.firstName} ${d.lastName}`.toLowerCase().trim();
        return name.indexOf(value.toLowerCase().trim()) !== -1;
      });
      setFilteredData(filteredData);
    } else {
      setFilteredData(data);
    }
  };

  const colorMap = [
    {
      value: 75,
      color: pieScoreColors[0],
    },
    {
      value: 50,
      color: pieScoreColors[1],
    },
    {
      value: 0,
      color: pieScoreColors[2],
    },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {title && <h4 style={{ textAlign: 'center', marginBottom: 0 }}>{title}</h4>}
      <SearchBox
        placeholder='Search by name'
        value={searchString}
        onChange={_filterData}
        showIcon={false}
        onClear={() => {
          setFilteredData(data);
          setSearchString('');
        }}
        width='200px'
        containerStyle={{ marginLeft: -12 }}
        inputStyle={{ color: '#000', background: '#fff !important', textAlign: 'left', marginLeft: 15, fontSize: 13 }}
      />
      <div className='html-bar-chart-container custom-scrollbar' style={style}>
        {filteredData.map((d, i) => {
          const label = `${d.firstName} ${d.lastName}`;
          const value = d.pieScore;
          return <HorizontalBar key={`bar-${i}`} title={`${data[i]}`} label={label} value={value} colorMap={colorMap} rank={d.rank} />;
        })}
      </div>
    </div>
  );
}
