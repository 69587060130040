import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Switch from 'react-switch';
import { setLayoutAreas, selectArea, applySelection, updateSelection, setSnapIntoGrid } from '../../redux/layoutsRedux';
import Button from '../../common/Button';
import Input from '../../common/Input';
import verticalPaddingIcon from '../../assets/icons/vertical-padding.svg';
import horizontalPaddingIcon from '../../assets/icons/horizontal-padding.svg';
import alignCenterIcon from '../../assets/icons/align-center.svg';

class AreaSelectionEditor extends PureComponent {
  state = {
    horizontalPadding: 0,
    verticalPadding: 0,
  };

  _onCancel = () => {
    const { selectArea, setSnapIntoGrid } = this.props;
    selectArea(null);
    setSnapIntoGrid(true);
    this.setState({ horizontalPadding: 0, verticalPadding: 0 });
  };

  _onApplySelection = () => {
    const { activeStudio, applySelection } = this.props;
    const { rows, columns } = activeStudio;
    setSnapIntoGrid(true);
    applySelection({ rows, columns });
    this.setState({ horizontalPadding: 0, verticalPadding: 0 });
  };

  _getTvSize = () => {
    const { activeStudio } = this.props;
    const { rows, columns } = activeStudio;
    const layoutAreasRect = document.getElementById('layout-areas').getBoundingClientRect();
    const wallWidth = layoutAreasRect.width;
    const wallHeight = layoutAreasRect.height;
    const tvWidth = wallWidth / columns;
    const tvHeight = wallHeight / rows;
    return { wallWidth, wallHeight, tvWidth, tvHeight };
  };

  _onCenter = () => {
    const { selectedArea, areaSelection, updateSelection, selectArea } = this.props;
    const { width, height, left, top } = selectedArea;

    const { tvWidth, tvHeight } = this._getTvSize();

    const beginTvColumn = Math.floor(left / tvWidth);
    const beginTvRow = Math.floor(top / tvHeight);
    const numTvColumns = Math.ceil((left - beginTvColumn * tvWidth + width) / tvWidth);
    const numTvRows = Math.ceil((top - beginTvRow * tvHeight + height) / tvHeight);

    const widthSpan = numTvColumns * tvWidth;
    const heightSpan = numTvRows * tvHeight;
    const paddingHorizontal = (widthSpan - width) / 2;
    const paddingVertical = (heightSpan - height) / 2;

    const horizontalPadding = Math.floor(((paddingHorizontal * 2) / widthSpan) * 100);
    const verticalPadding = Math.floor(((paddingVertical * 2) / heightSpan) * 100);

    const updatedSelection = {
      left: beginTvColumn * tvWidth + paddingHorizontal,
      top: beginTvRow * tvHeight + paddingVertical,
      width: areaSelection.width,
      height: areaSelection.height,
    };
    updateSelection(updatedSelection);
    selectArea({ ...selectedArea, ...updatedSelection });
    this.setState({ horizontalPadding, verticalPadding });
  };

  _onChangePaddingPercentage = ({ target }) => {
    const padding = target.value < 0 ? 0 : target.value > 35 ? 35 : target.value;

    this.setState({ [target.name]: padding }, () => {
      const { selectedArea, updateSelection, areaSelection, selectArea } = this.props;
      const { width, height, left, top } = selectedArea;

      const { tvWidth, tvHeight } = this._getTvSize();

      const { verticalPadding, horizontalPadding } = this.state;
      const verticalPaddingPercentage = verticalPadding / 100;
      const horizontalPaddingPercentage = horizontalPadding / 100;

      const beginTvColumn = Math.floor(left / tvWidth);
      const beginTvRow = Math.floor(top / tvHeight);
      const numTvColumns = Math.ceil((left - beginTvColumn * tvWidth + width) / tvWidth);
      const numTvRows = Math.ceil((top - beginTvRow * tvHeight + height) / tvHeight);

      const widthSpan = numTvColumns * tvWidth;
      const heightSpan = numTvRows * tvHeight;
      const paddingHorizontal = (widthSpan * horizontalPaddingPercentage) / 2;
      const paddingVertical = (heightSpan * verticalPaddingPercentage) / 2;

      let updatedSelection;
      if (target.name === 'verticalPadding') {
        updatedSelection = {
          left: areaSelection.left,
          width: areaSelection.width,
          top: beginTvRow * tvHeight + paddingVertical,
          height: heightSpan - paddingVertical * 2,
        };
      } else {
        updatedSelection = {
          top: areaSelection.top,
          height: areaSelection.height,
          left: beginTvColumn * tvWidth + paddingHorizontal,
          width: widthSpan - paddingHorizontal * 2,
        };
      }
      updateSelection(updatedSelection);
      selectArea({ ...selectedArea, ...updatedSelection });
    });
  };

  render() {
    return (
      <div
        style={{
          padding: 15,
          paddingTop: 30,
          gridColumnStart: 1,
          gridColumnEnd: 3,
          gridRowStart: 3,
          gridRowEnd: 4,
          minHeight: 300,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-end' }}>
            <div
              style={{
                marginRight: 80,
              }}
            >
              <div style={{ color: '#a9aaab', textAlign: 'left', marginBottom: 5 }}>Padding (%)</div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 15 }}>
                  <img src={horizontalPaddingIcon} alt='current layout' width={24} height={24} style={{ marginRight: 15 }} />
                  <Input
                    type='number'
                    name='verticalPadding'
                    label=''
                    value={this.state.verticalPadding}
                    onChange={this._onChangePaddingPercentage}
                    min='0'
                    max='35'
                    step='1'
                    style={{ width: '40px', marginLeft: 0 }}
                    containerStyle={{ margin: 0 }}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <img src={verticalPaddingIcon} alt='current layout' width={24} height={24} style={{ marginRight: 15 }} />
                  <Input
                    type='number'
                    name='horizontalPadding'
                    label=''
                    value={this.state.horizontalPadding}
                    onChange={this._onChangePaddingPercentage}
                    min='0'
                    max='35'
                    step='1'
                    style={{ width: '40px', marginLeft: 0 }}
                    containerStyle={{ margin: 0 }}
                  />
                </div>
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Button type='primary' onClick={this._onCenter} containerStyle={{ padding: 4, justifyContent: 'center', alignItems: 'center' }}>
                <img src={alignCenterIcon} alt='current layout' width={24} height={24} />
              </Button>
              <div style={{ color: '#a9aaab', textAlign: 'left', marginLeft: 15 }}>Align center</div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                color: '#a9aaab',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: 80,
              }}
            >
              <div style={{ marginRight: 7 }}>Snap into grid</div>
              <Switch
                onChange={() => this.props.setSnapIntoGrid(!this.props.snapIntoGrid)}
                checked={this.props.snapIntoGrid}
                checkedIcon={false}
                uncheckedIcon={false}
              />
            </div>
          </div>

          <div style={{ marginTop: 80 }}>
            <Button text='Apply' onClick={this._onApplySelection} />
            <Button type='secondary' text='Cancel' onClick={this._onCancel} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeStudio: state.studio.active,
  selectedArea: state.layouts.selectedArea,
  areaSelection: state.layouts.areaSelection,
  snapIntoGrid: state.layouts.snapIntoGrid,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ setLayoutAreas, selectArea, applySelection, updateSelection, setSnapIntoGrid }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AreaSelectionEditor);
