import React from 'react';

const Loader = () => (
  <div 
    style={{
      color: '#fff',
      textAlign: 'center',
      margin: 10
    }}
  >
    Loading...
  </div>
);
export default Loader;
