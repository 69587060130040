import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';

class Tabs extends PureComponent {
  componentDidMount() {
    const { onChange, location, options } = this.props;
    const selectedTabs = this._getSelectedTabs();
    if (selectedTabs[location.pathname]) {
      onChange && onChange(selectedTabs[location.pathname]);
    } else if (options && options.length > 0) {
      onChange && onChange(options[0].value);
    }
  }

  _getSelectedTabs() {
    return localStorage.getItem('selectedTabs') ? JSON.parse(localStorage.getItem('selectedTabs')) : {};
  }

  _onChange = (value) => {
    const { onChange, location } = this.props;

    const selectedTabs = this._getSelectedTabs();
    selectedTabs[location.pathname] = value;
    localStorage.setItem('selectedTabs', JSON.stringify(selectedTabs));

    onChange && onChange(value);
  };

  render() {
    const { options, value, style, disabled } = this.props;
    const _tabButtonClassName = disabled ? 'tab-button disabled' : 'tab-button';
    return (
      <div className='tabs' style={style}>
        {options.map((opt) => (
          <button
            key={`tab-${opt.value}`}
            className={value !== opt.value ? _tabButtonClassName : `${_tabButtonClassName} active`}
            onClick={() => this._onChange(opt.value)}
            disabled={disabled}
          >
            {opt.label}
          </button>
        ))}
      </div>
    );
  }
}

export default withRouter(Tabs);
