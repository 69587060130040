import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import participant_placeholder from '../../assets/images/participant_placeholder.png';
import participant_in_place from '../../assets/images/participant_in_place.png';
import Draggable from '../../common/Draggable';
import ContextMenu from '../../common/ContextMenu';
import { updateLayoutAreaData, getLayoutById } from '../../redux/layoutsRedux';
import ParticipantMetadata from './ParticipantMetadata';
import WallAsset from '../../common/WallAsset';
import ParticipantPhoto from '../../common/ParticipantPhoto';
import { findEventParticipantByDisplayNum } from '../../utils/find-event-participant';
import landscapeImg from '../../assets/images/feed_landscape.png';
import StudioFeed from './config/StudioFeed';
import VideoAssetMetadata from './VideoAssetMetadata';

class LayoutArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hovered: false,
      showContextMenu: false,
      contextMenuX: 0,
      contextMenuY: 0,
    };
  }

  _ref = React.createRef();

  _dragEnterCount = 0;

  _onDragEnter = () => {
    this._dragEnterCount += 1;
    this.setState({ hovered: true });
  };

  _onDragOver = (event) => {
    event.persist();
    event.preventDefault();
    event.stopPropagation();
  };

  _onDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this._dragEnterCount -= 1;
    if (this._dragEnterCount === 0) {
      this.setState({ hovered: false });
    }
  };

  _onDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this._dragEnterCount = 0;

    const { dataTransfer } = event;
    if (dataTransfer.getData('text')) {
      const dropData = JSON.parse(dataTransfer.getData('text'));
      const { areaIndex, onDrop, sourceType, removeSource, unassignParticipant } = this.props;

      const oldAreaIndex = dropData.data ? dropData.data.areaIndex : -1;
      if (oldAreaIndex !== areaIndex) {
        let dropped = false;
        if (!sourceType) {
          onDrop(areaIndex, dropData);
          dropped = true;
        } else if (sourceType === 'WallParticipant' && dropData.sourceType === 'Participant') {
          onDrop(areaIndex, dropData);
          dropped = true;
        }
        // If we are dragging from another area
        if (oldAreaIndex >= 0 && dropped) {
          if (sourceType === 'WallParticipant' && dropData.sourceType === 'Participant') {
            unassignParticipant(oldAreaIndex);
          } else {
            removeSource(oldAreaIndex);
          }
        }
      }
    }

    this.setState({ hovered: false });
  };

  _onContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showContextMenu: true, contextMenuX: e.pageX, contextMenuY: e.pageY });
  };

  _hideContextMenu = () => {
    this.setState({ showContextMenu: false, contextMenuX: 0, contextMenuY: 0 });
  };

  _getContextMenuItems = (disableCropping, disableEditing) => {
    const { sourceType, areas, currentLayoutData, isMultiSelected } = this.props;
    const contextMenuItems = [
      { value: 1, label: 'Halves', disabled: disableCropping },
      { value: 2, label: 'Thirds', disabled: disableCropping },
      { value: 3, label: 'Quadrants', disabled: disableCropping },
      { value: 4, label: 'Sextuplets', disabled: disableCropping },
      { value: 10, label: 'Edit', disabled: disableEditing || isMultiSelected },
      { value: 20, label: 'Remove', danger: true },
    ];
    if (!sourceType) {
      contextMenuItems.pop();
    }

    if (sourceType === 'Participant') {
      contextMenuItems.push({ value: 19, label: 'Unassign', danger: true });
    } else if ((sourceType === 'WallParticipant' || !sourceType) && !currentLayoutData.studioWall && !isMultiSelected) {
      const assignedNums = areas.filter((a) => a.sourceType === 'Participant' && a.data.wallDisplayNum).map((p) => p.data.wallDisplayNum);
      contextMenuItems.push({
        value: 18,
        label: 'Assign',
        options: Array.from(Array(100), (_, i) => i + 1).map((n) => {
          if (assignedNums.includes(n)) {
            return { value: n, label: n, danger: true };
          } else {
            return { value: n, label: n };
          }
        }),
      });
    }
    return contextMenuItems.sort((a, b) => a.value - b.value);
  };

  _onContextMenuItemSelection = (option, subOption) => {
    let left = 0;
    let width = 0;
    let height = 0;
    if (!option.disabled) {
      const { areaIndex, removeSource, onAreaDivision, onEdit, unassignParticipant, assignParticipant } = this.props;
      switch (option.value) {
        case 1:
          left = 1 / 4;
          width = 1 / 2;
          height = 1;
          onAreaDivision(areaIndex, width, height, { top: 0, left, width, height });
          break;
        case 2:
          left = 1 / 3;
          width = 1 / 3;
          height = 1;
          onAreaDivision(areaIndex, width, height, { top: 0, left, width, height });
          break;
        case 3:
          left = 0;
          width = 1 / 2;
          height = 1 / 2;
          onAreaDivision(areaIndex, width, height);
          break;
        case 4:
          left = 1 / 3;
          width = 1 / 3;
          height = 1 / 2;
          onAreaDivision(areaIndex, width, height, { top: 0, left: 1 / 6, width: 4 / 6, height: 1 });
          break;
        case 10:
          const { offsetTop, offsetLeft, clientWidth, clientHeight } = this._ref.current;
          const { padding } = this.props;
          let data = { top: offsetTop + 1, left: offsetLeft + 1, width: clientWidth, height: clientHeight };
          if (padding && (padding.top > 0 || padding.left > 0 || padding.right > 0 || padding.bottom > 0)) {
            data.top += padding.top;
            data.left += padding.left;
            data.width -= padding.right + padding.left;
            data.height -= padding.bottom + padding.top;
          }
          onEdit(areaIndex, data);
          break;
        case 18:
          if (subOption) {
            assignParticipant(areaIndex, subOption.value);
          }
          break;
        case 19:
          unassignParticipant(areaIndex);
          break;
        case 20:
          removeSource(areaIndex);
          break;
        default:
          break;
      }
    }
  };

  render() {
    const { hovered, showContextMenu, contextMenuX, contextMenuY } = this.state;
    const { style, sourceType, crop, tvWidth, tvHeight, data, padding, grid, width, height, selectedArea, onClick, areaIndex, isSelected } = this.props;

    let areaWidth = tvWidth;
    let areaHeight = tvHeight;

    let isQuadrant = false;
    let isSextuplet = false;
    let paddingStr = '';

    if (padding && (padding.top > 0 || padding.left > 0 || padding.right > 0 || padding.bottom > 0 || width > 1 || height > 1)) {
      const top = padding.top * tvHeight;
      const left = padding.left * tvWidth;
      const right = padding.right * tvWidth;
      const bottom = padding.bottom * tvHeight;
      paddingStr = `${top}px ${right}px ${bottom}px ${left}px`;

      const { gridRowStart, gridRowEnd, gridColumnStart, gridColumnEnd } = style;
      const gridColumnCount = gridColumnEnd - gridColumnStart;
      const gridRowCount = gridRowEnd - gridRowStart;
      const tvWidthCount = gridColumnCount / 6;
      const tvHeightCount = gridRowCount / 2;

      areaWidth = tvWidth * tvWidthCount - left - right;
      areaHeight = tvHeight * tvHeightCount - top - bottom;
    } else if (width === 1 / 2 && height === 1 / 2) {
      areaWidth = tvWidth / 2;
      areaHeight = tvHeight / 2;
      isQuadrant = true;
    } else if (width === 1 / 3 && height === 1 / 2) {
      areaWidth = tvWidth / 3;
      areaHeight = tvHeight / 2;
      isSextuplet = true;
    }

    if (sourceType === 'Asset') {
      const { metadata } = data;
      if (metadata) {
        if (metadata.width === metadata.height) {
          areaWidth = areaHeight;
        } else {
          const ratio = Math.min(areaWidth / metadata.width, areaHeight / metadata.height);
          areaWidth = metadata.width * ratio;
          areaHeight = metadata.height * ratio;
        }
      }
    }

    let image;
    if (sourceType === 'Asset') {
      image =
        data.type === 'image' ? (
          <WallAsset url={data && (data.previewFileKey || data.fileKey)} width={areaWidth} height={areaHeight} />
        ) : (
          <img width='100%' src={landscapeImg} alt='video asset' />
        );
    } else if (sourceType === 'WallParticipant') {
      image = <img style={{ width: isSextuplet ? 'auto' : areaWidth, height: areaHeight }} src={participant_placeholder} alt='wall participant' />;
    } else if (sourceType === 'Participant') {
      const ep = findEventParticipantByDisplayNum(data.wallDisplayNum, ['ON_WALL', 'ON_AIR', 'IN_STUDIO_QUEUE']);
      if (ep && ep.participant && ep.participant.photoUrl) {
        if (ep.participant && ep.participant.photoUrl) {
          image = <ParticipantPhoto photoUrl={ep.participant.photoUrl} width={areaWidth} height={areaHeight} />;
        } else {
          image = <img style={{ width: areaWidth, height: areaHeight }} src={participant_in_place} alt='participant' />;
        }
      } else {
        image = <img style={{ width: areaWidth, height: areaHeight }} src={participant_in_place} alt='participant' />;
      }
    } else if (sourceType === 'StudioFeed') {
      image = <StudioFeed />;
    }

    return (
      <div
        ref={this._ref}
        onDragEnter={this._onDragEnter}
        onDragOver={this._onDragOver}
        onDragLeave={this._onDragLeave}
        onDrop={this._onDrop}
        onContextMenu={this._onContextMenu}
        onClick={(event) => onClick && onClick(event, areaIndex)}
        style={{
          ...style,
          borderWidth: 1,
          borderColor: grid.color,
          borderStyle: 'dotted',
          pointerEvents: selectedArea ? 'none' : 'all',
        }}
      >
        {sourceType && (
          <Draggable dataTransfer={{ sourceType, data }}>
            <div
              style={{
                position: 'absolute',
                top: 0,
                display: 'flex',
                width: '100%',
                height: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: sourceType === 'Asset' ? 'flex-start' : 'center',
                  alignItems: sourceType === 'Asset' ? 'flex-start' : 'center',
                  overflow: 'hidden',
                  padding: paddingStr,
                }}
              >
                {image}
                {sourceType === 'Participant' && <ParticipantMetadata wallDisplayNum={data.wallDisplayNum} />}
                {sourceType === 'Asset' && data && data.type === 'video' && <VideoAssetMetadata data={data} />}
                {data.wallDisplayNum && (
                  <div
                    className='num'
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      backgroundColor: '#4e6379',
                      fontWeight: 600,
                      fontSize: 12,
                      color: '#fff',
                      padding: 4,
                    }}
                  >
                    {`#${data.wallDisplayNum}`}
                  </div>
                )}
              </div>
            </div>
          </Draggable>
        )}
        {showContextMenu && (
          <ContextMenu
            position={{ x: contextMenuX, y: contextMenuY }}
            items={this._getContextMenuItems(
              crop || padding || isQuadrant || isSextuplet || sourceType === 'Asset' || sourceType === 'StudioFeed',
              crop || isQuadrant || isSextuplet,
            )}
            onClose={this._hideContextMenu}
            onItemSelected={this._onContextMenuItemSelection}
          />
        )}
        <div
          style={{
            position: 'absolute',
            top: 0,
            display: 'flex',
            width: '100%',
            height: '100%',
            backgroundColor: isSelected ? '#2b763a' : hovered ? (sourceType ? '#c6103a' : '#2b763a') : 'transparent',
            opacity: 0.4,
            pointerEvents: 'none',
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  grid: state.layouts.grid,
  selectedArea: state.layouts.selectedArea,
  areas: state.layouts.areas,
  eventParticipant: state.events.participants,
  currentLayoutData: getLayoutById(state, state.layouts.current),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateLayoutAreaData }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LayoutArea);
