import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPauseCircle, faWindowRestore } from '@fortawesome/free-solid-svg-icons';
import Draggable from '../Draggable';
import ContextMenu from '../ContextMenu';
import { updateEventParticipantRequest } from '../../redux/wallRedux';
import { updateEventRequest } from '../../redux/eventsRedux';
import { post } from '../../services/api';

const ParticipantListItem = ({
  item,
  active,
  status,
  onItemSelect,
  participants,
  updateEventParticipant,
  onDragStart,
  onDragEnd,
  showClearResponseOption,
  activeEvent,
  updateEvent,
}) => {
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuX, setContextMenuX] = useState(0);
  const [contextMenuY, setContextMenuY] = useState(0);

  const _onClickHandler = () => {
    onItemSelect && onItemSelect(item);
  };

  const _onContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowContextMenu(true);
    setContextMenuX(e.pageX);
    setContextMenuY(e.pageY);
  };

  const _hideContextMenu = () => {
    setShowContextMenu(false);
    setContextMenuX(0);
    setContextMenuY(0);
  };

  const _getContextMenuItems = () => {
    const assignedNums = participants.filter((a) => a.wallDisplayNum).map((p) => p.wallDisplayNum);
    const options = Array.from(Array(100), (_, i) => i + 1)
      .filter((n) => item.wallDisplayNum !== n)
      .map((n) => {
        if (assignedNums.includes(n)) {
          return { value: n, label: n, danger: true };
        } else {
          return { value: n, label: n };
        }
      });
    const menuItems = [{ value: 1, label: 'Reassign', options }];
    if (showClearResponseOption) {
      menuItems.push({
        value: 2,
        label: 'Clear Response',
        danger: true,
      });
    }
    return menuItems;
  };

  const _onContextMenuItemSelection = async (option, subOption) => {
    if (option.value === 1) {
      updateEventParticipant(item._id, { wallDisplayNum: subOption.value });
    } else if (option.value === 2) {
      const { pandoV1UNID } = item.participant;
      if (pandoV1UNID) {
        updateEvent(activeEvent._id, {
          v1APIMessages: [{ type: 'clearSingleAudienceResponse', value: pandoV1UNID.split('-')[0], showName: activeEvent.name, uuid: item.participant._id }],
        });
        try {
          await post(`/eventParticipant/${item._id}/clearResponse`, {});
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  let statusColor = '';
  switch (status) {
    case 'IN_STUDIO_QUEUE':
    case 'ON_WALL':
      statusColor = '#43B77A';
      break;
    case 'IN_SCREENING_QUEUE':
      statusColor = '#B7771E';
      break;
    case 'NOT_CONNECTED':
      statusColor = '#B71330';
      break;
    default:
      break;
  }

  const activeClass = active ? 'attendee active' : 'attendee';
  return (
    <Draggable dataTransfer={{ data: item.participant, sourceType: 'Participant' }} onDragStart={() => onDragStart && onDragStart(item)} onDragEnd={onDragEnd}>
      <div className={activeClass} onClick={_onClickHandler} onContextMenu={_onContextMenu}>
        <div className='general-information'>
          <div className='name'>
            <span style={{ marginRight: 5 }}>{item.wallDisplayNum && `${item.wallDisplayNum}. `}</span>
            <span>{item.participant && (item.participant.name || `${item.participant.firstName} ${item.participant.lastName || ''}`)}</span>
          </div>
          <div className='location'>{item.participant && (item.participant.location || item.participant.city)}</div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {item.browserMinimized && <FontAwesomeIcon icon={faWindowRestore} color='#B71330' style={{ fontSize: 15, marginRight: 5 }} />}
          {item.paused && <FontAwesomeIcon icon={faPauseCircle} color='#b7771e' style={{ fontSize: 16, marginRight: 5 }} />}
          <div className='status'>
            <div className='indicator' style={{ backgroundColor: statusColor }} />
          </div>
        </div>
        {showContextMenu && (
          <ContextMenu
            position={{ x: contextMenuX, y: contextMenuY }}
            items={_getContextMenuItems()}
            onClose={_hideContextMenu}
            onItemSelected={_onContextMenuItemSelection}
          />
        )}
      </div>
    </Draggable>
  );
};

const mapStateToProps = (state) => ({
  participants: state.events.participants,
  activeEvent: state.events.active,
});

const mapDispatchToProps = (dispatch) => ({
  updateEventParticipant: (eventParticipantId, data) => dispatch(updateEventParticipantRequest(eventParticipantId, data)),
  updateEvent: (id, data) => dispatch(updateEventRequest(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantListItem);
