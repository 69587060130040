import React from 'react';
import PropTypes from 'prop-types';
import StringUtils from '../utils/string-utils';
import '../css/TextInput.css';

const TextInput = ({
  label,
  name,
  value,
  onChange,
  onKeyPress,
  secure,
  placeholder,
  hasError,
  errorMessage,
  generatePassword,
  onPasswordGenerated,
  labelStyle,
  inputStyle,
  disabled,
}) => {
  const _generatePassword = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onPasswordGenerated && onPasswordGenerated(StringUtils.generatePassword());
  };

  return (
    <div className='form-group'>
      <div className='form-label' style={labelStyle}>
        {label}
      </div>
      <div className={hasError ? 'form-control error' : 'form-control'}>
        <div style={{ display: 'flex' }}>
          <input
            type={secure ? 'password' : 'text'}
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
            onKeyPress={onKeyPress}
            autoComplete='off'
            style={{ ...inputStyle, color: disabled ? '#999' : '#fff' }}
            disabled={disabled}
          />
          {generatePassword && (
            <button className='link' onClick={_generatePassword}>
              Generate
            </button>
          )}
        </div>
        {hasError ? <div className='error-message'>{errorMessage}</div> : null}
      </div>
    </div>
  );
};

TextInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

TextInput.defaultProps = {
  label: '',
  name: '',
  placeholder: '',
  value: undefined,
};

export default TextInput;
