import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateSelection } from '../../redux/layoutsRedux';
import '../../css/LayoutCropper.css';

class LayoutAreaSelection extends Component {
  _ref = React.createRef();

  componentDidMount() {
    const { updateSelection, area } = this.props;
    const { top, left, width, height } = area;
    this._parentDiv = this._ref.current.parentNode.getBoundingClientRect();
    updateSelection({ top, left, width, height });
  }

  _onMouseDown = (event) => {
    event.stopPropagation();
    const { areaSelection, updateSelection, snapIntoGrid, verticalTvLines, horizontalTvLines } = this.props;
    const { target } = event;
    const initialMousePosition = {
      x: event.clientX - this._parentDiv.left,
      y: event.clientY - this._parentDiv.top,
    };

    const originalState = areaSelection;
    const newState = {};

    const _resize = async (event) => {
      event.stopPropagation();
      const newMousePosition = {
        x: event.clientX - this._parentDiv.left,
        y: event.clientY - this._parentDiv.top,
      };
      const diffX = Math.round(newMousePosition.x - initialMousePosition.x);
      const diffY = Math.round(newMousePosition.y - initialMousePosition.y);

      const newTop = Math.round(originalState.top + diffY);
      const newLeft = Math.round(originalState.left + diffX);
      const newHeight = Math.round(originalState.height + diffY);
      const newWidth = Math.round(originalState.width + diffX);

      if (target.classList.contains('handler-top-left')) {
        newState.top = newTop;
        newState.height = originalState.height - diffY;
        newState.left = newLeft;
        newState.width = originalState.width - diffX;
      } else if (target.classList.contains('handler-top-right')) {
        newState.top = newTop;
        newState.height = originalState.height - diffY;
        newState.left = originalState.left;
        newState.width = newWidth;
      } else if (target.classList.contains('handler-bottom-right')) {
        newState.top = originalState.top;
        newState.height = newHeight;
        newState.left = originalState.left;
        newState.width = newWidth;
      } else if (target.classList.contains('handler-bottom-left')) {
        newState.left = newLeft;
        newState.width = originalState.width - diffX;
        newState.top = originalState.top;
        newState.height = newHeight;
      }

      let diffTop = 0;
      let diffLeft = 0;

      if (snapIntoGrid) {
        horizontalTvLines.forEach((line) => {
          const higherRange = line - 15;
          const lowerRange = line + 15;

          let checkTop = false;
          let checkBottom = false;

          if (target.classList.contains('handler-top-left') || target.classList.contains('handler-top-right')) {
            checkTop = newState.top >= higherRange && newState.top <= lowerRange;
          } else if (target.classList.contains('handler-bottom-left') || target.classList.contains('handler-bottom-right')) {
            checkBottom = newState.top + newState.height >= higherRange && newState.top + newState.height <= lowerRange;
          }

          if (checkTop) {
            diffTop = line + 1 - newState.top;
          }
          if (checkBottom) {
            diffTop = newState.top + newState.height - (line - 1);
          }
        });

        verticalTvLines.forEach((line) => {
          const higherRange = line - 15;
          const lowerRange = line + 15;

          let checkLeft = false;
          let checkRight = false;

          if (target.classList.contains('handler-top-left') || target.classList.contains('handler-bottom-left')) {
            checkLeft = newState.left >= higherRange && newState.left <= lowerRange;
          } else if (target.classList.contains('handler-top-right') || target.classList.contains('handler-bottom-right')) {
            checkRight = newState.left + newState.width >= higherRange && newState.left + newState.width <= lowerRange;
          }

          if (checkLeft) {
            diffLeft = line + 1 - newState.left;
          }
          if (checkRight) {
            diffLeft = newState.left + newState.width - (line - 1);
          }
        });

        if (target.classList.contains('handler-bottom-right')) {
          newState.height -= diffTop;
          newState.width -= diffLeft;
        } else if (target.classList.contains('handler-bottom-left')) {
          newState.left += diffLeft;
          newState.height -= diffTop;
          newState.width -= diffLeft;
        } else if (target.classList.contains('handler-top-left')) {
          newState.top += diffTop;
          newState.left += diffLeft;
          newState.height -= diffTop;
          newState.width -= diffLeft;
        } else if (target.classList.contains('handler-top-right')) {
          newState.top += diffTop;
          newState.height -= diffTop;
          newState.width -= diffLeft;
        }
      }

      const { maxWidth, maxHeight } = this.props;
      if (newState.left < 0) {
        newState.left = 0;
        newState.width = newState.width + newLeft;
      } else if (newState.left + newState.width > maxWidth) {
        newState.width = maxWidth - newState.left;
      }

      if (newState.top < 0) {
        newState.top = 0;
        newState.height = newState.height + newTop;
      } else if (newState.top + newState.height > maxHeight) {
        newState.height = maxHeight - newState.top;
      }
      updateSelection({ ...newState });
    };

    const _removeEventListeners = () => {
      window.removeEventListener('mousemove', _resize);
      window.removeEventListener('mouseup', _removeEventListeners);
      this.props.onStopScaling({ ...newState });
    };

    window.addEventListener('mousemove', _resize);
    window.addEventListener('mouseup', _removeEventListeners);
  };

  _dragStart = (event) => {
    event.stopPropagation();
    const { areaSelection, updateSelection, snapIntoGrid, verticalTvLines, horizontalTvLines } = this.props;
    const initialMousePosition = {
      x: event.clientX - this._parentDiv.left,
      y: event.clientY - this._parentDiv.top,
    };

    const originalState = areaSelection;
    const newState = {};

    const _dragMove = (event) => {
      const newMousePosition = {
        x: event.clientX - this._parentDiv.left,
        y: event.clientY - this._parentDiv.top,
      };
      const diffX = Math.round(newMousePosition.x - initialMousePosition.x);
      const diffY = Math.round(newMousePosition.y - initialMousePosition.y);
      const newTop = Math.round(originalState.top + diffY);
      const newLeft = Math.round(originalState.left + diffX);

      const { maxWidth, maxHeight } = this.props;
      if (newLeft < 0) {
        newState.left = 0;
      } else if (newLeft + originalState.width > maxWidth) {
        newState.top = maxWidth - originalState.width;
      } else {
        newState.left = newLeft;
      }

      if (newTop < 0) {
        newState.top = 0;
      } else if (newTop + originalState.height > maxHeight) {
        newState.top = maxHeight - originalState.height;
      } else {
        newState.top = newTop;
      }

      newState.width = originalState.width;
      newState.height = originalState.height;

      let diffTop = 0;
      let diffLeft = 0;

      if (snapIntoGrid) {
        horizontalTvLines.forEach((line) => {
          const higherRange = line - 15;
          const lowerRange = line + 15;

          let checkTop = false;
          let checkBottom = false;

          if (newState.top >= higherRange && newState.top <= lowerRange) {
            checkTop = true;
          } else if (newState.top + newState.height >= higherRange && newState.top + newState.height <= lowerRange) {
            checkBottom = true;
          }

          if (checkTop) {
            diffTop = line + 1 - newState.top;
          }
          if (checkBottom) {
            diffTop = (newState.top + newState.height - (line - 1)) * -1;
          }
        });

        verticalTvLines.forEach((line) => {
          const higherRange = line - 15;
          const lowerRange = line + 15;

          let checkLeft = false;
          let checkRight = false;

          if (newState.left >= higherRange && newState.left <= lowerRange) {
            checkLeft = true;
          } else if (newState.left + newState.width >= higherRange && newState.left + newState.width <= lowerRange) {
            checkRight = true;
          }

          if (checkLeft) {
            diffLeft = line + 1 - newState.left;
          }
          if (checkRight) {
            diffLeft = (newState.left + newState.width - (line - 1)) * -1;
          }
        });

        newState.top += diffTop;
        newState.left += diffLeft;
      }

      updateSelection({ ...areaSelection, top: newState.top, left: newState.left });
    };

    const _removeEventListeners = () => {
      window.removeEventListener('mousemove', _dragMove);
      window.removeEventListener('mouseup', _removeEventListeners);
      this.props.onDragEnd({ ...areaSelection, top: newState.top, left: newState.left });
    };

    window.addEventListener('mousemove', _dragMove);
    window.addEventListener('mouseup', _removeEventListeners);
  };

  render() {
    const { areaSelection } = this.props;

    let containerStyle = {};
    if (areaSelection) {
      const { top, left, width, height } = areaSelection;
      containerStyle = { top, left, width, height };
    }

    return (
      <div ref={this._ref} className='resize-tool' style={containerStyle}>
        <div className='handler handler-top-left' onMouseDown={this._onMouseDown} />
        <div className='handler handler-top-right' onMouseDown={this._onMouseDown} />
        <div className='handler handler-bottom-right' onMouseDown={this._onMouseDown} />
        <div className='handler handler-bottom-left' onMouseDown={this._onMouseDown} />
        <div
          onMouseDown={this._dragStart}
          style={{
            position: 'absolute',
            top: 10,
            left: 10,
            right: 10,
            bottom: 10,
            cursor: 'grab',
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  areaSelection: state.layouts.areaSelection,
  snapIntoGrid: state.layouts.snapIntoGrid,
  horizontalTvLines: state.layouts.horizontalTvLines,
  verticalTvLines: state.layouts.verticalTvLines,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateSelection }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LayoutAreaSelection);
