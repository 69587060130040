function isFireFox() {
  return typeof InstallTrigger !== 'undefined';
}

class MediaDeviceUtil {
  static async getAvailableDevices(cached = true) {
    if (!cached) {
      delete MediaDeviceUtil.results.availableDevices;
    }
    if (!MediaDeviceUtil.results.availableDevices) {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const availableDevices = devices
        .map((device) => {
          const { deviceId, groupId, kind, label } = device;
          return { deviceId, groupId, kind, label };
        })
        .filter((device) => device.label.toLowerCase().includes('virtual') === false);

      const kinds = ['audioinput', 'audiooutput', 'videoinput'];
      const devicesMap = {};
      kinds.forEach((kind) => {
        const defaultDevice = availableDevices.find((dev) => dev.kind === kind && dev.deviceId === 'default');
        devicesMap[kind] = availableDevices
          .filter((dev) => dev.kind === kind && dev.deviceId !== 'default')
          .map((dev) => {
            if (defaultDevice && dev.groupId === defaultDevice.groupId) {
              return { ...dev, isDefault: true };
            } else {
              return dev;
            }
          });
      });

      const results = [];
      Object.values(devicesMap).forEach((devices) => {
        results.push(...devices);
      });
      MediaDeviceUtil.results.availableDevices = results;
    }
    return MediaDeviceUtil.results.availableDevices;
  }

  static async getSupportedResolutions(deviceId, cached = true) {
    let useSafeResolution;
    try {
      useSafeResolution = localStorage.getItem('useSafeResolution');
    } catch (err) {
      console.error(err);
    }
    // If iOS OR isSafari
    // ['1280x720', '640x480', '320x240']
    MediaDeviceUtil.resolutions = useSafeResolution ? ['640x480'] : ['1280x720', '960x720', '960x540', '800x600', '640x480', '640x360', '320x180'];

    if (!cached) {
      delete MediaDeviceUtil.supportedResolutions[deviceId];
    }
    if (!MediaDeviceUtil.supportedResolutions[deviceId]) {
      MediaDeviceUtil.supportedResolutions[deviceId] = await new Promise(async (resolve) => {
        const supportedResolutions = [];
        const stream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: { exact: deviceId } } });
        const videoTrack = stream.getVideoTracks()[0];

        const validResolutions = isFireFox() ? (useSafeResolution ? MediaDeviceUtil.resolutions : ['1280x720']) : MediaDeviceUtil.resolutions;

        for (let index = 0; index < validResolutions.length; index += 1) {
          const r = validResolutions[index];
          const parts = r.split('x');
          const width = parseInt(parts[0]);
          const height = parseInt(parts[1]);

          try {
            let constraints = {
              width: { exact: width },
              height: { exact: height },
            };
            let isSuccessful = false;
            setTimeout(() => {
              if (!isSuccessful) {
                localStorage.setItem('useSafeResolution', true);
                // window.location.reload();
              }
            }, 5000);
            await videoTrack.applyConstraints(constraints);
            isSuccessful = true;
            supportedResolutions.push(r);
          } catch (err) {
            // Ignore
            // console.log(err, width, height);
          }
          if (index === validResolutions.length - 1) {
            resolve(supportedResolutions);
          }
        }
        stream.getTracks().forEach((track) => track.stop());
      });
    }
    return MediaDeviceUtil.supportedResolutions[deviceId];
  }
}
MediaDeviceUtil.supportedResolutions = {};
MediaDeviceUtil.results = {};

export default MediaDeviceUtil;
