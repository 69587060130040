import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { get } from '../../services/api';
import Checkbox from '../../common/Checkbox';
import Button from '../../common/Button';

const styles = {
  twoColumnLayout: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
  },
  column: {
    maxHeight: 285,
    overflowY: 'auto',
  },
  button: {
    fontSize: 14,
    padding: '5px 15px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    color: '#adadad',
    marginBottom: 15,
  },
  title: {
    color: '#adadad',
    marginBottom: 15,
  },
  subtitle: {
    fontWeight: 'bold',
    fontSize: 14,
    color: '#adadad',
    marginBottom: 5,
    marginTop: 15,
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginBottom: 20,
  },
  checkboxWrapper: {
    marginRight: 10,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: 15,
  },
};

const defaultWallMetadataFields = [
  { metadataKey: 'firstName', label: 'First Name' },
  { metadataKey: 'lastName', label: 'Last Name' },
  { metadataKey: 'city', label: 'City' },
  { metadataKey: 'state', label: 'State' },
  { metadataKey: 'phone', label: 'Phone' },
];

function WallMetadataModal({ onClose, onSubmit, activeEvent }) {
  const [metadataFields, setMetadataFields] = useState([]);
  const [selectedMetadataFields, setSelectedMetadataFields] = useState([[], []]);
  const [hasRegistrationInfo, setHasRegistrationInfo] = useState(false);
  const [row1UseCommas, setRow1UseCommas] = useState(false);
  const [row2UseCommas, setRow2UseCommas] = useState(false);

  const getWallMetadata = useCallback(async () => {
    if (activeEvent) {
      try {
        const response = await get(`/event/${activeEvent._id}`);
        const { data } = response;
        if (data) {
          const { wallMetadata, erpFormFields } = data;
          setMetadataFields(erpFormFields);
          setHasRegistrationInfo(erpFormFields.length > 0);
          if (wallMetadata.length > 0) {
            const clonedWallMetadata = JSON.parse(JSON.stringify(wallMetadata));
            setRow1UseCommas(clonedWallMetadata[0][0] === 'true');
            setRow2UseCommas(clonedWallMetadata[1][0] === 'true');
            setSelectedMetadataFields(clonedWallMetadata.map((row) => row.slice(1)));
          } else {
            setSelectedMetadataFields([
              ['firstName', 'lastName'],
              ['city', 'state'],
            ]);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [activeEvent]);

  useEffect(() => {
    getWallMetadata();
  }, [getWallMetadata]);

  const selectMetadataField = useCallback(
    (value, row) => {
      const newSelectedMetadataFields = [...selectedMetadataFields];
      const index = newSelectedMetadataFields[row].indexOf(value);
      if (index === -1) {
        newSelectedMetadataFields[row].push(value);
      } else {
        newSelectedMetadataFields[row].splice(index, 1);
      }
      setSelectedMetadataFields(newSelectedMetadataFields);
    },
    [selectedMetadataFields],
  );

  const close = useCallback(() => {
    onClose();
  }, [onClose]);

  const submit = useCallback(() => {
    const wallMetadata = [
      [row1UseCommas, ...selectedMetadataFields[0]],
      [row2UseCommas, ...selectedMetadataFields[1]],
    ];
    onSubmit(wallMetadata);
  }, [onSubmit, selectedMetadataFields, row1UseCommas, row2UseCommas]);

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h3 style={{ margin: 0 }}>Wall Metadata</h3>
      </div>
      <div style={styles.twoColumnLayout}>
        <div style={{ flex: 1, marginRight: 5 }}>
          <div style={styles.title}>First Row</div>
          <div style={styles.checkboxWrapper}>
            <Checkbox
              label='Separate using commas'
              value={row1UseCommas}
              onChange={() => {
                setRow1UseCommas(!row1UseCommas);
              }}
            />
          </div>
          <div className='custom-scrollbar' style={styles.column}>
            <div style={styles.row}>
              <div style={styles.subtitle}>Day-of Contact</div>
              {defaultWallMetadataFields.map((field) => (
                <div key={field.metadataKey} style={styles.checkboxWrapper}>
                  <Checkbox
                    key={field.metadataKey}
                    label={field.label}
                    value={selectedMetadataFields[0].includes(field.metadataKey)}
                    onChange={() => {
                      selectMetadataField(field.metadataKey, 0);
                    }}
                    disabled={selectedMetadataFields[1].includes(field.metadataKey)}
                  />
                </div>
              ))}
            </div>
            {hasRegistrationInfo && (
              <div style={styles.row}>
                <div style={styles.subtitle}>Registration Info</div>
                {metadataFields.map((field) => (
                  <div key={field.metadataKey} style={styles.checkboxWrapper}>
                    <Checkbox
                      key={field.metadataKey}
                      label={field.label}
                      value={selectedMetadataFields[0].includes(field.metadataKey)}
                      onChange={() => {
                        selectMetadataField(field.metadataKey, 0);
                      }}
                      disabled={selectedMetadataFields[1].includes(field.metadataKey)}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div style={{ flex: 1, marginLeft: 5 }}>
          <div style={styles.title}>Second Row</div>
          <div style={styles.checkboxWrapper}>
            <Checkbox
              label='Separate using commas'
              value={row2UseCommas}
              onChange={() => {
                setRow2UseCommas(!row2UseCommas);
              }}
            />
          </div>
          <div className='custom-scrollbar' style={styles.column}>
            <div style={styles.row}>
              <div style={styles.subtitle}>Day-of Contact</div>
              {defaultWallMetadataFields.map((field) => (
                <div key={field.metadataKey} style={styles.checkboxWrapper}>
                  <Checkbox
                    key={field.metadataKey}
                    label={field.label}
                    value={selectedMetadataFields[1].includes(field.metadataKey)}
                    onChange={() => {
                      selectMetadataField(field.metadataKey, 1);
                    }}
                    disabled={selectedMetadataFields[0].includes(field.metadataKey)}
                  />
                </div>
              ))}
            </div>
            {hasRegistrationInfo && (
              <div style={styles.row}>
                <div style={styles.subtitle}>Registration Info</div>
                {metadataFields.map((field) => (
                  <div key={field.metadataKey} style={styles.checkboxWrapper}>
                    <Checkbox
                      key={field.metadataKey}
                      label={field.label}
                      value={selectedMetadataFields[1].includes(field.metadataKey)}
                      onChange={() => {
                        selectMetadataField(field.metadataKey, 1);
                      }}
                      disabled={selectedMetadataFields[0].includes(field.metadataKey)}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div style={styles.footer}>
        <Button text='Cancel' type='secondary' onClick={close} containerStyle={styles.button} />
        <Button text='Apply' onClick={submit} containerStyle={styles.button} />
      </div>
    </div>
  );
}

WallMetadataModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  activeEvent: PropTypes.shape({
    erpFormFields: PropTypes.arrayOf(
      PropTypes.shape({
        metadataKey: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export default WallMetadataModal;
