import React, { PureComponent } from 'react';
import swal from 'sweetalert';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getLayoutsRequest, setUpdatedLayoutConfig, getLayoutById, setLayoutAreas, setEvent, updateLayoutRequest, clearLayout } from '../../redux/layoutsRedux';
import { getAssetsRequest } from '../../redux/assetsRedux';
import { sendUpdate } from '../../redux/uiRedux';
import { getEventsRequest, setLiveLayout } from '../../redux/eventsRedux';
import { selectLayout } from '../../redux/wallRedux';
import Button from '../../common/Button';
import LayoutGrid from './LayoutGrid';
import LayoutList from './LayoutList';
import LayoutConfiguration from './config/LayoutConfiguration';
import layoutsIcon from '../../assets/images/navbar/layouts.svg';
import SocketClient from '../../utils/socket-client';
import AreaSelectionEditor from './AreaSelectionEditor';

class Layouts extends PureComponent {
  componentDidMount() {
    const {
      activeStudio,
      activeEvent,
      setEvent,
      getLayoutsRequest,
      getAssetsRequest,
      sendUpdate,
      setLiveLayout,
      getEventsRequest,
      selectLayout,
      setUpdatedLayoutConfig,
    } = this.props;

    const _checkZoomLevel = (showAlert = true) => {
      const zoomLevel = window.outerWidth / window.innerWidth;
      if (!this._unmounted && showAlert && zoomLevel !== 1) {
        swal({
          title: 'Warning',
          text: 'Please do not build layouts while your browser has Zoom enabled',
        });
      }
      window.matchMedia(`(resolution: ${window.devicePixelRatio}dppx)`).addEventListener('change', _checkZoomLevel, { once: true });
    };
    _checkZoomLevel();

    if (activeEvent && activeStudio) {
      setEvent(activeEvent);
      getLayoutsRequest(activeStudio._id, activeEvent._id);
      getAssetsRequest(activeEvent._id);
      SocketClient.joinRoom(`${activeStudio._id}:${activeEvent._id}:layout-config`);

      SocketClient.on('layout-config-update', ({ layoutConfig }) => {
        setUpdatedLayoutConfig(layoutConfig);
      });

      this._onAdminAppSync = (data) => {
        const { type } = data;
        const { activatedLayout } = data.payload || {};
        if (type === 'activateLayout') {
          setLiveLayout(activatedLayout);
          selectLayout(activatedLayout);
        } else if (type === 'start' || type === 'stop') {
          getEventsRequest(activeStudio._id);
        } else {
          sendUpdate(data);
        }
      };
      SocketClient.on('admin-app-sync', this._onAdminAppSync);
    }
  }

  async componentWillUnmount() {
    const { activeEvent, activeStudio } = this.props;
    if (activeEvent && activeStudio) {
      SocketClient.leaveRoom(`${activeStudio._id}:${activeEvent._id}:layout-config`);
    }
    SocketClient.removeAllListeners();
    SocketClient.off('admin-app-sync', this._onAdminAppSync);
    this._unmounted = true;
  }

  _fillWallWithParticipants = () => {
    const { areas, setLayoutAreas } = this.props;
    areas.forEach((area, index) => {
      if (!area.sourceType) {
        area.sourceType = 'WallParticipant';
        area.data = {};
        area.data.areaIndex = index;
      }
    });
    setLayoutAreas(areas);
  };

  _onClear = () => {
    const { current, activeStudio, clearLayout } = this.props;
    const { rows, columns } = activeStudio;
    clearLayout(current, rows, columns);
  };

  render() {
    const { participantCount, currentLayoutData, eventSelected, routesEnabled, selectedArea, fetching, newHashCode } = this.props;

    if (!routesEnabled) return <Redirect to='/app/studios' />;
    if (!eventSelected) return <Redirect to='/app/events' />;

    const editEnabled = currentLayoutData && currentLayoutData.studioWall && currentLayoutData.__v > 0 ? false : true;
    const enableSave = currentLayoutData && newHashCode && currentLayoutData.hashCode !== newHashCode.toString() ? true : false;

    return (
      <>
        <div className='layout-editor'>
          <div className='layout-selected'>
            {currentLayoutData && (
              <div className='name'>
                <img src={layoutsIcon} alt='current layout' />
                <span>{currentLayoutData.name}</span>
              </div>
            )}
          </div>
          <div className='layout-participants'>
            {currentLayoutData && editEnabled && (
              <>
                <div className='participant-count'>{`${participantCount} Participants on Layout`}</div>
                <Button
                  type='link'
                  text='Fill with Participants'
                  onClick={this._fillWallWithParticipants}
                  containerStyle={{ margin: 0, padding: '7px 0', paddingLeft: 30 }}
                  disabled={fetching || selectedArea}
                />
                <Button type='danger' text='Clear' onClick={this._onClear} containerStyle={{ margin: 0, marginLeft: 30 }} />
              </>
            )}
          </div>

          <div className='layout-content'>
            {!editEnabled && <div style={{ position: 'absolute', width: '100%', height: '100%', top: 0, zIndex: 200 }} />}
            <LayoutGrid />
          </div>
          {!selectedArea ? (
            <>
              <div className='layout-list-container'>
                <LayoutList enableSave={enableSave} />
              </div>

              <div className='layout-configuration-container'>{currentLayoutData && editEnabled && <LayoutConfiguration enableSave={enableSave} />}</div>
            </>
          ) : (
            <AreaSelectionEditor />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  routesEnabled: state.ui.routesEnabled,
  eventSelected: state.ui.eventSelected,
  participantCount: state.layouts.participantCount,
  fetching: state.layouts.fetching,
  areas: state.layouts.areas,
  activeEvent: state.events.active,
  liveLayout: state.events.active ? state.events.active.liveLayout : null,
  activeStudio: state.studio.active,
  current: state.layouts.current,
  currentLayoutData: getLayoutById(state, state.layouts.current),
  selectedArea: state.layouts.selectedArea,
  newHashCode: state.layouts.newHashCode,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getLayoutsRequest,
      setLayoutAreas,
      setEvent,
      getAssetsRequest,
      sendUpdate,
      setLiveLayout,
      getEventsRequest,
      updateLayoutRequest,
      selectLayout,
      setUpdatedLayoutConfig,
      clearLayout,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Layouts);
