import { createActions, handleActions } from 'redux-actions';

const initialState = {
  entities: {},
  list: [],
  fetching: false,
  error: null,
  deleted: null,
};

export const getAssetById = (state, id) => {
  const { entities } = state.assets;
  if (id) {
    return entities[id];
  }
  return {};
};

export const getPopulatedList = (state) => {
  const { list, entities } = state.assets;
  if (list.length) {
    return list.map((id) => entities[id]);
  }
  return [];
};

export const actions = {
  GET_ASSETS_REQUEST: 'GET_ASSETS_REQUEST',
  GET_ASSETS_SUCCESS: 'GET_ASSETS_SUCCESS',
  GET_ASSETS_FAILURE: 'GET_ASSETS_FAILURE',
  CREATE_ASSET_REQUEST: 'CREATE_ASSET_REQUEST',
  CREATE_ASSET_SUCCESS: 'CREATE_ASSET_SUCCESS',
  CREATE_ASSET_FAILURE: 'CREATE_ASSET_FAILURE',
  DELETE_ASSET_REQUEST: 'DELETE_ASSET_REQUEST',
  DELETE_ASSET_SUCCESS: 'DELETE_ASSET_SUCCESS',
  DELETE_ASSET_FAILURE: 'DELETE_ASSET_FAILURE',
  UPDATE_ASSET_REQUEST: 'UPDATE_ASSET_REQUEST',
  UPDATE_ASSET_SUCCESS: 'UPDATE_ASSET_SUCCESS',
  UPDATE_ASSET_FAILURE: 'UPDATE_ASSET_FAILURE',
  CLEAR_ASSETS: 'CLEAR_ASSETS',
};

export const {
  getAssetsRequest,
  getAssetsSuccess,
  getAssetsFailure,
  createAssetRequest,
  createAssetSuccess,
  createAssetFailure,
  deleteAssetRequest,
  deleteAssetSuccess,
  deleteAssetFailure,
  updateAssetRequest,
  updateAssetSuccess,
  updateAssetFailure,
  clearAssets,
} = createActions({
  [actions.GET_ASSETS_REQUEST]: (eventId) => ({ eventId }),
  [actions.GET_ASSETS_SUCCESS]: (data) => ({ data }),
  [actions.GET_ASSETS_FAILURE]: (error) => ({ error }),
  [actions.CREATE_ASSET_REQUEST]: (event, assets) => ({ event, assets }),
  [actions.CREATE_ASSET_SUCCESS]: (data) => ({ data }),
  [actions.CREATE_ASSET_FAILURE]: (error) => ({ error }),
  [actions.DELETE_ASSET_REQUEST]: (id) => ({ id }),
  [actions.DELETE_ASSET_SUCCESS]: (data) => ({ data }),
  [actions.DELETE_ASSET_FAILURE]: (error) => ({ error }),
  [actions.UPDATE_ASSET_REQUEST]: (id, asset) => ({ id, asset }),
  [actions.UPDATE_ASSET_SUCCESS]: (data) => ({ data }),
  [actions.UPDATE_ASSET_FAILURE]: (error) => ({ error }),
  [actions.CLEAR_ASSETS]: () => ({}),
});

const reducer = handleActions(
  {
    [actions.GET_ASSETS_REQUEST]: (state) => ({
      ...state,
      fetching: true,
      error: null,
    }),
    [actions.GET_ASSETS_SUCCESS]: (state, action) => {
      const { data } = action.payload;
      const list = data.map((asset) => asset._id);
      const entities = {};
      list.forEach((id) => {
        const a = data.find((asset) => asset._id === id);
        entities[id] = { ...a };
      });
      return {
        ...state,
        entities,
        list,
        fetching: false,
        error: null,
      };
    },
    [actions.GET_ASSETS_FAILURE]: (state, action) => ({
      ...state,
      fetching: false,
      error: action.payload.error,
    }),
    [actions.CREATE_ASSET_REQUEST]: (state) => ({
      ...state,
      fetching: true,
      error: null,
    }),
    [actions.CREATE_ASSET_SUCCESS]: (state, action) => ({
      ...state,
      list: [...state.list, action.payload.data._id],
      entities: { ...state.entities, [action.payload.data._id]: action.payload.data },
      fetching: false,
      error: null,
    }),
    [actions.CREATE_ASSET_FAILURE]: (state, action) => ({
      ...state,
      fetching: false,
      error: action.payload.error,
    }),
    [actions.DELETE_ASSET_REQUEST]: (state) => ({
      ...state,
      fetching: true,
      error: null,
      deleted: null,
    }),
    [actions.DELETE_ASSET_SUCCESS]: (state, action) => {
      const deletedId = action.payload.data;
      const { entities, list } = state;
      delete entities[deletedId];
      list.splice(list.indexOf(deletedId), 1);
      return {
        ...state,
        entities: { ...entities },
        list: [...list],
        fetching: false,
        error: null,
        deleted: true,
      };
    },
    [actions.DELETE_ASSET_FAILURE]: (state, action) => ({
      ...state,
      fetching: false,
      error: action.payload.error,
      deleted: false,
    }),
    [actions.UPDATE_ASSET_REQUEST]: (state) => ({
      ...state,
      fetching: true,
      error: null,
    }),
    [actions.UPDATE_ASSET_SUCCESS]: (state, action) => ({
      ...state,
      entities: { ...state.entities, [action.payload.data._id]: action.payload.data },
      fetching: false,
      error: null,
    }),
    [actions.UPDATE_ASSET_FAILURE]: (state, action) => ({
      ...state,
      fetching: false,
      error: action.payload.error,
    }),
    [actions.CLEAR_ASSETS]: (state) => ({
      ...state,
      entities: {},
      list: [],
    }),
  },
  initialState,
);

export default reducer;
