import React, { useState } from 'react';
import { connect } from 'react-redux';
import ContextMenu from '../../common/ContextMenu';
import Draggable from '../../common/Draggable';

const ParticipantListItem = (props) => {
  const { item, room, enableContextMenu, contextMenuItemSelectionHandler, contextMenuItems, maxRooms } = props;

  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });

  const onContextMenuHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (enableContextMenu) {
      setContextMenuPosition({ x: e.pageX, y: e.pageY });
      setShowContextMenu(true);
    }
  };

  const hideContextMenu = () => {
    setContextMenuPosition({ x: 0, y: 0 });
    setShowContextMenu(false);
  };

  const name = `${item.participant.firstName} ${item.participant.lastName || ''}`;

  return (
    <Draggable dataTransfer={{ ...item }}>
      <div className='participant-list-item' onContextMenu={onContextMenuHandler}>
        <span className='name' title={name}>
          {name}
        </span>
        <span>{room ? (room <= maxRooms ? room : `TS${room - maxRooms}`) : null}</span>
        {showContextMenu === true ? (
          <ContextMenu position={contextMenuPosition} items={contextMenuItems} onClose={hideContextMenu} onItemSelected={contextMenuItemSelectionHandler} />
        ) : null}
      </div>
    </Draggable>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { participants } = state.breakoutRooms;
  const { _id } = ownProps.item.participant;
  const room = participants[_id] ? participants[_id].room : undefined;
  return { room };
};

export default connect(mapStateToProps, null)(ParticipantListItem);
