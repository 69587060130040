import React, { PureComponent } from 'react';
import swal from 'sweetalert';
import isValidFileType from '../../utils/validate-file-type';

class FileDroppable extends PureComponent {
  counter = 0;

  _onDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.counter += 1;
    event.target.classList.add('active');
  };

  _onDragOver = (event) => {
    event.persist();
    event.preventDefault();
    event.stopPropagation();
  };

  _onDragLeave = (event) => {
    this.counter -= 1;
    if (this.counter === 0) {
      event.target.classList.remove('active');
    }
  };

  _onDrop = async (event) => {
    event.persist();
    event.preventDefault();
    event.stopPropagation();
    this.counter = 0;
    event.target.classList.remove('active');
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      let hasInvalidFileTypes = false;
      const validFiles = [];

      Array.from(event.dataTransfer.files).forEach((file) => {
        if (isValidFileType(file)) {
          validFiles.push(file);
        } else {
          hasInvalidFileTypes = true;
        }
      });

      if (!hasInvalidFileTypes) {
        try {
          const { onFilesDropped } = this.props;
          onFilesDropped && onFilesDropped(validFiles);
          event.dataTransfer && event.dataTransfer.clearData();
        } catch (error) {
          swal({
            title: 'Error',
            text: error.message,
          });
        }
      } else {
        swal({
          title: 'Error',
          text: 'Invalid file type',
        });
      }
    }
  };

  render() {
    return (
      <div
        className='file-uploader-dropzone'
        onDragEnter={this._onDragEnter}
        onDragOver={this._onDragOver}
        onDragLeave={this._onDragLeave}
        onDrop={this._onDrop}
      >
        <div style={{ pointerEvents: 'none' }}>Drop your files here</div>
        <div style={{ pointerEvents: 'none' }}>to upload assets</div>
      </div>
    );
  }
}

export default FileDroppable;
