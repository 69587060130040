import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import S3FileUploader from './S3FileUploader';
import { updateEventRequest } from '../../../redux/eventsRedux';
import { post, get } from '../../../services/api';
import StringUtil from '../../../utils/string-utils';
import Button from '../../../common/Button';
import CopyToClipboard from '../../../common/CopyToClipboard';
import '../../../css/Button.css';

const { REACT_APP_API_BASE_URL, REACT_APP_PANDO_CLIENT_HOST } = process.env;

const styles = {
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  sectionHeader: {
    paddingBottom: 0,
    paddingTop: 0,
    marginTop: 30,
    marginBottom: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formValue: {
    fontSize: 12,
    fontFamily: 'inherit',
    display: 'flex',
    flexDirection: 'row',
  },
};

export default function ArchiveDetails({ event }) {
  const _timer = useRef({});
  const dispatch = useDispatch();
  const activeStudio = useSelector((state) => state.studio.active);

  const [mediaConvertData, setMediaConvertData] = useState(null);

  const _fetchMediaConvertJob = async (jobId) => {
    try {
      const { data } = await get(`/aws/mediaConvert/jobs/${jobId}`);

      setMediaConvertData(data.Job);
      if (data.Job.Status === 'COMPLETE') {
        clearInterval(_timer.current);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const _createMediaConvertJob = async (fileKey, event) => {
    try {
      const response = await post('/aws/mediaConvert/createJob', {
        fileInput: fileKey,
        destination: `broadcasts/${event._id}`,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const { archivedMedia } = event;
    if (archivedMedia && archivedMedia.jobId && !archivedMedia.jobCompleted) {
      const { jobId } = archivedMedia;
      _timer.current = setInterval(() => {
        _fetchMediaConvertJob(jobId);
      }, 10000);

      _fetchMediaConvertJob(jobId);
    }

    return () => {
      if (_timer.current) {
        clearInterval(_timer.current);
      }
    };
  }, []);

  const _onUploadSuccess = async ({ fileKey, fileName, size, url }) => {
    try {
      const data = await _createMediaConvertJob(fileKey, event);
      _fetchMediaConvertJob(data.Job.Id);

      dispatch(
        updateEventRequest(event._id, {
          archivedMedia: {
            fileName,
            fileSize: size,
            s3FileKey: fileKey,
            uploadedAt: new Date(),
            jobId: data.Job.Id,
            jobCompleted: false,
            jobCreatedAt: new Date(),
            url: url.replace('.mp4', '.m3u8'),
          },
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };

  const _retry = async () => {
    const data = await _createMediaConvertJob(event.archivedMedia.s3FileKey, event);
    _fetchMediaConvertJob(data.Job.Id);

    dispatch(
      updateEventRequest(event._id, {
        archivedMedia: {
          ...event.archivedMedia,
          jobId: data.Job.Id,
          jobCreatedAt: new Date(),
        },
      }),
    );
  };

  const _downloadReport = () => {
    const filename = `Broadcast Archive Report - ${event.name}.xlsx`;
    axios({
      url: `${REACT_APP_API_BASE_URL}/report/downloadArchiveReport`,
      method: 'POST',
      responseType: 'blob',
      data: {
        filename,
        eventId: event._id,
      },
    }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    });
  };

  if (!event || !activeStudio) {
    return null;
  }

  let jobStatusColor = '#a9aaab';
  if (mediaConvertData && mediaConvertData.Status === 'ERROR') {
    jobStatusColor = '#b71330';
  }

  let archiveAccessUrl = `https://${REACT_APP_PANDO_CLIENT_HOST}/archive-player?ShowName=${encodeURIComponent(event.name)}&studioId=${activeStudio._id}`;

  return (
    <>
      <div className='modal-form-header' style={styles.sectionHeader}>
        <h4>Archived Media</h4>
        <Button
          text='Download Report'
          type='primary'
          onClick={_downloadReport}
          containerStyle={{
            fontSize: 14,
            padding: '3px 15px',
            margin: 0,
          }}
        />
      </div>
      {event.archivedMedia && (
        <>
          <div className='form-control' style={styles.flexRow}>
            <div className='form-label'>File Name</div>
            <div className='form-value' style={styles.formValue}>
              {event.archivedMedia.fileName}
            </div>
          </div>
          <div className='form-control' style={styles.flexRow}>
            <div className='form-label'>Size</div>
            <div className='form-value' style={styles.formValue}>
              {StringUtil.formatFileSize(event.archivedMedia.fileSize)}
            </div>
          </div>
          <div className='form-control' style={styles.flexRow}>
            <div className='form-label'>Uploaded At</div>
            <div className='form-value' style={styles.formValue}>
              {moment(event.archivedMedia.uploadedAt).format('MM/DD/YYYY hh:mm:ss A')}
            </div>
          </div>
          {mediaConvertData && (
            <div>
              <div className='form-control' style={styles.flexRow}>
                <div className='form-label'>MediaConvert Job Id</div>
                <div className='form-value' style={styles.formValue}>
                  {mediaConvertData.Id}
                </div>
              </div>
              <div className='form-control' style={styles.flexRow}>
                <div className='form-label'>Job Created At</div>
                <div className='form-value' style={styles.formValue}>
                  {moment(mediaConvertData.CreatedAt).format('MM/DD/YYYY hh:mm:ss A')}
                </div>
              </div>
              <div className='form-control' style={styles.flexRow}>
                <div className='form-label'>Job Status</div>
                <div className='form-value' style={{ ...styles.formValue, color: jobStatusColor }}>
                  {mediaConvertData.Status}
                </div>
              </div>
              {mediaConvertData.Status === 'ERROR' && (
                <>
                  <div className='form-control' style={styles.flexRow}>
                    <div className='form-label' />
                    <div className='form-value' style={{ ...styles.formValue, color: '#b71330' }}>
                      {mediaConvertData.ErrorMessage}
                      <div>
                        <Button
                          text='Retry'
                          type='secondary'
                          onClick={_retry}
                          containerStyle={{
                            padding: '0 12px',
                            fontSize: 13,
                            margin: 0,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
          <div className='form-control' style={styles.flexRow}>
            <div className='form-label'>Access URL</div>
            <div className='form-value' style={{ ...styles.formValue, maxWidth: 450 }}>
              {archiveAccessUrl}
              <CopyToClipboard text={archiveAccessUrl} />
            </div>
          </div>
        </>
      )}

      <S3FileUploader s3Folder={`broadcasts/${event._id}`} onUploadSuccess={_onUploadSuccess} />
      <div style={{ marginBottom: 30 }} />
    </>
  );
}
