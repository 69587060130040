import React, { forwardRef, useState, useEffect, useRef } from 'react';
import Popover from 'react-popover';
import { faMicrophone, faMicrophoneSlash, faCog, faPhotoVideo } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../common/IconButton';
import Spinner from '../../common/Spinner';
import MediaDevices from './MediaDevices';
import { SelfieSegmentation } from '@mediapipe/selfie_segmentation';
import pandoBgImage from '../../assets/images/pando-background.png';
import inceptionbgImage from '../../assets/images/inception-background.png';

function LocalVideo(props, { videoRef, canvasRef }) {
  const _timer = useRef(null);
  const _bgImage = useRef(null);

  const [showMediaDevices, setShowMediaDevices] = useState(false);
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [blurEnabled, setBlurEnabled] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      _bgImage.current = img;
    };
    img.src = pandoBgImage;

    const _onLoadedData = () => {
      canvasRef.current.width = videoRef.current.videoWidth;
      canvasRef.current.height = videoRef.current.videoHeight;
      const ctx = canvasRef.current.getContext('2d');
      (function loop() {
        ctx.drawImage(videoRef.current, 0, 0);
        _timer.current = setTimeout(loop, 1000 / 15); // drawing at 30fps
      })();
    };
    videoRef.current.addEventListener('loadeddata', _onLoadedData);

    return () => {
      if (_timer.current) {
        clearTimeout(_timer.current);
      }
      videoRef.current.removeEventListener('loadeddata', _onLoadedData);
    };
  }, []);

  useEffect(() => {
    props.onToggleAudio && props.onToggleAudio(audioEnabled);
  }, [audioEnabled]);

  function onToggleSettings() {
    setShowMediaDevices(!showMediaDevices);
  }

  function onToggleAudio() {
    setAudioEnabled(!audioEnabled);
  }

  function onOuterAction() {
    setShowMediaDevices(false);
  }

  const _toggleBlur = async () => {
    let timerId;
    if (!blurEnabled) {
      clearTimeout(_timer.current);

      function onResults(results) {
        const canvasElement = canvasRef.current;
        const canvasCtx = canvasRef.current.getContext('2d');
        canvasCtx.save();
        canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
        canvasCtx.drawImage(results.segmentationMask, 0, 0, canvasElement.width, canvasElement.height);

        // Only overwrite existing pixels.
        canvasCtx.globalCompositeOperation = 'source-out';
        canvasCtx.drawImage(_bgImage.current, 0, 0, canvasElement.width, canvasElement.height);

        // Only overwrite missing pixels.
        canvasCtx.globalCompositeOperation = 'destination-atop';
        canvasCtx.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height);

        canvasCtx.restore();
      }

      const video = videoRef.current;
      window._cancelReqAnimFrame = false;

      const selfieSegmentation = new SelfieSegmentation({
        locateFile: (file) => {
          return `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`;
        },
      });
      selfieSegmentation.setOptions({
        modelSelection: 1,
      });
      selfieSegmentation.onResults(onResults);

      const _loop = async () => {
        await selfieSegmentation.send({ image: video });
        // window._animReqFrameId = window.requestAnimationFrame(_loop);
        timerId = setTimeout(_loop, 1000 / 15);
        if (window._cancelReqAnimFrame) {
          // cancelAnimationFrame(window._animReqFrameId);
          clearTimeout(timerId);
        }
      };

      _loop();
    } else {
      window._cancelReqAnimFrame = true;
      const ctx = canvasRef.current.getContext('2d');
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      canvasRef.current.width = videoRef.current.videoWidth;
      canvasRef.current.height = videoRef.current.videoHeight;
      (function loop() {
        ctx.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
        _timer.current = setTimeout(loop, 1000 / 15); // drawing at 30fps
      })();
    }
    setBlurEnabled(!blurEnabled);
  };

  const hiddenStyle = {
    pointerEvents: props.hide ? 'none' : 'auto',
    opacity: props.hide ? 0 : 1,
  };

  return (
    <div className='local-media' style={hiddenStyle}>
      <video id='local-video' ref={videoRef} autoPlay muted width={250} height={141} style={{ opacity: 0 }} />
      <canvas id='local-video-canvas' ref={canvasRef} width={250} height={141} style={{ maxWidth: 250, maxHeight: 141, position: 'absolute', top: 0 }}></canvas>
      <div className='controls'>
        <IconButton
          onIcon={faPhotoVideo}
          offIcon={faPhotoVideo}
          value={blurEnabled}
          onChange={_toggleBlur}
          tooltip={blurEnabled ? 'Disable Blur' : 'Enable Blur'}
        />
        <IconButton onIcon={faMicrophone} offIcon={faMicrophoneSlash} value={audioEnabled} onChange={onToggleAudio} tooltip='Mic' size={12} />
        <Popover
          isOpen={showMediaDevices}
          body={<MediaDevices onDeviceChange={props.onChangeDevices} />}
          onOuterAction={onOuterAction}
          tipSize={6}
          place='below'
          style={{ fill: '#1e272f', zIndex: 99999 }}
        >
          <IconButton onIcon={faCog} offIcon={faCog} value={showMediaDevices} onChange={onToggleSettings} tooltip='Device Settings' size={12} />
        </Popover>
      </div>
    </div>
  );
}

export default forwardRef(LocalVideo);
