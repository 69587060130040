import { createActions, handleActions } from 'redux-actions';

const initialState = {
  status: 'waiting',
  progress: {},
  files: [],
  totalSize: 0,
  totalFiles: 0,
  responses: {},
};

export const actions = {
  SET_PROGRESS: 'SET_PROGRESS',
  UPLOAD_REQUEST: 'UPLOAD_FILES_REQUEST',
  UPLOAD_SUCCESS: 'UPLOAD_FILES_SUCCESS',
  UPLOAD_FAILURE: 'UPLOAD_FILES_FAILURE',
  ADD_FILE: 'ADD_FILE',
  START: 'START_UPLOAD',
  END: 'END_UPLOAD',
  RESET_UPLOADER: 'RESET_UPLOADER',
};

export const { setProgress, uploadFilesRequest, uploadFilesSuccess, uploadFilesFailure, addFile, startUpload, endUpload, resetUploader } = createActions({
  [actions.SET_PROGRESS]: data => ({ data }),
  [actions.UPLOAD_REQUEST]: (files, name, event, fileKey) => ({ files, name, event, fileKey }),
  [actions.UPLOAD_SUCCESS]: (data, index) => ({ data, index }),
  [actions.UPLOAD_FAILURE]: error => ({ error }),
  [actions.ADD_FILE]: file => ({ file }),
  [actions.START]: (size, files) => ({ size, files }),
  [actions.END]: () => ({}),
  [actions.RESET_UPLOADER]: () => ({}),
});

const reducer = handleActions(
  {
    [actions.RESET_UPLOADER]: state => ({
      ...state,
      ...initialState,
    }),
    [actions.SET_PROGRESS]: (state, action) => {
      const { data } = action.payload;
      return { ...state, progress: { ...state.progress, [data.index]: data.loaded } };
    },
    [actions.UPLOAD_REQUEST]: state => ({
      ...state,
      status: 'uploading',
    }),
    [actions.UPLOAD_SUCCESS]: (state, action) => ({
      ...state,
      responses: { ...state.responses, [action.payload.index]: action.payload.data },
    }),
    [actions.UPLOAD_FAILURE]: state => ({
      ...state,
      status: 'waiting',
    }),
    [actions.ADD_FILE]: (state, action) => ({
      ...state,
      files: action.payload.file,
    }),
    [actions.START]: (state, action) => ({
      ...state,
      totalFiles: action.payload.files,
      totalSize: action.payload.size,
    }),
    [actions.END]: state => ({
      ...state,
      status: 'completed',
    }),
  },
  initialState,
);

export default reducer;
