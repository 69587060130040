import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getEventParticipantsRequest, getEventParticipantsSuccess } from '../../redux/eventsRedux';
import SocketClient from '../../utils/socket-client';
import List from '../List';
import ParticipantListItem from './ParticipantListItem';
import SearchBox from '../SearchBox';
import { ON_WALL_ROLES } from '../../utils/user-roles';

const ParticipantList = ({
  activeStudio,
  activeEvent,
  getEventParticipantsRequest,
  getEventParticipantsSuccess,
  participants,
  allowedStatus,
  assignedParticipants,
  selectedParticipant,
  onItemSelect,
  onDragStart,
  onDragEnd,
  showClearResponseOption,
  hideEventSupportUsers,
}) => {
  const [filteredList, setFilteredList] = useState([]);
  const [searchString, setSearchString] = useState('');
  const _stateRef = useRef(null);

  // ComponentDidMount
  useEffect(() => {
    SocketClient.joinRoom(`${activeStudio._id}:${activeEvent._id}:participant-list`);
    SocketClient.on('participant-list-update', getEventParticipantsSuccess);
    getEventParticipantsRequest(activeEvent._id);
    // ComponentWillUnmount
    return () => {
      SocketClient.leaveRoom(`${activeStudio._id}:${activeEvent._id}:participant-list`);
    };
  }, []);

  useEffect(() => {
    _filterParticipants();
    _stateRef.current = { participants };
  }, [participants]);

  useEffect(() => {
    _filterParticipants();
  }, [allowedStatus]);

  useEffect(() => {
    _filterParticipants();
  }, [searchString]);

  useEffect(() => {
    _filterParticipants();
  }, [hideEventSupportUsers]);

  function _filterParticipants() {
    if (participants && allowedStatus) {
      setFilteredList(
        participants.filter((p) => {
          const isNotEventSupport = hideEventSupportUsers ? p.role !== 'Event Support' : true;
          const isAllowedStatus = allowedStatus.includes(p.status);
          const isAssigned = assignedParticipants ? p.participant && assignedParticipants.includes(p.wallDisplayNum) : false;
          let matchesSearch = true;
          if (searchString.length > 0) {
            const name = `${p.participant.firstName} ${p.participant.lastName}`.trim().toLowerCase();
            matchesSearch = name.includes(searchString.toLowerCase());
          }
          return isAllowedStatus && !isAssigned && matchesSearch && isNotEventSupport;
        }),
      );
    } else {
      setFilteredList(participants || []);
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: 400 }}>
      <div style={{ display: 'flex', flexDirection: 'row', margin: '10px 15px', alignItems: 'center' }}>
        <div style={{ color: '#939394', textAlign: 'left' }}>{`Participants (${filteredList.length})`}</div>
        <SearchBox value={searchString} onChange={({ target }) => setSearchString(target.value)} onClear={() => setSearchString('')} showIcon={true} />
      </div>
      <List
        data={filteredList}
        renderItem={(item) => (
          <ParticipantListItem
            item={item}
            status={item.status}
            active={selectedParticipant && selectedParticipant.participant._id === item.participant._id}
            onItemSelect={onItemSelect}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            showClearResponseOption={showClearResponseOption}
          />
        )}
        keyExtractor={(item) => `${item._id}`}
        containerStyle={{ flex: 1, position: 'relative' }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  let onWallParticipants = [];
  if (state.events.participants && state.events.participants.length > 0) {
    onWallParticipants = state.events.participants.filter((p) => ON_WALL_ROLES.includes(p.role));
  }
  return {
    activeStudio: state.studio.active,
    activeEvent: state.events.active,
    participants: onWallParticipants,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ getEventParticipantsRequest, getEventParticipantsSuccess }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantList);
