import React, { Component } from 'react';
import Button from '../../common/Button';
import TextInput from '../../common/TextInput';
import RadioButtons from '../../common/RadioButtons';
import swal from 'sweetalert';

const layoutTypes = [
  { value: 1, label: 'Template' },
  { value: 2, label: 'Event Layout' },
];

class LayoutForm extends Component {
  state = {
    name: '',
    type: layoutTypes[1],
  };

  componentDidMount() {
    const { model } = this.props;
    if (model) {
      this.setState({
        name: model.name,
        type: model.studioWall ? layoutTypes[0] : layoutTypes[1],
      });
    }
  }

  _onChangeLayoutType = (type) => {
    this.setState({ type: layoutTypes.find((t) => t.value === parseInt(type)) });
  };

  _onChangeHandler = async (event) => {
    event.stopPropagation();
    const { target } = event;
    this.setState({ [target.name]: target.value });
  };

  _onSubmitHandler = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const { onSubmit } = this.props;
    const { name, type } = this.state;
    if (name.length > 0 && type != null) {
      onSubmit && onSubmit({ name, type });
    } else {
      swal({
        text: 'Name and Layout type are required',
      });
    }
  };

  render() {
    const { edit } = this.props;
    const { name, type } = this.state;
    return (
      <div className='modal-form'>
        <div className='modal-form-header'>
          <h3>{edit ? 'Edit Layout' : 'Add Layout'}</h3>
        </div>
        <form autoComplete='off' noValidate onSubmit={this._onSubmitHandler}>
          <div className='modal-form-content'>
            <TextInput name='name' label='Name' onChange={this._onChangeHandler} value={name} />
            <RadioButtons label='Layout Type' onChange={this._onChangeLayoutType} options={layoutTypes} value={type && type.value} />
          </div>
          <div className='modal-form-footer'>
            <Button type='primary' submit text='Save' onClick={this._onSubmitHandler} />
          </div>
        </form>
      </div>
    );
  }
}

export default LayoutForm;
