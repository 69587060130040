import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { findEventParticipantByDisplayNum } from '../../utils/find-event-participant';

const ParticipantMetadata = ({ wallDisplayNum, eventParticipants }) => {
  const [metadata, setMetadata] = useState(null);
  useEffect(() => {
    if (eventParticipants) {
      const ep = findEventParticipantByDisplayNum(wallDisplayNum, ['ON_WALL', 'ON_AIR', 'IN_STUDIO_QUEUE']);
      if (ep) {
        setMetadata(ep);
      } else {
        setMetadata(null);
      }
    }
  }, [eventParticipants, wallDisplayNum]);

  return (
    metadata && (
      <div
        style={{
          display: 'flex',
          position: 'absolute',
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: 'rgba(0,0,0,0.5)',
          color: '#a9aaab',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: 12,
          height: 30,
          padding: 3,
        }}
      >
        <div style={{ margin: '0 10px', fontSize: 11 }}>
          <div>
            {`${metadata.wallDisplayNum ? `${metadata.wallDisplayNum}. ` : ''}${metadata.participant.firstName || ''} ${
              metadata.participant.lastName || ''
            }`.trim()}
          </div>
          <div>{metadata.participant.city}</div>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  eventParticipants: state.events.participants,
});

export default connect(mapStateToProps)(ParticipantMetadata);
