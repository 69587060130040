import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert';

import List from '../../common/List';
import { getUsersRequest, createUserRequest, deleteUserRequest, updateUserRequest, selectUser } from '../../redux/usersRedux';
import Modal from '../../common/Modal';
import addIcon from '../../assets/icons/add.svg';
import { post } from '../../services/api';

import UserForm from './UserForm';
import UserListItem from './UserListItem';
import UserDetail from './UserDetail';

import '../../css/Users.css';
import { isAdmin } from '../../redux/authRedux';

class Users extends PureComponent {
  state = {
    showModal: false,
  };

  componentDidMount() {
    const { getUsers } = this.props;
    getUsers();
  }

  componentDidUpdate(prevProps) {
    const { userUpdated } = this.props;
    if (prevProps.userUpdated !== userUpdated && userUpdated === true) {
      swal({
        text: 'User has been updated',
      });
    }
  }

  _openModal = () => {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  };

  _closeModal = () => {
    this.setState({
      showModal: false,
    });
  };

  _selectUser = async (user) => {
    const { selectUser } = this.props;
    await selectUser(null);
    selectUser(user);
  };

  _onDeleteHandler = async () => {
    const { deleteUser, selectedUser } = this.props;
    const willDelete = await swal({
      title: 'Warning',
      text: 'Are you sure you want to delete this user?',
      buttons: {
        cancel: 'No',
        confirm: 'Yes',
      },
      dangerMode: true,
    });
    if (willDelete) {
      deleteUser(selectedUser._id);
    }
  };

  _updateUser = (userData) => {
    const { updateUser, selectedUser } = this.props;
    if (selectedUser !== null) {
      if (userData.password === '') {
        delete userData.password;
      }
      updateUser(selectedUser._id, userData);
    }
  };

  _onSubmitHandler = (userData) => {
    const { createUser } = this.props;
    createUser(userData);
    this.setState({
      showModal: false,
    });
  };

  _onDisableMfa = async (userId) => {
    try {
      const { getUsers } = this.props;
      const { status } = await post('/auth/disableMFA', { userId });
      if (status === 200) {
        getUsers();
      }
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const { users, selectedUser, isAdmin } = this.props;
    const { showModal } = this.state;
    return (
      <div className='users-container'>
        <div className='users-content'>
          <div className='user-list'>
            <List
              data={users}
              renderItem={(item) => <UserListItem onSelect={this._selectUser} item={item} selected={selectedUser && selectedUser._id === item._id} />}
              containerStyle={{ height: 'calc(100% - 62px)', position: 'relative' }}
            />
            {isAdmin && (
              <div className='add-user' onClick={this._openModal}>
                <img src={addIcon} alt='add' style={{ marginLeft: 15 }} />
                Add User
              </div>
            )}
          </div>
          {selectedUser !== null ? (
            <UserDetail user={selectedUser} onSave={this._updateUser} onDelete={this._onDeleteHandler} onDisableMfa={this._onDisableMfa} />
          ) : null}
        </div>
        <Modal show={showModal} onClose={this._closeModal}>
          <UserForm onSubmit={this._onSubmitHandler} />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users.list,
  selectedUser: state.users.selectedUser,
  userCreated: state.users.userCreated,
  userUpdated: state.users.userUpdated,
  isAdmin: isAdmin(state),
});

const mapDispatchToProps = (dispatch) => ({
  getUsers: () => dispatch(getUsersRequest()),
  createUser: (user) => dispatch(createUserRequest(user)),
  deleteUser: (id) => dispatch(deleteUserRequest(id)),
  updateUser: (id, user) => dispatch(updateUserRequest(id, user)),
  selectUser: (user) => dispatch(selectUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
