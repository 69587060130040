class TimeUtil {
  static sleep(duration) {
    return new Promise((resolve) => {
      setTimeout(resolve, duration);
    });
  }

  static dateDifference = (startDate, endDate) => {
    let difference = endDate - startDate;

    const secondsInMilli = 1000;
    const minutesInMilli = secondsInMilli * 60;
    const hoursInMilli = minutesInMilli * 60;
    const daysInMilli = hoursInMilli * 24;

    const elapsedHours = (Math.floor(difference / hoursInMilli)).toString().padStart(2, 0);
    difference = difference % hoursInMilli;

    const elapsedMinutes = (Math.floor(difference / minutesInMilli)).toString().padStart(2, 0);
    difference = difference % minutesInMilli;

    const elapsedSeconds = (Math.floor(difference / secondsInMilli)).toString().padStart(2, 0);
    const dur = `${elapsedHours}:${elapsedMinutes}:${elapsedSeconds}`;
    return dur;
  }
}

export default TimeUtil;
