export default [
  {
    id: 'flex-container',
    label: '<b>Container</b>',
    attributes: { class: 'custom-container' },
    category: 'Custom',
    content: `<div class="custom-container"></div>`,
  },
  {
    id: 'section',
    label: '<b>Section</b>',
    attributes: { class: 'custom-block-section' },
    category: 'Custom',
    content: `<section class="custom-block-section">
      <h1>This is a simple title</h1>
      <img />
      <div>This is just a Lorem text: Lorem ipsum dolor sit amet</div>
    </section>`,
  },
];
