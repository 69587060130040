import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toggleNavBar } from '../../redux/uiRedux';
import NavBarButton from './NavBarButton';
import NavBarItem from './NavBarItem';
import './NavBar.css';

function NavBar({ collapsed, routesEnabled, liveRoutesEnabled, goLiveEnabled, eventSelected, routes, toggleNavBar, activeStudio, activeEvent }) {
  const _toggle = () => {
    localStorage.setItem('navBarCollapsed', !collapsed);
    toggleNavBar(!collapsed);
  };

  let isActiveEventLive = activeStudio && activeEvent && activeStudio.liveEvent && activeStudio.liveEvent === activeEvent._id;

  let navLinks = [];
  navLinks = routes
    .filter((r) => {
      if (!isActiveEventLive) {
        return r.navBar === true && !r.isLiveVersion;
      } else {
        return r.navBar === true && !['/app/layouts', '/app/polls', '/app/participants'].includes(r.link);
      }
    })
    .map(({ label, link, img, activeBorderColor, isLiveVersion }, index) => {
      let disabled = false;
      const liveRoutes = ['/app/wall', '/app/layouts-live', '/app/breakout-rooms', '/app/streams', '/app/polls-live', '/app/participants-live'];

      // selected event is not live
      if (!liveRoutesEnabled) {
        if (liveRoutes.includes(link)) {
          disabled = true;
        }
      }

      // studio is not configured
      if (!routesEnabled) {
        if (link !== '/app/studios') {
          disabled = true;
        }
      }

      // servers are not configured
      if (!goLiveEnabled) {
        if (link === '/app/goLive') {
          disabled = true;
        }
      }

      // studio or event is not selected
      if (!eventSelected) {
        if (
          link === '/app/layouts' ||
          link === '/app/polls' ||
          link === '/app/participants' ||
          link === '/app/broadcast/polls' ||
          link === '/app/broadcast/participants' ||
          link === '/app/broadcast/streams'
        ) {
          disabled = true;
        }
      }

      return (
        <NavBarItem
          key={`nav-bar-item-${index}`}
          label={label}
          link={link}
          icon={img}
          activeBorderColor={activeBorderColor}
          collapsed={collapsed}
          disabled={disabled}
          hide={
            ((link === '/app/layouts' || link === '/app/polls' || link === '/app/participants') && !isLiveVersion && liveRoutesEnabled) ||
            (isLiveVersion && !isActiveEventLive)
          }
        />
      );
    });

  return (
    <div className={collapsed ? 'navbar collapsed custom-scrollbar' : 'navbar custom-scrollbar'}>
      <NavBarButton collapsed={collapsed} onClick={_toggle} />
      {navLinks}
    </div>
  );
}

const mapStateToProps = (state) => ({
  collapsed: state.ui.navBarCollapsed,
  routesEnabled: state.ui.routesEnabled,
  liveRoutesEnabled: state.ui.liveRoutesEnabled,
  goLiveEnabled: state.ui.goLiveEnabled,
  eventSelected: state.ui.eventSelected,
  activeStudio: state.studio.active,
  activeEvent: state.events.active,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ toggleNavBar }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
