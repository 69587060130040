import React, { Component } from 'react';
import { connect } from 'react-redux';
import uuidV4 from 'uuid';
import {
  addMetadata,
  removeMetadata,
  getLayoutById,
  selectParticipant,
  assignParticipantRequest,
  removeParticipantFromWallRequest,
  updateEventParticipantRequest,
  removeMetadataInBulk,
} from '../../redux/wallRedux';
import { updateParticipantStatusRequest, updateEventRequest } from '../../redux/eventsRedux';
import { updateParticipantsInBulkRequest } from '../../redux/participantRedux';
import { updateLayoutRequest } from '../../redux/layoutsRedux';
import RTCStream from '../../utils/rtc-stream';
import SocketClient from '../../utils/socket-client';
import WallLayout from '../../common/WallLayout';
import { findEventParticipantByDisplayNum, findEventParticipantById } from '../../utils/find-event-participant';
import WallParticipant from './WallParticipant';
import { getParticipantExtraData } from '../../utils/participant-util';
import TvGridLine from '../layouts/TvGridLine';
import { post } from '../../services/api';
import '../../css/Grid.css';

class Wall extends Component {
  _videoRef = React.createRef();

  state = {
    maxHeight: 0,
    maxWidth: 0,
    selectedParticipants: [],
  };

  componentDidMount() {
    this._updateWallDimensions();
    // Sometime the video element takes time to render
    setTimeout(async () => {
      if (!this._unmounted) await this._getWallStream();
      if (!this._unmounted) this.props.setupTalkConnection();
    }, 250);
    window.addEventListener('resize', this._updateWallDimensions);
    window.addEventListener('devtoolschange', this._updateWallDimensions);
    window.onbeforeunload = this.componentWillUnmount.bind(this);
  }

  componentWillUnmount() {
    this._closeWallStream();
    window.removeEventListener('resize', this._updateWallDimensions);
    window.removeEventListener('devtoolschange', this._updateWallDimensions);
    window.onbeforeunload = () => {};
    this._unmounted = true;
  }

  componentDidUpdate(prevProps) {
    const { studioWall, collapsed } = this.props;

    if (prevProps.studioWall._id !== studioWall._id) {
      this.forceUpdate();
    }

    if (prevProps.collapsed !== collapsed) {
      setTimeout(() => {
        this._updateWallDimensions();
      }, 500);
    }
  }

  _getWallStream = async () => {
    try {
      const uuid = uuidV4();
      this.roomName = `${uuid}-admin-recv`;

      SocketClient.emit('get-wall-stream', { uuid });

      this.rtc = await RTCStream.get(this.roomName, this._videoRef.current);
    } catch (error) {
      console.log(error);
    }
  };

  _closeWallStream = () => {
    RTCStream.close(this);
  };

  _refreshBrowser = (ep) => {
    SocketClient.emit('refresh-browser', { uuid: ep.participant._id });
  };

  _getContextMenuItemSelectionHandler = (item) => async (option) => {
    const {
      selectedLayout,
      updateEventParticipant,
      removeParticipant,
      removeMetadata,
      selectParticipant,
      onAirParticipants,
      updateLayout,
      updateEvent,
      activeEvent,
      updateParticipantsInBulk,
      removeMetadataInBulk,
    } = this.props;
    const { _id } = item;

    const eventParticipant = findEventParticipantByDisplayNum(item.data.wallDisplayNum, ['ON_WALL', 'ON_AIR']);
    if (!option.disabled) {
      switch (option.value) {
        case 1:
        case 2:
        case 3:
        case 4:
          if (onAirParticipants[option.value]) {
            const ep = findEventParticipantById(onAirParticipants[option.value]);
            updateEventParticipant(ep._id, {
              status: 'ON_WALL',
              onAirNum: null,
            });
          }
          updateEventParticipant(eventParticipant._id, {
            status: 'ON_AIR',
            onAirNum: option.value,
          });
          break;
        case 5:
          if (this.state.selectedParticipants.length > 0) {
            const updateInBulkData = [];
            const metadata = [];
            const wallDisplayNums = [];

            for (let sp of this.state.selectedParticipants) {
              updateInBulkData.push({
                participant: sp.participant._id,
                wallDisplayNum: sp.wallDisplayNum,
                status: 'IN_STUDIO_QUEUE',
              });
              metadata.push({ area: sp.areaId, metadata: { wallDisplayNum: sp.wallDisplayNum } });
              wallDisplayNums.push(sp.wallDisplayNum);
            }

            removeMetadataInBulk(selectedLayout._id, metadata);
            updateParticipantsInBulk(updateInBulkData, activeEvent._id);

            const { areas } = selectedLayout;
            const payload = {
              areas: areas.map((area) => {
                const { sourceType, data } = area;
                if (sourceType === 'Participant' && data.wallDisplayNum && wallDisplayNums.includes(data.wallDisplayNum)) {
                  area.sourceType = 'WallParticipant';
                  area.data.wallDisplayNum = null;
                }
                return area;
              }),
            };
            updateLayout(selectedLayout._id, payload);

            this.setState({ selectedParticipants: [] });
          } else {
            removeParticipant(selectedLayout._id, { participantId: eventParticipant.participant._id, areaId: _id });
            removeMetadata(selectedLayout._id, _id);
            selectParticipant(null);
          }
          break;
        case 6:
          updateEventParticipant(eventParticipant._id, {
            status: 'ON_WALL',
            onAirNum: null,
          });
          break;
        case 7:
          const { areas } = selectedLayout;
          const payload = {
            areas: areas.map((area) => {
              const { sourceType, data } = area;
              if (sourceType === 'Participant' && data.wallDisplayNum && data.wallDisplayNum === item.data.wallDisplayNum) {
                area.sourceType = 'WallParticipant';
                area.data.wallDisplayNum = null;
              }
              return area;
            }),
          };
          updateLayout(selectedLayout._id, payload);
          break;
        case 8:
          const { pandoV1UNID } = eventParticipant.participant;
          if (pandoV1UNID) {
            updateEvent(activeEvent._id, {
              v1APIMessages: [
                { type: 'clearSingleAudienceResponse', value: pandoV1UNID.split('-')[0], showName: activeEvent.name, uuid: eventParticipant.participant._id },
              ],
            });
          }
          try {
            await post(`/eventParticipant/${eventParticipant._id}/clearResponse`, {});
          } catch (err) {
            console.log(err);
          }
          break;
        case 9:
          if (this.state.selectedParticipants.length > 0) {
            for (let sp of this.state.selectedParticipants) {
              this._refreshBrowser(sp);
            }
            this.setState({ selectedParticipants: [] });
          } else {
            this._refreshBrowser(eventParticipant);
          }
          break;
        default:
          break;
      }
    }
  };

  _getContextMenuItems = (participant) => {
    const { onAirParticipants } = this.props;
    let _contextMenuItems = [];

    if (this.state.selectedParticipants.length > 1) {
      return (_contextMenuItems = [
        {
          value: 5,
          label: 'Drop from Wall',
          danger: true,
        },
        {
          value: 9,
          label: 'Refresh',
        },
      ]);
    }

    const eventParticipant = findEventParticipantByDisplayNum(participant.data.wallDisplayNum, ['ON_AIR', 'ON_WALL']);
    if (eventParticipant) {
      _contextMenuItems = [
        {
          value: 1,
          label: 'On Air #1',
          danger: onAirParticipants[1] !== null,
          fixed: true,
        },
        {
          value: 2,
          label: 'On Air #2',
          danger: onAirParticipants[2] !== null,
          fixed: true,
        },
        {
          value: 3,
          label: 'On Air #3',
          danger: onAirParticipants[3] !== null,
          fixed: true,
        },
        {
          value: 4,
          label: 'On Air #4',
          danger: onAirParticipants[4] !== null,
          fixed: true,
        },
      ];
      if (eventParticipant.status === 'ON_AIR') {
        _contextMenuItems = [{ value: 6, label: 'Off Air', danger: true }];
      }
      _contextMenuItems.push(
        {
          value: 5,
          label: 'Drop from Wall',
          danger: true,
        },
        {
          value: 8,
          label: 'Clear Response',
          danger: true,
        },
        {
          value: 9,
          label: 'Refresh',
          danger: true,
        },
      );
    } else {
      _contextMenuItems.push({
        value: 7,
        label: 'Unassign',
        danger: true,
      });
    }
    return _contextMenuItems;
  };

  _onParticipantDrop = ({ participantId, areaId }) => {
    const { selectedLayout, removeMetadata, addMetadata, assignParticipant } = this.props;
    const ep = findEventParticipantById(participantId);
    const { areas } = selectedLayout;
    const prevArea = areas.find((area) => area.data && ep && area.data.wallDisplayNum === ep.wallDisplayNum);
    if (prevArea) {
      removeMetadata(selectedLayout._id, prevArea._id);
    }
    addMetadata(selectedLayout._id, areaId, ep);
    assignParticipant(selectedLayout._id, { participantId, areaId, oldAreaId: prevArea && prevArea._id });
  };

  _onSelectParticipant = async (event, eventParticipant) => {
    const { selectParticipant, selectedParticipant } = this.props;
    if (eventParticipant && eventParticipant._id) {
      try {
        const extraData = await getParticipantExtraData(eventParticipant._id);
        eventParticipant = { ...extraData, areaId: eventParticipant.areaId };

        if (event.metaKey || event.ctrlKey) {
          if (eventParticipant.status === 'ON_WALL') {
            let _selectedParticipants = [...this.state.selectedParticipants];
            if (selectedParticipant) {
              _selectedParticipants = [..._selectedParticipants, selectedParticipant];
              selectParticipant(null);
            }
            let index;
            if (eventParticipant.participant) {
              index = _selectedParticipants.findIndex((ep) => ep._id === eventParticipant._id);
            } else {
              index = _selectedParticipants.findIndex((ep) => ep.wallDisplayNum === eventParticipant.wallDisplayNum);
            }
            if (index === -1) {
              _selectedParticipants = [..._selectedParticipants, eventParticipant];
            } else {
              if (eventParticipant.participant) {
                _selectedParticipants = _selectedParticipants.filter((ep) => ep._id !== eventParticipant._id);
              } else {
                _selectedParticipants = _selectedParticipants.filter((ep) => ep.wallDisplayNum !== eventParticipant.wallDisplayNum);
              }
            }
            this.setState({ selectedParticipants: _selectedParticipants });
          }
        } else {
          this.setState({ selectedParticipants: [] });
          if (eventParticipant.participant) {
            selectParticipant(eventParticipant);
          }
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      selectParticipant(null);
      this.setState({ selectedParticipants: [] });
    }
  };

  _updateWallDimensions = () => {
    const { collapsed } = this.props;
    this.setState({
      maxHeight: window.innerHeight - 500,
      maxWidth: collapsed === true ? window.innerWidth - 68 - 120 : window.innerWidth - 200 - 120,
    });
  };

  _renderTVBreak = () => {
    const { activeStudio } = this.props;
    const { columns } = activeStudio;
    const { maxWidth, maxHeight } = this.state;

    if (maxWidth !== 0 && maxHeight !== 0) {
      const gridLines = [];

      const screenWidth = maxWidth / columns;

      for (let x = 0; x <= columns; x += 1) {
        if (x % 3 === 0 && x > 0 && x < columns) {
          const left = x * screenWidth;
          const color = '#287737';
          gridLines.push(<TvGridLine key={`vbLine-${x}`} direction='vertical' color={color} borderWidth={2} position={100 * (left / maxWidth)} />);
        }
      }
      return gridLines;
    }
    return null;
  };

  render() {
    const { studioWall, selectedLayout } = this.props;
    const { rows, columns } = studioWall;

    const { maxWidth, maxHeight, selectedParticipants } = this.state;

    return (
      <div className='wall-areas-container'>
        <div className='grid-container' style={{ position: 'relative' }}>
          <WallLayout rows={rows} columns={columns} maxHeight={maxHeight} maxWidth={maxWidth}>
            <div
              style={{
                position: 'absolute',
                boxSizing: 'border-box',
                bottom: 0,
                top: 0,
                left: 0,
                right: 0,
                width: '100%',
                height: '100%',
                zIndex: 100,
                overflow: 'hidden',
              }}
            >
              <div
                className='grid'
                style={{
                  width: '100%',
                  height: '100%',
                  gridTemplateColumns: `repeat(${columns * 6}, 1fr)`,
                  gridTemplateRows: `repeat(${rows * 2}, 1fr)`,
                }}
              >
                {selectedLayout &&
                  selectedLayout.areas.map((area, index) => {
                    const { sourceType, gridColumnStart, gridColumnEnd, gridRowStart, gridRowEnd } = area;
                    return (
                      <WallParticipant
                        key={area._id}
                        areaIndex={index + 1}
                        areaId={area._id}
                        style={{
                          gridColumnStart,
                          gridColumnEnd,
                          gridRowStart,
                          gridRowEnd,
                          height: 'auto',
                          position: 'relative',
                          zIndex: 9,
                        }}
                        sourceType={sourceType}
                        data={area.data}
                        enableContextMenu={area.sourceType === 'Participant' && area.data && area.data.wallDisplayNum}
                        contextMenuItems={this._getContextMenuItems(area)}
                        contextMenuItemSelectionHandler={this._getContextMenuItemSelectionHandler(area)}
                        onDrop={this._onParticipantDrop}
                        onSelectParticipant={this._onSelectParticipant}
                        selectedParticipants={selectedParticipants}
                      />
                    );
                  })}
                <div className='stream-container'>
                  <video id='remote-video' ref={this._videoRef} autoPlay muted />
                </div>
              </div>
              {this._renderTVBreak()}
            </div>
          </WallLayout>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  editedArea: state.wall.editedArea,
  selectedLayout: getLayoutById(state, ownProps.layout),
  collapsed: state.ui.navBarCollapsed,
  participants: state.events.participants,
  activeEvent: state.events.active,
  activeStudio: state.studio.active,
  selectedParticipant: state.wall.selectedParticipant,
});

const mapDispatchToProps = (dispatch) => ({
  addMetadata: (layout, area, metadata) => dispatch(addMetadata(layout, area, metadata)),
  removeMetadata: (layout, area) => dispatch(removeMetadata(layout, area)),
  updateParticipantStatus: (id, data) => dispatch(updateParticipantStatusRequest(id, data)),
  selectParticipant: (participant) => dispatch(selectParticipant(participant)),
  assignParticipant: (layoutId, data) => dispatch(assignParticipantRequest(layoutId, data)),
  removeParticipant: (layoutId, data) => dispatch(removeParticipantFromWallRequest(layoutId, data)),
  updateEventParticipant: (eventParticipantId, data) => dispatch(updateEventParticipantRequest(eventParticipantId, data)),
  updateLayout: (layoutId, data) => dispatch(updateLayoutRequest(layoutId, data)),
  updateEvent: (id, data) => dispatch(updateEventRequest(id, data)),
  updateParticipantsInBulk: (data, event, layout) => dispatch(updateParticipantsInBulkRequest(data, event, layout)),
  removeMetadataInBulk: (layout, data) => dispatch(removeMetadataInBulk(layout, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Wall);
