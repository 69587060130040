import React, { useEffect, useState, useRef } from 'react';

import Button from '../../../common/Button';
import { post, get } from '../../../services/api';
import CopyToClipboard from '../../../common/CopyToClipboard';

const styles = {
  buttonStyle: {
    fontSize: 14,
    padding: '5px 15px',
  },
  grantAccessInputContainer: {
    width: 400,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    background: '#141b21',
    border: '1px solid #f5f5f5',
    color: '#a9aaab',
    padding: 5,
  },
  grantAccessInput: {
    width: 376,
    display: 'inline-flex',
    flexWrap: 'wrap',
  },
  inputStyle: {
    border: 'none',
    outline: 'none',
    fontSize: 13,
    width: 'inherit',
    padding: 0,
    margin: '4px 0px 1px 2px',
    background: 'transparent',
    color: '#a9aaab',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 5,
    marginBottom: 5,
    fontSize: 13,
    backgroundColor: '#4e6379',
    color: '#f5f5f5',
    padding: '4px 0px 4px 6px',
  },
  button: {
    border: 'none',
    outline: 'none',
    background: 'transparent',
    color: '#f5f5f5',
    fontSize: 12,
    fontWeight: 900,
    cursor: 'pointer',
    position: 'relative',
    top: -1,
  },
  clearButton: {
    border: 'none',
    outline: 'none',
    background: 'transparent',
    color: '#a9aaab',
    fontSize: 20,
    fontWeight: 900,
    cursor: 'pointer',
  },
};

export default function GrantAccessModal({ studioId, eventId, onClose }) {
  const _inputRef = useRef(null);
  const [emailList, setEmailList] = useState([]);
  const [reportUrl, setReportUrl] = useState('');

  useEffect(() => {
    async function getAccessList() {
      try {
        const response = await get(`report/access/list?eventId=${eventId}`);
        setEmailList(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        _inputRef.current.focus();
      }
    }

    async function getShortUrl() {
      try {
        const urlParams = new URLSearchParams();
        urlParams.append('eventId', eventId);
        urlParams.append('studioId', studioId);
        const encodedParams = window.btoa(urlParams.toString());
        const { data } = await post('/shortUrl/shorten', { originalUri: encodedParams });
        setReportUrl(data);
      } catch (error) {
        console.error(error);
      }
    }

    getAccessList();
    getShortUrl();
  }, [eventId]);

  async function _grantAccess() {
    try {
      if (!emailList || emailList.length === 0) {
        return;
      }
      await post('report/access/grant', { eventId, emailAddresses: emailList });
    } catch (error) {
      console.error(error);
    } finally {
      onClose();
    }
  }

  function _addEmailAddress(e) {
    const { value } = e.target;
    if ((e.key === 'Enter' || e.key === ',') && value) {
      const emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      const values = value
        .split(',')
        .map((v) => v.trim().toLowerCase())
        .filter((v) => v !== '' && emailRegex.test(v) && !emailList.includes(v.toLowerCase()));

      setEmailList([...emailList, ...values]);
      _inputRef.current.value = '';
    }
  }

  return (
    <div className='grant-access-modal'>
      <h3>Report Access</h3>
      <div style={styles.grantAccessInputContainer}>
        <div className='grant-access-input' style={styles.grantAccessInput} onClick={() => _inputRef.current.focus()}>
          {emailList.map((email, i) => (
            <div key={`email-${i}`} style={styles.listItem}>
              <span style={{ marginRight: 2 }}>{email}</span>
              <button
                style={styles.button}
                onClick={(e) => {
                  e.stopPropagation();
                  setEmailList(emailList.filter((e) => e !== email));
                }}
              >
                x
              </button>
            </div>
          ))}
          <input ref={_inputRef} type='text' onKeyUp={_addEmailAddress} style={styles.inputStyle} />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <button
            style={styles.clearButton}
            onClick={(e) => {
              e.stopPropagation();
              setEmailList([]);
            }}
          >
            x
          </button>
        </div>
      </div>
      <div className='form-control' style={{ marginTop: 20, maxWidth: 400, wordBreak: 'break-all', alignSelf: 'flex-start' }}>
        <div className='form-value' style={{ fontSize: 12, fontFamily: 'inherit', display: 'flex', flexDirection: 'row', flex: 1 }}>
          {reportUrl}
          <CopyToClipboard text={reportUrl} />
        </div>
      </div>
      <div className='grant-access-modal-footer'>
        <Button text='Cancel' type='secondary' onClick={onClose} containerStyle={styles.buttonStyle} />
        <Button text='Save' onClick={_grantAccess} containerStyle={styles.buttonStyle} />
      </div>
    </div>
  );
}
