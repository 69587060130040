import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { updateParticipantStatusRequest } from '../../redux/eventsRedux';
import { getParticipantExtraData } from '../../utils/participant-util';
import { post } from '../../services/api';
import { VENUE_PARTICIPANT } from '../../utils/user-roles';

import OffWallList from './OffWallList';
import ScreeningArea from './ScreeningArea';

function VenueParticipants() {
  const activeEvent = useSelector((state) => state.events.active);
  const appMode = useSelector((state) => state.ui.appMode);
  const dispatch = useDispatch();

  const [venueParticipants, setVenueParticipants] = useState([]);
  const [selected, setSelected] = useState(null);
  const [selectedParticipants, setSelectedParticipants] = useState([]);

  useEffect(() => {
    if (!activeEvent) return;

    const fetchVenueParticipants = async () => {
      try {
        const response = await post('/eventParticipant/list', {
          event: activeEvent._id,
          roles: [VENUE_PARTICIPANT],
        });
        if (response.status === 200) {
          setVenueParticipants(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchVenueParticipants();
  }, [activeEvent]);

  const _selectParticipant = async (event, selectedParticipant) => {
    try {
      const index = selectedParticipants.findIndex((p) => p._id === selectedParticipant._id);
      if (event.metaKey || event.ctrlKey) {
        if (index === -1) {
          setSelected(null);
          setSelectedParticipants([...selectedParticipants, selectedParticipant]);
        } else {
          setSelected(null);
          setSelectedParticipants(selectedParticipants.filter((i) => i._id !== selectedParticipant._id));
        }
      } else {
        const extraData = await getParticipantExtraData(selectedParticipant._id);
        const sp = { ...selectedParticipant, ...extraData };
        setSelected(sp);
        setSelectedParticipants(selectedParticipants[index] && selectedParticipants[index]._id === selectedParticipant._id ? [] : [selectedParticipant]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const _archiveParticipant = () => {
    if (selected) {
      setSelected(null);
      dispatch(updateParticipantStatusRequest(selected._id, { status: 'ARCHIVED' }));
    }
  };

  const _removeParticipant = () => {
    if (selected) {
      setSelected(null);
      dispatch(updateParticipantStatusRequest(selected._id, { status: 'REMOVED' }));
    }
  };

  const connectedParticipants = venueParticipants.filter((p) => ['NOT_CONNECTED', 'ARCHIVED', 'REMOVED'].includes(p.status) === false);
  const archivedOrRemoved = venueParticipants.filter((p) => p.status === 'ARCHIVED' || p.status === 'REMOVED');

  return (
    <div className='content'>
      <div className='participants-queue'>
        <div className='list-header' style={{ padding: '0' }}>
          <div className='tab active'>{`Participants (${connectedParticipants.length}/${venueParticipants.length})`}</div>
        </div>
        <OffWallList
          items={connectedParticipants}
          onSelect={_selectParticipant}
          selectedParticipant={selected}
          selectedParticipants={selectedParticipants}
          onContextMenuAction={() => setSelectedParticipants([])}
          appMode={appMode}
        />
      </div>
      <div className='main'>
        <div className='media-container' style={{ borderBottom: '2px solid rgb(30, 39, 47)' }}>
          <ScreeningArea
            participant={selected}
            metadata={null}
            connected={false}
            onArchive={_archiveParticipant}
            onRemove={_removeParticipant}
            includeOffWall
            isAutomatedOnboardingEnabled={false}
          />
        </div>
      </div>
      <div className='connected-participants'>
        <div className='list-header' style={{ padding: '0' }}>
          <div className='tab active'>{`Archived/Removed (${archivedOrRemoved.length})`}</div>
        </div>
      </div>
    </div>
  );
}

export default VenueParticipants;
