const userRoles = [
  {
    label: 'Super Admin',
    value: 'SUPER_ADMIN',
  },
  {
    label: 'Admin',
    value: 'ADMIN',
  },
  {
    label: 'Tech Support',
    value: 'TECH_SUPPORT',
  },
  {
    label: 'Poll Support',
    value: 'POLL_SUPPORT',
  },
];

export default userRoles;
