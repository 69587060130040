import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import swal from '@sweetalert/with-react';
import {
  actions,
  createEventRequest,
  getEventsRequest,
  deleteEventRequest,
  duplicateEventRequest,
  updateEventRequest,
  selectEvent,
  setActiveEvent,
  removeEventParticipantsRequest,
  getEventParticipantsRequest,
  getEventRequest,
} from '../../redux/eventsRedux';
import { getAssetsRequest } from '../../redux/assetsRedux';
import { uploadFilesRequest, startUpload, addFile } from '../../redux/uploaderRedux';
import { updateRoutes } from '../../redux/uiRedux';
import { isAdmin } from '../../redux/authRedux';
import { loadTopBar } from '../../redux/uiRedux';
import { post } from '../../services/api';
import Button from '../../common/Button';
import List from '../../common/List';
import Modal from '../../common/Modal';
import addIcon from '../../assets/icons/add.svg';
import EventListItem from './EventListItem';
import EventForm from './forms/EventForm';
import SocketClient from '../../utils/socket-client';
import liveLabelImg from '../../assets/icons/live_label.png';
import Image from '../../common/Image';
import Spinner from '../../common/Spinner';
import EndMeetingAlertMessage from '../goLive/EndMeetingAlertMessage';

class EventList extends PureComponent {
  state = {
    openModal: false,
    eventData: undefined,
    formSubmitted: false,
    editing: false,
    currentTabIndex: 0,
    model: null,
    liveStreams: [],
  };

  _formRef = React.createRef();

  componentDidUpdate(prevProps) {
    const { status, responses, eventCreated, error, isLive, loadTopBar } = this.props;
    const { formSubmitted } = this.state;

    if (prevProps.isLive !== isLive) {
      loadTopBar();
    }

    if (prevProps.status !== status && status === 'completed' && formSubmitted) {
      this._addEvent(responses);
    } else if (prevProps.eventCreated !== eventCreated && eventCreated === true && formSubmitted) {
      this._uploadEventLogo();
    } else if (prevProps.error !== error && error && error.message) {
      swal({
        title: 'Error',
        text: error.message,
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this._timerId);
  }

  _onSetActiveHandler = (data) => {
    const { setActiveEvent, updateRoutes, getEventParticipants } = this.props;
    localStorage.removeItem('selectedLayout');
    localStorage.setItem('activeEvent', JSON.stringify(data));
    setActiveEvent(data);
    getEventParticipants(data._id);
    updateRoutes();
  };

  _onDuplicateHandler = (data) => {
    const { duplicateEvent, activeStudio } = this.props;
    duplicateEvent(data._id);
    SocketClient.emitAdminAppSync({
      type: actions.GET_EVENTS_REQUEST,
      payload: { studio: activeStudio._id },
    });
  };

  _onEditHandler = async (data) => {
    this.setState({ openModal: true, editing: true, model: data });
  };

  _onSelectHandler = async ({ _id }) => {
    const { getAssets, selectEvent, getEvent } = this.props;
    getEvent(_id);
    getAssets(_id);
    selectEvent(_id);
  };

  _onDeleteHandler = async (data) => {
    const willDelete = await swal({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete this Event?',
      buttons: true,
      dangerMode: true,
    });
    if (willDelete) {
      const { deleteEvent, activeStudio, activeEvent, setActiveEvent, updateRoutes } = this.props;
      deleteEvent(data._id);
      if (activeEvent && activeEvent._id === data._id) {
        localStorage.removeItem('selectedEvent');
        localStorage.removeItem('selectedLayout');
        localStorage.removeItem('activeEvent');
        setActiveEvent(undefined);
        updateRoutes();
      }
      SocketClient.emitAdminAppSync({
        type: actions.GET_EVENTS_REQUEST,
        payload: { studio: activeStudio._id },
      });
    }
  };

  _uploadEventLogo = () => {
    const { uploadFiles, newEventId, activeStudio } = this.props;
    const { eventData } = this.state;
    if (eventData.logoFile) {
      uploadFiles([eventData.logoFile], null, newEventId, undefined);
    } else {
      SocketClient.emitAdminAppSync({
        type: actions.GET_EVENTS_REQUEST,
        payload: { studio: activeStudio._id },
      });
    }
  };

  _onSubmitHandler = (data) => {
    const { model } = this.state;
    const { appMode, uploadFiles, createEvent, updateEvent, activeStudio, updateRoutes } = this.props;
    const {
      name,
      startTime,
      endTime,
      logoFile,
      logoFilename,
      allowEventSupportChatForPresenters,
      textChatForwardEmail,
      redirectUrl,
      maxOnWallAttendees,
      maxOffWallAttendees,
      enableBreakoutRoomsRecording,
      appendAttendeeInformation,
      scheduledStartTime,
      header,
      footer,
      htmlWidget,
      enableAutomatedOnboarding,
      enableOffWallAutomatedOnboarding,
      enableChat,
      enableReactions,
      enableQNAWidget,
      qnaForwardEmail,
      qnaWidgetAutoResponse,
      qnaPortalEventName,
      enableNotes,
      hideAttendeeDetails,
      latencyMode,
      showQRCode,
    } = data;
    this.setState({ eventData: data, formSubmitted: true }, async () => {
      const { editing } = this.state;

      const v1APIMessages = [
        { type: 'setEndMeetingFields', value: JSON.stringify({ redirectUrl, appendAttendeeInformation }) || '', showName: name },
        { type: 'setTextChatForwardEmail', value: textChatForwardEmail, showName: name },
        { type: 'allowEventSupportChatForPresenters', value: allowEventSupportChatForPresenters, showName: name },
      ];
      if (scheduledStartTime) {
        const value = Math.round(scheduledStartTime.getTime() / 1000);
        v1APIMessages.push({ type: 'setMeetingStartTime', value, showName: name });
      }

      if (editing === false) {
        createEvent({
          eventType: appMode,
          name,
          startTime,
          endTime,
          allowEventSupportChatForPresenters,
          textChatForwardEmail,
          redirectUrl,
          maxOnWallAttendees,
          maxOffWallAttendees,
          enableBreakoutRoomsRecording,
          appendAttendeeInformation,
          scheduledStartTime,
          studioWall: activeStudio._id,
          v1APIMessages,
          header,
          footer,
          htmlWidget,
          logoFilename,
          enableAutomatedOnboarding,
          enableOffWallAutomatedOnboarding,
          enableChat,
          enableReactions,
          enableQNAWidget,
          qnaForwardEmail,
          qnaWidgetAutoResponse,
          qnaPortalEventName,
          enableNotes,
          hideAttendeeDetails,
          latencyMode,
          showQRCode,
        });
      } else if (logoFile !== null) {
        uploadFiles([logoFile], null, model._id, undefined);
      } else if (model !== null) {
        const { activeEvent, setActiveEvent } = this.props;
        updateEvent(model._id, {
          eventType: appMode,
          name,
          startTime,
          endTime,
          allowEventSupportChatForPresenters,
          textChatForwardEmail,
          redirectUrl,
          maxOnWallAttendees,
          maxOffWallAttendees,
          enableBreakoutRoomsRecording,
          appendAttendeeInformation,
          scheduledStartTime,
          v1APIMessages,
          header,
          footer,
          htmlWidget,
          logoFilename,
          enableAutomatedOnboarding,
          enableOffWallAutomatedOnboarding,
          enableChat,
          enableReactions,
          enableQNAWidget,
          qnaForwardEmail,
          qnaWidgetAutoResponse,
          qnaPortalEventName,
          enableNotes,
          hideAttendeeDetails,
          latencyMode,
        });
        SocketClient.emitAdminAppSync({
          type: actions.GET_EVENTS_REQUEST,
          payload: { studio: activeStudio._id },
        });
        if (activeEvent && model && model._id === activeEvent._id) {
          const event = { ...activeEvent };
          event.eventType = appMode;
          event.name = name;
          event.startTime = startTime;
          event.endTime = endTime;
          event.allowEventSupportChatForPresenters = allowEventSupportChatForPresenters;
          event.textChatForwardEmail = textChatForwardEmail;
          event.redirectUrl = redirectUrl;
          event.maxOnWallAttendees = maxOnWallAttendees;
          event.maxOffWallAttendees = maxOffWallAttendees;
          event.enableBreakoutRoomsRecording = enableBreakoutRoomsRecording;
          event.appendAttendeeInformation = appendAttendeeInformation;
          event.scheduledStartTime = scheduledStartTime;
          event.header = header;
          event.footer = footer;
          event.htmlWidget = htmlWidget;
          event.logoFilename = logoFilename;
          event.enableAutomatedOnboarding = enableAutomatedOnboarding;
          event.enableOffWallAutomatedOnboarding = enableOffWallAutomatedOnboarding;
          event.enableChat = enableChat;
          event.enableReactions = enableReactions;
          event.enableQNAWidget = enableQNAWidget;
          event.qnaForwardEmail = qnaForwardEmail;
          event.qnaWidgetAutoResponse = qnaWidgetAutoResponse;
          event.enableNotes = enableNotes;
          event.qnaPortalEventName = qnaPortalEventName;
          event.hideAttendeeDetails = hideAttendeeDetails;
          event.broadcastStream = {
            latencyMode,
          };
          localStorage.setItem('activeEvent', JSON.stringify(event));
          setActiveEvent(event);
          updateRoutes();
        }
        this.setState({
          formSubmitted: false,
          model: null,
        });
      }
      this._closeModal();
    });
  };

  _addEvent = (uploaded) => {
    const { appMode, updateEvent, activeStudio, newEventId, activeEvent, setActiveEvent, updateRoutes } = this.props;
    const { eventData, model } = this.state;

    for (const i in uploaded) {
      const u = uploaded[i];
      const {
        name,
        startTime,
        endTime,
        allowEventSupportChatForPresenters,
        textChatForwardEmail,
        redirectUrl,
        maxOnWallAttendees,
        maxOffWallAttendees,
        enableBreakoutRoomsRecording,
        appendAttendeeInformation,
        scheduledStartTime,
        header,
        footer,
        htmlWidget,
        logoFilename,
        enableAutomatedOnboarding,
        enableOffWallAutomatedOnboarding,
        enableChat,
        enableReactions,
        enableQNAWidget,
        qnaForwardEmail,
        qnaWidgetAutoResponse,
        qnaPortalEventName,
        enableNotes,
        hideAttendeeDetails,
        latencyMode,
      } = eventData;

      const v1APIMessages = [
        { type: 'setEndMeetingFields', value: JSON.stringify({ redirectUrl, appendAttendeeInformation }) || '', showName: name },
        { type: 'setTextChatForwardEmail', value: textChatForwardEmail, showName: name },
        { type: 'allowEventSupportChatForPresenters', value: allowEventSupportChatForPresenters, showName: name },
      ];
      if (scheduledStartTime) {
        const value = Math.round(scheduledStartTime.getTime() / 1000);
        v1APIMessages.push({ type: 'setMeetingStartTime', value, showName: name });
      }

      const payload = {
        eventType: appMode,
        name,
        startTime,
        endTime,
        allowEventSupportChatForPresenters,
        textChatForwardEmail,
        studioWall: activeStudio._id,
        logo: u.fileKey,
        logoFilename,
        redirectUrl,
        maxOnWallAttendees,
        maxOffWallAttendees,
        enableBreakoutRoomsRecording,
        appendAttendeeInformation,
        scheduledStartTime,
        v1APIMessages,
        header,
        footer,
        htmlWidget,
        enableAutomatedOnboarding,
        enableOffWallAutomatedOnboarding,
        enableChat,
        enableReactions,
        enableQNAWidget,
        qnaForwardEmail,
        qnaWidgetAutoResponse,
        qnaPortalEventName,
        enableNotes,
        hideAttendeeDetails,
        latencyMode,
      };

      if (newEventId) {
        updateEvent(newEventId, payload);
      } else {
        updateEvent(model._id, payload);
        if (activeEvent && activeEvent._id === model._id) {
          const { latencyMode, ...rest } = payload;
          const event = { ...activeEvent, ...rest, broadcastStream: { latencyMode } };
          localStorage.setItem('activeEvent', JSON.stringify(event));
          setActiveEvent(event);
          updateRoutes();
        }
      }
    }
    this.setState(
      {
        eventData: undefined,
        formSubmitted: false,
        editing: false,
        model: null,
      },
      () => {
        SocketClient.emitAdminAppSync({
          type: actions.GET_EVENTS_REQUEST,
          payload: { studio: activeStudio._id },
        });
      },
    );
  };

  _closeModal = () => {
    this.setState({ openModal: false, editing: false });
  };

  _openModal = () => {
    const { selectEvent, socketServerList, activeStudio } = this.props;
    if (socketServerList.length || activeStudio.dbName === 'broadcast') {
      selectEvent(null);
      this.setState({ openModal: true, editing: false, model: null });
    } else {
      swal({
        title: 'Error',
        text: 'The Socket server needs to be configured to create Events.',
      });
    }
  };

  _getContextMenuItemSelectionHandler = (item) => async (option) => {
    const { removeEventParticipants, updateEvent, events, activeEvent, setActiveEvent, getEvent } = this.props;
    if (!option.disabled) {
      const evt = events[item];

      switch (option.value) {
        case 1:
          updateEvent(item, { isArchived: true });
          break;
        case 2:
          updateEvent(item, { isArchived: false });
          break;
        case 3:
          const confirm = await swal({
            title: 'Please confirm',
            text: `Are you sure you want to clear all chat messages from ${evt.name}?`,
            buttons: {
              cancel: 'No',
              confirm: 'Yes',
            },
            dangerMode: true,
          });
          if (confirm) {
            await post(`/chatMessage/clear`, { event: item });
          }
          break;
        case 9:
          const willDelete = await swal({
            title: 'Please confirm',
            text: `Are you sure you want to clear all participants data from ${evt.name}?`,
            buttons: {
              cancel: 'No',
              confirm: 'Yes',
            },
            dangerMode: true,
          });
          if (willDelete) {
            removeEventParticipants(item);
          }
          break;
        case 10:
          if (evt) {
            await swal({
              buttons: {},
              className: 'swal-custom-content',
              closeOnClickOutside: false,
              closeOnEsc: false,
              content: (
                <EndMeetingAlertMessage
                  activeEvent={evt}
                  isPando={false}
                  onCancel={() => swal.close()}
                  onEndMeeting={async ({ redirectUrl, appendAttendeeInformation }) => {
                    updateEvent(evt._id, {
                      redirectUrl,
                      appendAttendeeInformation,
                    });
                    await post('/studio/endBroadcast', { eventId: evt._id });
                    getEvent(evt._id);
                    const endTime = new Date();
                    if (activeEvent && activeEvent._id === evt._id) {
                      const updatedActiveEvent = { ...activeEvent, redirectUrl, appendAttendeeInformation, endTime };
                      localStorage.setItem('activeEvent', JSON.stringify(updatedActiveEvent));
                      setActiveEvent(updatedActiveEvent);
                    }
                    swal.close();
                    SocketClient.emitAdminAppSync({
                      type: 'endBroadcast',
                      payload: { _id: evt._id, redirectUrl, appendAttendeeInformation, endTime },
                    });
                  }}
                />
              ),
            });
          }
          break;
        default:
          break;
      }
    }
  };

  _saveChanges = (event) => {
    if (this._formRef.current) {
      this._formRef.current._onSubmitHandler(event);
    }
  };

  render() {
    const { list, archived, selected, isAdmin, isLive, liveEvent } = this.props;
    const { openModal, editing, currentTabIndex, model, liveStreams } = this.state;

    const eventListData = currentTabIndex === 0 ? list : archived;

    return (
      <>
        <div className='events-list'>
          <div className='event-list-tabs'>
            <div className={currentTabIndex === 0 ? 'tab active' : 'tab'} onClick={() => this.setState({ currentTabIndex: 0 })}>
              Available
            </div>
            <div className={currentTabIndex === 1 ? 'tab active' : 'tab'} onClick={() => this.setState({ currentTabIndex: 1 })}>
              Archived
            </div>
          </div>
          <List
            data={eventListData}
            renderItem={(item) => (
              <EventListItem
                eventId={item}
                onDuplicate={this._onDuplicateHandler}
                onEdit={this._onEditHandler}
                onDelete={this._onDeleteHandler}
                onSelect={this._onSelectHandler}
                selected={selected && selected === item}
                onSetActive={this._onSetActiveHandler}
                enableContextMenu={true}
                contextMenuItemSelectionHandler={this._getContextMenuItemSelectionHandler(item)}
                isAdmin={isAdmin}
              />
            )}
            keyExtractor={(item) => `${item}`}
            containerStyle={{ height: 'calc(100% - 116px)', position: 'relative' }}
          />
          {isAdmin && (
            <div className='add-event' onClick={this._openModal}>
              <img src={addIcon} alt='add' style={{ marginLeft: 15 }} />
              Add Event
            </div>
          )}
        </div>
        {openModal && (
          <Modal
            header={
              <div className='custom-form-header'>
                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center' }}>
                  {model && model.logo && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 112,
                        height: 63,
                        minWidth: 112,
                        minHeight: 63,
                        backgroundColor: '#435061',
                        overflow: 'hidden',
                        marginRight: 15,
                      }}
                    >
                      {model ? <Image source={model.logo} alt='event logo' responsive /> : <Spinner />}
                    </div>
                  )}
                  <h3 style={{ margin: 0, textAlign: 'left' }}>{model && model.name}</h3>
                  {isLive && model && liveEvent === model._id && (
                    <img src={liveLabelImg} alt='live' style={{ width: 'auto', marginLeft: 10, marginRight: 10 }} />
                  )}
                </div>
                <Button type='primary' submit text='Save' onClick={this._saveChanges} />
              </div>
            }
            show={true}
            onClose={() => this._closeModal()}
            contentStyle={{ height: 'calc(100% - 90px)' }}
          >
            <EventForm
              ref={this._formRef}
              model={model}
              onSubmit={this._onSubmitHandler}
              edit={editing === true}
              testRedirectUrl={false}
              fetchDataFromAPI={true}
            />
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let socketServerList = [];
  if (state.goLive.list) {
    const instancesArray = state.goLive.list.map((instanceId) => state.goLive.instances[instanceId]);
    socketServerList = instancesArray.filter((instance) => instance.serverType === 'SOCKET_SERVER');
  }
  return {
    appMode: state.ui.appMode,
    fetching: state.events.fetching,
    error: state.events.error,
    list: state.events.list,
    archived: state.events.archived,
    selected: state.events.selected,
    activeStudio: state.studio.active,
    status: state.uploader.status,
    responses: state.uploader.responses,
    eventCreated: state.events.eventCreated,
    newEventId: state.events.newEventId,
    activeEvent: state.events.active,
    isAdmin: isAdmin(state),
    isLive: state.goLive.isLive,
    liveEvent: state.studio.active ? state.studio.active.liveEvent : null,
    participants: state.events.participants,
    events: state.events.entities,
    socketServerList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getEvents: () => dispatch(getEventsRequest()),
  deleteEvent: (id) => dispatch(deleteEventRequest(id)),
  duplicateEvent: (id) => dispatch(duplicateEventRequest(id)),
  updateEvent: (id, data) => dispatch(updateEventRequest(id, data)),
  selectEvent: (event) => dispatch(selectEvent(event)),
  getAssets: (eventId) => dispatch(getAssetsRequest(eventId)),
  createEvent: (event) => dispatch(createEventRequest(event)),
  uploadFiles: (file, name, event, key) => dispatch(uploadFilesRequest(file, name, event, key)),
  start: (size, files) => dispatch(startUpload(size, files)),
  addFile: (file) => dispatch(addFile(file)),
  setActiveEvent: (event) => dispatch(setActiveEvent(event)),
  updateRoutes: () => dispatch(updateRoutes()),
  removeEventParticipants: (event) => dispatch(removeEventParticipantsRequest(event)),
  loadTopBar: () => dispatch(loadTopBar()),
  getEventParticipants: (event) => dispatch(getEventParticipantsRequest(event)),
  getEvent: (eventId) => dispatch(getEventRequest(eventId)),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(EventList);
