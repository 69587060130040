export default [
  {
    name: 'Flex',
    open: false,
    buildProps: [
      'flex-direction',
      'flex-wrap',
      'justify-content',
      'align-items',
      'align-content',
      'order',
      'flex-basis',
      'flex-grow',
      'flex-shrink'
    ]
  }
];
