import React, { useState, useEffect, useRef } from 'react';
import Button from '../../common/Button';
import TextInput from '../../common/TextInput';
import Checkbox from '../../common/Checkbox';
import Switch from '../../common/Switch';
import Spinner from '../../common/Spinner';
import StringUtil from '../../utils/string-utils';
import TimeUtil from '../../utils/time-util';
import { get, post } from '../../services/api';

export default function EndMeetingAlertMessage({ activeEvent, participants, onCancel, onEndMeeting, isPando = true }) {
  const breakOutRoomsConfig = useRef(null);
  const [redirectUrl, setRedirectUrl] = useState('');
  const [isValidRedirectUrl, setIsValidRedirectUrl] = useState(true);
  const [shutdownGateway, setShutdownGateway] = useState(false);
  const [appendAttendeeInformation, setAppendAttendeeInformation] = useState(false);
  const [endBreakoutRoomsMessage, setEndBreakoutRoomsMessage] = useState(false);

  useEffect(() => {
    if (activeEvent) {
      (async () => {
        try {
          const { data } = await get(`/event/${activeEvent._id}`);
          setRedirectUrl(data.redirectUrl || '');
          setAppendAttendeeInformation(data.appendAttendeeInformation || false);
          const { data: breakoutRoomsConfig } = await get(`/breakoutRoom/config/${activeEvent._id}`);
          if (breakoutRoomsConfig) {
            breakOutRoomsConfig.current = breakoutRoomsConfig;
          }
        } catch (err) {
          console.error(err);
        }
      })();
    }
  }, []);

  const _onEndEvent = async () => {
    if (redirectUrl && !StringUtil.validateUrl(redirectUrl)) {
      setIsValidRedirectUrl(false);
      return;
    } else {
      if (breakOutRoomsConfig.current && breakOutRoomsConfig.current.status === 'STARTED') {
        setEndBreakoutRoomsMessage(true);
        await post(`/breakoutRoom/stop`, { config: breakOutRoomsConfig.current._id });
        await TimeUtil.sleep(15000);
        setEndBreakoutRoomsMessage(false);
      }
      onEndMeeting({ redirectUrl, appendAttendeeInformation, shutdownGateway });
    }
  };

  let message;
  if (participants) {
    const onWallCount = participants.filter((p) => p.status === 'ON_WALL' || p.status === 'ON_AIR').length;
    const breakoutRoomCount = participants.filter((p) => p.status === 'IN_BREAKOUT_ROOM').length;
    if (onWallCount > 0 || breakoutRoomCount > 0) {
      message = `There ${onWallCount === 1 ? `is still ${onWallCount} participant` : `are still ${onWallCount} participants`} on the wall and ${
        breakoutRoomCount === 1 ? `${breakoutRoomCount} participant` : `${breakoutRoomCount} participants`
      } in breakout rooms.`;
    }
  }

  return (
    <div>
      {message && (
        <div style={{ color: '#adadad', marginBottom: 30 }}>
          <h2>Are you sure?</h2>
          <div style={{ textAlign: 'left' }}>{message}</div>
        </div>
      )}

      <div>
        <TextInput
          name='redirectUrl'
          label='Redirect URL'
          onChange={({ target }) => setRedirectUrl(target.value)}
          value={redirectUrl}
          type='text'
          hasError={!isValidRedirectUrl}
          errorMessage='Please enter a valid URL'
        />
        <Checkbox label='Append Attendee Information' value={appendAttendeeInformation} onChange={(value) => setAppendAttendeeInformation(value)} />
      </div>
      {isPando && (
        <div style={{ color: '#adadad', marginBottom: 30, marginTop: 30 }}>
          <div style={{ textAlign: 'left', fontSize: 14 }}>
            If this is the last event of the day, please remember to shut down all of the Gateway processes and then all of the running servers.
          </div>
          <Switch
            label='Shutdown Gateway Processes'
            onChange={() => setShutdownGateway(!shutdownGateway)}
            checked={shutdownGateway}
            height={16}
            width={34}
            containerStyle={{ marginTop: 15 }}
          />
        </div>
      )}
      {isPando && endBreakoutRoomsMessage && (
        <div
          style={{ color: '#adadad', marginBottom: 15, marginTop: 15, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
        >
          <Spinner style={{ transform: 'scale(0.5)' }} />
          <div style={{ textAlign: 'left', fontSize: 14 }}>Waiting for breakout rooms to end...</div>
        </div>
      )}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: 30 }}>
        <Button type='secondary' text='Cancel' onClick={onCancel} disabled={endBreakoutRoomsMessage} />
        <Button type='danger' text='End Event' onClick={_onEndEvent} disabled={endBreakoutRoomsMessage} />
      </div>
    </div>
  );
}
