import React, { PureComponent } from 'react';
import '../css/List.css';

class List extends PureComponent {
  _renderListItems = () => {
    const { data, renderItem, keyExtractor } = this.props;
    if (data) {
      return data.map((item, index) => {
        const _key = (keyExtractor && keyExtractor(item, index)) || `list-item-${index}`;
        return (
          <li key={_key} className='list-item'>
            {renderItem && renderItem(item, index)}
          </li>
        );
      });
    }
    return [];
  };

  render() {
    const { containerStyle } = this.props;
    return (
      <div className='list-root' style={containerStyle}>
        <div className='list-wrapper'>
          <ul className='list'>{this._renderListItems()}</ul>
        </div>
      </div>
    );
  }
}

export default List;
