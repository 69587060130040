import React from 'react';

function ApiKeyListItem({ item, onSelect, selected }) {
  const _onClickHandler = () => {
    onSelect && onSelect(item);
  };

  return (
    <div className={selected ? 'user-list-item selected' : 'user-list-item'} onClick={_onClickHandler}>
      <div className='username'>{item.name}</div>
    </div>
  );
}

export default ApiKeyListItem;
