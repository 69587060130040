import { all, takeLatest, call, put } from 'redux-saga/effects';

import { get, post, del, put as update } from '../services/api';
import {
  actions,
  getUsersSuccess,
  getUsersFailure,
  createUserSuccess,
  createUserFailure,
  deleteUserSuccess,
  deleteUserFailure,
  updateUserSuccess,
  updateUserFailure
} from '../redux/usersRedux';

export function* fetchUsers() {
  try {
    const response = yield call(get, '/user/');
    if (response.status === 200) {
      yield put(getUsersSuccess(response.data));
    } else {
      yield put(
        getUsersFailure({
          status: response.status,
          message: response.data
        })
      );
    }
  } catch (error) {
    yield put(getUsersFailure(error));
  }
}

export function* postUser(action) {
  try {
    const { user } = action.payload;
    const response = yield call(post, '/user/', user);
    if (response.status === 200) {
      yield put(createUserSuccess(response.data));
    } else {
      yield put(
        createUserFailure({
          status: response.status,
          message: response.data
        })
      );
    }
  } catch (error) {
    yield put(createUserFailure(error));
  }
}

export function* deleteUser(action) {
  try {
    const { id } = action.payload;
    const response = yield call(del, `/user/${id}`);
    if (response.status === 200) {
      yield put(deleteUserSuccess(id));
    } else {
      yield put(
        deleteUserFailure({
          status: response.status,
          message: response.data
        })
      );
    }
  } catch (error) {
    yield put(deleteUserFailure(error));
  }
}

export function* putUser(action) {
  try {
    const { user, id } = action.payload;
    const response = yield call(update, `/user/${id}`, user);
    if (response.status === 200) {
      yield put(updateUserSuccess(response.data));
    } else {
      yield put(
        updateUserFailure({
          status: response.status,
          message: response.data
        })
      );
    }
  } catch (error) {
    yield put(updateUserFailure(error));
  }
}

export default function*() {
  yield all([
    takeLatest([actions.GET_USERS_REQUEST], fetchUsers),
    takeLatest([actions.CREATE_USER_REQUEST], postUser),
    takeLatest([actions.DELETE_USER_REQUEST], deleteUser),
    takeLatest([actions.UPDATE_USER_REQUEST], putUser)
  ]);
}
