import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';

import '../../css/Pagination.css';

function Items({ currentItems }) {
  return (
    <>
      {currentItems &&
        currentItems.map((systemEvent) => {
          return (
            <tr key={systemEvent._id} style={{ backgroundColor: 'rgb(32, 39, 47)', color: 'rgb(169, 170, 171)' }}>
              <td style={{ verticalAlign: 'top', padding: 10, textAlign: 'center' }}>{systemEvent.timestamp}</td>
              <td style={{ verticalAlign: 'top', padding: 10, textAlign: 'center' }}>{systemEvent.adminAppUser}</td>
              <td style={{ verticalAlign: 'top', padding: 10, textAlign: 'center' }}>{systemEvent.studio}</td>
              <td style={{ verticalAlign: 'top', padding: 10, textAlign: 'center' }}>{systemEvent.event}</td>
              <td style={{ verticalAlign: 'top', padding: 10, textAlign: 'center' }}>{systemEvent.action}</td>
              <td style={{ verticalAlign: 'top', padding: 10, textAlign: 'center' }}>{systemEvent.extraInfo}</td>
            </tr>
          );
        })}
    </>
  );
}

function PaginatedItems({ items, itemsPerPage }) {
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
    setTimeout(() => {
      const thead = document.getElementsByTagName('thead')[0];
      const pagination = document.getElementsByClassName('pagination')[0];
      pagination.style.left = `${thead.clientWidth / 2}px`;
    }, 0);
  }, [itemOffset, itemsPerPage, items]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <Items currentItems={currentItems} />
      <tr>
        <td colSpan={6}>
          <ReactPaginate
            previousLabel='<'
            nextLabel='>'
            breakLabel='...'
            breakClassName='break-me'
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName='pagination'
            activeClassName='active'
          />
        </td>
      </tr>
    </>
  );
}

export default PaginatedItems;
