import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { uploadFilesRequest, addFile, startUpload } from '../../redux/uploaderRedux';

import '../../css/FileUploader.css';
import FileDroppable from './FileDroppable';

class FileUploader extends PureComponent {
  _onFilesDropped = async (files) => {
    if (files.length !== 0) {
      const { onStarted, uploadFiles, event } = this.props;
      uploadFiles(files, null, event);
      onStarted && onStarted();
    }
  };

  componentDidUpdate(prevProps) {
    const { status, responses, onCompleted } = this.props;
    if (prevProps.status !== status && status === 'completed') {
      onCompleted && onCompleted(responses);
    }
  }

  render() {
    return (
      <div className='file-uploader'>
        <FileDroppable onFilesDropped={this._onFilesDropped} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.uploader.status,
  responses: state.uploader.responses,
  event: state.events.selected,
});

const mapDispatchToProps = (dispatch) => ({
  uploadFiles: (files, name, event) => dispatch(uploadFilesRequest(files, name, event)),
  addFile: (file) => dispatch(addFile(file)),
  start: (size, files) => dispatch(startUpload(size, files)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileUploader);
