import React, { Component } from 'react';
import swal from '@sweetalert/with-react';
import uuidv4 from 'uuid/v4';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Switch from 'react-switch';

import Button from '../../common/Button';
import {
  addMetadata,
  removeMetadata,
  selectLayout,
  clearWall,
  getLayoutById,
  removeMetadataInBulk,
  addMetadataInBulk,
  selectParticipant,
  getLayoutConfigRequest,
  getLayoutConfigSuccess,
  setHightlightedParticipantIndex,
  setActivatedLayout,
} from '../../redux/wallRedux';
import { updateEventRequest, setLiveLayout, setActiveEvent } from '../../redux/eventsRedux';
import { updateParticipantsInBulkRequest } from '../../redux/participantRedux';
import { updateLayoutRequest } from '../../redux/layoutsRedux';
import { getAssetsRequest } from '../../redux/assetsRedux';
import SocketClient from '../../utils/socket-client';
import { findEventParticipantByDisplayNum } from '../../utils/find-event-participant';
import RTC from '../../utils/rtc-common';
import { EVENT_SUPPORT, ON_WALL_ROLES } from '../../utils/user-roles';

import AttendeeListContainer from './AttendeeListContainer';
import LayoutsDropDown from './LayoutsDropDown';
import Wall from './Wall';
import Preview from './Preview';
import BackgroundAssetPreview from './BackgroundAssetPreview';

class WallContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPreview: false,
      activatedLayout: null,
      prevLayout: null,
      onAirParticipants: { 1: null, 2: null, 3: null, 4: null },
      wallSupportStaffUuid: uuidv4(),
      fillParticipantsAfterLayoutSwitch: true,
    };
  }

  componentDidMount() {
    const { activeEvent, activeStudio, getLayoutConfigSuccess, getAssetsRequest } = this.props;
    if (activeStudio && activeEvent) {
      getAssetsRequest(activeEvent._id);
      SocketClient.joinRoom(`${activeStudio._id}:${activeEvent._id}:layout-config`);
      SocketClient.joinRoom(`${activeStudio._id}:${activeEvent._id}:wall-admin`);

      SocketClient.on('layout-config-update', ({ layoutConfig }) => {
        if (!layoutConfig.studioWall) {
          getLayoutConfigSuccess(layoutConfig);
        }
      });

      this._onAdminAppSync = (data) => {
        const { selectLayout, activeEvent, setActiveEvent } = this.props;
        let event;
        if (data) {
          switch (data.type) {
            case 'activateLayout':
              const { activatedLayout } = data.payload;
              if (activatedLayout !== this.state.activatedLayout) {
                selectLayout(activatedLayout);
                this._activateLayout();
              }
              break;
            case 'sendFeedToStudioQueue':
              event = { ...activeEvent };
              event.sendFeedToStudioQueue = data.payload.value;
              localStorage.setItem('activeEvent', JSON.stringify(event));
              setActiveEvent(event);
              break;
            case 'enablePPTControl':
              event = { ...activeEvent };
              event.enablePPTControl = data.payload.value;
              localStorage.setItem('activeEvent', JSON.stringify(event));
              setActiveEvent(event);
              break;
            case 'setMeetingStartTime':
              event = { ...activeEvent };
              event.scheduledStartTime = data.payload.value;
              localStorage.setItem('activeEvent', JSON.stringify(event));
              setActiveEvent(event);
              break;
            case 'startMeetingBreak':
              event = { ...activeEvent };
              event.breakStarted = true;
              event.breakStartTime = data.payload.value;
              localStorage.setItem('activeEvent', JSON.stringify(event));
              setActiveEvent(event);
              break;
            case 'stopMeetingBreak':
              event = { ...activeEvent };
              event.breakStarted = false;
              event.breakStartTime = null;
              localStorage.setItem('activeEvent', JSON.stringify(event));
              setActiveEvent(event);
              break;
            case 'enableShowWall':
              event = { ...activeEvent };
              event.enableShowWall = data.payload.value;
              localStorage.setItem('activeEvent', JSON.stringify(event));
              setActiveEvent(event);
              break;
            case 'enableShareScreen':
              event = { ...activeEvent };
              event.enableShareScreen = data.payload.value;
              localStorage.setItem('activeEvent', JSON.stringify(event));
              setActiveEvent(event);
              break;
            case 'rehearsalMode':
              event = { ...activeEvent };
              event.rehearsalMode = data.payload.value;
              localStorage.setItem('activeEvent', JSON.stringify(event));
              setActiveEvent(event);
              break;
            default:
              break;
          }
        }
      };

      SocketClient.on('admin-app-sync', this._onAdminAppSync);
      window.onbeforeunload = this.componentWillUnmount.bind(this);
      this._updateOnAirParticipantsMap();
    }
  }

  componentWillUnmount() {
    const { activeStudio, activeEvent, selectParticipant } = this.props;
    SocketClient.emit('wall-staff-disconnected');
    this._closeRtcConnection();
    if (activeStudio && activeEvent) {
      SocketClient.leaveRoom(`${activeStudio._id}:${activeEvent._id}:layout-config`);
      SocketClient.leaveRoom(`${activeStudio._id}:${activeEvent._id}:wall-admin`);
    }
    SocketClient.removeAllListeners();
    SocketClient.off('admin-app-sync', this._onAdminAppSync);
    selectParticipant(null);
    window.onbeforeunload = () => {};
    this._unmounted = true;
  }

  componentDidUpdate(prevProps) {
    const { onAir } = this.props;
    if (JSON.stringify(prevProps.onAir) !== JSON.stringify(onAir)) {
      this._updateOnAirParticipantsMap();
    }
  }

  _updateOnAirParticipantsMap = () => {
    const { participants } = this.props;
    if (participants) {
      const onAirParticipants = { 1: null, 2: null, 3: null, 4: null };
      participants
        .filter((p) => p.status === 'ON_AIR')
        .forEach((p) => {
          onAirParticipants[p.onAirNum] = p.participant._id;
        });
      this.setState({ onAirParticipants });
    }
  };

  _fillWall = async () => {
    const { selectedLayout, inStudio, updateParticipantsInBulk, activeEvent, addMetadataInBulk, updateLayout } = this.props;
    if (selectedLayout) {
      const { areas } = selectedLayout;
      const preAssignedAreas = areas.filter((a) => a.sourceType === 'Participant' && a.data.wallDisplayNum);
      const availableAreas = areas.filter((a) => a.sourceType === 'WallParticipant' && !a.data.wallDisplayNum);

      const updateInBulkData = [];
      const metadata = [];

      const preAssignedAreasWallDisplayNums = preAssignedAreas.map((a) => a.data.wallDisplayNum);
      if (preAssignedAreas.length) {
        for (const p of inStudio) {
          if (preAssignedAreasWallDisplayNums.includes(p.wallDisplayNum)) {
            const area = preAssignedAreas.find((a) => a.data.wallDisplayNum === p.wallDisplayNum);
            updateInBulkData.push({ participant: p.participant._id, status: 'ON_WALL' });
            metadata.push({ area: area._id, metadata: { wallDisplayNum: p.wallDisplayNum } });
          }
        }
      }

      const remainingInStudio = inStudio.filter((p) => !preAssignedAreasWallDisplayNums.includes(p.wallDisplayNum));

      let index = 0;
      if (availableAreas.length) {
        for (const p of remainingInStudio) {
          const area = availableAreas[index++];
          if (area) {
            updateInBulkData.push({ participant: p.participant._id, status: 'ON_WALL' });
            metadata.push({ area: area._id, metadata: { wallDisplayNum: p.wallDisplayNum } });
            const layoutAreaIndex = areas.findIndex((a) => area._id === a._id);
            areas[layoutAreaIndex].sourceType = 'Participant';
            areas[layoutAreaIndex].wallDisplayNum = p.wallDisplayNum;
          }
        }
      }

      addMetadataInBulk(selectedLayout._id, metadata);
      updateParticipantsInBulk(updateInBulkData, activeEvent._id);

      const payload = { areas };
      updateLayout(selectedLayout._id, payload);
    }
  };

  _clear = () => {
    const { clearWall, selectedLayout, updateParticipantsInBulk, activeEvent, updateLayout } = this.props;
    if (selectedLayout) {
      const { areas } = selectedLayout;
      const areasWithParticipant = areas.filter((a) => a.sourceType === 'Participant' && a.data.wallDisplayNum);
      const updateInBulkData = [];
      for (const area of areasWithParticipant) {
        const ep = findEventParticipantByDisplayNum(area.data.wallDisplayNum, ['ON_WALL', 'ON_AIR']);
        if (ep) {
          updateInBulkData.push({
            participant: ep.participant._id,
            wallDisplayNum: ep.wallDisplayNum,
            status: 'IN_STUDIO_QUEUE',
          });
        }
      }
      updateParticipantsInBulk(updateInBulkData, activeEvent._id);

      const payload = {
        areas: areas.map((area) => {
          if (area.sourceType === 'Participant' && area.data.wallDisplayNum) {
            area.sourceType = 'WallParticipant';
            area.data.wallDisplayNum = null;
          }
          return area;
        }),
      };
      updateLayout(selectedLayout._id, payload);

      clearWall(selectedLayout._id);
    }
  };

  _changePreviewLayout = (layoutId) => {
    const { selected } = this.props;
    if (layoutId !== selected) {
      this.setState({ showPreview: true }, () => {
        this.props.selectLayout(layoutId);
      });
    }
  };

  _activateLayout = async (layoutId, isButtonPress) => {
    const {
      layouts,
      selected,
      updateParticipantsInBulk,
      activeEvent,
      updateEvent,
      setLiveLayout,
      setHightlightedParticipantIndex,
      inStudio,
      addMetadataInBulk,
      updateLayout,
      setActivatedLayout,
    } = this.props;
    const { activatedLayout, fillParticipantsAfterLayoutSwitch } = this.state;

    const nextLayout = layoutId ? layouts[layoutId] : layouts[selected];

    if (isButtonPress) {
      let deactivatedParticipantsData = [];
      if (activatedLayout) {
        const previousLayout = layouts[activatedLayout];
        for (const area of previousLayout.areas) {
          if (area.sourceType === 'Participant' && area.data.wallDisplayNum) {
            const ep = findEventParticipantByDisplayNum(area.data.wallDisplayNum, ['ON_WALL', 'ON_AIR']);
            if (ep) {
              deactivatedParticipantsData.push({ participant: ep.participant._id, status: 'IN_STUDIO_QUEUE', wallDisplayNum: area.data.wallDisplayNum });
            }
          }
        }
      }

      const newParticipantsData = [];
      for (const area of nextLayout.areas) {
        if (area.sourceType === 'Participant' && area.data.wallDisplayNum) {
          const ep = findEventParticipantByDisplayNum(area.data.wallDisplayNum, ['ON_WALL', 'ON_AIR', 'IN_STUDIO_QUEUE']);
          if (ep) {
            const participantId = ep.participant._id;
            newParticipantsData.push({
              participant: participantId,
              status: ep.status === 'ON_AIR' ? 'ON_AIR' : 'ON_WALL',
              wallDisplayNum: area.data.wallDisplayNum,
            });
            deactivatedParticipantsData = deactivatedParticipantsData.filter((dp) => dp.participant !== participantId);
          }
        }
      }

      if (fillParticipantsAfterLayoutSwitch) {
        const metadata = [];
        if (deactivatedParticipantsData.length > 0) {
          for (const area of nextLayout.areas) {
            if (area.sourceType === 'WallParticipant' && !area.data.wallDisplayNum) {
              const dp = deactivatedParticipantsData.pop();
              if (dp) {
                const ep = findEventParticipantByDisplayNum(dp.wallDisplayNum, ['ON_WALL', 'ON_AIR']);
                if (ep) {
                  area.data.wallDisplayNum = ep.wallDisplayNum;
                  newParticipantsData.push({
                    participant: ep.participant._id,
                    status: ep.status === 'ON_AIR' ? 'ON_AIR' : 'ON_WALL',
                    wallDisplayNum: ep.wallDisplayNum,
                  });
                  metadata.push({ area: area._id, metadata: { wallDisplayNum: ep.wallDisplayNum } });
                }
              }
            }
          }
        }

        const newParticipantsDisplayNums = newParticipantsData.map((p) => p.wallDisplayNum);
        const remainingInStudio = inStudio.filter((p) => !newParticipantsDisplayNums.includes(p.wallDisplayNum));

        for (const area of nextLayout.areas) {
          if (area.sourceType === 'WallParticipant' && !area.data.wallDisplayNum) {
            const dp = remainingInStudio.pop();
            if (dp) {
              const ep = findEventParticipantByDisplayNum(dp.wallDisplayNum, ['IN_STUDIO_QUEUE']);
              if (ep) {
                area.data.wallDisplayNum = ep.wallDisplayNum;
                newParticipantsData.push({
                  participant: ep.participant._id,
                  status: ep.status === 'ON_AIR' ? 'ON_AIR' : 'ON_WALL',
                  wallDisplayNum: ep.wallDisplayNum,
                });
                metadata.push({ area: area._id, metadata: { wallDisplayNum: ep.wallDisplayNum } });
              }
            }
          }
        }
        addMetadataInBulk(nextLayout._id, metadata);
        setLiveLayout(nextLayout._id); // Need to update redux before calling the API
        updateLayout(nextLayout._id, { areas: nextLayout.areas });
      }
      updateParticipantsInBulk(newParticipantsData.concat(deactivatedParticipantsData), activeEvent._id);
    }

    this.setState({ activatedLayout: nextLayout._id, showPreview: false }, async () => {
      setLiveLayout(nextLayout._id);
      setActivatedLayout(nextLayout._id);
      if (isButtonPress) {
        updateEvent(activeEvent._id, { liveLayout: nextLayout._id });
        setHightlightedParticipantIndex(null);
        SocketClient.emitAdminAppSync({
          type: 'activateLayout',
          payload: { activatedLayout: nextLayout._id },
        });
      }
    });
  };

  _cancel = () => {
    const { activatedLayout } = this.state;
    if (activatedLayout) {
      this.props.selectLayout(activatedLayout);
    }
    this.setState({ showPreview: false, prevLayout: activatedLayout });
  };

  _closeRtcConnection = () => {
    if (this._rtc && this._rtc.pc) {
      this._rtc.pc.getLocalStreams().forEach((stream) => {
        stream.getTracks().forEach((track) => track.stop());
      });
      this._rtc.stop();
      clearInterval(this._rtcTimerId);
    }
  };

  _setupTalkConnection = async () => {
    const { wallSupportStaffUuid } = this.state;
    const stream = await window.navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });

    this._rtc = new RTC(`${wallSupportStaffUuid}-client-send`);

    const pc = await this._rtc.setup();
    pc.addStream(stream);

    await new Promise((resolve, reject) => {
      let attemptCount = 0;
      this._rtcTimerId = setInterval(async () => {
        if (attemptCount++ < 5) {
          this._rtc.sendMessage({ type: 'state', value: 'CONFIG_COMPLETED' });
          console.debug('Waiting for READY_FOR_OFFER');
          const { type, value } = await this._rtc.getMessage('state');
          if (type === 'state' && value === 'READY_FOR_OFFER') {
            clearInterval(this._rtcTimerId);
            resolve();
          }
        } else {
          clearInterval(this._rtcTimerId);
          if (!this._unmounted) {
            reject('Unable to get a response from the RTC Server.');
            swal({
              title: 'Unable to get a response from the RTC Server.',
              text: 'Please contact support',
            });
          }
        }
      }, 1000);
    });

    await this._rtc.startClient();
  };

  render() {
    const { liveRoutesEnabled, selected, activeStudio, layoutsList, onWall, onAir, selectedLayout } = this.props;
    const { activatedLayout, showPreview, onAirParticipants, wallSupportStaffUuid, fillParticipantsAfterLayoutSwitch } = this.state;
    if (!liveRoutesEnabled) return <Redirect to='/app/goLive' />;

    return (
      <div className='wall-container'>
        <div className='layout-list'>
          <LayoutsDropDown onChange={this._changePreviewLayout} onActivateLayout={this._activateLayout} activatedLayout={activatedLayout} />
          {!showPreview && (
            <div>
              <span className='participant-count'>{`${onWall.length + onAir.length} Participants on Wall`}</span>
              <Button type='link' text='Fill with Participants' onClick={this._fillWall} containerStyle={{ margin: 0, padding: '7px 0', marginLeft: 30 }} />
              <Button type='danger' text='Clear' onClick={this._clear} containerStyle={{ margin: 0, marginLeft: 30 }} />
            </div>
          )}
        </div>
        <div className='wall-layout' style={{ position: 'relative' }}>
          {activeStudio && layoutsList && activatedLayout && this.props.selectedLayout ? (
            <Wall studioWall={activeStudio} layout={activatedLayout} onAirParticipants={onAirParticipants} setupTalkConnection={this._setupTalkConnection} />
          ) : (
            <Preview studioWall={activeStudio} />
          )}
          {showPreview === true && <Preview studioWall={activeStudio} layout={selected} />}
        </div>
        <div className='wall-layout-options'>
          <AttendeeListContainer
            layout={activatedLayout}
            onAirParticipants={onAirParticipants}
            wallSupportStaffUuid={wallSupportStaffUuid}
            showPreview={showPreview}
          />
          {showPreview && selected && (
            <div className='preview-layout-options'>
              <div className='title'>Preview</div>
              <div className='buttons'>
                <Button type='danger' text='Activate Layout' onClick={() => this._activateLayout(null, true)} containerStyle={{ margin: 0 }} />
                {activatedLayout ? <Button type='secondary' text='Cancel' onClick={this._cancel} containerStyle={{ margin: 0, marginLeft: 30 }} /> : null}
              </div>
              <div className='form-group' style={{ margin: 0, width: 260, userSelect: 'none', marginTop: 30 }}>
                <div className='form-label' style={{ flex: 4, marginBottom: 3 }}>
                  Auto-fill Participants
                </div>
                <div className='form-control' style={{ flex: 1 }}>
                  <Switch
                    onChange={() => this.setState({ fillParticipantsAfterLayoutSwitch: !fillParticipantsAfterLayoutSwitch })}
                    checked={fillParticipantsAfterLayoutSwitch}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onColor='#711a20'
                  />
                </div>
              </div>
              <BackgroundAssetPreview selectedLayout={selectedLayout} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { participants } = state.events;
  let inStudio = [];
  let onAir = [];
  let onWall = [];

  if (participants && participants.length >= 0) {
    const onWallParticipants = participants.filter((p) => ON_WALL_ROLES.includes(p.role));
    inStudio = onWallParticipants.filter((p) => p.status === 'IN_STUDIO_QUEUE' && p.role !== EVENT_SUPPORT);
    onWall = onWallParticipants.filter((p) => p.status === 'ON_WALL');
    onAir = onWallParticipants.filter((p) => p.status === 'ON_AIR');
  }

  const selectedLayout = getLayoutById(state, state.wall.selectedLayout);
  return {
    breakoutRoomsConfig: state.breakoutRooms.config,
    liveRoutesEnabled: state.ui.liveRoutesEnabled,
    activeStudio: state.studio.active,
    activeEvent: state.events.active,
    selected: state.wall.selectedLayout,
    layouts: state.wall.layouts,
    layoutsList: state.wall.list,
    selectedLayout,
    participants,
    inStudio,
    onAir,
    onWall,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addMetadata: (layout, area, metadata) => dispatch(addMetadata(layout, area, metadata)),
  removeMetadata: (layout, area) => dispatch(removeMetadata(layout, area)),
  selectLayout: (layout) => dispatch(selectLayout(layout)),
  clearWall: (layout) => dispatch(clearWall(layout)),
  updateParticipantsInBulk: (data, event, layout) => dispatch(updateParticipantsInBulkRequest(data, event, layout)),
  updateEvent: (id, data) => dispatch(updateEventRequest(id, data)),
  setLiveLayout: (data) => dispatch(setLiveLayout(data)),
  removeMetadataInBulk: (layout, data) => dispatch(removeMetadataInBulk(layout, data)),
  addMetadataInBulk: (layout, data) => dispatch(addMetadataInBulk(layout, data)),
  selectParticipant: (participant) => dispatch(selectParticipant(participant)),
  getLayoutConfig: (layoutId, data) => dispatch(getLayoutConfigRequest(layoutId, data)),
  getLayoutConfigSuccess: (data) => dispatch(getLayoutConfigSuccess(data)),
  updateLayout: (layoutId, data) => dispatch(updateLayoutRequest(layoutId, data)),
  setHightlightedParticipantIndex: (index) => dispatch(setHightlightedParticipantIndex(index)),
  getAssetsRequest: (eventId) => dispatch(getAssetsRequest(eventId)),
  setActiveEvent: (event) => dispatch(setActiveEvent(event)),
  setActivatedLayout: (data) => dispatch(setActivatedLayout(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WallContainer);
