import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import closeIcon from '../assets/icons/close.svg';

import Backdrop from './Backdrop';
import '../css/Modal.css';

const Modal = ({ show, onClose, children, header, contentStyle, headerStyle }) => {
  const _close = () => {
    onClose && onClose();
  };

  const _onKeyUp = (event) => {
    if (event.keyCode === 27 && show === true) {
      _close();
    }
  };

  useEffect(() => {
    if (show) {
      document.addEventListener('keyup', _onKeyUp, true);
    }
    return () => {
      document.removeEventListener('keyup', _onKeyUp, true);
    };
  }, [show]);

  if (!show) return null;

  return (
    <Backdrop>
      <div className='modal-wrapper fade-in'>
        <div className='modal-header'>
          <div className='modal-header-content' style={headerStyle}>
            {header}
          </div>
          <div onClick={_close} role='button' tabIndex='-1' className='modal-close-btn'>
            <img src={closeIcon} alt='close' />
          </div>
        </div>
        <div className='modal-content custom-scrollbar' style={contentStyle}>
          {children}
        </div>
      </div>
    </Backdrop>
  );
};

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  headerStyle: PropTypes.shape({}),
  contentStyle: PropTypes.shape({}),
};

export default Modal;
