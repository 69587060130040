import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import Popover from 'react-popover';

import Select from '../../common/Select';
import { put } from '../../services/api';
import SocketClient from '../../utils/socket-client';
import Button from '../../common/Button';
import archiveIcon from '../../assets/icons/archive.svg';
import trashIcon from '../../assets/icons/trash.svg';
import screeningQueueIcon from '../../assets/icons/screening-queue.svg';
import studioQueueIcon from '../../assets/icons/studio-queue.svg';
import sendToOnboardingIcon from '../../assets/icons/send-to-onboarding.svg';
import connectIcon from '../../assets/icons/connect.svg';

import Property from './Property';
import WebRTCDetails from './WebRTCDetails';

class ScreeningArea extends PureComponent {
  _videoRef = React.createRef();

  _audioRef = React.createRef();

  state = {
    videoWidth: 0,
    videoHeight: 0,
    audioInputs: [],
    audioOutputs: [],
    videoInputs: [],
    resolutions: [],
    selectedAudioInput: undefined,
    selectedAudioOutput: undefined,
    selectedVideoInput: undefined,
    selectedResolution: undefined,
    showWebRTCDetails: false,
  };

  componentDidMount() {
    SocketClient.on('message', (payload) => {
      if (payload.type === 'update-video-input') {
        this.setState({ selectedVideoInput: payload.data });
      } else if (payload.type === 'update-resoluton') {
        const parts = payload.data.value.split('x');
        const videoWidth = parseInt(parts[0]);
        const videoHeight = parseInt(parts[1]);
        this.setState({ selectedResolution: payload.data, videoWidth, videoHeight });
      } else if (payload.type === 'update-audio-input') {
        this.setState({ selectedAudioInput: payload.data });
      } else if (payload.type === 'update-audio-output') {
        this.setState({ selectedAudioOutput: payload.data });
      } else if (payload.type === 'update-resolution-list') {
        const { resolutionsList, selectedResolution } = payload.data;
        this.setState({ resolutions: resolutionsList, selectedResolution });
      }
    });
  }

  componentWillUnmount() {
    if (SocketClient.socket) {
      SocketClient.socket.removeListener('message');
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { participant } = this.props;
    const { selectedVideoInput, selectedResolution, selectedAudioInput, selectedAudioOutput } = this.state;
    if (prevProps.participant !== participant && participant) {
      this._populateLists();
    }

    if (prevState.selectedVideoInput && selectedVideoInput && prevState.selectedVideoInput.deviceId !== selectedVideoInput.deviceId) {
      this._updateParticipantDevices();
    }
    if (prevState.selectedResolution && selectedResolution && prevState.selectedResolution.value !== selectedResolution.value) {
      this._updateParticipantDevices();
    }
    if (prevState.selectedAudioInput && selectedAudioInput && prevState.selectedAudioInput.deviceId !== selectedAudioInput.deviceId) {
      this._updateParticipantDevices();
    }
    if (prevState.selectedAudioOutput && selectedAudioOutput && prevState.selectedAudioOutput.deviceId !== selectedAudioOutput.deviceId) {
      this._updateParticipantDevices();
    }
  }

  _updateParticipantDevices = async () => {
    try {
      const { participant, activeEvent } = this.props;
      const { selectedAudioInput, selectedVideoInput, selectedAudioOutput, selectedResolution } = this.state;

      const { videoInput, audioInput, audioOutput } = participant.participant.selectedDevices;

      if (selectedVideoInput) {
        videoInput.deviceId = selectedVideoInput.deviceId;
        videoInput.label = selectedVideoInput.label;
      }

      if (selectedResolution) {
        const parts = selectedResolution.value.split('x');
        const width = parseInt(parts[0]);
        const height = parseInt(parts[1]);
        videoInput.width = width;
        videoInput.height = height;
      }

      if (selectedAudioInput) {
        audioInput.deviceId = selectedAudioInput.deviceId;
        audioInput.label = selectedAudioInput.label;
      }

      if (selectedAudioOutput) {
        audioOutput.deviceId = selectedAudioOutput.deviceId;
        audioOutput.label = selectedAudioOutput.label;
      }

      await put(`/participant/${participant.participant._id}`, { event: activeEvent._id, selectedDevices: { videoInput, audioInput, audioOutput } });
    } catch (error) {
      console.log(error);
    }
  };

  _populateLists = () => {
    const { participant } = this.props.participant;
    const audioInputs = [];
    const audioOutputs = [];
    const videoInputs = [];
    let resolutions = [];

    if (participant.availableDevices) {
      participant.availableDevices.forEach((device) => {
        if (device.kind === 'audioinput') {
          audioInputs.push({ label: device.label, deviceId: device.deviceId });
        } else if (device.kind === 'audiooutput') {
          audioOutputs.push({ label: device.label, deviceId: device.deviceId });
        } else if (device.kind === 'videoinput') {
          videoInputs.push({ label: device.label, deviceId: device.deviceId });
        }
      });
    }

    if (participant.supportedResolutions) {
      resolutions = participant.supportedResolutions.map((res) => ({ value: res, label: res }));
    }

    let selectedResolution;
    let selectedVideoInput;
    let selectedAudioInput;
    let selectedAudioOutput;
    let videoWidth;
    let videoHeight;

    const { selectedDevices } = participant;

    if (selectedDevices) {
      if (videoInputs.length && selectedDevices.videoInput) {
        selectedVideoInput = videoInputs.find((d) => d.deviceId === selectedDevices.videoInput.deviceId);
        if (selectedDevices.videoInput && selectedDevices.videoInput.width && selectedDevices.videoInput.height) {
          const resolution = `${selectedDevices.videoInput.width}x${selectedDevices.videoInput.height}`;
          videoWidth = selectedDevices.videoInput.width;
          videoHeight = selectedDevices.videoInput.height;
          selectedResolution = { value: resolution, label: resolution };
        }
      }
      if (audioInputs.length && selectedDevices.audioInput) {
        selectedAudioInput = audioInputs.find((d) => d.deviceId === selectedDevices.audioInput.deviceId);
      }
      if (audioOutputs.length && selectedDevices.audioOutput) {
        selectedAudioOutput = audioOutputs.find((d) => d.deviceId === selectedDevices.audioOutput.deviceId);
      }
    }

    this.setState({
      audioInputs,
      audioOutputs,
      videoInputs,
      resolutions,
      selectedAudioInput,
      selectedAudioOutput,
      selectedVideoInput,
      selectedResolution,
      videoWidth,
      videoHeight,
    });
  };

  _onVideoInputChange = (videoInput) => {
    this.setState(
      {
        selectedVideoInput: videoInput,
      },
      () => {
        const { participant } = this.props.participant;
        SocketClient.emit('change-input', { uuid: participant._id, type: 'videoinput', data: videoInput });
      },
    );
  };

  _onResolutionChange = (resolution) => {
    const parts = resolution.value.split('x');
    const videoWidth = parseInt(parts[0]);
    const videoHeight = parseInt(parts[1]);
    this.setState(
      {
        selectedResolution: resolution,
        videoWidth,
        videoHeight,
      },
      () => {
        const { participant } = this.props.participant;
        SocketClient.emit('change-input', {
          uuid: participant._id,
          type: 'resolution',
          data: { resolution: this.state.selectedResolution, deviceId: this.state.selectedVideoInput.deviceId },
        });
      },
    );
  };

  _onAudioInputChange = (audioInput) => {
    this.setState(
      {
        selectedAudioInput: audioInput,
      },
      () => {
        const { participant } = this.props.participant;
        SocketClient.emit('change-input', {
          uuid: participant._id,
          type: 'audioinput',
          data: audioInput,
        });
      },
    );
  };

  _onAudioOutputChange = (audioOutput) => {
    this.setState(
      {
        selectedAudioOutput: audioOutput,
      },
      () => {
        const { participant } = this.props.participant;
        SocketClient.emit('change-input', {
          uuid: participant._id,
          type: 'audiooutput',
          data: audioOutput,
        });
      },
    );
  };

  _bytesToSize = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) {
      return 'n/a';
    }
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) {
      return `${bytes} ${sizes[i]})`;
    }
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
  };

  _toggleWebRTCDetails = () => {
    const { showWebRTCDetails } = this.state;
    this.setState({ showWebRTCDetails: !showWebRTCDetails });
  };

  render() {
    const {
      selectedVideoInput,
      selectedAudioInput,
      selectedAudioOutput,
      selectedResolution,
      videoInputs,
      audioInputs,
      audioOutputs,
      resolutions,
      videoHeight,
      videoWidth,
      showWebRTCDetails,
    } = this.state;
    const { metadata, participant, connected, includeOffWall, socketConnected, allServersRunning, status, appMode, isAutomatedOnboardingEnabled } = this.props;
    let osName = '';
    let osVersion = '';
    let browserName = '';
    let browserVersion = '';
    let deviceType = '';
    let deviceVendor = '';
    let deviceModel = '';
    let isMacOs = false;

    if (participant && participant.participant && participant.participant.platform) {
      osName = participant.participant.platform.osName;
      browserName = participant.participant.platform.browserName;
      browserVersion = participant.participant.platform.browserVersion;
      osVersion = participant.participant.platform.osVersion;
      isMacOs = osName && osName.toUpperCase().indexOf('MAC') >= 0;
      deviceType = participant.participant.platform.deviceType;
      deviceVendor = participant.participant.platform.deviceVendor;
      deviceModel = participant.participant.platform.deviceModel;
    }

    const hiddenStyle = {
      pointerEvents: includeOffWall || !connected ? 'none' : 'auto',
      opacity: includeOffWall || !participant ? 0 : 1,
    };

    const isPando = appMode === 'PANDO';

    return (
      <>
        <div
          className='sidebar sidebar-left custom-scrollbar'
          style={{
            borderRight: '2px solid #1e272f',
            marginTop: includeOffWall ? 0 : 141,
            height: includeOffWall ? '100%' : 'calc(100% - 141px)',
            overflowX: 'hidden',
          }}
        >
          <div className='property-list '>
            {participant && isPando && (
              <>
                <Property
                  label='Supported Browser'
                  value={
                    participant.systemCheck && participant.systemCheck.isCompatibleBrowser === true ? (
                      <span style={{ color: 'green' }}>&#10004;</span>
                    ) : participant.systemCheck && participant.systemCheck.isCompatibleBrowser === false ? (
                      <span style={{ color: 'red' }}>X</span>
                    ) : (
                      ''
                    )
                  }
                />
                <Property
                  label='Browser Permissions'
                  value={
                    participant.systemCheck && participant.systemCheck.hasCamMicAccess === true ? (
                      <span style={{ color: 'green' }}>&#10004;</span>
                    ) : participant.systemCheck && participant.systemCheck.hasCamMicAccess === false ? (
                      <span style={{ color: 'red' }}>X</span>
                    ) : (
                      ''
                    )
                  }
                />
                <Property
                  label='Bandwidth'
                  value={
                    participant.systemCheck && participant.systemCheck.bandwidth !== null && participant.systemCheck.bandwidth >= 0
                      ? `${participant.systemCheck.bandwidth} Mbps`
                      : ''
                  }
                />
                <Property
                  label='Camera'
                  value={
                    participant.systemCheck && participant.systemCheck.canSeeSelf === true ? (
                      <span style={{ color: 'green' }}>&#10004;</span>
                    ) : participant.systemCheck && participant.systemCheck.canSeeSelf === false ? (
                      <span style={{ color: 'red' }}>X</span>
                    ) : (
                      ''
                    )
                  }
                />
                <Property
                  label='Loopback Video'
                  value={
                    participant.systemCheck && participant.systemCheck.canSeeLoopbackVideo === true ? (
                      <span style={{ color: 'green' }}>&#10004;</span>
                    ) : participant.systemCheck && participant.systemCheck.canSeeLoopbackVideo === false ? (
                      <span style={{ color: 'red' }}>X</span>
                    ) : (
                      ''
                    )
                  }
                />
                <Property
                  label='Photo Taken'
                  value={
                    participant.systemCheck && participant.systemCheck.photoTaken === true ? (
                      <span style={{ color: 'green' }}>&#10004;</span>
                    ) : participant.systemCheck && participant.systemCheck.photoTaken === false ? (
                      <span style={{ color: 'red' }}>X</span>
                    ) : (
                      ''
                    )
                  }
                />
                <Property
                  label='Microphone'
                  value={
                    participant.systemCheck && participant.systemCheck.canSeeAudioSignal === true ? (
                      <span style={{ color: 'green' }}>&#10004;</span>
                    ) : participant.systemCheck && participant.systemCheck.canSeeAudioSignal === false ? (
                      <span style={{ color: 'red' }}>X</span>
                    ) : (
                      ''
                    )
                  }
                />
                <Property
                  label='Speakers'
                  value={
                    participant.systemCheck && participant.systemCheck.canHearTestSound === true ? (
                      <span style={{ color: 'green' }}>&#10004;</span>
                    ) : participant.systemCheck && participant.systemCheck.canHearTestSound === false ? (
                      <span style={{ color: 'red' }}>X</span>
                    ) : (
                      ''
                    )
                  }
                />
                <Property
                  label='Audio Recording'
                  value={
                    participant.systemCheck && participant.systemCheck.canHearSelf === true ? (
                      <span style={{ color: 'green' }}>&#10004;</span>
                    ) : participant.systemCheck && participant.systemCheck.canHearSelf === false ? (
                      <span style={{ color: 'red' }}>X</span>
                    ) : (
                      ''
                    )
                  }
                />
                <Property label='Screening Completed' value={participant.screeningCompleted === true ? <span style={{ color: 'green' }}>&#10004;</span> : ''} />
                {videoHeight && videoWidth && <Property label='Resolution' value={`${videoWidth}x${videoHeight}`} />}
                <Property label='Public IP' value={participant.publicIp} />
                <Property label='DNS Name' value={participant.dnsName} />
                <Property label='Privacy' value={participant.privacy} />
                <Property label='Connect Count' value={participant.connectCount} />
                <Property label='Disconnect Count' value={participant.disconnectCount} />

                <Popover
                  isOpen={showWebRTCDetails}
                  body={<WebRTCDetails uploadData={participant.uploadConnectionType} downloadData={participant.downloadConnectionType} />}
                  onOuterAction={this._toggleWebRTCDetails}
                  tipSize={6}
                  place='right'
                  style={{ fill: '#1e272f', zIndex: 99999 }}
                >
                  <Property label='WebRTC Details' value={showWebRTCDetails ? 'Hide' : 'Show'} onClick={this._toggleWebRTCDetails} />
                </Popover>
              </>
            )}

            {participant && !isPando && (
              <>
                <Property label='Public IP' value={participant.publicIp} />
                <Property label='DNS Name' value={participant.dnsName} />
                <Property label='Connect Count' value={participant.connectCount} />
                <Property label='Disconnect Count' value={participant.disconnectCount} />
                <Property label='Device Type' value={deviceType} />
                <Property label='Device Vendor' value={deviceVendor} />
                <Property label='Device Model' value={deviceModel} />
                <Property label='OS' value={`${osName} ${osVersion}`} />
                <Property label='Browser' value={`${browserName} ${browserVersion}`} />
              </>
            )}

            {metadata ? (
              <>
                <Property label='Bytes Received' value={`${this._bytesToSize(metadata.videoBytesReceived + metadata.audioBytesReceived)}`} />
                <Property label='Bytes Sent' value={`${this._bytesToSize(metadata.videoBytesSent + metadata.audioBytesSent)}`} />
              </>
            ) : null}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            justifyContent: isPando ? 'space-between' : 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          {isPando && (
            <div className='media' style={hiddenStyle}>
              {this.props.children}
              <div className='platform'>
                <span>{`${osName} ${osVersion}`}</span>
                <span>{`${browserName} ${browserVersion}`}</span>
              </div>
            </div>
          )}
          {this.props.participant && (
            <div className='actions' style={{ marginBottom: 20, display: 'flex' }}>
              {isPando && isAutomatedOnboardingEnabled && (
                <Button
                  type='secondary'
                  icon={sendToOnboardingIcon}
                  text=''
                  containerStyle={styles.actionButton}
                  iconStyle={{ margin: 0 }}
                  onClick={this.props.onSendToOnboarding}
                  disabled={status === 'ONBOARDING' || status === 'NOT_CONNECTED' || status === 'ARCHIVED' || !socketConnected || includeOffWall}
                  tooltip='Onboarding'
                />
              )}
              {isPando && (
                <Button
                  type='secondary'
                  icon={screeningQueueIcon}
                  text=''
                  containerStyle={styles.actionButton}
                  iconStyle={{ margin: 0 }}
                  onClick={this.props.toScreeningQueue}
                  disabled={
                    status === 'ONBOARDING' ||
                    status === 'IN_SCREENING_QUEUE' ||
                    status === 'NOT_CONNECTED' ||
                    status === 'ARCHIVED' ||
                    !socketConnected ||
                    includeOffWall
                  }
                  tooltip='Screening'
                />
              )}
              <Button type='lightBlue' containerStyle={styles.actionButton} onClick={this.props.onRefresh} tooltip='Refresh Details'>
                <FontAwesomeIcon icon={faSync} color='#fff' style={{ cursor: 'pointer', fontSize: 15 }} />
              </Button>
              {isPando && (
                <Button
                  type='primary'
                  icon={connectIcon}
                  text=''
                  onClick={this.props.onConnect}
                  containerStyle={styles.actionButton}
                  iconStyle={{ margin: 0 }}
                  disabled={
                    connected || status !== 'IN_SCREENING_QUEUE' || !socketConnected || !allServersRunning || !participant.hasWebRTCConnection || includeOffWall
                  }
                  tooltip='Connect'
                />
              )}
              <Button
                type='secondary'
                icon={archiveIcon}
                text=''
                containerStyle={styles.actionButton}
                iconStyle={{ margin: 0 }}
                onClick={this.props.onArchive}
                disabled={status === 'ONBOARDING' || status === 'ARCHIVED' || !socketConnected}
                tooltip='Archive'
              />
              <Button
                type='secondary'
                icon={trashIcon}
                text=''
                containerStyle={styles.actionButton}
                iconStyle={{ margin: 0, width: 26, height: 26 }}
                onClick={this.props.onRemove}
                disabled={status === 'ONBOARDING' || status === 'ARCHIVED' || status === 'REMOVED' || !socketConnected}
                tooltip='Remove'
              />
              {isPando && (
                <Button
                  type='secondary'
                  icon={studioQueueIcon}
                  text=''
                  containerStyle={styles.actionButton}
                  iconStyle={{ margin: 0 }}
                  onClick={this.props.toStudioQueue}
                  disabled={
                    status === 'ONBOARDING' ||
                    status === 'IN_STUDIO_QUEUE' ||
                    status === 'NOT_CONNECTED' ||
                    status === 'ARCHIVED' ||
                    !socketConnected ||
                    includeOffWall
                  }
                  tooltip='Studio'
                />
              )}
            </div>
          )}
        </div>
        <div className='sidebar' style={{ borderLeft: '2px solid #1e272f' }}>
          {this.props.participant && isPando && (
            <div className='media-devices' style={hiddenStyle}>
              <div className='device-list-container'>
                <div className='label'>Video</div>
                <Select
                  list={videoInputs}
                  listKey='deviceId'
                  listLabel='label'
                  onChange={this._onVideoInputChange}
                  selected={selectedVideoInput && selectedVideoInput.deviceId}
                  currentOption={selectedVideoInput && selectedVideoInput.label}
                  containerStyle={{ width: '100%' }}
                  small
                  disabled={!this.props.connected}
                />
              </div>
              <div className='device-list-container'>
                <div className='label'>Resolution</div>
                <Select
                  list={resolutions}
                  listKey='value'
                  listLabel='label'
                  onChange={this._onResolutionChange}
                  selected={selectedResolution && selectedResolution.value}
                  currentOption={selectedResolution && selectedResolution.label}
                  containerStyle={{ width: '100%' }}
                  small
                  disabled={!this.props.connected}
                />
              </div>
              <div className='device-list-container'>
                <div className='label'>Mic.</div>
                <Select
                  list={audioInputs}
                  listKey='deviceId'
                  listLabel='label'
                  onChange={this._onAudioInputChange}
                  selected={selectedAudioInput && selectedAudioInput.deviceId}
                  currentOption={selectedAudioInput && selectedAudioInput.label}
                  containerStyle={{ width: '100%' }}
                  small
                  disabled={!this.props.connected}
                />
              </div>
              <div className='device-list-container'>
                <div className='label'>Speaker</div>
                <Select
                  list={audioOutputs}
                  listKey='deviceId'
                  listLabel='label'
                  onChange={this._onAudioOutputChange}
                  selected={selectedAudioOutput && selectedAudioOutput.deviceId}
                  currentOption={selectedAudioOutput && selectedAudioOutput.label}
                  containerStyle={{ width: '100%' }}
                  small
                  disabled={!this.props.connected || browserName === 'Safari'}
                />
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

const styles = {
  actionButton: {
    margin: '0 5px',
    padding: 5,
    width: 36,
    height: 36,
  },
};

const mapStateToProps = (state, ownProps) => {
  const { participants, active } = state.events;
  if (participants) {
    const epId = ownProps.participant && ownProps.participant._id;
    const eventParticipant = participants.find((p) => p._id === epId);
    const status = eventParticipant ? eventParticipant.status : null;
    return {
      status,
      activeEvent: active,
      allServersRunning: state.goLive.allServersRunning,
      socketConnected: state.ui.socketConnected,
      appMode: state.ui.appMode,
    };
  }
  return {};
};

export default connect(mapStateToProps, null)(ScreeningArea);
