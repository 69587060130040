import React from 'react';
import PropTypes from 'prop-types';
import '../css/Input.css';

function Input(props) {
  const { label, id, onChange, stacked, disabled, containerStyle, inputStyle, ...rest } = props;
  const input = <input id={id} {...rest} onChange={onChange} autoComplete='off' disabled={disabled} style={inputStyle} />;
  if (stacked) {
    return (
      <div className='stacked-input' style={containerStyle}>
        {label && <label htmlFor={id}>{label}</label>}
        {input}
      </div>
    );
  } else {
    return (
      <div className='input-wrapper' style={containerStyle}>
        <label htmlFor={id} className='input-label'>
          {label || null}
          {input}
        </label>
      </div>
    );
  }
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'number', 'password']).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  stacked: PropTypes.bool,
};

Input.defaultProps = {
  value: '',
  id: '',
  label: '',
};

export default Input;
