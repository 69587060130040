import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import Checkbox from '../../common/Checkbox';
import { PollTypes, PollInputTypes, PrefixAnswersWith } from '../../utils/polls';

import CustomRadio from './CustomRadio';

const styles = {
  questionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    background: '#24313F',
  },
  title: {
    textAlign: 'left',
    color: '#a9aaab',
    marginBottom: 5,
  },
  question: {
    fontSize: 18,
    padding: '15px 5px',
    textAlign: 'left',
  },
  listContainer: {
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    margin: '5px',
  },
  list: {
    margin: 0,
  },
  listItem: {
    textAlign: 'left',
    listStyle: 'none',
    wordWrap: 'anywhere',
  },
};

function PollPreview({ data, prefixAnswersWith, correctAnswerIndex, pollType, inputType }) {
  const [question, setQuestion] = useState(null);
  const [responses, setResponses] = useState([]);

  useEffect(() => {
    if (data) {
      const parts = data.split('\n').filter((d) => !!d);
      const _responses = [];

      parts.forEach((p, i) => {
        if (i === 0) {
          setQuestion(p);
        } else {
          _responses.push(p);
        }
      });

      setResponses(_responses);
    } else {
      setQuestion(null);
      setResponses([]);
    }
  }, [data]);

  const _renderResponses = useCallback(() => {
    const r = responses.map((resp, i) => {
      const id = `poll-response-${i}`;
      let label;
      if (prefixAnswersWith === PrefixAnswersWith.NOTHING) {
        label = resp;
      } else if (prefixAnswersWith === PrefixAnswersWith.LETTERS) {
        label = `${String.fromCharCode(97 + i).toUpperCase()}. ${resp}`;
      } else if (prefixAnswersWith === PrefixAnswersWith.NUMBERS) {
        label = `${i + 1}. ${resp}`;
      }

      if (pollType === PollTypes.POLL && inputType === PollInputTypes.CHECKBOX) {
        return (
          <li key={id} style={styles.listItem}>
            <Checkbox label={label} value={false} onChange={() => {}} style={{ marginBottom: 10, alignItems: 'flex-start' }} />
          </li>
        );
      }
      return (
        <li key={id} style={styles.listItem}>
          <CustomRadio label={label} name='answer' value={resp} checked={correctAnswerIndex === i} onChange={() => {}} style={{ pointerEvents: 'none' }} />
        </li>
      );
    });
    return r;
  }, [responses, prefixAnswersWith, correctAnswerIndex, pollType, inputType]);

  return (
    <>
      <div style={styles.title}>Preview</div>
      <div style={styles.questionsContainer}>
        {question && <div style={styles.question}>{question}</div>}
        <div className='custom-scrollbar' style={styles.listContainer}>
          <ul style={{ ...styles.list, paddingLeft: 5 }}>{_renderResponses()}</ul>
        </div>
      </div>
    </>
  );
}

PollPreview.propTypes = {
  data: PropTypes.string.isRequired,
  prefixAnswersWith: PropTypes.number.isRequired,
  correctAnswerIndex: PropTypes.number.isRequired,
  pollType: PropTypes.number.isRequired,
  inputType: PropTypes.number.isRequired,
};

export default PollPreview;
