import React from 'react';

const SelectListOptions = (props) => {
  const { handleOptionsEvents, setOptionRef, focusedOption, options, listKey, listLabel, selected, small, listContainerStyle, hideOption } = props;

  let customContainerStyle = {};
  let customLabelStyle = {};

  if (small) {
    customContainerStyle = { height: 24 };
    customLabelStyle = { fontSize: 13 };
  }

  let optionsList = null;
  if (options && options.length) {
    optionsList = options.map((opt, index) => {
      if (hideOption && hideOption === opt[listKey]) {
        return null;
      }

      let optionClass = 'option';
      if (focusedOption === opt[listKey]) {
        optionClass += ' active';
      }
      if (selected === opt[listKey]) {
        optionClass += ' selected';
      }
      return (
        <div
          tabIndex='0'
          role='option'
          id={opt[listKey]}
          aria-selected={focusedOption === opt[listKey]}
          key={opt[listKey]}
          onClick={(e) => handleOptionsEvents(opt, index, e)}
          onKeyDown={(e) => handleOptionsEvents(opt, index, e)}
          ref={setOptionRef}
          aria-posinset={index}
          aria-setsize={opt[listLabel].length}
          className={optionClass}
          style={customContainerStyle}
        >
          <div className='option-label' style={customLabelStyle}>
            {opt[listLabel]}
          </div>
        </div>
      );
    });
  }

  return (
    <div className='select-list-options' style={listContainerStyle}>
      {optionsList}
    </div>
  );
};

export default SelectListOptions;
