import React from 'react';
import cameraIcon from '../../../assets/icons/camera_alt.svg';

export default function StudioFeed() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        color: '#999999',
        background: '#171F27',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <img src={cameraIcon} alt='studio feed icon' style={{ width: '15%' }} />
      <span style={{ fontSize: '1rem' }}>Studio Feed</span>
    </div>
  );
}
