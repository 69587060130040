import { createActions, handleActions } from 'redux-actions';

const initialState = {
  fetching: false,
  error: null,
};

export const actions = {
  UPDATE_PARTICIPANTS_IN_BULK_REQUEST: 'UPDATE_PARTICIPANTS_IN_BULK_REQUEST',
  UPDATE_PARTICIPANTS_IN_BULK_SUCCESS: 'UPDATE_PARTICIPANTS_IN_BULK_SUCCESS',
  UPDATE_PARTICIPANTS_IN_BULK_FAILURE: 'UPDATE_PARTICIPANTS_IN_BULK_FAILURE',
};

export const { updateParticipantsInBulkRequest, updateParticipantsInBulkSuccess, updateParticipantsInBulkFailure } = createActions({
  [actions.UPDATE_PARTICIPANTS_IN_BULK_REQUEST]: (data, event, layout) => ({
    data,
    event,
    layout,
  }),
  [actions.UPDATE_PARTICIPANTS_IN_BULK_SUCCESS]: (data) => ({ data }),
  [actions.UPDATE_PARTICIPANTS_IN_BULK_FAILURE]: (error) => ({ error }),
});

const reducer = handleActions(
  {
    [actions.UPDATE_PARTICIPANTS_IN_BULK_REQUEST]: (state) => ({
      ...state,
      fetching: true,
      error: null,
    }),
    [actions.UPDATE_PARTICIPANTS_IN_BULK_SUCCESS]: (state) => {
      return {
        ...state,
        fetching: false,
        error: null,
      };
    },
    [actions.UPDATE_PARTICIPANTS_IN_BULK_FAILURE]: (state, action) => ({
      ...state,
      fetching: false,
      error: action.payload.error,
    }),
  },
  initialState,
);

export default reducer;
