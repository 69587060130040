import React, { PureComponent } from 'react';
import swal from 'sweetalert';
import PropTypes from 'prop-types';
import Button from '../../common/Button';
import TextInput from '../../common/TextInput';
import Select from '../../common/Select';
import Switch from '../../common/Switch';
import userRoles from './user-roles';
import { connect } from 'react-redux';

class UserForm extends PureComponent {
  static propTypes = {
    edit: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    edit: false,
  };

  state = {
    username: '',
    password: '',
    hidePassword: true,
    selectedRole: null,
    availableRoles: userRoles,
    broadcastEnabled: false,
  };

  componentDidMount() {
    const { mainUser } = this.props;
    let availableRoles = userRoles;
    if (mainUser.role !== 'SUPER_ADMIN') {
      availableRoles = availableRoles.filter((obj) => obj.value !== 'SUPER_ADMIN');
    }
    this.setState({
      availableRoles,
      selectedRole: { ...availableRoles[0] },
    });
  }

  _onChangeHandler = (event) => {
    const { target } = event;
    let { hidePassword } = this.state;
    if (target.name === 'password' && !hidePassword) {
      hidePassword = true;
    }

    this.setState({ [target.name]: target.value, hidePassword });
  };

  _onSubmitHandler = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { onSubmit, edit } = this.props;
    const { username, password, selectedRole, broadcastEnabled } = this.state;

    if ((username === '' || password === '') && edit === false) {
      swal({
        title: 'Error',
        text: 'Username and password are required',
      });
      return false;
    }

    if (username === '' && edit === true) {
      swal({
        title: 'Error',
        text: 'Username is required',
      });
      return false;
    }

    if (password !== '' && password.length < 8) {
      swal({
        title: 'Error',
        text: 'Password should be at least 8 characters in length',
      });
      return false;
    }

    onSubmit({ username, password, role: selectedRole.value, broadcastEnabled });
  };

  _onRoleChange = (selectedRole) => {
    this.setState({ selectedRole });
  };

  _setGeneratedPassword = (password) => {
    this.setState({ password, hidePassword: false });
  };

  render() {
    const { edit } = this.props;
    const { username, password, selectedRole, hidePassword, availableRoles, broadcastEnabled } = this.state;
    return (
      <div className='modal-form'>
        <div className='modal-form-header'>
          <h3>{edit ? 'Edit User' : 'Add User'}</h3>
        </div>
        <form onSubmit={this._onSubmitHandler} autoComplete='off'>
          <div className='modal-form-content'>
            <TextInput name='username' label='Username' value={username} onChange={this._onChangeHandler} />
            <TextInput
              name='password'
              label='Password'
              secure={hidePassword}
              value={password}
              onChange={this._onChangeHandler}
              generatePassword={true}
              onPasswordGenerated={this._setGeneratedPassword}
            />
            <div className='form-group'>
              <div className='form-label'>Role</div>
              <div className='form-control'>
                <Select
                  list={availableRoles}
                  listKey='value'
                  listLabel='label'
                  onChange={this._onRoleChange}
                  selected={selectedRole && selectedRole.value}
                  currentOption={selectedRole && selectedRole.label}
                  small
                />
              </div>
            </div>
            {selectedRole && (selectedRole.value === 'SUPER_ADMIN' || selectedRole.value === 'ADMIN') && (
              <div className='form-group'>
                <div className='form-label'>Broadcast Enabled</div>
                <div className='form-control'>
                  <Switch
                    label=''
                    onChange={() => this.setState({ broadcastEnabled: !broadcastEnabled })}
                    checked={broadcastEnabled}
                    height={16}
                    width={34}
                    containerStyle={{ marginBottom: 12 }}
                  />
                </div>
              </div>
            )}
          </div>
          <div className='modal-form-footer'>
            <Button text='Save' submit />
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mainUser: state.auth.user,
});

export default connect(mapStateToProps, null)(UserForm);
