export const EVENT_SUPPORT = 'Event Support';
export const WALL_PARTICIPANT = 'Wall Participant';
export const WALL_PRESENTER = 'Wall Presenter';
export const OFF_WALL_PARTICIPANT = 'Off-wall Participant';
export const OFF_WALL_ADMIN = 'Off-wall Admin';
export const OBSERVER = 'Observer';
export const VENUE_OFF_WALL = 'Venue Off-wall';
export const VENUE_ON_WALL = 'Venue On-wall';
export const VENUE_PARTICIPANT = 'Venue Participant';

export const OFF_WALL_ROLES = [OFF_WALL_ADMIN, OFF_WALL_PARTICIPANT, OBSERVER];
export const ON_WALL_ROLES = [EVENT_SUPPORT, WALL_PARTICIPANT, WALL_PRESENTER];
