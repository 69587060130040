export const PrefixAnswersWith = {
  NOTHING: 0,
  LETTERS: 1,
  NUMBERS: 2,
};

export const DisplayResultLabelsAs = {
  NUMBERS: 0,
  PERCENTAGES: 1,
  BOTH: 2,
};

export const PollTypes = {
  POLL: 0,
  QUESTION: 1,
};

export const PollInputTypes = {
  RADIO: 0,
  CHECKBOX: 1,
};

export const SpreadsheetDelimiters = {
  NEW_LINE: 0,
  PIPE: 1,
};
