import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  updateConfigRequest,
  createConfigRequest,
  getStudiosRequest,
  setStudioConfig,
  getStudioById,
  canUpdate,
  setActiveStudio,
  selectStudio,
} from '../../redux/studioRedux';
import WallLayout from '../../common/WallLayout';
import { updateRoutes } from '../../redux/uiRedux';
import { getAwsRegionsRequest } from '../../redux/goLiveRedux';
import { isSuperAdmin } from '../../redux/authRedux';
import StudioForm from './StudioForm';
import StudioList from './StudioList';
import UnconfiguredStudio from './UnconfiguredStudio';
import InstancesList from './InstanceList';

class Studio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxHeight: window.innerHeight - 444,
      maxWidth: props.collapsed === true ? window.innerWidth - 68 - 120 : window.innerWidth - 200 - 120,
    };
  }

  componentDidMount() {
    const { getStudios, getAwsRegions } = this.props;
    getAwsRegions();
    getStudios();

    window.addEventListener('resize', this._resizeWallLayout);
    window.addEventListener('devtoolschange', this._resizeWallLayout);
  }

  async componentDidUpdate(prevProps) {
    const { error, list, activeStudio, selectStudio, collapsed } = this.props;

    if (prevProps.error !== error && error) {
      alert(error);
    }

    if (prevProps.collapsed !== collapsed) {
      this._resizeWallLayout();
    }

    if (prevProps.list !== list && list.length) {
      if (activeStudio) {
        selectStudio(activeStudio._id);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._resizeWallLayout);
    window.removeEventListener('devtoolschange', this._resizeWallLayout);
  }

  _resizeWallLayout = () => {
    const { collapsed } = this.props;
    this.setState({
      maxHeight: window.innerHeight - 444,
      maxWidth: collapsed === true ? window.innerWidth - 68 - 120 : window.innerWidth - 200 - 120,
    });
  };

  _onChangeHandler = (data) => {
    const { rows, columns } = data;
    const { setStudioConfig } = this.props;
    setStudioConfig(rows, columns);
  };

  _onFormSubmission = (data) => {
    const { rows, columns, awsRegion, awsDefaultAvailabilityZone, pptControllerURL, offWallStreamUrl } = data;
    const { updateConfig, selected, createConfig, updateRoutes, activeStudio, setActiveStudio } = this.props;
    const janusServers = JSON.parse(data.janusServers);
    if (rows !== 0 && columns !== 0) {
      if (selected !== null) {
        updateConfig(selected, { rows, columns, awsRegion, awsDefaultAvailabilityZone, pptControllerURL, offWallStreamUrl, janusServers });
        if (selected === activeStudio._id) {
          const studio = { ...activeStudio };
          studio.rows = rows;
          studio.columns = columns;
          studio.awsRegion = awsRegion;
          studio.awsDefaultAvailabilityZone = awsDefaultAvailabilityZone;
          studio.pptControllerURL = pptControllerURL;
          studio.offWallStreamUrl = offWallStreamUrl;
          studio.janusServers = janusServers;
          localStorage.setItem('activeStudio', JSON.stringify(studio));
          setActiveStudio(studio);
        }
        updateRoutes();
      } else {
        createConfig({ rows, columns, awsRegion, awsDefaultAvailabilityZone, pptControllerURL, offWallStreamUrl, janusServers });
      }
    } else {
      alert('Rows and columns should be greater than 0');
    }
  };

  render() {
    const { list, canUpdate, isSuperAdmin, selectedStudioData } = this.props;
    const { maxWidth, maxHeight } = this.state;

    let rows = 0;
    let columns = 0;
    if (selectedStudioData) {
      rows = selectedStudioData.rows;
      columns = selectedStudioData.columns;
    }

    return (
      <div className='studio-container'>
        <div className='studio-layout'>
          {rows > 0 && columns > 0 ? <WallLayout rows={rows} columns={columns} maxWidth={maxWidth} maxHeight={maxHeight} /> : <UnconfiguredStudio />}
        </div>
        <div className='studio-configuration'>
          <div className='studio-list'>
            <StudioList data={list} isSuperAdmin={isSuperAdmin} />
          </div>
          <div className='studio-form'>
            {selectedStudioData ? (
              <>
                <StudioForm data={selectedStudioData} onSubmit={this._onFormSubmission} onChange={this._onChangeHandler} canUpdate={canUpdate} />
                {isSuperAdmin && <InstancesList studio={selectedStudioData} />}
              </>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  fetching: state.studio.fetching,
  error: state.studio.error,
  selected: state.studio.selected,
  selectedStudioData: getStudioById(state, state.studio.selected),
  canUpdate: canUpdate(state),
  list: state.studio.list,
  activeStudio: state.studio.active,
  isSuperAdmin: isSuperAdmin(state),
  collapsed: state.ui.navBarCollapsed,
});

const mapDispatchToProps = (dispatch) => ({
  updateConfig: (id, data) => dispatch(updateConfigRequest(id, data)),
  createConfig: (config) => dispatch(createConfigRequest(config)),
  getStudios: () => dispatch(getStudiosRequest()),
  setStudioConfig: (rows, columns) => dispatch(setStudioConfig(rows, columns)),
  updateRoutes: () => dispatch(updateRoutes()),
  setActiveStudio: (id) => dispatch(setActiveStudio(id)),
  getAwsRegions: () => dispatch(getAwsRegionsRequest()),
  selectStudio: (studio) => dispatch(selectStudio(studio)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Studio);
