import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert';

import ApiKeyForm from './ApiKeyForm';
import ApiKeyListItem from './ApiKeyListItem';
import ApiKeyDetail from './ApiKeyDetail';

import List from '../../common/List';
import { getApiKeysRequest, createApiKeyRequest, updateApiKeyRequest, deleteApiKeyRequest } from '../../redux/apiKeyRedux';
import Modal from '../../common/Modal';
import addIcon from '../../assets/icons/add.svg';
import '../../css/Users.css';

class ApiKeys extends PureComponent {
  state = {
    showModal: false,
    selected: null,
  };

  componentDidMount() {
    this.props.getApiKeys();
  }

  componentDidUpdate(prevProps) {
    const { successful } = this.props;
    if (prevProps.successful !== successful && successful === true) {
      this.props.getApiKeys();
    }
  }

  _openModal = () => {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  };

  _closeModal = () => {
    this.setState({
      showModal: false,
    });
  };

  _onSelectHandler = (item) => {
    this.setState({ selected: undefined }, () => {
      this.setState({ selected: item });
    });
  };

  _onDeleteHandler = async () => {
    const { deleteApiKey } = this.props;
    const { selected } = this.state;
    const willDelete = await swal({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete this API Key?',
      buttons: true,
      dangerMode: true,
    });
    if (willDelete) {
      deleteApiKey(selected._id);
    }
  };

  _onUpdateHandler = (data) => {
    const { updateApiKey } = this.props;
    const { selected } = this.state;
    if (selected !== null) {
      updateApiKey(selected._id, data);
    }
  };

  _onSubmitHandler = (data) => {
    const { createApiKey } = this.props;
    createApiKey(data);
    this.setState({
      showModal: false,
      selected: null,
    });
  };

  render() {
    const { apiKeys } = this.props;
    const { showModal, selected } = this.state;
    return (
      <div className='users-container'>
        <div className='users-content'>
          <div className='user-list'>
            <List
              data={apiKeys}
              renderItem={(item) => <ApiKeyListItem onSelect={this._onSelectHandler} item={item} selected={selected && selected._id === item._id} />}
              containerStyle={{ height: 'calc(100% - 62px)' }}
            />
            <div className='add-user' onClick={this._openModal}>
              <img src={addIcon} alt='add' style={{ marginLeft: 15 }} />
              Add API Key
            </div>
          </div>
          {selected ? <ApiKeyDetail data={selected} onSave={this._onUpdateHandler} onDelete={this._onDeleteHandler} /> : null}
        </div>
        <Modal show={showModal} onClose={this._closeModal}>
          <ApiKeyForm onSubmit={this._onSubmitHandler} />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  apiKeys: state.apiKeys.list,
  successful: state.apiKeys.successful,
});

const mapDispatchToProps = (dispatch) => ({
  getApiKeys: () => dispatch(getApiKeysRequest()),
  createApiKey: (data) => dispatch(createApiKeyRequest(data)),
  deleteApiKey: (id) => dispatch(deleteApiKeyRequest(id)),
  updateApiKey: (id, data) => dispatch(updateApiKeyRequest(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiKeys);
