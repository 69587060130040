import { get } from '../services/api';

const cache = {};

export default class ImageCache {
  static async get(url) {
    if (!cache[url]) {
      cache[url] = new Promise(async (resolve) => {
        const file = await get(`/file/?path=${url}&t=${Date.now()}`, null, 'arraybuffer');
        const image = btoa(new Uint8Array(file.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
        resolve(`data:image;base64,${image}`);
      });
    }
    const base64Img = await cache[url];
    return base64Img;
  }
  static remove(url) {
    delete cache[url];
  }
}
