import { all, takeLatest, call, put, delay } from 'redux-saga/effects';
import { get, post, put as update, del } from '../services/api';
import {
  actions,
  getLayoutsSuccess,
  getLayoutsFailure,
  createLayoutSuccess,
  createLayoutFailure,
  updateLayoutSuccess,
  updateLayoutFailure,
  resetLayout,
  loadAreas,
  loadLayoutSuccess,
  loadLayoutFailure,
  deleteLayoutFailure,
  deleteLayoutSuccess,
  updateLayoutsOrderFailure,
  updateLayoutsOrderSuccess,
  duplicateLayoutSuccess,
  duplicateLayoutFailure,
} from '../redux/layoutsRedux';

export function* fetchLayouts(action) {
  try {
    const { eventId, studioId } = action.payload;
    const response = yield call(get, `/layout?event=${eventId}&studio=${studioId}`);
    if (response.status === 200) {
      yield put(getLayoutsSuccess(response.data));
    } else {
      yield put(getLayoutsFailure({ status: response.status, message: response.data }));
    }
  } catch (error) {
    yield put(getLayoutsFailure(error));
  }
}

export function* createLayout(action) {
  try {
    const { layout } = action.payload;
    const created = yield call(post, '/layout/', layout);
    if (created.status === 200) {
      yield put(createLayoutSuccess(created.data));
    } else {
      yield put(createLayoutFailure({ status: created.status, message: created.data }));
    }
  } catch (error) {
    yield put(createLayoutFailure(error));
  }
}

export function* updateLayout(action) {
  try {
    const { data, id } = action.payload;
    const response = yield call(update, `/layout/${id}`, data);
    if (response.status === 200) {
      yield put(updateLayoutSuccess(response.data));
    } else {
      yield put(
        updateLayoutFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(updateLayoutFailure(error));
  }
}

export function* setLayout(action) {
  try {
    const { layout } = action.payload;
    if (layout) {
      yield put(resetLayout());
      // Needed to wait for the dom to reset
      yield delay(100);
      yield put(loadAreas(action.payload));
      yield put(loadLayoutSuccess(layout));
    } else {
      yield put(loadLayoutFailure());
    }
  } catch (error) {
    yield put(loadLayoutFailure());
  }
}

export function* deleteLayout(action) {
  try {
    const { id } = action.payload;
    const response = yield call(del, `/layout/${id}`);
    if (response.status === 200) {
      yield put(deleteLayoutSuccess({ id }));
    } else {
      yield put(
        deleteLayoutFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(deleteLayoutFailure(error));
  }
}

export function* orderLayouts(action) {
  try {
    const { list } = action.payload;
    const response = yield call(post, '/layout/updateOrder', { list });
    if (response.status === 200) {
      yield put(updateLayoutsOrderSuccess(response.data));
    } else {
      yield put(
        updateLayoutsOrderFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(updateLayoutsOrderFailure(error));
  }
}

export function* duplicateLayout(action) {
  try {
    const { layout, event } = action.payload;
    const duplicated = yield call(post, `/layout/${layout}/duplicate`, { event });
    if (duplicated.status === 200) {
      yield put(duplicateLayoutSuccess(duplicated.data));
    } else {
      yield put(duplicateLayoutFailure({ status: duplicated.status, message: duplicated.data }));
    }
  } catch (error) {
    yield put(duplicateLayoutFailure(error));
  }
}

export default function* () {
  yield all([
    takeLatest([actions.GET_LAYOUTS_REQUEST], fetchLayouts),
    takeLatest([actions.CREATE_LAYOUT_REQUEST], createLayout),
    takeLatest([actions.UPDATE_LAYOUT_REQUEST], updateLayout),
    takeLatest([actions.SET_LAYOUT], setLayout),
    takeLatest([actions.DELETE_LAYOUT_REQUEST], deleteLayout),
    takeLatest([actions.UPDATE_LAYOUTS_ORDER_REQUEST], orderLayouts),
    takeLatest([actions.DUPLICATE_LAYOUT_REQUEST], duplicateLayout),
  ]);
}
