import React from 'react';

export default function HorizontalBar({ label, title, value, valueLabel, colorMap, showValue = false, rank }) {
  const bgColor = colorMap.find((c) => value >= c.value).color;
  return (
    <div className='horizontal-bar'>
      <div className='horizontal-bar-label'>{label}</div>
      <div className='bar-container'>
        {rank && <span className='bar-value'>{`#${rank}`}</span>}
        <div className='bar' title={title} style={{ width: value === 0 ? 2 : `${value}%`, backgroundColor: bgColor }} />
        {showValue && <span className='bar-value'>{valueLabel || value}</span>}
      </div>
    </div>
  );
}
