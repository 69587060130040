import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import NavBar from './common/NavBar/NavBar';
import TopBar from './common/TopBar';
import { PANDO_ROUTES, BROADCAST_ROUTES } from './Routes';

const Layout = (props) => {
  const { match, user, collapsed, loading, numStudios, selectedTile } = props;
  const routes = selectedTile === 'broadcast' ? BROADCAST_ROUTES : PANDO_ROUTES;
  let allowedRoutes = routes.filter((route) => route.allowedRoles.includes(user.role));
  if (numStudios === 1 && user.role !== 'SUPER_ADMIN') {
    allowedRoutes = allowedRoutes.splice(1);
  }
  const [pageTitle, setPageTitle] = useState(undefined);
  const isAdmin = user.role === 'ADMIN' || user.role === 'SUPER_ADMIN';

  return (
    <div className='app'>
      <NavBar routes={allowedRoutes} />
      <div
        className='body'
        style={{
          width: collapsed ? 'calc(100% - 68px)' : 'calc(100% - 200px)',
        }}
      >
        <TopBar showSettings={isAdmin} showSystemEvents={isAdmin} pageTitle={pageTitle} />
        {!loading && (
          <div className='route-container'>
            {allowedRoutes.map((r, i) => {
              const Page = r.component;
              return (
                <Route
                  exact
                  key={i}
                  path={`${match.url}${r.path}`}
                  render={(props) => {
                    setPageTitle(r.label.toUpperCase());
                    return <Page {...props} />;
                  }}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Layout;
