import { store } from '../redux';

export function findEventParticipantById(participantId) {
  const state = store.getState();
  const { participants } = state.events;
  return participants && participants.find((p) => p.participant && p.participant._id === participantId);
}

export function findEventParticipantByDisplayNum(num, statuses) {
  const state = store.getState();
  const { participants } = state.events;
  return participants && participants.find((p) => p.wallDisplayNum === num && statuses.includes(p.status));
}
