import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setTvLines } from '../../redux/layoutsRedux';
import WallLayout from '../../common/WallLayout';
import TvGridLine from './TvGridLine';
import LayoutAreas from './LayoutAreas';

class LayoutGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ref: null,
      width: 0,
      height: 0,
      maxHeight: window.innerHeight - 444,
      maxWidth: props.collapsed === true ? window.innerWidth - 68 - 120 : window.innerWidth - 200 - 120,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this._resizeWallLayout);
    window.addEventListener('devtoolschange', this._resizeWallLayout);
    this._resizeWallLayout();
  }

  componentDidUpdate(prevProps, prevState) {
    const { collapsed } = this.props;
    const { ref } = this.state;
    if (prevProps.collapsed !== collapsed) {
      this._resizeWallLayout();
    }
    if (prevState.ref !== ref && ref) {
      this._resizeWallLayout();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._resizeWallLayout);
    window.removeEventListener('devtoolschange', this._resizeWallLayout);
  }

  _resizeWallLayout = () => {
    const { collapsed } = this.props;
    if (this.state.ref) {
      const { width, height } = this.state.ref.getBoundingClientRect();
      this.setState({
        width,
        height,
        maxHeight: window.innerHeight - 444,
        maxWidth: collapsed === true ? window.innerWidth - 68 - 120 : window.innerWidth - 200 - 120,
      });
    }
  };

  _renderTVAndGridLines = () => {
    const { activeStudio, setTvLines } = this.props;
    const { rows, columns } = activeStudio;
    const { width, height } = this.state;

    if (width !== 0 && height !== 0) {
      const gridLines = [];
      const verticalLines = [];
      const horizontalLines = [];

      const screenWidth = width / columns;
      const screenHeight = height / rows;

      for (let x = 0; x <= columns; x += 1) {
        const left = x * screenWidth;
        verticalLines.push(left);
        let color = '#20B1EE';
        let borderWidth = 1;
        if (x % 3 === 0 && x > 0 && x < columns) {
          color = '#287737';
          borderWidth = 2;
        }
        gridLines.push(<TvGridLine key={`vbLine-${x}`} direction='vertical' color={color} borderWidth={borderWidth} position={100 * (left / width)} />);
      }
      for (let y = 0; y <= rows; y += 1) {
        const top = y * screenHeight;
        horizontalLines.push(top);
        gridLines.push(<TvGridLine key={`hbLine-${y}`} direction='horizontal' color='#20B1EE' position={100 * (top / height)} />);
      }

      setTvLines({
        horizontalTvLines: horizontalLines,
        verticalTvLines: verticalLines,
      });
      return gridLines;
    }
    return null;
  };

  _setRef = (node) => {
    this.setState({ ref: node });
  };

  render() {
    const { width, height, maxHeight, maxWidth } = this.state;
    const { activeStudio, currentLayoutId } = this.props;
    const { rows, columns } = activeStudio;

    return (
      <WallLayout rows={rows} columns={columns} maxHeight={maxHeight} maxWidth={maxWidth}>
        <div ref={this._setRef} className='layout-areas-container'>
          {currentLayoutId && this._renderTVAndGridLines()}
          <LayoutAreas rows={rows} columns={columns} width={width} height={height} />
        </div>
      </WallLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  activeStudio: state.studio.active,
  collapsed: state.ui.navBarCollapsed,
  currentLayoutId: state.layouts.current,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ setTvLines }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LayoutGrid);
