import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import './css/Login.css';

import { loginRequest } from './redux/authRedux';
import Button from './common/Button';
import TextInput from './common/TextInput';
import Input from './common/Input';
import { post, getQRCode, setToken } from './services/api';

class Login extends Component {
  state = {
    formControls: {
      username: {
        value: '',
        valid: true,
        errorMessage: '',
      },
      password: {
        value: '',
        valid: true,
        errorMessage: '',
      },
    },
    redirectToReferrer: false,
    mfaCode: '',
    qrCode: null,
    showMfaForm: false,
    mfaEnabled: false,
  };

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    if (prevProps.user !== user && user !== null) {
      if (user.disableMfa === true) {
        this.setState({ redirectToReferrer: true });
      } else {
        this.setState({ showMfaForm: true, mfaEnabled: user.mfaEnabled }, () => {
          if (this.state.mfaEnabled) {
            this._getQrCode();
          } else {
            this._enableMfa();
          }
        });
      }
    }
  }

  _onChange = (event) => {
    const { name, value } = event.target;

    const formControls = {
      ...this.state.formControls,
    };
    const formElement = {
      ...formControls[name],
    };
    formElement.value = value;
    formControls[name] = formElement;

    this.setState({ formControls });
  };

  _onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { formControls } = this.state;
    const { username, password } = formControls;

    if (username.value === '') {
      username.valid = false;
      username.errorMessage = 'Username is required';
    } else {
      username.valid = true;
      username.errorMessage = '';
    }

    if (password.value === '') {
      password.valid = false;
      password.errorMessage = 'Password is required';
    } else {
      password.valid = true;
      password.errorMessage = '';
    }

    this.setState({ formControls }, () => {
      const { formControls } = this.state;
      const { username, password } = formControls;
      if (username.valid === true && password.valid === true) {
        this.props.login(username.value, password.value);
      }
    });
  };

  _getQrCode = async () => {
    try {
      const { user } = this.props;
      const qrCode = await getQRCode('/auth/mfaQRCode', { userId: user._id });
      const blob = new Blob([qrCode.data], { type: 'image/png' });
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.setState({ qrCode: reader.result });
      };
    } catch (error) {
      console.error(error);
    }
  };

  _enableMfa = async () => {
    try {
      const { user } = this.props;
      const response = await post('/auth/enableMFA', { userId: user._id });
      if (response.status === 200) {
        this._getQrCode();
      }
    } catch (error) {
      console.error(error);
    }
  };

  _verify = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      const { user } = this.props;
      const response = await post('/auth/verifyCode', { userId: user._id, code: this.state.mfaCode });
      if (response.status === 200) {
        const { token } = response.data;
        localStorage.setItem('token', token);
        setToken(token);
        this.setState({ redirectToReferrer: true });
      }
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/app/studios' } };
    const { redirectToReferrer } = this.state;

    if (redirectToReferrer) return <Redirect to={from} />;

    const { formControls, showMfaForm, mfaCode, qrCode, mfaEnabled } = this.state;
    const { username, password } = formControls;

    return (
      <div className='login-container'>
        <div className='login-form-container'>
          {!showMfaForm ? (
            <>
              <div className='title'>LOGIN</div>
              <form onSubmit={this._onSubmit} noValidate>
                <TextInput
                  name='username'
                  label='Username'
                  value={username.value}
                  onChange={this._onChange}
                  hasError={!username.valid}
                  errorMessage={username.errorMessage}
                />
                <TextInput
                  name='password'
                  label='Password'
                  secure
                  value={password.value}
                  onChange={this._onChange}
                  hasError={!password.valid}
                  errorMessage={password.errorMessage}
                />
                <div style={{ textAlign: 'center' }}>
                  <Button text='Login' submit containerStyle={{ marginTop: 30 }} />
                </div>
              </form>
            </>
          ) : (
            <>
              <div className='title' style={{ marginBottom: 15 }}>
                Multi-factor Authentication
              </div>
              {mfaEnabled ? (
                <>
                  <div className='subtitle'>Please enter your code below</div>
                  <form onSubmit={this._verify} noValidate>
                    <Input
                      type='text'
                      name='mfaCode'
                      value={mfaCode}
                      onChange={({ target }) => this.setState({ mfaCode: target.value })}
                      containerStyle={{ display: 'flex', justifyContent: 'center' }}
                      inputStyle={{ margin: 0 }}
                    />
                    <div style={{ textAlign: 'center' }}>
                      <Button text='Verify' containerStyle={{ marginTop: 30 }} onClick={this._verify} />
                    </div>
                  </form>
                </>
              ) : (
                <div style={{ textAlign: 'center' }}>
                  <div className='subtitle'>Please use Google Authenticator to scan the QR code below.</div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {qrCode ? <img src={qrCode} alt='code' style={{ width: 200 }} /> : <div style={{ width: 200, height: 200 }} />}
                    <Button text='Continue' containerStyle={{ marginTop: 30 }} onClick={() => this.setState({ mfaEnabled: true })} />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  error: state.auth.error,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  login: (username, password) => dispatch(loginRequest(username, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
