import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import {
  createConfigRequest,
  selectStudio,
  updateConfigRequest,
  deleteStudioRequest,
  setActiveStudio,
  getStudioById,
  setSocketServerUrl,
} from '../../redux/studioRedux';
import { updateRoutes } from '../../redux/uiRedux';
import List from '../../common/List';
import addIcon from '../../assets/icons/add.svg';
import { setActiveEvent, getEventParticipantsRequest } from '../../redux/eventsRedux';
import { get, setStudioIdHeader } from '../../services/api';
import StudioListItem from './StudioListItem';
import SocketClient from '../../utils/socket-client';

const { REACT_APP_SOCKET_SERVER_DOMAIN } = process.env;

class StudioList extends PureComponent {
  _addStudioHandler = () => {
    const { createConfig } = this.props;
    const studioName = window.prompt('Studio Name:', '');
    if (studioName == null) return;
    const studioModel = {
      name: studioName || 'New studio',
      rows: 0,
      columns: 0,
    };
    createConfig(studioModel);
  };

  _connectStudioSocketServer = async (studioId) => {
    try {
      setStudioIdHeader(studioId);
      const instancesResponse = await get(`/instance?studio=${studioId}`);
      if (instancesResponse.status === 200 && instancesResponse.data) {
        SocketClient.disconnect();
        const socketServer = instancesResponse.data.find((i) => i.serverType === 'SOCKET_SERVER');
        if (socketServer && socketServer.subDomain && socketServer.subDomain.length > 0) {
          const socketServerUrl = `https://${socketServer.subDomain}.${REACT_APP_SOCKET_SERVER_DOMAIN}`;
          SocketClient.connect(socketServerUrl);
          this.props.setSocketServerUrl(socketServerUrl);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  _onSetActiveHandler = (studio) => {
    const { setActiveStudio, setActiveEvent, updateRoutes, activeEvent, getEventParticipants } = this.props;

    const storedActiveStudio = localStorage.getItem('activeStudio');
    if (storedActiveStudio && activeEvent) {
      const prevActiveStudio = JSON.parse(storedActiveStudio);
      localStorage.setItem(`${prevActiveStudio._id}-activeEvent`, JSON.stringify(activeEvent));
    }

    localStorage.removeItem('selectedEvent');
    localStorage.removeItem('selectedLayout');
    localStorage.removeItem('activeEvent');

    localStorage.setItem('activeStudio', JSON.stringify(studio));

    setStudioIdHeader(studio._id);
    setActiveStudio(studio);

    const prevStudioActiveEvent = localStorage.getItem(`${studio._id}-activeEvent`);
    if (prevStudioActiveEvent) {
      const prevActiveEvent = JSON.parse(prevStudioActiveEvent);
      setActiveEvent(prevActiveEvent);
      getEventParticipants(prevActiveEvent._id);
      localStorage.setItem('activeEvent', prevStudioActiveEvent);
    } else {
      setActiveEvent(undefined);
    }
    updateRoutes();
    this._connectStudioSocketServer(studio._id);
  };

  _selectStudio = (studio) => {
    const { selectStudio } = this.props;
    selectStudio(studio._id);
  };

  _onEditHandler = (studio) => {
    const { updateConfig, activeStudio, setActiveStudio } = this.props;
    const studioName = window.prompt('Studio Name:', studio.name);
    if (studioName == null) return;
    updateConfig(studio._id, { name: studioName });
    if (studio._id === activeStudio._id) {
      const studio = { ...activeStudio };
      studio.name = studioName;
      localStorage.setItem('activeStudio', JSON.stringify(studio));
      setActiveStudio(studio);
      this._connectStudioSocketServer(studio._id);
    }
  };

  _onDeleteHandler = async (studio) => {
    const { activeStudio, setActiveEvent, setActiveStudio, updateRoutes } = this.props;
    const willDelete = await swal({
      title: 'Are you sure?',
      text: 'Are you sure that you want to delete this Studio?',
      buttons: true,
      dangerMode: true,
    });
    if (willDelete) {
      const { deleteStudio } = this.props;
      deleteStudio(studio._id);
      if (activeStudio && studio._id === activeStudio._id) {
        localStorage.removeItem('selectedEvent');
        localStorage.removeItem('selectedLayout');
        localStorage.removeItem('activeEvent');
        setActiveEvent(undefined);
        setActiveStudio(undefined);
        updateRoutes();
      }
    }
  };

  render() {
    const { data, selected, isSuperAdmin } = this.props;
    return (
      <>
        <List
          data={data}
          renderItem={(item) => (
            <StudioListItem
              item={item}
              selected={item === selected}
              onSelected={this._selectStudio}
              onEdit={this._onEditHandler}
              onDelete={this._onDeleteHandler}
              onSetActive={this._onSetActiveHandler}
              isSuperAdmin={isSuperAdmin}
            />
          )}
          keyExtractor={(item) => `${item}`}
          containerStyle={{ height: 'calc(100% - 65px)', position: 'relative' }}
        />
        {isSuperAdmin && (
          <div className='add-studio' onClick={this._addStudioHandler}>
            <img src={addIcon} alt='add' style={{ marginLeft: 15 }} />
            Add Studio
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  selected: state.studio.selected,
  newStudio: getStudioById(state, state.studio.newStudioId),
  activeStudio: state.studio.active,
  activeEvent: state.events.active,
});

const mapDispatchToProps = (dispatch) => ({
  createConfig: (config) => dispatch(createConfigRequest(config)),
  updateConfig: (id, data) => dispatch(updateConfigRequest(id, data)),
  selectStudio: (studio) => dispatch(selectStudio(studio)),
  deleteStudio: (id) => dispatch(deleteStudioRequest(id)),
  setActiveStudio: (id) => dispatch(setActiveStudio(id)),
  setActiveEvent: (event) => dispatch(setActiveEvent(event)),
  updateRoutes: () => dispatch(updateRoutes()),
  setSocketServerUrl: (url) => dispatch(setSocketServerUrl(url)),
  getEventParticipants: (event) => dispatch(getEventParticipantsRequest(event)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudioList);
