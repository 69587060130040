import React, { PureComponent } from 'react';

import Button from '../../common/Button';
import TextInput from '../../common/TextInput';

class ApiKeyForm extends PureComponent {
  state = {
    name: '',
  };

  _onChangeHandler = (event) => {
    const { target } = event;
    this.setState({ [target.name]: target.value });
  };

  _onSubmitHandler = async () => {
    const { onSubmit } = this.props;
    const { name } = this.state;

    if (name === '') {
      alert("Name can't be empty");
      return false;
    }

    onSubmit(this.state);
  };

  render() {
    const { name } = this.state;
    return (
      <div className='modal-form'>
        <div className='modal-form-header'>
          <h3>{'Add API Key'}</h3>
        </div>
        <div className='modal-form-content'>
          <form autoComplete='off'>
            <TextInput name='name' label='Name' value={name} onChange={this._onChangeHandler} />
          </form>
        </div>
        <div className='modal-form-footer'>
          <Button type='primary' text='Save' onClick={this._onSubmitHandler} />
        </div>
      </div>
    );
  }
}

export default ApiKeyForm;
