import SocketClient from './socket-client';
import swal from '@sweetalert/with-react';
import { get } from '../services/api';

const { maybePreferCodec, preferBitRate } = require('./sdputils');

const USE_H264 = true;
const MAX_VIDEO_BITRATE = 1000;
const MAX_AUDIO_BITRATE = 50;

export default class RTCCommon {
  constructor(roomName) {
    this.roomName = roomName;
    this.getMessage = this.getMessage.bind(this);
    this.getAnswer = this.getAnswer.bind(this);
    this.getOffer = this.getOffer.bind(this);
    this.onCandidate = this.onCandidate.bind(this);
    this.sendCandidate = this.sendCandidate.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.setup = this.setup.bind(this);
  }

  async setup(ignoreConnectionError = false) {
    await SocketClient.connected.promise;
    SocketClient.joinRoom(this.roomName);

    const response = await get('/studio/iceServers');
    const iceServers = response.data;

    console.debug('Creating RTCPeerConnection');
    this.pc = new RTCPeerConnection({
      bundlePolicy: 'max-bundle',
      rtcpMuxPolicy: 'require',
      iceServers,
    });

    this.pc.onconnectionstatechange = () => {
      const { connectionState } = this.pc;
      console.log('Connection state changed:', this.roomName, connectionState);
      if ((connectionState === 'disconnected' || connectionState === 'failed' || connectionState === 'closed') && !ignoreConnectionError) {
        this.showConnectionErrorModal();
      }
    };

    this.pc.onicecandidate = ({ candidate }) => {
      if (candidate) {
        console.debug('Sending ICE candidate');
        this.sendCandidate(candidate);
      }
    };

    this.queuedCandidates = [];
    this.onCandidate(async (candidate) => {
      if (!this.pc.remoteDescription) {
        this.queuedCandidates.push(candidate);
        return;
      }
      console.debug('Adding ICE candidate');
      await this.pc.addIceCandidate(candidate);
      console.debug('Added ICE candidate');
    });

    return this.pc;
  }

  async showConnectionErrorModal() {
    const confirmed = await swal({
      title: 'Connection Error',
      text: `Your WebRTC connection has been lost. Please refresh the page.`,
      buttons: {
        cancel: 'Cancel',
        confirm: 'Refresh',
      },
      dangerMode: true,
    });
    if (confirmed) {
      window.location.reload();
    }
  }

  async startClient() {
    console.debug('Creating offer');
    const offer = await this.pc.createOffer({ offerToReceiveVideo: true });

    console.debug('Created offer; setting local description');
    await this.pc.setLocalDescription(offer);

    if (USE_H264) {
      offer.sdp = maybePreferCodec(offer.sdp, 'video', 'send', 'H264');
      // offer.sdp = maybePreferCodec(offer.sdp, 'video', 'receive', 'VP9');
    }

    // This doens't seem to work. Tested on Chrome. Leaving here in case it works on other browsers.
    offer.sdp = preferBitRate(offer.sdp, MAX_VIDEO_BITRATE, 'video');
    offer.sdp = preferBitRate(offer.sdp, MAX_AUDIO_BITRATE, 'audio');

    // This does work but may have browser limitations
    this._setBitrate(MAX_VIDEO_BITRATE, 'video');
    this._setBitrate(MAX_AUDIO_BITRATE, 'audio');

    console.debug('Set local description; sending offer');
    this.sendMessage(offer);

    console.debug('Waiting for answer');
    const answer = await this.getAnswer();

    console.debug('Received answer; setting remote description');
    await this.pc.setRemoteDescription(answer);
    console.debug('Set remote description');

    await Promise.all(
      this.queuedCandidates.splice(0).map(async (candidate) => {
        console.debug('Adding ICE candidate');
        await this.pc.addIceCandidate(candidate);
        console.debug('Added ICE candidate');
      }),
    );
  }

  async startServer() {
    console.debug('Waiting for offer');
    const offer = await this.getOffer();

    console.debug('Received offer; setting remote description');
    await this.pc.setRemoteDescription(offer);

    console.debug('Set remote description; creating answer');
    const answer = await this.pc.createAnswer();

    console.debug('Created answer; setting local description');
    await this.pc.setLocalDescription(answer);

    console.debug('Set local description; sending answer');
    this.sendMessage(answer);

    await Promise.all(
      this.queuedCandidates.splice(0).map(async (candidate) => {
        console.debug('Adding ICE candidate');
        await this.pc.addIceCandidate(candidate);
        console.debug('Added ICE candidate');
      }),
    );
  }

  getMessage(type) {
    return new Promise((resolve) => {
      SocketClient.on('message', (message) => {
        if (message.type === type) {
          resolve(message);
        }
      });
    });
  }

  async getAnswer() {
    const answer = await this.getMessage('answer');
    return new RTCSessionDescription(answer);
  }

  async getOffer() {
    const offer = await this.getMessage('offer');
    return new RTCSessionDescription(offer);
  }

  onCandidate(callback) {
    SocketClient.on('candidate', (data) => {
      const candidate = new RTCIceCandidate(data);
      callback(candidate);
    });
  }

  sendCandidate(candidate) {
    SocketClient.emit('candidate', { roomName: this.roomName, candidate });
  }

  sendMessage(message) {
    SocketClient.emit('message', { roomName: this.roomName, message });
  }

  _setBitrate(bitrate, type) {
    const sender = this.pc.getSenders().find((s) => s.track && s.track.kind === type);
    if (sender) {
      const parameters = sender.getParameters();
      if (!parameters.encodings) {
        parameters.encodings = [{}];
      }
      parameters.encodings[0].maxBitrate = bitrate * 1000; // bitrate of 125 will set it to 125 Kbps
      sender.setParameters(parameters);
    }
  }

  stop() {
    // All socket listeners get removed when the route is unmounted
    // So no need to worry about candidate and message listerners above
    SocketClient.socket.removeListener('message');
    SocketClient.socket.removeListener('candidate');
    SocketClient.leaveRoom(this.roomName);
    this.pc && this.pc.close();
    console.log('Stop WebRTC', this.roomName);
    if (this.roomName.endsWith('-admin-recv')) {
      const uuid = this.roomName.replace('-admin-recv', '');
      SocketClient.emit('close-stream', { uuid });
    }
  }
}
