import React, { Component } from 'react';
import swal from 'sweetalert';
import PropTypes from 'prop-types';
import Button from '../../../common/Button';
import TextInput from '../../../common/TextInput';
import RadioButtons from '../../../common/RadioButtons';
import FileInput from '../../../common/FileInput';

class AssetForm extends Component {
  static propTypes = {
    edit: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    edit: false,
  };

  state = {
    name: '',
    type: null,
    file: null,
  };

  componentDidMount() {
    const { model } = this.props;
    if (model) {
      this.setState({
        name: model.name,
        type: model.type,
      });
    } else {
      this.setState({ type: 'image' });
    }
  }

  _onTypeChange = (type) => {
    this.setState({ type });
  };

  _validateFile = async (file) => {
    if (this.state.type === 'image' && !file.type.includes('image')) {
      throw new Error('The selected Asset type does not match the file type');
    } else if (this.state.type === 'video' && !file.type.includes('video')) {
      throw new Error('The selected Asset type does not match the file type');
    }
    if (file.type.includes('image/')) {
      await new Promise((resolve, reject) => {
        let img = new Image();
        img.onload = () => {
          if (img.naturalWidth > 3840 && img.naturalHeight > 2160) {
            reject(new Error('The maximum allowed image resolution is 3840x2160'));
          } else {
            resolve();
          }
        };
        img.src = URL.createObjectURL(file);
      });
    }
  };

  _onChangeHandler = async (event) => {
    const { target } = event;
    if (target.name === 'assetFile') {
      try {
        const file = target.files[0];
        this.setState({ file });

        await this._validateFile(file);
      } catch (error) {
        swal({
          title: 'Error',
          text: error.message,
        });
      }
    } else {
      this.setState({ [target.name]: target.value });
    }
  };

  _onSubmitHandler = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const { onSubmit, edit } = this.props;
    const { name, type, file } = this.state;
    try {
      if (file === null && !edit) {
        throw new Error('Asset logo is required');
      }

      await this._validateFile(file);

      onSubmit && onSubmit({ name, type, file });
    } catch (error) {
      swal({
        title: 'Error',
        text: error.message,
      });
    }
  };

  render() {
    const { edit } = this.props;
    const { name, type } = this.state;

    return (
      <div className='modal-form'>
        <div className='modal-form-header'>
          <h3>{edit ? 'Edit Asset' : 'Add Asset'}</h3>
        </div>
        <form autoComplete='off' noValidate onSubmit={this._onSubmitHandler}>
          <div className='modal-form-content'>
            <TextInput name='name' label='Name' onChange={this._onChangeHandler} value={name} />
            <RadioButtons
              label='Type'
              onChange={this._onTypeChange}
              options={[
                {
                  label: 'Image',
                  value: 'image',
                },
                {
                  label: 'Video',
                  value: 'video',
                },
              ]}
              value={type}
            />
            <FileInput name='assetFile' label='Select File' onChange={this._onChangeHandler} />
          </div>
          <div className='modal-form-footer'>
            <Button type='primary' submit text='Save' onClick={this._onSubmitHandler} />
          </div>
        </form>
      </div>
    );
  }
}

export default AssetForm;
