import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import Button from '../../common/Button';
import TextInput from '../../common/TextInput';
import Select from '../../common/Select';
import AutoComplete from '../../common/AutoComplete';
import Switch from '../../common/Switch';
import userRoles from './user-roles';
import StringUtils from '../../utils/string-utils';
import { isAdmin } from '../../redux/authRedux';

class UserDetail extends PureComponent {
  state = {
    username: '',
    password: '',
    confirmPassword: '',
    hidePassword: true,
    availableRoles: [],
    selectedRole: null,
    selectedStudios: [],
    broadcastEnabled: false,
  };

  componentDidMount() {
    const { user, mainUser } = this.props;
    if (user) {
      const { username, role, broadcastEnabled } = user;
      let availableRoles = userRoles;
      if (mainUser.role !== 'SUPER_ADMIN') {
        availableRoles = availableRoles.filter((obj) => obj.value !== 'SUPER_ADMIN');
      }
      const selectedRole = userRoles.find((r) => r.value === role);
      this.setState({
        username,
        password: '',
        confirmPassword: '',
        availableRoles,
        selectedRole,
        broadcastEnabled: !!broadcastEnabled,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { userUpdated, user } = this.props;
    const { username, role, broadcastEnabled } = user;
    if (prevProps.user._id !== user._id || (prevProps.userUpdated !== userUpdated && userUpdated === true)) {
      const selectedRole = userRoles.find((r) => r.value === role);
      this.setState({ password: '', confirmPassword: '', username, selectedRole, broadcastEnabled });
    }
  }

  _generatePassword = () => {
    const randomPassword = StringUtils.generatePassword();
    this.setState({ password: randomPassword, confirmPassword: randomPassword, hidePassword: false });
  };

  _onChangeHandler = (event) => {
    const { name, value } = event.target;
    let { hidePassword } = this.state;
    if (name === 'password' && value === '') {
      hidePassword = true;
    }
    this.setState({ [name]: value, hidePassword });
  };

  _onLoad = (studios) => {
    const { user } = this.props;
    const { selectedStudios } = this.state;
    const selectedStudioIds = user.studios;
    for (let i = 0; i < selectedStudioIds.length; i++) {
      const currentSelection = studios.find((s) => s._id === selectedStudioIds[i]);
      if (currentSelection) {
        selectedStudios.push(currentSelection);
      }
    }
    this.setState({ selectedStudios });
  };

  _onSave = () => {
    const { onSave, mainUser } = this.props;
    const { password, confirmPassword, username, selectedRole, selectedStudios, broadcastEnabled } = this.state;
    if (password !== confirmPassword) {
      swal({
        title: 'Error',
        text: `Passwords don't match`,
      });
      return false;
    }
    if (username === '') {
      swal({
        title: 'Error',
        text: 'Username is required',
      });
      return false;
    }
    if (mainUser.role !== 'TECH_SUPPORT' && mainUser.role !== 'POLL_SUPPORT' && selectedRole.value !== 'SUPER_ADMIN' && selectedStudios.length === 0) {
      swal({
        title: 'Error',
        text: 'Please assign at least one studio to the user',
      });
      return false;
    }

    let data = { username, password, role: selectedRole.value, broadcastEnabled };
    if (mainUser.role !== 'TECH_SUPPORT' || mainUser.role !== 'POLL_SUPPORT') {
      data.studios = selectedStudios.map((s) => s._id);
    }
    onSave && onSave(data);
  };

  _onDelete = () => {
    const { onDelete } = this.props;
    onDelete && onDelete();
  };

  _onRoleChange = (selectedRole) => {
    this.setState({
      selectedRole,
      selectedStudios: selectedRole.value === 'SUPER_ADMIN' ? [] : this.state.selectedStudios,
    });
  };

  _setStudios = (selectedStudios) => {
    this.setState({ selectedStudios });
  };

  render() {
    const { username, password, confirmPassword, hidePassword, selectedRole, selectedStudios, availableRoles, broadcastEnabled } = this.state;
    const { isAdmin, user, onDisableMfa } = this.props;

    return (
      <div className='user-detail'>
        <div className='form'>
          <div className='header' style={{ marginTop: 0 }}>
            <span className='title'>Profile</span>
          </div>
          <TextInput name='username' label='Username' value={username} onChange={this._onChangeHandler} />
          {isAdmin && (
            <>
              <div className='form-group'>
                <div className='form-label'>Role</div>
                <div className='form-control'>
                  <Select
                    list={availableRoles}
                    listKey='value'
                    listLabel='label'
                    onChange={this._onRoleChange}
                    selected={selectedRole && selectedRole.value}
                    currentOption={selectedRole && selectedRole.label}
                    small
                  />
                </div>
              </div>
              {selectedRole && selectedRole.value && ['SUPER_ADMIN', 'ADMIN'].includes(selectedRole.value) && isAdmin && (
                <div className='form-group'>
                  <div className='form-label'>Broadcast Enabled</div>
                  <div className='form-control'>
                    <Switch
                      label=''
                      onChange={() => this.setState({ broadcastEnabled: !broadcastEnabled })}
                      checked={broadcastEnabled}
                      height={16}
                      width={34}
                      containerStyle={{ marginBottom: 12 }}
                    />
                  </div>
                </div>
              )}
            </>
          )}
          <div className='header'>
            <span className='title'>Change password</span>
            <span className='link' onClick={this._generatePassword}>
              Generate
            </span>
          </div>
          <TextInput
            name='password'
            placeholder='Leave blank to not change'
            label='New Password'
            secure={hidePassword === true}
            value={password}
            onChange={this._onChangeHandler}
          />
          <TextInput name='confirmPassword' label='Re-enter Password' secure={hidePassword === true} value={confirmPassword} onChange={this._onChangeHandler} />
          {selectedRole && selectedRole.value !== 'SUPER_ADMIN' && isAdmin && (
            <>
              <div className='header'>
                <span className='title'>Studios Available</span>
              </div>
              <div className='form-group'>
                <div className='form-label'>Studios</div>
                <div className='form-control'>
                  <AutoComplete
                    apiURL='/studio/'
                    apiFilter={(s) => s.dbName !== 'broadcast'}
                    isMulti={true}
                    onLoad={(studios) => this._onLoad(studios)}
                    getOptionLabel={(s) => {
                      return s.name;
                    }}
                    getOptionValue={(s) => {
                      return s._id;
                    }}
                    onChange={(value) => this._setStudios(value)}
                    value={selectedStudios}
                    maxMenuHeight={150}
                  />
                </div>
              </div>
            </>
          )}
          <div className='user-actions'>
            <Button type='primary' text='Save' onClick={this._onSave} containerStyle={{ margin: 0, marginBottom: 30 }} />
            <Button type='danger' text='Delete' onClick={this._onDelete} containerStyle={{ marginLeft: 30, marginBottom: 30 }} />
            {isAdmin && user && user.mfaEnabled && (
              <Button type='danger' text='Reset MFA' onClick={() => onDisableMfa(user._id)} containerStyle={{ marginLeft: 30, marginBottom: 30 }} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAdmin: isAdmin(state),
  mainUser: state.auth.user,
  userUpdated: state.users.userUpdated,
});

export default connect(mapStateToProps, null)(UserDetail);
