export default class Deferred {
  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.reject = (err) => {
        this.state = 'rejected';
        reject(err);
      };
      this.resolve = (res) => {
        this.state = 'resolved';
        resolve(res);
      };
      this.state = 'pending';
    });
  }
}
