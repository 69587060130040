import React, { useState, useEffect } from 'react';

import { get, setStudioIdHeader } from '../../services/api';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
};

export default function QRCode() {
  const [imgSrc, setImgSrc] = useState(null);

  useEffect(() => {
    const { location } = window;
    const urlParams = new URLSearchParams(location.search);
    const studioId = urlParams.get('studioId');
    const eventId = urlParams.get('eventId');

    document.body.style.background = '#fff';

    async function getEventQrCode() {
      try {
        const { data } = await get(`/event/${eventId}`);
        setImgSrc(data.venueLoginQRCode);
      } catch (err) {
        console.error(err);
      }
    }

    setStudioIdHeader(studioId);
    getEventQrCode();
  }, []);

  return <div style={styles.container}>{imgSrc && <img src={imgSrc} alt='Event QR Code' />}</div>;
}
