import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAssetById } from '../../redux/assetsRedux';
import Image from '../../common/Image';
import landscapeImg from '../../assets/images/feed_landscape.png';
import Draggable from '../../common/Draggable';
import ContextMenu from '../../common/ContextMenu';
import { setBackgroundVideo, setBackgroundImageUrl } from '../../redux/layoutsRedux';
import BackgroundAssetLabel from '../../common/BackgroundAssetLabel';
import { downloadAsset, formatDuration } from '../../utils/assets-util';

const AssetListItem = ({ data, backgroundVideoUrl, setBackgroundVideo, backgroundImageUrl, setBackgroundImageUrl }) => {
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuX, setContextMenuX] = useState(0);
  const [contextMenuY, setContextMenuY] = useState(0);

  function _onContextMenu(e) {
    e.preventDefault();
    e.stopPropagation();
    if (data) {
      setShowContextMenu(true);
      setContextMenuX(e.pageX);
      setContextMenuY(e.pageY);
    }
  }

  function _hideContextMenu() {
    setShowContextMenu(false);
    setContextMenuX(0);
    setContextMenuY(0);
  }

  function _getContextMenuItems() {
    const contextMenuItems = [];
    if (data && data.type === 'video') {
      if (data.fileUrl === backgroundVideoUrl) {
        contextMenuItems.push({ value: 2, label: 'Remove as background', danger: true });
      } else {
        contextMenuItems.push({ value: 1, label: 'Set as background' });
      }
    } else {
      if (data.fileUrl === backgroundImageUrl) {
        contextMenuItems.push({ value: 4, label: 'Remove as background', danger: true });
      } else {
        contextMenuItems.push({ value: 3, label: 'Set as background' });
      }
    }
    contextMenuItems.push({ value: 9, label: 'Download' });
    return contextMenuItems;
  }

  async function _onContextMenuItemSelection(option) {
    switch (option.value) {
      case 1:
        setBackgroundVideo(data.fileUrl, data.metadata);
        break;
      case 2:
        setBackgroundVideo(null, null);
        break;
      case 3:
        setBackgroundImageUrl(data.fileUrl);
        break;
      case 4:
        setBackgroundImageUrl(null);
        break;
      case 9:
        downloadAsset(data);
        break;
      default:
        break;
    }
  }

  let backgroundAssetStyle = { border: 'none' };
  let backgroundOnly = false;

  if (data && data.type === 'video') {
    if (backgroundVideoUrl === data.fileUrl) {
      backgroundAssetStyle = {
        boxSizing: 'border-box',
        border: '2px solid #287737',
      };
    }
  } else if (data && data.type === 'image' && backgroundImageUrl === data.fileUrl) {
    backgroundAssetStyle = {
      boxSizing: 'border-box',
      border: '2px solid #287737',
    };
  }

  let overlayDiv;
  if (backgroundVideoUrl === data.fileUrl || backgroundImageUrl === data.fileUrl) {
    overlayDiv = <BackgroundAssetLabel label='BG' color='rgb(40,119,55)' />;
  } else if (data.metadata.width > 3840 || data.metadata.height > 2160 || data.metadata.width === 0) {
    backgroundOnly = true;
    backgroundAssetStyle.pointerEvents = 'none';
    overlayDiv = <BackgroundAssetLabel label='BG ONLY' color='#B7771E' />;
  }

  let assetText;
  if (data.metadata.width > 0) {
    const { width, height, duration, frameRate } = data.metadata;
    if (data.type === 'video') {
      const metadataStr = `${frameRate ? `${frameRate}fps ` : ''} ${duration ? `${formatDuration(duration)} - ` : ''} ${width}x${height}`.trim();
      assetText = `${data.name} (${metadataStr})`;
    } else {
      assetText = `${data.name} (${width}x${height})`;
    }
  } else {
    assetText = `${data.name} (Unknown)`;
  }

  const asset = (
    <div style={{ width: 160, margin: '0 10px 20px' }}>
      <div className='asset' style={{ position: 'relative', margin: 0 }} onContextMenu={_onContextMenu}>
        {data.type === 'image' ? (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 160, height: 90 }}>
            <Image source={data.previewFileKey || data.fileKey} alt={data.name} responsive maxHeight={90} style={backgroundAssetStyle} />
          </div>
        ) : (
          <img src={landscapeImg} alt='video' width='100%' style={backgroundAssetStyle} />
        )}
        {overlayDiv}
        <div className='asset-name'>{assetText}</div>
      </div>
      {showContextMenu && (
        <ContextMenu
          position={{ x: contextMenuX, y: contextMenuY }}
          items={_getContextMenuItems()}
          onClose={_hideContextMenu}
          onItemSelected={_onContextMenuItemSelection}
        />
      )}
    </div>
  );

  if (backgroundOnly) return <div>{asset}</div>;

  return (
    data && (
      <Draggable
        dataTransfer={{
          sourceType: 'Asset',
          data,
        }}
      >
        {asset}
      </Draggable>
    )
  );
};

const mapStateToProps = (state, ownProps) => ({
  data: getAssetById(state, ownProps.asset),
  backgroundVideoUrl: state.layouts.backgroundVideoUrl,
  backgroundImageUrl: state.layouts.backgroundImageUrl,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ setBackgroundVideo, setBackgroundImageUrl }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AssetListItem);
