import React, { useState, useEffect } from 'react';
import Select from '../../common/Select';
import MediaDeviceUtil from '../../utils/media-device-util';

function Settings({ onDeviceChange }) {
  const [_videoDevices, setVideoDevices] = useState(null);
  const [_audioDevices, setAudioDevices] = useState(null);
  const [_audioOutputDevices, setAudioOutputDevices] = useState(null);
  const [_selectedVideoDevice, setSelectedVideoDevice] = useState('');
  const [_selectedAudioDevice, setSelectedAudioDevice] = useState('');
  const [_selectedAudioOutputDevice, setSelectedAudioOutputDevice] = useState('');

  async function updateMediaDevices() {
    const devices = await MediaDeviceUtil.getAvailableDevices();
    const videoDevices = devices.filter((v) => v.kind === 'videoinput');
    const audioDevices = devices.filter((v) => v.kind === 'audioinput');
    const audioOutputDevices = devices.filter((v) => v.kind === 'audiooutput');

    setVideoDevices(videoDevices);
    setAudioDevices(audioDevices);
    setAudioOutputDevices(audioOutputDevices);
  }

  useEffect(() => {
    (async () => {
      const availableDevices = await MediaDeviceUtil.getAvailableDevices();

      const filteredVideoDevices = availableDevices.filter((d) => d.kind === 'videoinput');
      const filteredAudioDevices = availableDevices.filter((d) => d.kind === 'audioinput');
      const filteredAudioOutputDevices = availableDevices.filter((d) => d.kind === 'audiooutput');

      setVideoDevices(filteredVideoDevices);
      setAudioDevices(filteredAudioDevices);
      setAudioOutputDevices(filteredAudioOutputDevices);

      const savedVideoDevice = localStorage.getItem('current-video-device');
      const savedAudioDevice = localStorage.getItem('current-audio-device');
      const savedAudioOutputDevice = localStorage.getItem('current-audio-output-device');

      const defaultVideoInput = filteredVideoDevices.find((dev) => dev.isDefault === true) || filteredVideoDevices[0];
      let initialVideoInput = defaultVideoInput;
      if (savedVideoDevice) {
        const videoDevice = JSON.parse(savedVideoDevice);
        const validVideoInput = filteredVideoDevices.find((dev) => dev.deviceId === videoDevice.deviceId);
        if (validVideoInput) {
          initialVideoInput = validVideoInput;
        }
      }

      const defaultAudioInput = filteredAudioDevices.find((dev) => dev.isDefault === true) || filteredAudioDevices[0];
      let initialAudioInput = defaultAudioInput;
      if (savedAudioDevice) {
        const audioDevice = JSON.parse(savedAudioDevice);
        const validAudioInput = filteredAudioDevices.find((dev) => dev.deviceId === audioDevice.deviceId);
        if (validAudioInput) {
          initialAudioInput = validAudioInput;
        }
      }

      const defaultAudioOutput = filteredAudioOutputDevices.find((dev) => dev.isDefault === true) || filteredAudioOutputDevices[0];
      let initialAudioOutput = defaultAudioOutput;
      if (savedAudioOutputDevice) {
        const audioOutputDevice = JSON.parse(savedAudioOutputDevice);
        const validAudioOutput = filteredAudioOutputDevices.find((dev) => dev.deviceId === audioOutputDevice.deviceId);
        if (validAudioOutput) {
          initialAudioOutput = validAudioOutput;
        }
      }
      setSelectedVideoDevice(initialVideoInput);
      setSelectedAudioDevice(initialAudioInput);
      setSelectedAudioOutputDevice(initialAudioOutput);
    })();

    window.navigator.mediaDevices.addEventListener('devicechange', updateMediaDevices);
    return () => {
      window.navigator.mediaDevices.removeEventListener('devicechange', updateMediaDevices);
    };
  }, []);

  function _onDeviceChange(option, type) {
    localStorage.setItem(`current-${type}-device`, JSON.stringify(option));
    if (type === 'video') {
      setSelectedVideoDevice(option);
    } else if (type === 'audio') {
      setSelectedAudioDevice(option);
    } else if (type === 'audio-output') {
      setSelectedAudioOutputDevice(option);
    }
    onDeviceChange(option.deviceId, type);
  }

  return (
    <div className='settings'>
      <div className='device-list-container'>
        <div className='label'>Camera</div>
        <Select
          list={_videoDevices}
          listKey='deviceId'
          listLabel='label'
          onChange={(option) => _onDeviceChange(option, 'video')}
          selected={_selectedVideoDevice && _selectedVideoDevice.deviceId}
          currentOption={_selectedVideoDevice && _selectedVideoDevice.label}
          containerStyle={{ width: '100%' }}
          small
        />
      </div>
      <div className='device-list-container'>
        <div className='label'>Microphone</div>
        <Select
          list={_audioDevices}
          listKey='deviceId'
          listLabel='label'
          onChange={(option) => _onDeviceChange(option, 'audio')}
          selected={_selectedAudioDevice && _selectedAudioDevice.deviceId}
          currentOption={_selectedAudioDevice && _selectedAudioDevice.label}
          containerStyle={{ width: '100%' }}
          small
        />
      </div>
      <div className='device-list-container'>
        <div className='label'>Speaker</div>
        <Select
          list={_audioOutputDevices}
          listKey='deviceId'
          listLabel='label'
          onChange={(option) => _onDeviceChange(option, 'audio-output')}
          selected={_selectedAudioOutputDevice && _selectedAudioOutputDevice.deviceId}
          currentOption={_selectedAudioOutputDevice && _selectedAudioOutputDevice.label}
          containerStyle={{ width: '100%' }}
          small
        />
      </div>
    </div>
  );
}

export default Settings;
