import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import swal from '@sweetalert/with-react';
import { actions, updateAssetRequest, deleteAssetRequest, getAssetsRequest, createAssetRequest } from '../../redux/assetsRedux';
import { uploadFilesRequest, startUpload, addFile } from '../../redux/uploaderRedux';
import { getLayoutsRequest } from '../../redux/layoutsRedux';
import { getEventById, getEventsRequest } from '../../redux/eventsRedux';
import { isAdmin } from '../../redux/authRedux';
import Loader from '../../common/Loader';
import Modal from '../../common/Modal';
import FileUploader from '../../common/FileUploader';
import FileProgress from '../../common/FileUploader/FileProgress';
import Button from '../../common/Button';
import Asset from './Asset';
import AssetForm from './forms/AssetForm';
import SocketClient from '../../utils/socket-client';
import Reporting from './Reporting';
import PreviewButton from './PreviewButton';
import BreakoutRoomsRecordings from './BreakoutRoomsRecordings';

class EventAssets extends PureComponent {
  state = {
    openModal: false,
    selectedAsset: null,
    formSubmitted: false,
  };

  componentDidUpdate(prevProps) {
    const { deleted, getLayouts, activeEvent, activeStudio } = this.props;
    if (prevProps.deleted !== deleted && deleted === true) {
      if (activeStudio && activeEvent) {
        getLayouts(activeStudio._id, activeEvent._id);
      }
    }
  }

  _closeModal = (reset) => {
    if (reset) {
      this.setState({ openModal: false, selectedAsset: null });
    } else {
      this.setState({ openModal: false });
    }
  };

  _openModal = () => {
    this.setState({ openModal: true });
  };

  _onEditHandler = (data) => {
    this.setState({ openModal: true, selectedAsset: data });
  };

  _onDeleteHandler = async (data) => {
    const { deleteAsset, selectedEvent } = this.props;
    const willDelete = await swal({
      title: 'Please confirm',
      text: 'Are you sure you want to delete this Asset?',
      buttons: {
        cancel: 'No',
        confirm: 'Yes',
      },
      dangerMode: true,
    });
    if (willDelete) {
      deleteAsset(data._id);
      SocketClient.emitAdminAppSync({
        type: actions.GET_ASSETS_REQUEST,
        payload: { eventId: selectedEvent },
      });
    }
  };

  _onSubmitHandler = (data) => {
    const { uploadFiles, updateAsset, selectedEvent } = this.props;
    const { selectedAsset } = this.state;
    let { name, file, type } = data;

    this.setState({ formSubmitted: true }, async () => {
      if (file !== null) {
        const fileKey = selectedAsset ? selectedAsset.fileKey : undefined;
        uploadFiles([file], name, selectedEvent, fileKey);
      } else {
        updateAsset(selectedAsset._id, { name, type });
        SocketClient.emitAdminAppSync({
          type: actions.GET_ASSETS_REQUEST,
          payload: { eventId: selectedEvent },
        });
        this.setState({ selectedAsset: null });
      }
      this._closeModal();
    });
  };

  _addAsset = (uploaded) => {
    const { createAsset, selectedEvent, updateAsset } = this.props;
    const { selectedAsset, formSubmitted } = this.state;

    if (formSubmitted) {
      if (selectedAsset !== null) {
        for (const i in uploaded) {
          const u = uploaded[i];
          const payload = {
            name: u.fileName,
            type: u.fileType === 'mp4' ? 'video' : 'image',
            fileUrl: u.url,
            previewUrl: u.url,
            fileKey: u.fileKey,
            event: selectedEvent,
            metadata: u.metadata,
          };
          updateAsset(selectedAsset._id, payload);
          SocketClient.emitAdminAppSync({
            type: actions.GET_ASSETS_REQUEST,
            payload: { eventId: selectedEvent },
          });
        }
      } else {
        createAsset(selectedEvent, uploaded);
        SocketClient.emitAdminAppSync({
          type: actions.GET_ASSETS_REQUEST,
          payload: { eventId: selectedEvent },
        });
      }
      this.setState({ selectedAsset: null, formSubmitted: false });
    }
  };

  _showQRCode = () => {
    const { selectedEventObj, activeStudio } = this.props;
    window.open(
      `/qrCode?studioId=${activeStudio._id}&eventId=${selectedEventObj._id}`,
      'Venue Login QR Code',
      'width=1280,height=720,toolbar=0,menubar=0,location=0',
    );
  };

  render() {
    const { list, fetching, selectedEvent, isAdmin, selectedEventObj } = this.props;
    const { openModal, selectedAsset } = this.state;
    let assets = null;

    if (list !== null && selectedEvent) {
      assets = list.map((a, i) => (
        <div key={a} className='asset-column'>
          <Asset assetId={a} onDelete={this._onDeleteHandler} onEdit={this._onEditHandler} isAdmin={isAdmin} />
        </div>
      ));
      if (!assets.length) {
        assets = <div style={{ color: 'white', margin: '0 auto', padding: 20 }}>There are no assets for this event</div>;
      }
    }

    const buttonStyle = {
      fontSize: 14,
      padding: '5px 15px',
    };

    return (
      <div className='event-assets'>
        <div className='event-assets-header'>
          {(() => {
            if (selectedEvent) {
              return (
                <>
                  <FileProgress />
                  {isAdmin && (
                    <>
                      {selectedEventObj && selectedEventObj.erpAccountId && (
                        <Button text='Show QR Code' onClick={this._showQRCode} containerStyle={buttonStyle} />
                      )}
                      <PreviewButton event={selectedEventObj} style={buttonStyle} />
                      <Button text='Add Asset' onClick={this._openModal} containerStyle={buttonStyle} />
                    </>
                  )}
                </>
              );
            }
            return <FileProgress />;
          })()}
        </div>
        {selectedEvent && isAdmin && <FileUploader onCompleted={this._addAsset} onStarted={() => this.setState({ formSubmitted: true })} />}
        <div className='assets-list'>
          <div className='asset-row'>{fetching ? <Loader /> : assets}</div>
          <BreakoutRoomsRecordings />
        </div>

        <Modal show={openModal} onClose={() => this._closeModal(true)}>
          <AssetForm onSubmit={this._onSubmitHandler} model={selectedAsset} edit={selectedAsset !== null} />
        </Modal>
        <div className='event-assets-footer'>{isAdmin && <Reporting />}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  deleted: state.assets.deleted,
  fetching: state.assets.fetching,
  error: state.assets.error,
  list: state.assets.list,
  selectedEvent: state.events.selected,
  selectedEventObj: getEventById(state, state.events.selected),
  activeEvent: state.events.active,
  activeStudio: state.studio.active,
  isAdmin: isAdmin(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateAsset: (id, data) => dispatch(updateAssetRequest(id, data)),
  deleteAsset: (id) => dispatch(deleteAssetRequest(id)),
  getAssets: (eventId) => dispatch(getAssetsRequest(eventId)),
  createAsset: (event, assets) => dispatch(createAssetRequest(event, assets)),
  uploadFiles: (file, name, event, key) => dispatch(uploadFilesRequest(file, name, event, key)),
  start: (size, files) => dispatch(startUpload(size, files)),
  addFile: (file) => dispatch(addFile(file)),
  getLayouts: (studioId, eventId) => dispatch(getLayoutsRequest(studioId, eventId)),
  getEvents: (studio) => dispatch(getEventsRequest(studio)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventAssets);
