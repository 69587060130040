import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ContextMenu from '../../common/ContextMenu';
import Image from '../../common/Image';
import editIcon from '../../assets/icons/edit.svg';
import deleteIcon from '../../assets/icons/delete.svg';
import { getAssetById } from '../../redux/assetsRedux';
import landscapeImg from '../../assets/images/feed_landscape.png';
import { downloadAsset, formatDuration } from '../../utils/assets-util';

class Asset extends PureComponent {
  static propTypes = {
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
  };

  state = {
    isHovering: false,
    showContextMenu: false,
    contextMenuPos: {
      x: 0,
      y: 0,
    },
  };

  _onContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      showContextMenu: true,
      contextMenuPos: {
        x: e.pageX,
        y: e.pageY,
      },
    });
  };

  _hideContextMenu = () => {
    this.setState({
      showContextMenu: false,
      contextMenuPos: {
        x: 0,
        y: 0,
      },
    });
  };

  _onContextMenuItemSelection = (option) => {
    switch (option.value) {
      case 1:
        downloadAsset(this.props.asset);
        break;
      default:
        break;
    }
  };

  _onEditClickHandler = (e) => {
    e.stopPropagation();
    const { asset, onEdit } = this.props;
    onEdit(asset);
  };

  _onDeleteClickHandler = (e) => {
    e.stopPropagation();
    const { asset, onDelete } = this.props;
    onDelete(asset);
  };

  _onMouseEnterHandler = () => {
    this.setState({ isHovering: true });
  };

  _onMouseLeaveHandler = () => {
    this.setState({ isHovering: false });
  };

  render() {
    const { asset, isAdmin } = this.props;
    const { isHovering, showContextMenu, contextMenuPos } = this.state;
    let content = null;
    if (asset) {
      let overlay = null;
      if (isHovering && isAdmin) {
        overlay = (
          <div className='asset-overlay'>
            <div className='asset-options'>
              <div style={{ margin: 10, cursor: 'pointer' }} onClick={this._onEditClickHandler} role='button' tabIndex='-1' onKeyUp={() => {}}>
                <img src={editIcon} alt='edit' />
              </div>
              <div style={{ margin: 10, cursor: 'pointer' }} onClick={this._onDeleteClickHandler} role='button' tabIndex='-1' onKeyUp={() => {}}>
                <img src={deleteIcon} alt='delete' />
              </div>
            </div>
          </div>
        );
      }

      let assetText;
      if (asset.metadata.width > 0) {
        const { width, height, duration, frameRate } = asset.metadata;
        if (asset.type === 'video') {
          let fps = frameRate;
          if (frameRate && frameRate.includes('/')) {
            const frameRateParts = frameRate.split('/');
            const frameRateNum = parseInt(frameRateParts[0], 10);
            const frameRateDen = parseInt(frameRateParts[1], 10);
            fps = (frameRateNum / frameRateDen).toFixed(2);
          }
          const metadataStr = `${fps ? `${fps}fps ` : ''} ${duration ? `${formatDuration(duration)} - ` : ''} ${width}x${height}`.trim();
          assetText = `${asset.name} (${metadataStr})`;
        } else {
          assetText = `${asset.name} (${width}x${height})`;
        }
      } else {
        assetText = `${asset.name} (Unknown)`;
      }

      content = (
        <div className='asset' onMouseEnter={this._onMouseEnterHandler} onMouseLeave={this._onMouseLeaveHandler} onContextMenu={this._onContextMenu}>
          <div
            style={{
              position: 'relative',
              alignSelf: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '220px',
            }}
          >
            {(() => {
              if (asset.type === 'image') {
                return <Image source={asset.previewFileKey || asset.fileKey} alt={asset.name} responsive />;
              }
              return <img src={landscapeImg} alt='video' width='100%' />;
            })()}
            {overlay}
          </div>
          <div className='asset-name'>{assetText}</div>
          {showContextMenu && (
            <ContextMenu
              position={{ x: contextMenuPos.x, y: contextMenuPos.y }}
              items={[{ value: 1, label: 'Download' }]}
              onClose={this._hideContextMenu}
              onItemSelected={this._onContextMenuItemSelection}
            />
          )}
        </div>
      );
    }
    return content;
  }
}

const mapStateToProps = (state, props) => ({
  asset: getAssetById(state, props.assetId),
});

export default connect(mapStateToProps, null)(Asset);
