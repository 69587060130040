import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import swal from '@sweetalert/with-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import {
  startInstanceRequest,
  stopInstanceRequest,
  restartInstanceRequest,
  setInstance,
  checkInstancesStatus,
  clearLiveInstanceData,
  launchFromImageRequest,
} from '../../redux/goLiveRedux';
import Button from '../../common/Button';
import ContextMenu from '../../common/ContextMenu';
import compositorImg from '../../assets/icons/compositor.jpg';
import rtcImg from '../../assets/icons/RTC.jpg';
import socketServerImg from '../../assets/icons/socket_server.jpg';
import decoderImg from '../../assets/icons/decoder.png';
import stopIcon from '../../assets/icons/stop.svg';
import startIcon from '../../assets/icons/start.svg';
import restartIcon from '../../assets/icons/restart.svg';
import SocketClient from '../../utils/socket-client';
import { compositorInstanceTypes, socketServerInstanceTypes, rtcServerInstanceTypes, decoderServerInstanceTypes } from '../../utils/aws-util';
import StringUtil from '../../utils/string-utils';

import InstanceType from './InstanceType';
import InstanceAZ from './InstanceAZ';
import Counter from './Counter';
import Widget from './Widget';

class Instance extends PureComponent {
  state = {
    nextStatus: null,
    showContextMenu: false,
    contextMenuPosition: {
      x: 0,
      y: 0,
    },
  };

  _timer = null;

  componentDidMount() {
    const { data } = this.props;
    let nextStatus = null;
    if (data.status === 'pending') {
      nextStatus = 'running';
    } else if (data.status === 'stopping') {
      nextStatus = 'stopped';
    }
    this.props.setInstance(data);
    this.setState({ nextStatus });

    this._onAdminAppSync = (msg) => {
      const { payload } = msg;
      if (payload && payload._id === this.props.instance) {
        if (msg.type === 'START_INSTANCE') {
          this._startInstance(payload);
        } else if (msg.type === 'STOP_INSTANCE') {
          this._stopInstance(payload);
        } else if (msg.type === 'RESTART_INSTANCE') {
          this._restartInstance(payload);
        }
      }
    };

    SocketClient.on('admin-app-sync', this._onAdminAppSync);
  }

  componentDidUpdate(prevProps) {
    const { runningFromImage, data } = this.props;
    if (prevProps.runningFromImage !== runningFromImage && runningFromImage === true && data) {
      this.setState({ nextStatus: 'running' });
    }
  }

  componentWillUnmount() {
    if (this._timer) {
      clearInterval(this._timer);
    }
    SocketClient.off('admin-app-sync', this._onAdminAppSync);
  }

  _startInstance = (data) => {
    this.setState({ nextStatus: 'running' }, () => {
      this.props.startInstance(data._id);
      this.props.setInstance({ ...data });
      this.props.checkInstancesStatus();
    });
  };

  _stopInstance = (data) => {
    this.setState({ nextStatus: 'stopped' }, () => {
      this.props.stopInstance(data._id);
      this.props.setInstance({ ...data });
      this.props.checkInstancesStatus();
    });
  };

  _restartInstance = (data) => {
    this.setState({ nextStatus: 'running' }, () => {
      this.props.restartInstance(data._id);
      setTimeout(() => {
        this.props.clearLiveInstanceData(data.instanceId);
      }, 1000);
      this.props.setInstance({ ...data });
      this.props.checkInstancesStatus();
    });
  };

  _onStartHandler = async (isStartAll) => {
    const { data, checkCapacity, ignoreCheckCapacity, appMode } = this.props;
    // why do we need checkCapacity === true?
    if (appMode === 'BROADCAST' || isStartAll || ignoreCheckCapacity === true || (await checkCapacity())) {
      this._startInstance(data);
      SocketClient.emitAdminAppSync({
        type: 'START_INSTANCE',
        payload: { ...data },
      });
    }
  };

  _onStopHandler = () => {
    const { data } = this.props;
    this._stopInstance(data);
    SocketClient.emitAdminAppSync({
      type: 'STOP_INSTANCE',
      payload: { ...data },
    });
  };

  _onRestartHandler = () => {
    const { data } = this.props;
    this._restartInstance(data);
    SocketClient.emitAdminAppSync({
      type: 'RESTART_INSTANCE',
      payload: { ...data },
    });
  };

  _formatBytes = (megaBytes, decimals = 2) => {
    if (megaBytes === 0) return '0 Bytes';
    const k = 1024;
    const bytes = megaBytes * k * k;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${Math.round((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  _updateAndRestart = async () => {
    const confirmed = await swal({
      title: 'Are you sure?',
      text: 'Are you sure you want to update and restart this Instance?',
      buttons: [
        {
          text: 'Cancel',
          value: false,
          visible: true,
          className: '',
          closeModal: true,
        },
        {
          text: 'Yes',
          value: true,
          visible: true,
          className: 'swal-custom-button--red',
          closeModal: true,
        },
      ],
      dangerMode: true,
    });
    if (confirmed) {
      const { liveData } = this.props;
      SocketClient.emit('update-and-restart', liveData.instanceId);
    }
  };

  _onLaunchFromAmi = async () => {
    const confirmed = await swal({
      title: 'Are you sure?',
      text: 'Are you sure you want to launch this Instance from AMI?',
      buttons: [
        {
          text: 'Cancel',
          value: false,
          visible: true,
          className: '',
          closeModal: true,
        },
        {
          text: 'Yes',
          value: true,
          visible: true,
          closeModal: true,
        },
      ],
      dangerMode: true,
    });
    if (confirmed) {
      const { launchFromImage, data } = this.props;
      launchFromImage(data._id);
    }
  };

  _onContextMenuHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      contextMenuPosition: { x: e.pageX, y: e.pageY },
      showContextMenu: true,
    });
  };

  _hideContextMenu = () => {
    this.setState({
      contextMenuPosition: { x: 0, y: 0 },
      showContextMenu: false,
    });
  };

  render() {
    const { data, liveData, networkData, isLive, socketServersReady, decoderServerReady, availabilityZones, activeStudio, socketConnected } = this.props;
    const { showContextMenu, contextMenuPosition } = this.state;

    let actionButtons;
    let statusColor;
    let statusIndicatorColor;
    let serverTypeImg;
    let networkInputMb = 0;
    let networkOutputMb = 0;
    let cpuUsage = 0;
    let usedMemPct = 0;
    let processUptime = 0;
    let osUptime = 0;
    let beCount = 0;
    let rtcCount = 0;
    let nginxConn = 0;

    if (liveData) {
      cpuUsage = Math.round(liveData.cpuUsage);
      networkOutputMb = Math.round(networkData.outputMb);
      networkInputMb = Math.round(networkData.inputMb);
      usedMemPct = Math.round((liveData.usedMemMb * 100) / liveData.totalMemMb);
      osUptime = liveData.osUptime;
      processUptime = liveData.processUptime;
      beCount = liveData.adminBackendSocketCount;
      rtcCount = liveData.rtcSocketCount;
      nginxConn = liveData.nginxConnections;
    }

    const isCompositor = data.serverType === 'COMPOSITOR';
    const isDecoder = data.serverType === 'DECODER';
    const isSocketServer = data.serverType === 'SOCKET_SERVER';
    const isRtcServer = data.serverType === 'RTC';

    let instaceTypesList = [];
    let canStartServer;
    if (isSocketServer) {
      serverTypeImg = socketServerImg;
      canStartServer = true;
      instaceTypesList = socketServerInstanceTypes;
    } else if (isDecoder) {
      serverTypeImg = decoderImg;
      canStartServer = socketServersReady;
      instaceTypesList = decoderServerInstanceTypes;
    } else if (isCompositor) {
      serverTypeImg = compositorImg;
      canStartServer = decoderServerReady;
      instaceTypesList = compositorInstanceTypes;
    } else if (isRtcServer) {
      serverTypeImg = rtcImg;
      canStartServer = decoderServerReady;
      instaceTypesList = rtcServerInstanceTypes;
    }

    const isRunning = data.status === 'running';
    const isStopped = data.status === 'stopped';
    const isStoppingOrPending = data.status === 'stopping' || data.status === 'pending';
    const isRunningCompositorWithLiveData = isRunning && isCompositor && liveData;

    if (isRunning) {
      statusColor = '#244B42';
      statusIndicatorColor = '#36AF6D';
      actionButtons = (
        <div className='actions'>
          <Button type='secondary' icon={restartIcon} text='Restart' onClick={this._onRestartHandler} disabled={false} />
          <Button type='danger' icon={stopIcon} text='Stop' onClick={this._onStopHandler} disabled={isLive} />
        </div>
      );
    } else if (isStopped) {
      networkInputMb = 0;
      networkOutputMb = 0;
      cpuUsage = 0;
      usedMemPct = 0;
      statusColor = '#461C2B';
      statusIndicatorColor = '#FF062B';
      actionButtons = (
        <div className='actions'>
          {canStartServer ? (
            <Button type='primary' icon={startIcon} text='Start' onClick={() => this._onStartHandler(false)} containerStyle={{ padding: '7px 20px' }} />
          ) : null}
        </div>
      );
    } else if (isStoppingOrPending) {
      networkInputMb = 0;
      networkOutputMb = 0;
      cpuUsage = 0;
      usedMemPct = 0;
      statusColor = '#5F4723';
      statusIndicatorColor = '#FAAE22';
      actionButtons = <div className='actions' />;
    }

    return (
      <div className='instance-container'>
        {showContextMenu === true ? (
          <ContextMenu
            position={contextMenuPosition}
            items={[{ value: 1, label: 'Launch from AMI' }]}
            onClose={this._hideContextMenu}
            onItemSelected={this._onLaunchFromAmi}
          />
        ) : null}
        <div className='header' onContextMenu={this._onContextMenuHandler}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginBottom: 10 }}>
            <div className='name'>{data.name}</div>
            <div className='status' style={{ backgroundColor: statusColor }}>
              <span className='indicator' style={{ backgroundColor: statusIndicatorColor }} />
              <span>{data.status}</span>
            </div>
          </div>
          <div style={{ color: '#43586f', fontFamily: 'Open Sans', fontSize: 14, marginRight: 14 }}>
            {osUptime && isRunning && socketConnected ? StringUtil.formatSeconds(osUptime) : '\u00A0'}
          </div>
        </div>

        <div className='server-type'>
          <img src={serverTypeImg} alt={data.serverType} />
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <InstanceType list={instaceTypesList} instanceId={data._id} value={data.type} status={data.status} disabled={data.lockInstanceType} />
            <InstanceAZ
              list={availabilityZones}
              instanceId={data._id}
              value={data.availabilityZone || activeStudio.awsDefaultAvailabilityZone}
              status={data.status}
            />
          </div>
          <div className='ip-address'>{data.privateIp}</div>
        </div>

        <div className='widget' style={{ padding: 15, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ color: '#8b8b8b', fontWeight: 600 }}>
            Process Uptime
            <FontAwesomeIcon icon={faSync} color='#939394' style={{ cursor: 'pointer', fontSize: 15, marginLeft: 8 }} onClick={this._updateAndRestart} />
          </div>
          <div className='process-uptime'>
            {isRunning && liveData && Date.now() - liveData.timestamp >= 15000 && (
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                color='#FAAE22'
                style={{ cursor: 'pointer', fontSize: 15, marginLeft: 8, marginRight: 8, marginBottom: 1 }}
              />
            )}
            {processUptime && isRunning && socketConnected ? StringUtil.formatSeconds(processUptime) : '\u00A0'}
          </div>
        </div>

        <Widget
          title='CPU'
          type='progress'
          percentage={liveData && isRunning && socketConnected ? cpuUsage : 0}
          total={liveData && isRunning && liveData.cpuCount && socketConnected ? `${liveData.cpuCount} CPUs` : '-'}
        />
        <Widget
          title='Memory'
          type='progress'
          percentage={liveData && isRunning && socketConnected ? usedMemPct : 0}
          total={liveData && isRunning && liveData.totalMemMb && socketConnected ? `${Math.round(liveData.totalMemMb / 1024)} GB` : '-'}
        />
        <Widget
          title='Network'
          type='network'
          upload={networkData && socketConnected ? `${networkOutputMb} MB/sec` : '0 MB/sec'}
          download={networkData && socketConnected ? `${networkInputMb} MB/sec` : '0 MB/sec'}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            padding: 15,
            borderBottom: '1px solid #182026',
            height: 50,
          }}
        >
          {isRunningCompositorWithLiveData &&
            liveData.gpuData &&
            socketConnected &&
            liveData.gpuData.map((data) => (
              <div key={`gpu-util-${data.num}`} style={{ width: 60, margin: 5, display: 'flex', justifyContent: 'flex-start' }}>
                <div className='vertical-progress-bar'>
                  <div className='progress' style={{ bottom: `${data.util.replace('%', '').trim()}%` }} />
                </div>
                <div style={{ marginLeft: 5, textAlign: 'left' }}>
                  <div style={{ color: '#43586f' }}>{`GPU${parseInt(data.num) + 1}`}</div>
                  <div>{data.util}</div>
                </div>
              </div>
            ))}

          {isSocketServer && (
            <>
              <div style={{ flex: 1 }}>
                <Counter label='Backend' value={beCount} />
                <Counter label='RTC' value={rtcCount} />
              </div>
              <div style={{ flex: 1 }}>
                <Counter label='NGINX' value={nginxConn} />
              </div>
            </>
          )}
        </div>

        {actionButtons}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const data = state.goLive.instances[ownProps.instance];
  let liveData;
  let networkData;
  if (data) {
    liveData = state.goLive.liveData[data.instanceId];
  }
  if (liveData) {
    networkData = liveData.networkInOut.total;
  }
  return {
    data,
    liveData,
    networkData,
    compositorServersReady: state.goLive.compositorServersReady,
    decoderServerReady: state.goLive.decoderServerReady,
    socketServersReady: state.goLive.socketServersReady,
    isLive: state.goLive.isLive,
    runningFromImage: state.goLive.runningFromImage,
    availabilityZones: state.goLive.availabilityZones,
    activeStudio: state.studio.active,
    socketConnected: state.ui.socketConnected,
    appMode: state.ui.appMode,
  };
};

const mapDispatchToProps = (dispatch) => ({
  startInstance: (id) => dispatch(startInstanceRequest(id)),
  stopInstance: (id) => dispatch(stopInstanceRequest(id)),
  restartInstance: (id) => dispatch(restartInstanceRequest(id)),
  setInstance: (data) => dispatch(setInstance(data)),
  checkInstancesStatus: () => dispatch(checkInstancesStatus()),
  clearLiveInstanceData: (instanceId) => dispatch(clearLiveInstanceData(instanceId)),
  launchFromImage: (id) => dispatch(launchFromImageRequest(id)),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Instance);
