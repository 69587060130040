import React, { Component } from 'react';
import { connect } from 'react-redux';
import WallArea from './WallArea';
import WallLayout from '../../common/WallLayout';
import { getLayoutById } from '../../redux/wallRedux';
import TvGridLine from '../layouts/TvGridLine';

class Preview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ref: null,
      maxHeight: window.innerHeight - 500,
      maxWidth: props.collapsed === true ? window.innerWidth - 68 - 120 : window.innerWidth - 200 - 120,
    };
  }

  componentDidMount() {
    this._updateWallDimensions();
    window.addEventListener('resize', this._updateWallDimensions);
    window.addEventListener('devtoolschange', this._updateWallDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._updateWallDimensions);
    window.removeEventListener('devtoolschange', this._updateWallDimensions);
  }

  componentDidUpdate(prevProps) {
    const { studioWall, collapsed } = this.props;

    if (prevProps.studioWall._id !== studioWall._id) {
      this.forceUpdate();
    }

    if (prevProps.collapsed !== collapsed) {
      setTimeout(() => {
        this._updateWallDimensions();
      }, 500);
    }
  }

  _setRef = (el) => {
    if (el !== null) {
      this.setState({ ref: el });
    }
  };

  _updateWallDimensions = () => {
    const { collapsed } = this.props;
    this.setState({
      maxHeight: window.innerHeight - 500,
      maxWidth: collapsed === true ? window.innerWidth - 68 - 120 : window.innerWidth - 200 - 120,
    });
  };

  _renderTVBreak = () => {
    const { activeStudio } = this.props;
    const { rows, columns } = activeStudio;
    const { maxWidth, maxHeight } = this.state;

    if (maxWidth !== 0 && maxHeight !== 0) {
      const gridLines = [];

      const screenWidth = maxWidth / columns;
      const screenHeight = maxHeight / rows;

      for (let x = 0; x <= columns; x += 1) {
        const left = x * screenWidth;
        let color = '#20B1EE';
        let borderWidth = 1;
        if (x % 3 === 0 && x > 0 && x < columns) {
          color = '#287737';
          borderWidth = 2;
        }
        gridLines.push(<TvGridLine key={`vbLine-${x}`} direction='vertical' color={color} borderWidth={borderWidth} position={100 * (left / maxWidth)} />);
      }
      for (let y = 0; y <= rows; y += 1) {
        const top = y * screenHeight;
        gridLines.push(<TvGridLine key={`hbLine-${y}`} direction='horizontal' color='#20B1EE' position={100 * (top / maxHeight)} />);
      }
      return gridLines;
    }
    return null;
  };

  _renderAreas = () => {
    const { selectedLayout, studioWall } = this.props;
    const { rows, columns } = studioWall;

    let wallAreas = [];
    if (selectedLayout && this.state.ref !== null) {
      const { offsetWidth, offsetHeight } = this.state.ref;
      const tvWidth = offsetWidth / columns;
      const tvHeight = offsetHeight / rows;

      selectedLayout.areas.forEach((area, areaIndex) => {
        const { sourceType, crop, data, gridRowStart, gridRowEnd, gridColumnStart, gridColumnEnd, padding, width, height } = area;
        wallAreas.push(
          <WallArea
            key={`area-${areaIndex}`}
            areaIndex={areaIndex}
            style={{
              gridRowStart,
              gridRowEnd,
              gridColumnStart,
              gridColumnEnd,
              height: 'auto',
              position: 'relative',
            }}
            rows={rows}
            columns={columns}
            tvWidth={tvWidth}
            tvHeight={tvHeight}
            sourceType={sourceType}
            crop={crop}
            data={data}
            padding={padding}
            width={width}
            height={height}
          />,
        );
      });
    }
    return wallAreas;
  };

  render() {
    const { studioWall, selectedLayout } = this.props;
    const { rows, columns } = studioWall;
    const { maxWidth, maxHeight } = this.state;

    const content = (
      <WallLayout rows={studioWall.rows} columns={studioWall.columns} maxHeight={maxHeight} maxWidth={maxWidth}>
        <div
          ref={this._setRef}
          className='areas-wrapper'
          style={{
            display: 'grid',
            width: '100%',
            height: '100%',
            gridTemplateColumns: `repeat(${columns * 6}, 1fr)`,
            gridTemplateRows: `repeat(${rows * 2}, 1fr)`,
          }}
        >
          {this._renderAreas()}
        </div>
        {this._renderTVBreak()}
      </WallLayout>
    );

    return !selectedLayout ? content : <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 1000 }}>{content}</div>;
  }
}

const mapStateToProps = (state, ownProps) => ({
  editedArea: state.wall.editedArea,
  selectedLayout: getLayoutById(state, ownProps.layout),
  collapsed: state.ui.navBarCollapsed,
  activeStudio: state.studio.active,
});

export default connect(mapStateToProps, null)(Preview);
