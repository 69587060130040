import React, { useState } from 'react';
import Button from '../../common/Button';
import TextInput from '../../common/TextInput';
import { post } from '../../services/api';
import Logo from '../../assets/images/inception-logo-blue.png';

export default function LoginForm({ onSubmit, eventId }) {
  const [email, setEmail] = useState('');
  const [accessCode, setAccessCode] = useState('');
  const [hasRequestedCode, setHasRequestedCode] = useState(false);

  async function _handleSubmit() {
    try {
      const { data } = await post(`/auth/access/validate`, { email, accessCode, eventId });
      const isAuthorized = data.isValid;
      onSubmit({ isAuthorized, email, accessCode });
    } catch (error) {
      console.log(error);
    }
  }

  async function _getAccessCode() {
    try {
      await post(`/auth/access/code`, { email, eventId });
      setHasRequestedCode(true);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className='reports-container' style={{ alignItems: 'center', justifyContent: 'center' }}>
      <div style={{ display: 'flex', flexDirection: 'column', width: 360, padding: 30, borderRadius: 4 }}>
        <img src={Logo} alt='Logo' style={{ width: 180, margin: '0 auto', marginBottom: 15 }} />
        <TextInput
          name='email'
          label='Email'
          value={email}
          onChange={(e) => setEmail(e.target.value.toLowerCase())}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              _getAccessCode();
            }
          }}
          labelStyle={{ color: '#000' }}
          inputStyle={{ color: '#000' }}
          disabled={hasRequestedCode}
        />
        <div className={hasRequestedCode ? 'access-code-input expanded' : 'access-code-input'}>
          <TextInput
            name='accessCode'
            label='Access Code'
            value={accessCode}
            onChange={(e) => setAccessCode(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                _handleSubmit();
              }
            }}
            labelStyle={{ color: '#000' }}
            inputStyle={{ color: '#000' }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <Button
            type='primary'
            text={hasRequestedCode ? 'Login' : 'Request Access'}
            onClick={() => {
              if (hasRequestedCode) {
                _handleSubmit();
              } else {
                _getAccessCode();
              }
            }}
            containerStyle={{ margin: '30px 0 0 0', width: 380 }}
          />
        </div>
      </div>
    </div>
  );
}
