import React, { useEffect, useRef, useState } from 'react';
import 'grapesjs/dist/css/grapes.min.css';
import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.css';
import '../../css/HTMLEditor.css';
import grapesjs from 'grapesjs';
import 'grapesjs-preset-webpage';

import blocks from './Blocks';
import styles from './Styles';

const HTMLEditor = () => {
  const _editorRef = useRef();
  const [editor, setEditor] = useState(undefined);
  console.log(editor);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      const editorInstance = grapesjs.init({
        container: _editorRef.current,
        components: '',
        style: `
          .header {
            color: red;
          }
          .custom-container {
            display: flex;
            margin: 10px;
            padding: 10px;
          }
          .flex-row {
            flex-direction: row;
            justify-content: space-between;
          }
          .custom-block-section {
            color: blue;
          }
        `,
        storageManager: false,
        height: '100%',
        width: '100%',
        styleManager: {
          sectors: styles
        },
        plugins: ['gjs-preset-webpage'],
        pluginsOpts: {
          'gjs-preset-webpage': {
            'gjs-blocks-basic': {
              flexGrid: true
            }
          }
        }
      });

      const { BlockManager } = editorInstance;

      blocks.forEach(block => {
        BlockManager.add(block.id, block);
      });

      setEditor(editorInstance);
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  return (
    <div className='html-editor-container'>
      <div className='content'>
        <div ref={_editorRef} className='editor' />
      </div>
    </div>
  );
};

export default HTMLEditor;
