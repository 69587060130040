export const socketServerInstanceTypes = [
  { value: 'c6a.2xlarge', label: '500 Off Wall - c6a.2xlarge', maxOffWallParticipants: 500 },
  { value: 'c6a.4xlarge', label: '1000 Off Wall - c6a.4xlarge', maxOffWallParticipants: 1000 },
  { value: 'c6a.8xlarge', label: '2500 Off Wall - c6a.8xlarge', maxOffWallParticipants: 2500 },
  { value: 'c6a.16xlarge', label: '5000 Off Wall - c6a.16xlarge', maxOffWallParticipants: 5000 },
];

export const compositorInstanceTypes = [
  { value: 'g3.8xlarge', label: 'Internal Testing - g3.8xlarge' },
  { value: 'g3.16xlarge', label: 'Affordable - g3.16xlarge' },
  { value: 'p2.8xlarge', label: 'Pricey - p2.8xlarge' },
];

export const decoderServerInstanceTypes = [
  { value: 'c5.2xlarge', label: '3 On Wall - c5.2xlarge', maxOnWallParticipants: 3 },
  { value: 'c5.9xlarge', label: '30 On Wall - c5.9xlarge', maxOnWallParticipants: 30 },
  { value: 'c5.18xlarge', label: '75 On Wall - c5.18xlarge', maxOnWallParticipants: 75 },
  { value: 'c5.24xlarge', label: '100 On Wall - c5.24xlarge', maxOnWallParticipants: 100 },
  { value: 'c6i.32xlarge', label: '150 On Wall - c6i.32xlarge', maxOnWallParticipants: 150 },
  { value: 'c6a.32xlarge', label: '150 On Wall - c6a.32xlarge', maxOnWallParticipants: 150 },
  { value: 'c6a.48xlarge', label: '200 On Wall - c6a.48xlarge', maxOnWallParticipants: 200 },
];

export const rtcServerInstanceTypes = [
  { value: 'c5.2xlarge', label: '3 On Wall - c5.2xlarge', maxOnWallParticipants: 3 },
  { value: 'c5.9xlarge', label: '15 On Wall - c5.9xlarge', maxOnWallParticipants: 15 },
  { value: 'c5.12xlarge', label: '20 On Wall - c5.12xlarge', maxOnWallParticipants: 20 },
  { value: 'c5.18xlarge', label: '30 On Wall - c5.18xlarge', maxOnWallParticipants: 30 },
  { value: 'c5.24xlarge', label: '40 On Wall - c5.24xlarge', maxOnWallParticipants: 40 },
  { value: 'c6a.32xlarge', label: '60 On Wall - c6a.32xlarge', maxOnWallParticipants: 60 },
];
