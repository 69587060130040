import React, { PureComponent } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import copyIcon from '../../assets/icons/copy.svg';
import deleteIcon from '../../assets/icons/delete.svg';
import editIcon from '../../assets/icons/edit.svg';
import { getEventById } from '../../redux/eventsRedux';
import { isAdmin } from '../../redux/authRedux';
import Image from '../../common/Image';
import defaultIcon from '../../assets/images/default-event.png';
import ContextMenu from '../../common/ContextMenu';
import liveLabelImg from '../../assets/icons/live_label.png';
import ActivateIcon from '../../common/ActivateIcon';
import SocketClient from '../../utils/socket-client';

class EventListItem extends PureComponent {
  static propTypes = {
    selected: PropTypes.bool,
    eventId: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    onDuplicate: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selected: false,
  };

  state = {
    showOptions: false,
    showContextMenu: false,
    contextMenuPosition: { x: 0, y: 0 },
  };

  _onMouseOver = () => {
    this.setState({ showOptions: true });
  };

  _onMouseOut = () => {
    this.setState({ showOptions: false });
  };

  _onSetActive = (e) => {
    e.stopPropagation();
    const { event, onSetActive } = this.props;
    onSetActive && onSetActive(event);
  };

  _onClickHandler = () => {
    const { event, onSelect } = this.props;
    onSelect && onSelect(event);
  };

  _onDuplicateClickHandler = (e) => {
    e.stopPropagation();
    const { event, onDuplicate } = this.props;
    onDuplicate && onDuplicate(event);
  };

  _onEditClickHandler = (e) => {
    e.stopPropagation();
    const { event, onEdit } = this.props;
    onEdit && onEdit(event);
  };

  _onDeleteClickHandler = (e) => {
    e.stopPropagation();
    const { event, onDelete, activeStudio } = this.props;
    if (!activeStudio.liveEvent || event._id.toString() !== activeStudio.liveEvent.toString()) {
      onDelete && onDelete(event);
    } else {
      swal({
        title: `Can't delete a LIVE Event`,
      });
    }
  };

  _getContextMenuItems = () => {
    const { event, liveEvent, isAdmin, appMode, liveStreams } = this.props;

    if (!isAdmin) {
      return [];
    }

    if (event.isArchived) {
      return [{ value: 2, label: 'Unarchive' }];
    } else if (appMode === 'PANDO') {
      return liveEvent && liveEvent === event._id
        ? [{ value: 3, label: 'Clear Text Chat' }]
        : [
            { value: 1, label: 'Archive' },
            { value: 3, label: 'Clear Text Chat' },
            { value: 9, label: 'Clear Event Data' },
          ];
    } else if (appMode === 'BROADCAST') {
      const { broadcastStream } = event;
      return broadcastStream && liveStreams && liveStreams.find((s) => s.channelArn === broadcastStream.channelArn) && SocketClient.socket.connected
        ? [
            { value: 10, label: 'End Event', danger: true },
            { value: 3, label: 'Clear Text Chat' },
          ]
        : [
            { value: 1, label: 'Archive' },
            { value: 3, label: 'Clear Text Chat' },
            { value: 9, label: 'Clear Event Data' },
          ];
    } else {
      return [];
    }
  };

  _onContextMenuHandler = (e) => {
    const { enableContextMenu } = this.props;
    if (enableContextMenu) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({
        contextMenuPosition: { x: e.pageX, y: e.pageY },
        showContextMenu: true,
      });
    }
  };

  _hideContextMenu = () => {
    this.setState({
      contextMenuPosition: { x: 0, y: 0 },
      showContextMenu: false,
    });
  };

  render() {
    const { appMode, event, selected, active, contextMenuItemSelectionHandler, activeStudio, isAdmin, liveStreams } = this.props;
    const { contextMenuPosition, showContextMenu } = this.state;
    const imgStyle = {
      height: '100%',
      display: 'block',
      alignSelf: 'center',
    };
    let liveLabel;
    if (appMode === 'PANDO' && activeStudio && activeStudio.liveEvent) {
      if (event._id.toString() === activeStudio.liveEvent.toString()) {
        liveLabel = <img src={liveLabelImg} alt='live' style={{ width: 'auto', marginLeft: 10 }} />;
      }
    } else if (appMode === 'BROADCAST' && activeStudio) {
      if (
        event.broadcastStream &&
        liveStreams &&
        liveStreams.find((s) => s.channelArn === event.broadcastStream.channelArn) &&
        SocketClient.socket.connected &&
        !event.endTime
      ) {
        liveLabel = <img src={liveLabelImg} alt='live' style={{ width: 'auto', marginLeft: 10 }} />;
      }
    }

    let listItem = null;
    let containerWidth = '100%';
    let eventTitleWidth = '78%';
    if (event) {
      let options = null;
      if (this.state.showOptions) {
        options = (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              position: 'absolute',
              top: 0,
              bottom: 0,
              right: 0,
              backgroundColor: '#23313f',
              alignItems: 'center',
            }}
          >
            {!event.isArchived && (
              <>
                {active && active._id === event._id ? (
                  <div className='event-option' onClick={() => ({})} role='button' tabIndex='-1' onKeyUp={() => {}}>
                    <ActivateIcon width={26} height={26} color='#287737' />
                  </div>
                ) : (
                  <div className='event-option' onClick={this._onSetActive} role='button' tabIndex='-1' onKeyUp={() => {}}>
                    <ActivateIcon width={26} height={26} />
                  </div>
                )}
              </>
            )}
            {isAdmin && (
              <>
                <div className='event-option' onClick={this._onDuplicateClickHandler} role='button' tabIndex='-1' onKeyUp={() => {}}>
                  <img src={copyIcon} alt='copy' />
                </div>
                <div className='event-option' onClick={this._onEditClickHandler} role='button' tabIndex='-1' onKeyUp={() => {}}>
                  <img src={editIcon} alt='edit' />
                </div>
                {event.isArchived && (
                  <div className='event-option' onClick={this._onDeleteClickHandler} role='button' tabIndex='-1' onKeyUp={() => {}}>
                    <img src={deleteIcon} alt='delete' />
                  </div>
                )}
              </>
            )}
          </div>
        );
      }
      listItem = (
        <div
          className='event-list-item'
          onClick={this._onClickHandler}
          role='none'
          onMouseEnter={this._onMouseOver}
          onMouseLeave={this._onMouseOut}
          onContextMenu={this._onContextMenuHandler}
        >
          {showContextMenu === true ? (
            <ContextMenu
              position={contextMenuPosition}
              items={this._getContextMenuItems()}
              onClose={this._hideContextMenu}
              onItemSelected={contextMenuItemSelectionHandler}
            />
          ) : null}
          <div className={selected ? 'event selected' : 'event'}>
            <div style={{ display: 'flex', flexDirection: 'row', width: containerWidth }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 112,
                  height: 63,
                  minWidth: 112,
                  minHeight: 63,
                  backgroundColor: '#435061',
                  overflow: 'hidden',
                }}
              >
                {event.logoPreview ? <Image source={event.logoPreview} alt='event logo' /> : <img src={defaultIcon} alt='event logo' style={imgStyle} />}
              </div>
              <div className='event-info' style={{ width: eventTitleWidth }}>
                <div className='event-title'>
                  <span>{event.name}</span>
                  {liveLabel}
                </div>
                <div className='event-date'>{event.scheduledStartTime ? moment(event.scheduledStartTime).format('MM/DD/YYYY hh:mm A') : '-'}</div>
              </div>
            </div>
            {options}
          </div>
        </div>
      );
    }
    return listItem;
  }
}

const mapStateToProps = (state, props) => ({
  appMode: state.ui.appMode,
  event: getEventById(state, props.eventId),
  active: state.events.active,
  activeStudio: state.studio.active,
  liveEvent: state.studio.active ? state.studio.active.liveEvent : null,
  isAdmin: isAdmin(state),
  liveStreams: state.studio.liveStreams,
});

export default connect(mapStateToProps, null)(EventListItem);
