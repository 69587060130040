import React, { useRef } from 'react';
import uuidV4 from 'uuid';
import { Doughnut } from 'react-chartjs-2';
import { getColorScale, getDefaultPluginConfig } from '../../utils/charts-util';
import htmlLegendPlugin from './HtmlLegendPlugin';

export default function DoughnutChart({ title, data, labels, style, colors }) {
  const htmlLegendContainerId = useRef(uuidV4());
  const colorScale = colors || getColorScale(labels.length);

  return (
    <div style={style}>
      <Doughnut
        data={{
          labels,
          datasets: [
            {
              data,
              backgroundColor: colorScale,
              borderColor: colorScale,
              borderWidth: 1,
            },
          ],
        }}
        options={{
          plugins: {
            ...getDefaultPluginConfig(title),
            htmlLegend: {
              containerID: `${htmlLegendContainerId.current}`,
            },
            legend: {
              display: false,
            },
            datalabels: {
              display: true,
            },
          },
        }}
        plugins={[htmlLegendPlugin]}
      />
      <div id={`${htmlLegendContainerId.current}`} />
    </div>
  );
}
