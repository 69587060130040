import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SearchBox from '../../common/SearchBox';
import Button from '../../common/Button';
import { getLayoutById, setHightlightedParticipantIndex, updateEventParticipantRequest } from '../../redux/wallRedux';
import { updateParticipantsInBulkRequest } from '../../redux/participantRedux';

class OnAirSlotAllocator extends Component {
  state = {
    searchString: '',
    wallDisplayNum: '',
    valid: true,
  };

  _setOnAir = async (onAirSlotNum) => {
    const { participants, updateParticipantsInBulkRequest, onAirParticipants, activeEvent, setHightlightedParticipantIndex } = this.props;
    const { wallDisplayNum, valid } = this.state;
    if (valid && wallDisplayNum) {
      const eventParticipant = participants.find(
        (p) => (p.status === 'ON_WALL' || p.status === 'ON_AIR') && p.wallDisplayNum && p.wallDisplayNum === parseInt(wallDisplayNum),
      );
      if (eventParticipant) {
        const nextOnAirParticipantId = eventParticipant.participant._id;
        const onAirParticipantsData = [];
        if (onAirParticipants[onAirSlotNum]) {
          if (onAirParticipants[onAirSlotNum] === nextOnAirParticipantId) {
            onAirParticipantsData.push({ participant: nextOnAirParticipantId, status: 'ON_WALL', onAirNum: null });
          } else {
            onAirParticipantsData.push(
              { participant: onAirParticipants[onAirSlotNum], status: 'ON_WALL', onAirNum: null },
              { participant: nextOnAirParticipantId, status: 'ON_AIR', onAirNum: onAirSlotNum },
            );
          }
        } else {
          onAirParticipantsData.push({ participant: nextOnAirParticipantId, status: 'ON_AIR', onAirNum: onAirSlotNum });
        }
        updateParticipantsInBulkRequest(onAirParticipantsData, activeEvent._id);
        this.setState({ searchString: '', wallDisplayNum: '' });
      } else {
        this.setState({ searchString: '', wallDisplayNum: '' });
      }
    } else if (onAirParticipants[onAirSlotNum]) {
      updateParticipantsInBulkRequest([{ participant: onAirParticipants[onAirSlotNum], status: 'ON_WALL', onAirNum: null }], activeEvent._id);
    }
    setHightlightedParticipantIndex(null);
  };

  _hightlightParticipant = () => {
    const { valid, wallDisplayNum } = this.state;
    const { participants, setHightlightedParticipantIndex } = this.props;
    if (valid) {
      const ep = participants.find((p) => p.wallDisplayNum === parseInt(wallDisplayNum));
      if (ep && (ep.status === 'ON_WALL' || ep.status === 'ON_AIR')) {
        setHightlightedParticipantIndex(ep.wallDisplayNum);
      } else {
        setHightlightedParticipantIndex(null);
      }
    } else {
      setHightlightedParticipantIndex(null);
    }
  };

  _onChange = ({ target }) => {
    const { participants } = this.props;
    const filteredParticipants = participants.filter((p) => p.status === 'ON_WALL' || p.status === 'ON_AIR');
    let ep = {};
    if (target.value.length > 0) {
      ep = filteredParticipants.find((p) => {
        if (isNaN(target.value)) {
          return `${p.participant.firstName || ''} ${p.participant.lastName || ''}`.toLowerCase().trim().includes(target.value.toLowerCase());
        } else {
          return p.wallDisplayNum === parseInt(target.value);
        }
      });
    }
    this.setState(
      {
        searchString: target.value,
        wallDisplayNum: ep && ep.wallDisplayNum,
        valid: !!ep,
      },
      this._hightlightParticipant,
    );
  };

  _cycleOnAir = async () => {
    const areas = this.props.selectedLayout.areas.filter((a) => {
      return a.sourceType === 'Participant';
    });

    let onAirParticipants = [];
    for (let i = 0; i < areas.length; i++) {
      const area = areas[i];
      const eventParticipant = this.props.participants.find((p) => p.wallDisplayNum && p.wallDisplayNum === parseInt(area.data.wallDisplayNum));
      if (onAirParticipants.length < 4) {
        onAirParticipants.push(eventParticipant.participant._id);
      }
      if (onAirParticipants.length === 4 || i === areas.length - 1) {
        const onAirParticipantsData = [];
        onAirParticipants.forEach((p, j) => {
          onAirParticipantsData.push({ participant: p, status: 'ON_AIR', onAirNum: j + 1 });
        });

        this.props.updateParticipantsInBulkRequest(onAirParticipantsData, this.props.activeEvent._id);

        await new Promise((resolve) => setTimeout(resolve, 3000));

        this.props.updateParticipantsInBulkRequest(
          onAirParticipantsData.map((oap) => {
            oap.status = 'ON_WALL';
            oap.onAirNum = null;
            return oap;
          }),
          this.props.activeEvent._id,
        );
        onAirParticipants = [];
      }
    }
  };

  render() {
    const { onAirParticipants } = this.props;
    const { searchString, valid } = this.state;
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          padding: 10,
          flexWrap: 'wrap',
        }}
      >
        <div style={{ width: '25%', marginRight: 30 }}>
          <SearchBox
            value={searchString}
            onChange={this._onChange}
            showIcon={false}
            inputStyle={
              !valid
                ? { borderBottom: '1px solid #711a20', backgroundColor: 'rgba(185, 44, 44, 0.2)', marginLeft: 0, marginRight: 15 }
                : { marginLeft: 0, marginRight: 15 }
            }
          />
        </div>
        <Button
          type={onAirParticipants[1] ? 'danger' : 'secondary'}
          text='On Air #1'
          onClick={() => this._setOnAir(1)}
          containerStyle={{ padding: '7px 14px', fontSize: 14 }}
        />
        <Button
          type={onAirParticipants[2] ? 'danger' : 'secondary'}
          text='On Air #2'
          onClick={() => this._setOnAir(2)}
          containerStyle={{ padding: '7px 14px', fontSize: 14 }}
        />
        <Button
          type={onAirParticipants[3] ? 'danger' : 'secondary'}
          text='On Air #3'
          onClick={() => this._setOnAir(3)}
          containerStyle={{ padding: '7px 14px', fontSize: 14 }}
        />
        <Button
          type={onAirParticipants[4] ? 'danger' : 'secondary'}
          text='On Air #4'
          onClick={() => this._setOnAir(4)}
          containerStyle={{ padding: '7px 14px', fontSize: 14 }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  activeEvent: state.events.active,
  participants: state.events.participants,
  selectedLayout: getLayoutById(state, ownProps.layout),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setHightlightedParticipantIndex, updateParticipantsInBulkRequest, updateEventParticipantRequest }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OnAirSlotAllocator);
