import { combineReducers } from 'redux';

import rootSaga from '../sagas';

import configureStore from './createStore';
import studioReducer from './studioRedux';
import layoutsReducer from './layoutsRedux';
import goLiveReducer from './goLiveRedux';
import eventsReducer from './eventsRedux';
import uiReducer from './uiRedux';
import assetsReducer from './assetsRedux';
import uploaderReducer from './uploaderRedux';
import wallReducer from './wallRedux';
import authReducer from './authRedux';
import usersReducer from './usersRedux';
import breakoutRoomsReducer from './breakoutRoomsRedux';
import apiKeysReducer from './apiKeyRedux';
import participantReducer from './participantRedux';

export const reducers = combineReducers({
  studio: studioReducer,
  layouts: layoutsReducer,
  goLive: goLiveReducer,
  events: eventsReducer,
  ui: uiReducer,
  assets: assetsReducer,
  uploader: uploaderReducer,
  wall: wallReducer,
  auth: authReducer,
  users: usersReducer,
  breakoutRooms: breakoutRoomsReducer,
  apiKeys: apiKeysReducer,
  participants: participantReducer,
});

export const { store } = configureStore(reducers, rootSaga);
