import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';

class ColorPickerHex extends PureComponent {
  static propTypes = {
    hexValue: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { hexValue } = props;
    this.state = {
      hexValue,
      displayColorPicker: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { hexValue } = this.props;
    if (prevProps.hexValue !== hexValue) {
      this.setState({ hexValue });
    }
  }

  handleClick = () => {
    const { displayColorPicker } = this.setState;
    this.setState({ displayColorPicker: !displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color) => {
    const { onChange } = this.props;
    onChange(color.hex);
    this.setState({ hexValue: color.hex });
  };

  render() {
    const { hexValue, displayColorPicker } = this.state;

    const styles = {
      color: {
        width: '36px',
        height: '20px',
        borderRadius: 0,
        background: hexValue,
      },
      swatch: {
        padding: 0,
        background: '#fff',
        borderRadius: 0,
        border: 1,
        borderStyle: 'solid',
        borderColor: 'gray',
        marginTop: 3,
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    };

    return (
      <div>
        <div style={styles.swatch} onClick={this.handleClick}>
          <div style={styles.color} />
        </div>
        {displayColorPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={this.handleClose} />
            <ChromePicker color={hexValue} onChange={this.handleChange} />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ColorPickerHex;
