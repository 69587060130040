import { createActions, handleActions } from 'redux-actions';

const initialState = {
  user: null,
  fetching: false,
  checking: false,
  error: null,
};

export const actions = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGOUT: 'LOGOUT',
  CHECK_SESSION: 'CHECK_SESSION',
};

export const { loginRequest, loginSuccess, loginFailure, logout, checkSession } = createActions({
  [actions.LOGIN_REQUEST]: (username, password) => ({ username, password }),
  [actions.LOGIN_SUCCESS]: (data) => ({ data }),
  [actions.LOGIN_FAILURE]: (error) => ({ error }),
  [actions.LOGOUT]: () => ({}),
  [actions.CHECK_SESSION]: () => ({}),
});

const reducer = handleActions(
  {
    [actions.LOGIN_REQUEST]: (state) => ({
      ...state,
      error: null,
      fetching: true,
      user: null,
    }),
    [actions.LOGIN_SUCCESS]: (state, action) => ({
      ...state,
      user: action.payload.data,
      fetching: false,
      checking: false,
      error: null,
    }),
    [actions.LOGIN_FAILURE]: (state, action) => {
      let error = null;
      if (action.payload) {
        if (action.payload.response) {
          error = action.payload.response.data.message;
        } else {
          error = action.payload.toString();
        }
      }
      return {
        ...initialState,
        error,
      };
    },
    [actions.LOGOUT]: (state) => ({
      ...state,
      ...initialState,
    }),
    [actions.CHECK_SESSION]: (state) => ({
      ...state,
      error: null,
      checking: true,
    }),
  },
  initialState,
);

export const isAdmin = (state) => {
  const { user } = state.auth;
  return user && (user.role === 'ADMIN' || user.role === 'SUPER_ADMIN');
};

export const isSuperAdmin = (state) => {
  const { user } = state.auth;
  return user && (user.role === 'SUPER_ADMIN');
};

export default reducer;
