import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortNumericDown, faSortNumericUp, faSortAlphaDown, faSortAlphaUp } from '@fortawesome/free-solid-svg-icons';
import ContextMenu from '../../common/ContextMenu';

export default function ParticipantsListHeader({ onChangeSort, hideDuration }) {
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
  const [sortBy, setSortBy] = useState('Name');
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    onChangeSort && onChangeSort({ sortBy, sortDirection });
  }, [sortBy, sortDirection]);

  const _onContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowContextMenu(true);
    setContextMenuPosition({ x: e.pageX, y: e.pageY });
  };

  const _getContextMenuItems = () => {
    const items = [
      {
        value: 1,
        icon: <FontAwesomeIcon icon={sortBy === 'Name' && sortDirection === 'asc' ? faSortAlphaUp : faSortAlphaDown} color='#aaa' />,
        label: 'Sort by name',
      },
      {
        value: 2,
        icon: <FontAwesomeIcon icon={sortBy === 'Duration' && sortDirection === 'asc' ? faSortNumericUp : faSortNumericDown} color='#aaa' />,
        label: 'Sort by duration',
      },
    ];
    if (hideDuration) {
      items.splice(1, 1);
    }
    return items;
  };

  const _onContextMenuItemSelection = async (option) => {
    try {
      switch (option.value) {
        case 1:
          if (sortBy === 'Name') {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
          } else {
            setSortDirection('asc');
          }
          setSortBy('Name');
          break;
        case 2:
          if (sortBy === 'Duration') {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
          } else {
            setSortDirection('asc');
          }
          setSortBy('Duration');
          break;
        default:
          break;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const _hideContextMenu = () => {
    setShowContextMenu(false);
    setContextMenuPosition({ x: 0, y: 0 });
  };

  return (
    <div className='participant-list-header' onClick={_onContextMenu}>
      {showContextMenu === true && (
        <ContextMenu position={contextMenuPosition} items={_getContextMenuItems()} onClose={_hideContextMenu} onItemSelected={_onContextMenuItemSelection} />
      )}
      {sortBy === 'Name' ? (
        <FontAwesomeIcon icon={sortDirection === 'desc' ? faSortAlphaUp : faSortAlphaDown} color='#aaa' />
      ) : (
        <FontAwesomeIcon icon={sortDirection === 'desc' ? faSortNumericUp : faSortNumericDown} color='#aaa' />
      )}
      <span>{sortBy}</span>
    </div>
  );
}
