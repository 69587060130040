export const toBase64 = (file) => {
  const p = new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
  return p;
};

export const base64toBlob = (b64Data, contentType = '', sliceSize = 521) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType });
};

export const base64ToFile = (b64Data) => {
  const block = b64Data.split(';');
  const contentType = block[0].split(':')[1];
  const realData = block[1].split(',')[1];
  const blob = base64toBlob(realData, contentType);
  return new File([blob], 'image.png');
};

export const compressImage = (img, originalWidth, originalHeight) => {
  const canvas = document.createElement('canvas');
  canvas.width = 1280;
  canvas.height = 720;
  const context = canvas.getContext('2d');
  const { width: canvasWidth, height: canvasHeight } = canvas;
  context.clearRect(0, 0, canvasWidth, canvasHeight);
  context.fillStyle = '#000000';
  context.fillRect(0, 0, canvasWidth, canvasHeight);

  const ratio = Math.min(canvasWidth / originalWidth, canvasHeight / originalHeight);
  const width = originalWidth * ratio;
  const height = originalHeight * ratio;

  context.drawImage(img, 0, 0, originalWidth, originalHeight, canvasWidth / 2 - width / 2, canvasHeight / 2 - height / 2, width, height);

  let data;
  let quality = 1;

  while (!data || data.length > 256 * 1024) {
    data = canvas.toDataURL('image/jpeg', quality);
    quality *= 0.95;
  }

  return base64ToFile(data);
};
