import React from 'react';

const Property = ({ label, value, onClick }) => {
  let _className = 'property';
  if (!!onClick) {
    _className += ' clickable';
  }
  return (
    <div className={_className} onClick={onClick}>
      <span className='label'>{`${label}:`}</span>
      <span className='value'>{value}</span>
    </div>
  );
};

export default Property;
