import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { get, setStudioIdHeader } from '../../services/api';
import Spinner from '../../common/Spinner';
import Image from '../../common/Image';
import SocketClient from '../../utils/socket-client';

import ParticipantRow from './ParticipantRow';

import '../../css/Leaderboard.css';

function Leaderboard({ location }) {
  const [data, setData] = useState(null);
  const [event, setEvent] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const studioId = urlParams.get('studioId');
    const socketServerUrl = urlParams.get('socketServerUrl');
    const eventId = urlParams.get('eventId');

    document.body.style.background = '#fff';

    async function getLeaderboard() {
      try {
        const { data: leaderboardData } = await get(`/poll/leaderboard?event=${eventId}`);
        setData(leaderboardData);
      } catch (err) {
        console.error(err);
      }
    }

    async function getEvent() {
      try {
        const { data: eventData } = await get(`/event/${eventId}`);
        setEvent(eventData);
      } catch (err) {
        console.error(err);
      }
    }

    setStudioIdHeader(studioId);
    getEvent();
    getLeaderboard();

    SocketClient.connect(socketServerUrl);
    SocketClient.joinRoom(`${studioId}:${eventId}:leaderboard`);

    SocketClient.on('question-answered', () => {
      getLeaderboard();
    });

    return () => {
      SocketClient.leaveRoom(`${studioId}:${eventId}:leaderboard`);
      SocketClient.removeAllListeners();
    };
  }, []);

  if (!data) {
    return <Spinner />;
  }

  return (
    <div className='leaderboard-page'>
      <div className='leaderboard-header'>
        {event && event.logo && (
          <div className='logo'>
            <Image source={event.logo} alt='event' responsive />
          </div>
        )}
        <h1>Leaderboard</h1>
      </div>
      <div className='leaderboard-container'>
        <div className='leaderboard'>
          {Object.entries(data.leaderboard).map(([key, value], index) => {
            const animationDelay = index * 0.2;
            return <ParticipantRow key={key} value={value} data={data} animationDelay={animationDelay} />;
          })}
        </div>
      </div>
    </div>
  );
}

Leaderboard.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default Leaderboard;
