import React, { useState, useEffect, useRef } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { get, setStudioIdHeader } from '../../services/api';
import ImageCache from '../../utils/image-cache';
import StringUtil from '../../utils/string-utils';
import SocketClient from '../../utils/socket-client';
import '../../css/Polls.css';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, ChartDataLabels);

export default function PollResults({ location }) {
  const ref = useRef(null);
  const _resultsTimerId = useRef(null);
  const containerRef = useRef(null);

  const [pollData, setPollData] = useState(null);
  const [bgImage, setBgImage] = useState(null);
  const [question, setQuestion] = useState(null);
  const [labels, setLabels] = useState([]);
  const [values, setValues] = useState([]);
  const [fontSize, setFontSize] = useState(22);

  const urlParams = new URLSearchParams(location.search);
  const studioId = urlParams.get('studioId');
  const socketServerUrl = urlParams.get('socketServerUrl');
  const eventId = urlParams.get('eventId');

  const _getResults = async (pollId) => {
    try {
      const _values = [];
      const { data: pollResponses } = await get(`/poll/${pollId}/results`);
      const { labels, poll } = ref.current;
      labels.forEach((label) => {
        let _label = label.map((l) => l.trim()).join(' ');
        if (poll.prefixAnswersWith !== 0) {
          _label = _label.substring(_label.indexOf('.') + 2, _label.length);
        }
        const resp = pollResponses.find((r) => r._id === _label);
        if (resp) {
          _values.push(resp.count);
        } else {
          _values.push(0);
        }
      });
      setValues(_values);
    } catch (err) {
      console.error(err);
    }
  };

  const _getPollData = async (pollId) => {
    try {
      const { data: poll } = await get(`/poll/${pollId}`);
      const parts = poll.data.split('\n').filter((d) => !!d);
      const _labels = [];

      let longestlabel = 0;
      parts.forEach((p, i) => {
        if (i === 0) {
          setQuestion(p);
        } else {
          if (p.length > longestlabel) {
            longestlabel = p.length;
          }
          let label;
          if (poll.prefixAnswersWith === 0) {
            label = p;
          } else if (poll.prefixAnswersWith === 1) {
            label = `${String.fromCharCode(97 + i - 1).toUpperCase()}. ${p}`;
          } else if (poll.prefixAnswersWith === 2) {
            label = `${i}. ${p}`;
          }
          _labels.push(label);
        }
      });

      const _fontsize = Math.max(24 - longestlabel / 12, 12);
      const _labelMaxChars = Math.min(50 + (longestlabel / 20) * 5, 100);

      const width = containerRef.current.offsetWidth / 2;
      let r = width / 576;

      for (let i = 0; i < _labels.length; i++) {
        _labels[i] = StringUtil.splitLabelInSections(_labels[i], _labelMaxChars);
      }

      setFontSize(_fontsize * r);
      setLabels(_labels);

      ref.current = { poll, labels: _labels };
      if (poll.backgroundImage) {
        const img = await ImageCache.get(poll.backgroundImage);
        setBgImage(img);
      } else {
        setBgImage(null);
      }
      setPollData(poll);
    } catch (err) {
      console.error(err);
    }
  };

  const _displayResults = async (pollId) => {
    await _getPollData(pollId);
    _getResults(pollId);
    clearInterval(_resultsTimerId.current);
    _resultsTimerId.current = setInterval(() => _getResults(pollId), 1000);
  };

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      localStorage.removeItem('poll-results-window-open');
    });
    setStudioIdHeader(studioId);

    (async () => {
      try {
        const { data: studio } = await get(`/studio/${studioId}`);
        const isBroadcast = studio.dbName === 'broadcast';
        const liveEventId = isBroadcast ? eventId : studio.liveEvent;
        if (liveEventId) {
          const { data } = await get(`/poll/status?event=${liveEventId}`);
          if (data) {
            const { showingResults } = data;
            if (showingResults) {
              _displayResults(showingResults._id);
            }
          }
        }
      } catch (err) {
        console.error(err);
      }
    })();

    SocketClient.connect(socketServerUrl);
    SocketClient.joinRoom(`${studioId}:poll-results-window`);

    SocketClient.on('poll-results-window-command', async ({ type, payload }) => {
      if (type === 'display-poll-results') {
        _displayResults(payload.pollId);
      } else if (type === 'clear-poll-results' && ref.current && ref.current.poll && ref.current.poll._id === payload.pollId) {
        setValues([]);
      }
    });

    return () => {
      clearInterval(_resultsTimerId.current);
      localStorage.removeItem('poll-results-window-open');
    };
  }, []);

  return (
    <div
      className='poll-results'
      style={{
        backgroundImage: bgImage ? `url(${bgImage})` : 'none',
        backgroundColor: pollData && pollData.backgroundColor ? pollData.backgroundColor : '#fff',
      }}
    >
      <h1 style={{ color: pollData && pollData.textColor ? pollData.textColor : '#000' }}>{question}</h1>
      <div ref={containerRef} className='chart-container'>
        <Bar
          datasetIdKey='id'
          data={{
            labels: labels,
            datasets: [
              {
                id: 1,
                label: '',
                data: values,
                backgroundColor: [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(153, 102, 255, 1)',
                  'rgba(255, 159, 64, 1)',
                ],
                datalabels: {
                  anchor: 'center',
                  color: '#000000',
                  borderColor: '#000000',
                  font: { size: '30' },
                },
                borderColor: [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(153, 102, 255, 1)',
                  'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
              },
            ],
          }}
          options={{
            events: [],
            indexAxis: 'y',
            scales: {
              x: {
                ticks: {
                  display: false,
                },
                grid: {
                  display: false,
                },
              },
              y: {
                afterFit: (scaleInstance) => {
                  if (!containerRef.current) return;
                  const { offsetWidth } = containerRef.current;
                  scaleInstance.width = offsetWidth / 2;
                },
                grid: {
                  display: false,
                },
                ticks: {
                  font: {
                    size: fontSize,
                    weight: 600,
                  },
                  color: pollData && pollData.textColor ? pollData.textColor : '#000',
                },
              },
            },
            tooltips: false,
            legend: {
              display: false,
            },
            plugins: {
              legend: {
                display: false,
              },
              datalabels: {
                backgroundColor: (context) => {
                  return context.dataset.backgroundColor;
                },
                borderColor: 'white',
                borderRadius: 25,
                borderWidth: 0,
                padding: 5,
                color: 'white',
                display: (context) => {
                  const dataset = context.dataset;
                  const value = dataset.data[context.dataIndex];
                  return value >= 1;
                },
                font: {
                  weight: 'bold',
                },
                formatter: (value, context) => {
                  const { displayResultLabelsAs } = ref.current.poll;
                  if (displayResultLabelsAs === 1 || displayResultLabelsAs === 2) {
                    const dataset = context.dataset;
                    const count = dataset.data.length;
                    let total = 0;
                    for (let i = 0; i < count; i++) {
                      total += parseInt(dataset.data[i]);
                    }
                    const percent = Math.round((value * 100) / total);
                    return displayResultLabelsAs === 2 ? `${percent}% (${value})` : `${percent}%`;
                  } else {
                    return value;
                  }
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
}
