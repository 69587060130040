import React, { useState } from 'react';
import { connect } from 'react-redux';
import Tabs from '../../common/Tabs';
import GoLiveContent from './GoLiveContent';
import Gateway from './Gateway';
import ClusterHealth from './ClusterHealth';

const { REACT_APP_CLUSTER_ENABLED } = process.env;

function GoLive({ appMode }) {
  const [activeTab, setActiveTab] = useState('servers');

  if (appMode === 'BROADCAST') {
    return <GoLiveContent />;
  }

  const tabs = [
    { value: 'servers', label: 'AWS Servers' },
    { value: 'gateway', label: 'Studio Gateway' },
  ];
  if (REACT_APP_CLUSTER_ENABLED === 'true') {
    tabs.push({ value: 'cluster', label: 'Cluster Health' });
  }

  return (
    <>
      <Tabs options={tabs} value={activeTab} onChange={(tab) => setActiveTab(tab)} style={{ marginBottom: 20 }} />
      <div style={{ height: 'calc(100% - 55px)' }}>
        {activeTab === 'gateway' && <Gateway />}
        {activeTab === 'servers' && <GoLiveContent />}
        {activeTab === 'cluster' && <ClusterHealth />}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  appMode: state.ui.appMode,
});

export default connect(mapStateToProps)(GoLive);
