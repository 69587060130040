import React from 'react';

export const Table = ({ children }) => {
  return <table>{children}</table>;
};

export const TableHeader = ({ children }) => {
  return (
    <thead>
      <tr style={{ textAlign: 'left' }}>{children && children.map((child, index) => <th key={index}>{child}</th>)}</tr>
    </thead>
  );
};

export const TableBody = ({ children }) => {
  return <tbody style={{ margin: 0 }}>{children}</tbody>;
};

export const TableRow = ({ children }) => {
  return <tr>{children && children.map((child, index) => <td key={index}>{child}</td>)}</tr>;
};
