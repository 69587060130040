import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ImageCache from '../utils/image-cache';

function ParticipantPhoto({ photoUrl, width, height, customStyle }) {
  const [imgSrc, setImgSrc] = useState(null);

  useEffect(() => {
    (async () => {
      if (photoUrl) {
        const url = new URL(photoUrl);
        const imgData = await ImageCache.get(url.pathname.substring(1));
        setImgSrc(imgData);
      }
    })();
  }, [photoUrl]);

  return imgSrc && <img style={{ width, height, ...customStyle }} src={imgSrc} alt='participant' />;
}

ParticipantPhoto.propTypes = {
  photoUrl: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customStyle: PropTypes.shape({}),
};

export default ParticipantPhoto;
