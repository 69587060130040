import { get } from '../services/api';

export const downloadAsset = async (data) => {
  const link = document.createElement('a');
  try {
    const response = await get(`/file/download?fileKey=${data.fileKey}`);
    const downloadUrl = response.data;
    const ext = data.fileKey.split('.').pop();
    link.href = downloadUrl;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.download = `${data.name}.${ext}`;
    document.body.appendChild(link);
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      }),
    );
  } catch (error) {
    console.error(error);
  } finally {
    document.body.removeChild(link);
  }
};

export const formatDuration = (seconds) => {
  let hrs = Math.floor(seconds / 3600) % 24;
  let min = Math.floor(seconds / 60) % 60;
  let sec = Math.floor(seconds % 60);
  return `${min < 10 ? '0' + min : min}:${sec < 10 ? '0' + sec : sec}`;
};
