import React from 'react';
import ReactSwitch from 'react-switch';
import '../css/Switch.css';

export default function Switch({ onChange, checked, label, onColor, offColor, width, height, disabled, containerStyle, labelStyle }) {
  return (
    <label className='switch-container' style={containerStyle}>
      <ReactSwitch
        onChange={onChange}
        checked={checked}
        checkedIcon={false}
        uncheckedIcon={false}
        onColor={onColor || '#43B77A'}
        offColor={offColor || '#4e6379'}
        height={height || 28}
        width={width || 56}
        disabled={disabled}
      />
      <span className='label' style={labelStyle}>
        {label}
      </span>
    </label>
  );
}
