import React from 'react';

import unconfiguredIcon from '../../assets/icons/studio1.svg';

const UnconfiguredStudio = () => (
  <div className='studio-placeholder'>
    <img src={unconfiguredIcon} alt='Unconfigured' />
    <p>Please set the number of rows and columns of screens your studio wall is comprised of</p>
  </div>
);

export default UnconfiguredStudio;
