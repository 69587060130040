import React from 'react';
import AsyncSelect from 'react-select/async';
import { get } from '../../services/api';

export default function AutoComplete({
  apiURL,
  apiFilter,
  isMulti,
  searchParams,
  onLoad,
  onChange,
  getOptionLabel,
  getOptionValue,
  value,
  noOptionsMessage,
  maxMenuHeight,
}) {
  const _loadOptions = (inputValue) => {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await get(apiURL);
        if (apiFilter) {
          response.data = response.data.filter(apiFilter);
        }
        onLoad(response.data);
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const _handleInputChange = (newValue) => {
    return newValue.replace(/\W/g, '');
  };

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      color: '#f5f5f5',
      flex: 1,
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: '#141b21',
      boxShadow: 'none',
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: '#141b21',
      color: '#a9aaab',
    }),
  };

  return (
    <AsyncSelect
      styles={customStyles}
      isMulti={isMulti}
      value={value}
      onInputChange={_handleInputChange}
      loadOptions={_loadOptions}
      onChange={onChange}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      noOptionsMessage={noOptionsMessage}
      cacheOptions
      defaultOptions
      maxMenuHeight={maxMenuHeight || 200}
      menuPlacement='auto'
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary75: '#a9aaab',
          primary50: '#a9aaab',
          primary25: '#202b36',
          primary: '#787878',
          neutral0: '#a9aaab',
          neutral5: '#a9aaab',
          neutral10: '#4e6379',
          neutral20: '#a9aaab',
          neutral30: '#a9aaab',
          neutral40: '#a9aaab',
          neutral50: '#a9aaab',
          neutral60: '#f5f5f5',
          neutral70: '#f5f5f5',
          neutral80: '#f5f5f5',
          neutral90: '#f5f5f5',
        },
      })}
    />
  );
}
