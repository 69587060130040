import { all, takeLatest, call, take, fork, put, delay } from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';

import {
  actions as uploaderActions,
  endUpload,
  startUpload,
  addFile,
  setProgress,
  uploadFilesFailure,
  uploadFilesSuccess,
  resetUploader,
} from '../redux/uploaderRedux';
import { uploadToS3 } from '../services/api';

function createUploader(file, name, type, index, event, fileKey) {
  let emit;
  const chan = eventChannel((emitter) => {
    emit = emitter;
    return () => {};
  });
  const uploadProgressCb = ({ total, loaded }) => {
    emit({ index, loaded });
    if (loaded === total) {
      emit(END);
    }
  };
  const uploadPromise = uploadToS3(file, name, type, event, fileKey, uploadProgressCb);
  return [uploadPromise, chan];
}

function* uploadProgressWatcher(chan) {
  while (true) {
    const { index, loaded } = yield take(chan);
    yield put(setProgress({ index, loaded }));
  }
}

function* postFile(file, name, type, index, event, fileKey) {
  const [uploadPromise, chan] = yield call(createUploader, file, name, type, index, event, fileKey);
  yield fork(uploadProgressWatcher, chan);
  const res = yield call(() => uploadPromise);
  return put(uploadFilesSuccess(res, index));
}

function* uploadFiles(action) {
  try {
    if (action.payload) {
      const { files, name, event, fileKey } = action.payload;
      let totalSize = 0;
      const totalFiles = files.length;

      const promises = [];
      const _files = [];
      for (let i = 0; i < files.length; i += 1) {
        const f = files[i];
        const file = {
          name: name || f.name,
          size: f.size,
          type: f.type,
        };
        totalSize += file.size;
        _files.push(file);
      }
      yield put(addFile(_files));
      yield put(startUpload(totalSize, totalFiles));
      for (let i = 0; i < files.length; i += 1) {
        const f = files[i];
        const fileParts = f.name.split('.');
        const fileType = fileParts.pop();
        const fileName = name || f.name;

        promises.push(yield call(postFile, f, fileName, fileType, i, event, fileKey));
      }
      yield all(promises);
      yield put(endUpload());
      yield delay(500);
      yield put(resetUploader());
    }
  } catch (error) {
    console.log(error);
    yield put(uploadFilesFailure(error));
  }
}

export default function* () {
  yield all([takeLatest([uploaderActions.UPLOAD_REQUEST], uploadFiles)]);
}
