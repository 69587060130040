import { all, takeLatest, call, put } from 'redux-saga/effects';

import {
  actions as studioActions,
  getStudiosFailure,
  getStudiosSuccess,
  updateConfigSuccess,
  updateConfigFailure,
  createConfigSuccess,
  createConfigFailure,
  deleteStudioSuccess,
  deleteStudioFailure,
} from '../redux/studioRedux';
import { disableRoutes } from '../redux/uiRedux';
import { get, put as update, post, del } from '../services/api';

export function* fetchStudios() {
  try {
    const response = yield call(get, '/studio/');
    if (response.status === 200) {
      yield put(getStudiosSuccess(response.data));
    } else {
      yield put(disableRoutes());
      yield put(getStudiosSuccess([]));
    }
  } catch (error) {
    yield put(getStudiosFailure(error));
  }
}

export function* updateStudioWall(action) {
  try {
    const { id, configuration } = action.payload;
    const response = yield call(update, `/studio/${id}`, configuration);
    if (response.status === 200) {
      yield put(updateConfigSuccess(response.data));
    } else {
      yield put(updateConfigFailure({ status: response.status, message: response.data }));
    }
  } catch (error) {
    yield put(updateConfigFailure(error));
  }
}

export function* createStudioWall(action) {
  try {
    const { configuration } = action.payload;
    const response = yield call(post, '/studio/', configuration);
    if (response.status === 200) {
      yield put(createConfigSuccess(response.data));
    } else {
      yield put(updateConfigFailure({ status: response.status, message: response.data }));
    }
  } catch (error) {
    yield put(createConfigFailure(error));
  }
}

export function* deleteStudio(action) {
  try {
    const { id } = action.payload;
    const response = yield call(del, `/studio/${id}`);
    if (response.status === 200) {
      yield put(deleteStudioSuccess(response.data));
    } else {
      yield put(deleteStudioFailure({ status: response.status, message: response.data }));
    }
  } catch (error) {
    yield put(deleteStudioFailure(error));
  }
}

export default function* () {
  yield all([
    takeLatest([studioActions.UPDATE_REQUEST], updateStudioWall),
    takeLatest([studioActions.CREATE_REQUEST], createStudioWall),
    takeLatest([studioActions.GET_REQUEST], fetchStudios),
    takeLatest([studioActions.DELETE_REQUEST], deleteStudio),
  ]);
}
