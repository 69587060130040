import React from 'react';
import { useSelector } from 'react-redux';
import Button from '../../common/Button';

const { REACT_APP_PANDO_CLIENT_HOST } = process.env;

export default function PreviewButton({ event, style }) {
  const activeStudio = useSelector((state) => state.studio.active);

  const _openPreview = () => {
    if (!activeStudio || !event) return;

    window.open(
      `https://${REACT_APP_PANDO_CLIENT_HOST}/login?ShowName=${event.name}&studioId=${activeStudio._id}&isPreview=true`,
      'Preview',
      'width=1280,height=720,toolbar=0,menubar=0,location=0',
    );
  };

  return <Button text='Preview' onClick={_openPreview} containerStyle={style} />;
}
