import React, { useState } from 'react';
import { connect } from 'react-redux';

import { getStudioById } from '../../redux/studioRedux';
import deleteIcon from '../../assets/icons/delete.svg';
import editIcon from '../../assets/icons/edit.svg';
import ActivateIcon from '../../common/ActivateIcon';

const StudioListItem = (props) => {
  const { studio, active, onEdit, onDelete, onSelected, onSetActive, isSuperAdmin } = props;
  let itemClass = 'studio-list-item';
  let options = null;

  const [showOptions, setShowOptions] = useState(false);

  const _onMouseOver = () => {
    setShowOptions(true);
  };

  const _onMouseOut = () => {
    setShowOptions(false);
  };

  const _onSetActive = (event) => {
    event.stopPropagation();
    onSetActive && onSetActive(studio);
  };

  const _onEdit = (event) => {
    event.stopPropagation();
    onEdit && onEdit(studio);
  };

  const _onDelete = (event) => {
    event.stopPropagation();
    onDelete && onDelete(studio);
  };

  if (showOptions) {
    itemClass += ' active';
    options = (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {active && active._id === studio._id ? (
          <div className='studio-option' onClick={() => ({})} role='button' tabIndex='-1' onKeyUp={() => {}}>
            <ActivateIcon width={26} height={26} color='#287737' />
          </div>
        ) : (
          <div className='studio-option' onClick={_onSetActive} role='button' tabIndex='-1' onKeyUp={() => {}}>
            <ActivateIcon width={26} height={26} />
          </div>
        )}
        {isSuperAdmin && (
          <>
            <div className='studio-option' onClick={_onEdit} role='button' tabIndex='-1' onKeyUp={() => {}}>
              <img src={editIcon} alt='edit' />
            </div>
            <div className='studio-option' onClick={_onDelete} role='button' tabIndex='-1' onKeyUp={() => {}}>
              <img src={deleteIcon} alt='delete' />
            </div>
          </>
        )}
      </div>
    );
  }
  return (
    <div className={itemClass} onClick={() => onSelected(studio)} onMouseEnter={_onMouseOver} onMouseLeave={_onMouseOut}>
      <span>{studio.name}</span>
      {options}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  studio: getStudioById(state, ownProps.item),
  active: state.studio.active,
});

export default connect(mapStateToProps, null)(StudioListItem);
