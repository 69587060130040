import React, { PureComponent } from 'react';
import uuidV4 from 'uuid';
import RTC from '../../utils/rtc-common';
import SocketClient from '../../utils/socket-client';

export default class OnAirStream extends PureComponent {
  constructor(props) {
    super(props);
    this._videoRef = React.createRef();
    this._rtc = null;
  }

  componentDidMount() {
    this._getClientStream();
    window.onbeforeunload = this.componentWillUnmount.bind(this);
  }

  componentWillUnmount() {
    this._closeClientStream();
    this.props.onUnmount(this.props.onAirSlot);
    window.onbeforeunload = () => {};
  }

  _getClientStream = async () => {
    const { onAirSlot, onReady } = this.props;
    const uuid = uuidV4();
    const roomName = `${uuid}-admin-recv`;

    const rtc = new RTC(roomName);
    const pc = await rtc.setup();

    SocketClient.emit('get-on-air-stream', { onAirSlot, uuid });

    pc.ontrack = ({ track }) => {
      console.debug(`Received ${track.kind} MediaStreamTrack with ID ${track.id}`);
      if (track.kind === 'video') {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(track);
        if (this._videoRef.current) {
          this._videoRef.current.muted = true;
          this._videoRef.current.srcObject = mediaStream;
          onReady(onAirSlot);
        }
      }
    };

    await new Promise(async (resolve) => {
      const { type, value } = await rtc.getMessage('state');
      if (type === 'state' && value === 'CONFIG_COMPLETED') {
        resolve();
      }
    });

    console.debug('Sending READY_FOR_OFFER');
    rtc.sendMessage({
      type: 'state',
      value: 'READY_FOR_OFFER',
    });

    await rtc.startServer();
    this._rtc = rtc;
  };

  _closeClientStream = () => {
    if (this._rtc) {
      this._rtc.pc.getRemoteStreams().forEach((stream) => {
        stream.getTracks().forEach((track) => track.stop());
      });
      this._rtc.stop();
    }
  };

  render() {
    const { onAirParticipant, onAirSlot, style } = this.props;

    let participantName = '\u00A0';
    let location = '\u00A0';
    if (onAirParticipant) {
      const { wallDisplayNum, participant } = onAirParticipant;
      const { firstName, lastName } = participant;
      const index = wallDisplayNum ? `${wallDisplayNum}.` : '';
      participantName = `${index} ${firstName} ${lastName}`.trim();
      location = participant.city;
    }

    return (
      <div className='stream' style={{ ...style, gridArea: `onAirSlot${onAirSlot}` }}>
        <div className='video-container'>
          <div className='num' style={{ color: onAirParticipant ? '#e81d32' : '#bbbcbc' }}>
            {`#${onAirSlot}`}
          </div>
          <video ref={this._videoRef} autoPlay muted style={{ opacity: onAirParticipant ? 1 : 0 }} />
        </div>
        <div>
          <div>{participantName}</div>
          <div>{location}</div>
        </div>
      </div>
    );
  }
}
