import studio from './assets/images/navbar/studio.svg';
import events from './assets/images/navbar/events.svg';
import layouts from './assets/images/navbar/layouts.svg';
import layoutsAlt from './assets/images/navbar/layouts-alt.svg';
import goLive from './assets/images/navbar/goLive.svg';
import participantsIcon from './assets/images/navbar/participants.svg';
import participantsGreenIcon from './assets/images/navbar/participants-green.svg';
import wall from './assets/images/navbar/wall.svg';
import streams from './assets/images/navbar/streams.svg';
import userIcon from './assets/icons/participant.svg';
import breakoutRoomsIcon from './assets/images/navbar/rooms.svg';
import pollIcon from './assets/images/navbar/poll.svg';
import pollGreenIcon from './assets/images/navbar/poll-green.svg';
import Studios from './routes/studios';
import Events from './routes/events';
import Layouts from './routes/layouts';
import GoLive from './routes/goLive';
import Wall from './routes/wall';
import Streams from './routes/streams';
import Users from './routes/users';
import Participants from './routes/participants';
import BreakoutRooms from './routes/breakoutRooms';
import ApiKeys from './routes/apiKeys';
import SystemEvents from './routes/systemEvents';
import HTMLEditor from './routes/htmlEditor';
import Polls from './routes/polls';

export const PANDO_ROUTES = [
  {
    label: 'Studios',
    navBar: true,
    component: Studios,
    link: '/app/studios',
    path: '/studios',
    activeBorderColor: '#70c34e',
    img: studio,
    allowedRoles: ['SUPER_ADMIN', 'ADMIN', 'TECH_SUPPORT', 'POLL_SUPPORT'],
  },
  {
    label: 'Events',
    navBar: true,
    component: Events,
    link: '/app/events',
    path: '/events',
    activeBorderColor: '#70c34e',
    img: events,
    allowedRoles: ['SUPER_ADMIN', 'ADMIN', 'TECH_SUPPORT', 'POLL_SUPPORT'],
  },
  {
    label: 'Participants',
    navBar: true,
    component: Participants,
    link: '/app/participants',
    path: '/participants',
    activeBorderColor: '#70c34e',
    img: participantsGreenIcon,
    allowedRoles: ['SUPER_ADMIN', 'ADMIN', 'TECH_SUPPORT'],
    isLiveVersion: false,
  },
  {
    label: 'Layouts',
    navBar: true,
    component: Layouts,
    link: '/app/layouts',
    path: '/layouts',
    activeBorderColor: '#70c34e',
    img: layouts,
    allowedRoles: ['SUPER_ADMIN', 'ADMIN'],
    isLiveVersion: false,
  },
  {
    label: 'Polls',
    navBar: true,
    component: Polls,
    link: '/app/polls',
    path: '/polls',
    activeBorderColor: '#70c34e',
    img: pollGreenIcon,
    allowedRoles: ['SUPER_ADMIN', 'ADMIN', 'TECH_SUPPORT', 'POLL_SUPPORT'],
    isLiveVersion: false,
  },
  {
    label: 'Go Live',
    navBar: true,
    component: GoLive,
    link: '/app/goLive',
    path: '/goLive',
    activeBorderColor: '#ff0830',
    img: goLive,
    allowedRoles: ['SUPER_ADMIN', 'ADMIN'],
  },
  {
    label: 'Participants',
    navBar: true,
    component: Participants,
    link: '/app/participants-live',
    path: '/participants-live',
    activeBorderColor: '#ff0830',
    img: participantsIcon,
    allowedRoles: ['SUPER_ADMIN', 'ADMIN', 'TECH_SUPPORT'],
    isLiveVersion: true,
  },
  {
    label: 'Layouts',
    navBar: true,
    component: Layouts,
    link: '/app/layouts-live',
    path: '/layouts-live',
    activeBorderColor: '#ff0830',
    img: layoutsAlt,
    allowedRoles: ['SUPER_ADMIN', 'ADMIN'],
    isLiveVersion: true,
  },
  {
    label: 'Wall',
    navBar: true,
    component: Wall,
    link: '/app/wall',
    path: '/wall',
    activeBorderColor: '#ff0830',
    img: wall,
    allowedRoles: ['SUPER_ADMIN', 'ADMIN'],
  },
  {
    label: 'Rooms',
    navBar: true,
    component: BreakoutRooms,
    link: '/app/breakout-rooms',
    path: '/breakout-rooms',
    activeBorderColor: '#ff0830',
    img: breakoutRoomsIcon,
    allowedRoles: ['SUPER_ADMIN', 'ADMIN', 'TECH_SUPPORT'],
  },
  {
    label: 'Streams',
    navBar: true,
    component: Streams,
    link: '/app/streams',
    path: '/streams',
    activeBorderColor: '#ff0830',
    img: streams,
    allowedRoles: ['SUPER_ADMIN', 'ADMIN'],
  },
  {
    label: 'Polls',
    navBar: true,
    component: Polls,
    link: '/app/polls-live',
    path: '/polls-live',
    activeBorderColor: '#ff0830',
    img: pollIcon,
    allowedRoles: ['SUPER_ADMIN', 'ADMIN', 'TECH_SUPPORT', 'POLL_SUPPORT'],
    isLiveVersion: true,
  },
  {
    label: 'Users',
    navBar: false,
    component: Users,
    link: '/app/users',
    path: '/users',
    activeBorderColor: '#70c34e',
    img: userIcon,
    allowedRoles: ['SUPER_ADMIN', 'ADMIN', 'TECH_SUPPORT', 'POLL_SUPPORT'],
  },
  {
    label: 'API Keys',
    navBar: false,
    component: ApiKeys,
    link: '/app/apiKeys',
    path: '/apiKeys',
    activeBorderColor: '#70c34e',
    img: userIcon,
    allowedRoles: ['SUPER_ADMIN', 'ADMIN'],
  },
  {
    label: 'System Events',
    navBar: false,
    component: SystemEvents,
    link: '/app/systemEvents',
    path: '/systemEvents',
    activeBorderColor: '#70c34e',
    img: userIcon,
    allowedRoles: ['SUPER_ADMIN', 'ADMIN'],
  },
  {
    label: 'HTML Editor',
    navBar: false,
    component: HTMLEditor,
    link: '',
    path: '/html-editor',
    activeBorderColor: '#70c34e',
    img: userIcon,
    allowedRoles: ['SUPER_ADMIN', 'ADMIN'],
  },
];

export const BROADCAST_ROUTES = [
  {
    label: 'Events',
    navBar: true,
    component: Events,
    link: '/app/broadcast/events',
    path: '/broadcast/events',
    activeBorderColor: '#70c34e',
    img: events,
    allowedRoles: ['SUPER_ADMIN', 'ADMIN'],
  },
  {
    label: 'Polls',
    navBar: true,
    component: Polls,
    link: '/app/broadcast/polls',
    path: '/broadcast/polls',
    activeBorderColor: '#70c34e',
    img: pollGreenIcon,
    allowedRoles: ['SUPER_ADMIN', 'ADMIN'],
  },
  {
    label: 'Go Live',
    navBar: true,
    component: GoLive,
    link: '/app/broadcast/goLive',
    path: '/broadcast/goLive',
    activeBorderColor: '#ff0830',
    img: goLive,
    allowedRoles: ['SUPER_ADMIN', 'ADMIN'],
  },
  {
    label: 'Participants',
    navBar: true,
    component: Participants,
    link: '/app/broadcast/participants',
    path: '/broadcast/participants',
    activeBorderColor: '#ff0830',
    img: participantsIcon,
    allowedRoles: ['SUPER_ADMIN', 'ADMIN'],
  },
  {
    label: 'Streams',
    navBar: true,
    component: Streams,
    link: '/app/broadcast/streams',
    path: '/broadcast/streams',
    activeBorderColor: '#ff0830',
    img: streams,
    allowedRoles: ['SUPER_ADMIN', 'ADMIN'],
  },
];
