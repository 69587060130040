import React, { PureComponent } from 'react';

import downIcon from '../assets/icons/arrow_down.svg';
import upIcon from '../assets/icons/arrow_up.svg';
import '../css/DropDown.css';

class DropDown extends PureComponent {
  state = {
    hidden: true,
  };

  _hideMenu = (event) => {
    const { classList } = event.target;
    if (!classList.contains('dropdown-button')) {
      this.setState({ hidden: true });
    }
  };

  componentDidMount() {
    document.body.addEventListener('click', this._hideMenu, false);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this._hideMenu, false);
  }

  _toggleMenu = () => {
    this.setState((prevState) => ({
      hidden: !prevState.hidden,
    }));
  };

  _renderMenuItems = () => {
    let { children } = this.props;
    children = children.filter(Boolean);
    if (children) {
      const { hidden } = this.state;
      const menuItems = React.Children.map(children, (child) => <div className='dropdown-menu-item'>{child}</div>);
      const menu = (
        <div className='dropdown-menu' style={{ visibility: hidden === true ? 'hidden' : 'visible' }}>
          {menuItems}
        </div>
      );
      return menu;
    }
    return null;
  };

  render() {
    const { hidden } = this.state;
    const { label, icon, containerStyle } = this.props;
    let arrow = downIcon;
    let menuButtonClass = 'dropdown-button';

    if (hidden === false) {
      menuButtonClass = 'dropdown-button open';
      arrow = upIcon;
    }

    return (
      <div className='dropdown'>
        <div className={menuButtonClass} onClick={this._toggleMenu} style={containerStyle}>
          {icon ? <img src={icon} alt='icon' width='20' height='20' /> : null}
          <span>{label}</span>
          <img className='arrow' src={arrow} alt='arrow' />
        </div>
        {this._renderMenuItems()}
      </div>
    );
  }
}

export default DropDown;
