import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Select from '../../common/Select';
import { post } from '../../services/api';
import { launchFromImageSuccess, launchFromImageFailure } from '../../redux/goLiveRedux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

function InstanceAZ({ list, instanceId, value, status, disabled, launchFromImageSuccess, launchFromImageFailure }) {
  const [showList, setShowList] = useState(false);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    if (value) {
      const type = list.find((it) => it.ZoneName === value);
      if (type) {
        setSelected(type.ZoneName);
      }
    }
  }, [list]);

  useEffect(() => {
    if (status !== 'stopped') {
      setShowList(false);
    }
  }, [status]);

  const _onEdit = () => {
    if (status === 'stopped' && showList === false && !disabled) {
      setShowList(!showList);
    }
  };

  const _onChange = async (type) => {
    try {
      const prevType = selected;
      setLoading(true);
      setShowList(false);
      if (selected !== type.ZoneName) {
        setSelected(type.ZoneName);
        const response = await post(`/instance/${instanceId}/launchFromImage`, { availabilityZone: type.ZoneName });
        if (response.status !== 200) {
          setSelected(prevType);
        } else {
          launchFromImageSuccess();
        }
      }
    } catch (error) {
      console.error(error);
      launchFromImageFailure();
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={status === 'stopped' && !showList && !disabled ? 'instance-type stopped' : 'instance-type'} onClick={_onEdit}>
      {!showList && selected && selected}
      {status === 'stopped' && showList && !disabled && (
        <div className='types-list'>
          <Select
            list={list}
            listKey='ZoneName'
            listLabel='ZoneName'
            onChange={_onChange}
            selected={selected}
            currentOption={selected}
            containerStyle={{ width: 110 }}
            small
          />
        </div>
      )}
      {disabled && <FontAwesomeIcon icon={faLock} color='#43586f' style={{ fontSize: 12, marginLeft: 8 }} />}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  launchFromImageSuccess: () => dispatch(launchFromImageSuccess()),
  launchFromImageFailure: () => dispatch(launchFromImageFailure()),
});

export default connect(null, mapDispatchToProps)(InstanceAZ);
