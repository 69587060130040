import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../css/IconButton.css';

const IconButton = (props) => {
  const { onIcon, offIcon, value, onChange, tooltip, onColor, size } = props;

  const _onClickHandler = (event) => {
    event.stopPropagation();
    onChange && onChange({ value: !value });
  };

  const className = value === false ? 'icon-button off' : 'icon-button on';

  return (
    <div className={className} onClick={_onClickHandler} role='none' tabIndex='-1' onKeyUp={() => {}}>
      {value === false ? <FontAwesomeIcon icon={offIcon} color='#FFFFFF' style={{ fontSize: size || 16 }} /> : <FontAwesomeIcon icon={onIcon} color={onColor || '#FFFFFF'} style={{ fontSize: size || 16 }} />}
      {tooltip ? <span className='tooltip'>{tooltip}</span> : null}
    </div>
  );
};

export default IconButton;
