import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setGridColor, setLayoutAreas } from '../../../redux/layoutsRedux';
// import ColorPicker from '../../../common/ColorPicker';
import Button from '../../../common/Button';

const LayoutGridForm = ({ grid, onSave, setGridColor, enableSave, setLayoutAreas, areas }) => {
  // const { color } = grid;

  const _unassignAll = () => {
    areas.forEach((area) => {
      if (area.sourceType === 'Participant') {
        area.sourceType = 'WallParticipant';
        area.data = null;
      }
    });
    setLayoutAreas(areas);
  };

  return (
    <div className='layout-configuration'>
      <div className='layout-configuration-actions' style={{ marginBottom: 5 }}>
        <Button text='Save' onClick={onSave} disabled={!enableSave} />
        <Button type='danger' text='Unassign All' onClick={_unassignAll} containerStyle={{ margin: 0, padding: '7px 18px' }} />
      </div>
      {/* <ColorPicker label='Grid Color' value={color} onChange={(value) => setGridColor(value)} /> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  grid: state.layouts.grid,
  areas: state.layouts.areas,
  currentLayoutId: state.layouts.current,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ setGridColor, setLayoutAreas }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LayoutGridForm);
