import React, { PureComponent } from 'react';
import swal from '@sweetalert/with-react';
import Spinner from '../../common/Spinner';

const styles = {
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 100,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default class ProgramFeed extends PureComponent {
  state = {
    streamUnavailable: false,
    playerState: null,
    showEnterMeeting: null,
    customStyle: {},
    fullscreen: false,
  };

  componentDidMount() {
    const script = document.createElement('script');
    const body = document.getElementsByTagName('body')[0];
    script.src = 'https://player.live-video.net/1.12.0/amazon-ivs-player.min.js';
    body.appendChild(script);
    script.addEventListener('load', () => {
      if (window.IVSPlayer.isPlayerSupported) {
        this._stream();
      } else {
        swal({
          title: 'Browser not supported',
          text: 'Your browser is not compatible. Please use a different browser.',
        });
      }
    });
  }

  _stream = () => {
    this.videoElement = document.getElementById('off-wall-player');
    this.player = window.IVSPlayer.create();
    this.player.attachHTMLVideoElement(this.videoElement);

    const { PlayerState, PlayerEventType } = window.IVSPlayer;
    const { READY, PLAYING, ENDED } = PlayerState;
    const { ERROR } = PlayerEventType;

    this.player.addEventListener(READY, () => {
      this.setState({ playerState: READY });
    });

    this.player.addEventListener(PLAYING, () => {
      this.setState({ playerState: PLAYING, streamUnavailable: false });
    });

    this.player.addEventListener(ENDED, () => {
      this.setState({ playerState: ENDED, streamUnavailable: true });
      this.player.delete();
      setTimeout(() => {
        this._stream();
      }, 3000);
    });

    this.player.addEventListener(ERROR, (e) => {
      console.log('Player error:', e);
      this.setState({ playerState: ERROR, streamUnavailable: true });
      this.player.delete();
      setTimeout(() => {
        this._stream();
      }, 3000);
    });

    this.player.load(this.props.url);
    this.player.play();
  };

  render() {
    const { playerState } = this.state;
    const { PLAYING } = window.IVSPlayer ? window.IVSPlayer.PlayerState : {};

    return (
      <div className='program-feed'>
        <div style={styles.overlay}>{playerState !== PLAYING && <Spinner />}</div>
        <video id='off-wall-player' autoPlay muted={false} controls={false} />
      </div>
    );
  }
}
