import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImageCache from '../utils/image-cache';

class Image extends PureComponent {
  static propTypes = {
    source: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    responsive: PropTypes.bool,
    fillContainer: PropTypes.bool,
    noCache: PropTypes.bool,
  };

  static defaultProps = {
    width: undefined,
    height: undefined,
    responsive: false,
    fillContainer: false,
    noCache: false,
  };

  _img = React.createRef();

  async componentDidMount() {
    await this._loadImage();
  }

  componentDidUpdate(prevProps) {
    const { source } = this.props;
    if (prevProps.source !== source) {
      this._loadImage();
    }
  }

  _loadImage = async () => {
    try {
      if (this._img.current !== null) {
        const { source } = this.props;
        this._img.current.src = await ImageCache.get(source);
      }
    } catch (error) {}
  };

  render() {
    const { alt, width, height, responsive, fillContainer, maxHeight, style } = this.props;

    let imgStyle = {};
    if (responsive) {
      imgStyle = {
        maxWidth: '100%',
        height: 'auto',
        maxHeight: maxHeight ? `${maxHeight}px` : '220px',
        display: 'block',
        alignSelf: 'center',
      };
    }

    if (fillContainer) {
      imgStyle = {
        width: '100%',
        height: '100%',
        display: 'block',
      };
    }

    return <img src='https://place-hold.it/160x90/1C2733/1C2733' ref={this._img} alt={alt} width={width} height={height} style={{ ...imgStyle, ...style }} />;
  }
}

export default Image;
