import React from 'react';
import { formatDuration } from '../../utils/assets-util';

const VideoAssetMetadata = ({ data }) => {
  let assetText = '';
  if (data) {
    if (data.metadata.width > 0) {
      const { width, height, duration, frameRate } = data.metadata;
      if (data.type === 'video') {
        const metadataStr = `${frameRate ? `${frameRate}fps ` : ''} ${duration ? `${formatDuration(duration)} - ` : ''} ${width}x${height}`.trim();
        assetText = `${data.name} (${metadataStr})`;
      } else {
        assetText = `${data.name} (${width}x${height})`;
      }
    } else {
      assetText = `${data.name}`;
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        color: '#a9aaab',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: 30,
        padding: 3,
      }}
    >
      <div style={{ margin: '0 5px', fontSize: 10, overflow: 'hidden' }}>
        <div>{assetText}</div>
      </div>
    </div>
  );
};

export default VideoAssetMetadata;
