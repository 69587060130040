import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import ColorPickerHex from '../../../common/ColorPickerHex';
import Switch from '../../../common/Switch';
import Input from '../../../common/Input';
import DropDownFormField from './DropDownFormField';
import '../../../css/Wysiwyg.css';

const DEFAULT_FOOTER_HTML =
  '<p><span style="color: rgb(255,255,255);">For technical support, please call the help desk at 866-864-2865<br>Event Code:</span></p>\n';

class Wysiwyg extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    displayEnabled: PropTypes.bool,
    displayShowHtml: PropTypes.bool,
    displayWidth: PropTypes.bool,
    displayPinTo: PropTypes.bool,
    displaySpacing: PropTypes.bool,
    displayShowPandoLogo: PropTypes.bool,
    allowSelectUnits: PropTypes.bool,
    enabled: PropTypes.bool,
    height: PropTypes.number,
    heightUnits: PropTypes.string,
    width: PropTypes.number,
    widthUnits: PropTypes.string,
    markup: PropTypes.string,
    bgColor: PropTypes.string,
    isHtmlLocked: PropTypes.bool,
    showPandoLogo: PropTypes.bool,
    pinTo: PropTypes.string,
    spacing: PropTypes.shape({
      left: PropTypes.number,
      right: PropTypes.number,
      top: PropTypes.number,
      bottom: PropTypes.number,
    }),
  };

  static defaultProps = {
    title: '',
    displayEnabled: true,
    displayShowHtml: true,
    displayShowPandoLogo: true,
    displayWidth: false,
    displayPinTo: false,
    displaySpacing: false,
    allowSelectUnits: false,
    enabled: false,
    height: 60,
    heightUnits: 'px',
    width: 100,
    widthUnits: 'px',
    markup: DEFAULT_FOOTER_HTML,
    bgColor: '#FFFFFF',
    isHtmlLocked: false,
    showPandoLogo: false,
    pinTo: 'top-right',
    spacing: { left: 0, right: 0, top: 0, bottom: 0 },
  };

  constructor(props) {
    super(props);
    const { enabled, height, heightUnits, width, widthUnits, markup, bgColor, isHtmlLocked, showPandoLogo, pinTo, spacing } = props;
    this.state = {
      enabled,
      editorState: EditorState.createEmpty(),
      height,
      heightUnits,
      width,
      widthUnits,
      showHtml: isHtmlLocked,
      markup,
      bgColor,
      isHtmlLocked,
      showPandoLogo,
      pinTo,
      spacing,
    };
  }

  componentDidMount() {
    const { markup, isHtmlLocked } = this.props;
    if (markup.length > 0 && !isHtmlLocked) {
      const contentBlock = htmlToDraft(markup);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      this.setState({ editorState });
    }
  }

  getData = () => {
    const { enabled, height, heightUnits, width, widthUnits, bgColor, editorState, showHtml, markup, isHtmlLocked, showPandoLogo, pinTo, spacing } = this.state;
    let html = markup;
    if (!showHtml) {
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      html = draftToHtml(rawContentState, {
        trigger: '#',
        separator: ' ',
      });
    }
    return {
      enabled,
      height,
      heightUnits,
      width,
      widthUnits,
      bgColor,
      html,
      isHtmlLocked: showHtml ? true : isHtmlLocked,
      showPandoLogo,
      pinTo,
      spacing,
    };
  };

  _onEditorStateChange = (editorState) => {
    this.setState({ editorState });
  };

  _onToggleEnabled = () => {
    const { enabled } = this.state;
    this.setState({ enabled: !enabled });
  };

  _onToggleShowHtml = () => {
    const { editorState, showHtml, markup } = this.state;

    if (showHtml) {
      const contentBlock = htmlToDraft(markup);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const updatedEditorState = EditorState.createWithContent(contentState);

      const rawContentState = convertToRaw(updatedEditorState.getCurrentContent());
      let html = draftToHtml(rawContentState, {
        trigger: '#',
        separator: ' ',
      });

      if (markup.trim().toLowerCase() === html.trim().toLowerCase()) {
        this.setState({ editorState: updatedEditorState, showHtml: !showHtml });
      } else {
        this.setState({ isHtmlLocked: true });
      }
    } else {
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      let html = draftToHtml(rawContentState, {
        trigger: '#',
        separator: ' ',
      });

      html = html.replace(/(<p[^>]+?>|<p>|<\/p>)/, '');
      html = html.substring(0, html.length - 5);
      this.setState({ showHtml: !showHtml, markup: html });
    }
  };

  _onToggleShowPandoLogo = () => {
    const { showPandoLogo } = this.state;
    this.setState({ showPandoLogo: !showPandoLogo });
  };

  _onChangeHeight = (height) => {
    this.setState({ height });
  };

  _onChangeHeightUnits = (name, selected) => {
    this.setState({ [name]: selected.value });
  };

  _onChangeWidth = (width) => {
    this.setState({ width });
  };

  _onChangeWidthUnits = (name, selected) => {
    this.setState({ [name]: selected.value });
  };

  _onChangeBgColor = (hexValue) => {
    this.setState({ bgColor: hexValue });
  };

  _onMarkupChange = (evt) => {
    this.setState({ markup: evt.target.value });
  };

  _onChangePinTo = (name, selected) => {
    const { spacing } = this.state;
    const spacingOptions = selected.value.split('-');
    const newSpacing = { left: 0, right: 0, top: 0, bottom: 0 };
    newSpacing[spacingOptions[0]] = spacing[spacingOptions[0]];
    newSpacing[spacingOptions[1]] = spacing[spacingOptions[1]];
    this.setState({ [name]: selected.value, spacing: newSpacing });
  };

  _onChangeSpacing = (type, value) => {
    const { spacing } = this.state;
    const newSpacing = { ...spacing };
    newSpacing[type] = value;
    this.setState({ spacing: newSpacing });
  };

  render() {
    const { title, displayEnabled, displayShowHtml, displayShowPandoLogo, displayWidth, displayPinTo, displaySpacing, allowSelectUnits } = this.props;
    const { enabled, height, heightUnits, width, widthUnits, showHtml, markup, isHtmlLocked, bgColor, editorState, showPandoLogo, pinTo, spacing } = this.state;
    const switchWidth = 34;
    const switchHeight = 16;

    const spacingOptions = pinTo.split('-');

    return (
      <section className='form-section'>
        <h4 style={{ color: '#ADADAD', height: 5, vAlign: 'center' }}>{title}</h4>
        {displayEnabled && (
          <div className='form-group'>
            <div className='form-label'>Enabled</div>
            <div className='form-control' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <Switch
                label=''
                onChange={this._onToggleEnabled}
                checked={enabled}
                height={switchHeight}
                width={switchWidth}
                containerStyle={{ vAlign: 'center', marginBottom: 0 }}
              />
            </div>
          </div>
        )}
        {enabled && (
          <div>
            {displayShowHtml && (
              <div className='form-group'>
                <div className='form-label'>Show HTML</div>
                <div className='form-control' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Switch
                    label=''
                    disabled={isHtmlLocked}
                    onChange={this._onToggleShowHtml}
                    checked={showHtml}
                    height={switchHeight}
                    width={switchWidth}
                    containerStyle={{ vAlign: 'center', marginBottom: 0 }}
                  />
                </div>
              </div>
            )}
            {displayShowPandoLogo && (
              <div className='form-group'>
                <div className='form-label'>Show Pando Logo</div>
                <div className='form-control' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Switch
                    label=''
                    onChange={this._onToggleShowPandoLogo}
                    checked={showPandoLogo}
                    height={switchHeight}
                    width={switchWidth}
                    containerStyle={{ vAlign: 'center', marginBottom: 0 }}
                  />
                </div>
              </div>
            )}
            <div className='form-group' style={{ margin: 0 }}>
              <div className='form-label'>Background Color</div>
              <div className='form-control' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <ColorPickerHex onChange={this._onChangeBgColor} hexValue={bgColor} />
              </div>
            </div>
            <div className='form-group' style={{ margin: 0, padding: 0 }}>
              <div className='form-label'>Height</div>
              <div className='form-control' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                <Input
                  name='footerHeight'
                  type='number'
                  onChange={({ target }) => this._onChangeHeight(target.value)}
                  value={height}
                  containerStyle={{ marginLeft: 0, marginTop: 0, paddingTop: 0 }}
                  inputStyle={{ marginLeft: 0, textAlign: 'left', width: 30 }}
                />
                {allowSelectUnits && (
                  <DropDownFormField
                    name='heightUnits'
                    label='Height Units'
                    list={[
                      { value: 'px', label: 'px' },
                      { value: '%', label: '%' },
                    ]}
                    value={heightUnits}
                    onChange={this._onChangeHeightUnits}
                    containerStyle={{ width: 70, marginBottom: 16 }}
                  />
                )}
                {!allowSelectUnits && <label style={{ color: '#777', marginLeft: 8 }}>px</label>}
              </div>
            </div>
            {displayWidth && (
              <div className='form-group' style={{ margin: 0, padding: 0 }}>
                <div className='form-label'>Width</div>
                <div className='form-control' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <Input
                    name='width'
                    type='number'
                    onChange={({ target }) => this._onChangeWidth(target.value)}
                    value={width}
                    containerStyle={{ marginLeft: 0, marginTop: 0, paddingTop: 0 }}
                    inputStyle={{ marginLeft: 0, textAlign: 'left', width: 30 }}
                  />
                  <DropDownFormField
                    name='widthUnits'
                    label='Width Units'
                    list={[
                      { value: 'px', label: 'px' },
                      { value: '%', label: '%' },
                    ]}
                    value={widthUnits}
                    onChange={this._onChangeWidthUnits}
                    containerStyle={{ width: 70, marginBottom: 16 }}
                  />
                </div>
              </div>
            )}
            {displayPinTo && (
              <div className='form-group' style={{ minWidth: 400, marginTop: 0 }}>
                <div className='form-label'>Pin To</div>
                <div className='form-control' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <DropDownFormField
                    name='pinTo'
                    label='Pin To'
                    list={[
                      { value: 'top-left', label: 'top-left' },
                      { value: 'top-right', label: 'top-right' },
                      { value: 'bottom-left', label: 'bottom-left' },
                      { value: 'bottom-right', label: 'bottom-right' },
                    ]}
                    value={pinTo}
                    onChange={this._onChangePinTo}
                  />
                </div>
              </div>
            )}
            {displaySpacing && (
              <ul className='list' style={{ listStyle: 'none', paddingLeft: 0 }}>
                {spacingOptions.map((s, index) => (
                  <li key={index}>
                    <div className='form-group' style={{ minWidth: 400, marginTop: 0 }}>
                      <div className='form-label'>
                        {`${s.charAt(0).toUpperCase() + s.slice(1)} `}
                        Spacing
                      </div>
                      <div className='form-control' style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                        <Input
                          name={s}
                          type='number'
                          onChange={({ target }) => this._onChangeSpacing(s, target.value)}
                          value={spacing[s]}
                          containerStyle={{ marginLeft: 0, marginTop: 0, paddingTop: 0 }}
                          inputStyle={{ marginLeft: 0, textAlign: 'left', width: 30 }}
                        />
                        <label style={{ color: '#777', marginLeft: 8 }}>px</label>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
            {showHtml ? (
              <textarea style={{ width: 700, height: 200 }} value={markup} autoComplete='off' rows={4} onChange={this._onMarkupChange} />
            ) : (
              <div className='form-group' style={{ marginTop: 0 }}>
                <Editor
                  editorState={editorState}
                  wrapperClassName='wysiwyg-wrapper'
                  editorClassName='editor-class'
                  toolbarClassName='toolbar-class'
                  toolbarStyle={{ width: 700 }}
                  editorStyle={{ width: 712, fontFamily: 'Arial', color: 'white', fontSize: 13, backgroundColor: bgColor, height }}
                  onEditorStateChange={this._onEditorStateChange}
                />
              </div>
            )}
          </div>
        )}
      </section>
    );
  }
}

export default Wysiwyg;
