import React, { useState } from 'react';
import { connect } from 'react-redux';

import deleteIcon from '../../assets/icons/delete.svg';
import editIcon from '../../assets/icons/edit.svg';

const InstancesListItem = ({ instanceData, onEdit, onDelete }) => {
  let options = null;
  const [showOptions, setShowOptions] = useState(false);

  const _onMouseOver = () => {
    setShowOptions(true);
  };

  const _onMouseOut = () => {
    setShowOptions(false);
  };

  const _onEdit = (event) => {
    event.stopPropagation();
    onEdit && onEdit(instanceData._id);
  };

  const _onDelete = (event) => {
    event.stopPropagation();
    onDelete && onDelete(instanceData._id);
  };

  if (showOptions) {
    options = (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className='option' onClick={_onEdit} role='button' tabIndex='-1' onKeyUp={() => {}}>
          <img src={editIcon} alt='edit' />
        </div>
        <div className='option' onClick={_onDelete} role='button' tabIndex='-1' onKeyUp={() => {}}>
          <img src={deleteIcon} alt='delete' />
        </div>
      </div>
    );
  }

  return (
    <div className='instances-list-item' onMouseEnter={_onMouseOver} onMouseLeave={_onMouseOut}>
      <span>{instanceData && instanceData.name}</span>
      {options}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  let instanceData = ownProps.item ? state.goLive.instances[ownProps.item] : {};
  return { instanceData };
};

export default connect(mapStateToProps, null)(InstancesListItem);
