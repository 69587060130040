import React, { Component } from 'react';
import swal from 'sweetalert';
import { connect } from 'react-redux';
import { updateLayoutRequest, getLayoutById } from '../../../redux/layoutsRedux';
import participantIcon from '../../../assets/icons/participant.svg';
import cameraIcon from '../../../assets/icons/camera_alt.svg';
import Draggable from '../../../common/Draggable';
import LayoutGridForm from './LayoutGridForm';
import ParticipantList from '../../../common/ParticipantList/ParticipantList';
import StringUtils from '../../../utils/string-utils';
import AssetList from '../AssetList';

class LayoutConfiguration extends Component {
  _updateConfiguration = async () => {
    const { updateLayout, current, grid, areas, backgroundVideoUrl, backgroundVideoMetadata, backgroundImageUrl, activeStudio } = this.props;
    const { rows, columns } = activeStudio;
    const maxRows = rows * 2;
    const maxCols = columns * 6;

    let areasOutsideLayout = false;
    let extremelyLargeArea = false;
    areas.forEach((area) => {
      const { gridRowStart, gridRowEnd, gridColumnStart, gridColumnEnd, width, height } = area;
      if (gridColumnStart > maxCols || gridColumnEnd > maxCols + 1 || gridRowStart > maxRows || gridRowEnd > maxRows + 1) {
        areasOutsideLayout = true;
      } else if (width > 3 || height > 3) {
        extremelyLargeArea = true;
      }
    });
    if (areasOutsideLayout) {
      swal({
        title: 'Error',
        text: 'Found areas outside layout bounds.',
      });
      return;
    } else if (extremelyLargeArea) {
      swal({
        title: 'Error',
        text: 'Found unsupported large area.',
      });
      return;
    }

    const videoAssets = areas.filter((a) => a.sourceType === 'Asset' && a.data && a.data.type === 'video');
    if (videoAssets.length > 0 && backgroundVideoUrl) {
      swal({
        title: 'Error',
        text: 'Layouts with a video background cannot have video assets.',
      });
    } else if (videoAssets.length > 3) {
      swal({
        title: 'Error',
        text: 'Layouts cannot have more than 3 video assets.',
      });
    } else {
      const hashCode = StringUtils.hash64(JSON.stringify({ areas, backgroundVideoUrl, backgroundVideoMetadata, backgroundImageUrl }));
      const payload = {
        grid,
        backgroundVideoUrl,
        backgroundVideoMetadata,
        backgroundImageUrl,
        areas: areas.map((area) => {
          if ((area.sourceType === 'Asset' && area.data && area.data.type === 'image') || (area.sourceType === 'Participant' && area.data)) {
            const { data, ...areaProps } = area;
            const { base64Img, ...dataProps } = data;
            return { ...areaProps, data: { ...dataProps } };
          } else {
            return area;
          }
        }),
        hashCode,
        version: 2,
      };
      updateLayout(current, payload);
    }
  };

  render() {
    const { current, activeStudio, activeEvent, areas, enableSave, currentLayoutData } = this.props;

    let assignedParticipantsNums = [];
    if (areas.length) {
      assignedParticipantsNums = areas.filter((area) => area.sourceType === 'Participant' && area.data).map((area) => area.data.wallDisplayNum);
    }

    let content = null;
    if (current) {
      content = (
        <div style={{ display: 'flex', height: '100%', minHeight: 300 }}>
          {activeStudio.liveEvent === activeEvent._id && !currentLayoutData.studioWall && (
            <ParticipantList allowedStatus={['IN_STUDIO_QUEUE', 'ON_WALL', 'ON_AIR']} assignedParticipants={assignedParticipantsNums} />
          )}
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <LayoutGridForm onSave={this._updateConfiguration} enableSave={enableSave} />
            <div className='scrollbar' style={{ display: 'flex', flexWrap: 'wrap', overflowY: 'auto' }}>
              <Draggable dataTransfer={{ sourceType: 'WallParticipant', data: null }}>
                <div className='block' style={{ width: 160, height: 90, margin: '0 10px 20px' }}>
                  <img src={participantIcon} alt='participant' />
                  <span>Participant</span>
                </div>
              </Draggable>
              <Draggable dataTransfer={{ sourceType: 'StudioFeed', data: null }}>
                <div className='block' style={{ width: 160, height: 90, margin: '0 10px 20px' }}>
                  <img src={cameraIcon} alt='studio feed icon' />
                  <span>Studio Feed</span>
                </div>
              </Draggable>
              {!currentLayoutData.studioWall && <AssetList />}
            </div>
          </div>
        </div>
      );
    }
    return content;
  }
}

const mapStateToProps = (state) => ({
  current: state.layouts.current,
  grid: state.layouts.grid,
  areas: state.layouts.areas,
  activeStudio: state.studio.active,
  activeEvent: state.events.active,
  backgroundVideoUrl: state.layouts.backgroundVideoUrl,
  backgroundVideoMetadata: state.layouts.backgroundVideoMetadata,
  backgroundImageUrl: state.layouts.backgroundImageUrl,
  currentLayoutData: getLayoutById(state, state.layouts.current),
});

const mapDispatchToProps = (dispatch) => ({
  updateLayout: (id, data) => dispatch(updateLayoutRequest(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutConfiguration);
