import React, { useState, useEffect } from 'react';
import { get } from '../../services/api';
import StringUtil from '../../utils/string-utils';
import serverIcon from '../../assets/icons/server-one.png';
import Counter from './Counter';

export default function ClusterHealth() {
  const [clusterStatusData, setClusterStatusData] = useState([]);

  useEffect(() => {
    const fetchClusterHealth = async () => {
      const { data } = await get('/clusterStatus');
      const { mongoCluster, redisCluster, awsInstances } = data;
      const _clusterStatusData = mongoCluster.map((c, i) => {
        const privateIp = c.name.split(':')[0];
        const awsInstance = awsInstances.find((i) => i.privateIp === privateIp);
        return {
          ...c,
          awsInstance,
          redis: redisCluster[i],
        };
      });
      setClusterStatusData(_clusterStatusData);
    };
    fetchClusterHealth();

    const timerId = setInterval(fetchClusterHealth, 3000);
    return () => clearInterval(timerId);
  }, []);

  return (
    <div className='cluster'>
      <div className='content' style={{ color: '#fff' }}>
        <div className='instances'>
          {clusterStatusData.map((server) => {
            const statusColor = server.awsInstance.status === 'running' ? '#244B42' : '#461C2B';
            const statusIndicatorColor = server.awsInstance.status === 'running' ? '#36AF6D' : '#FF062B';

            return (
              <div className='instance-container' key={`s-${server._id}`} style={{ minHeight: 462 }}>
                <div className='header'>
                  <div
                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginBottom: 10 }}
                  >
                    <div className='name'>{server.awsInstance.privateIp}</div>
                    <div className='status' style={{ backgroundColor: statusColor }}>
                      <span className='indicator' style={{ backgroundColor: statusIndicatorColor }} />
                      <span>{server.awsInstance.status}</span>
                    </div>
                  </div>
                  <div style={{ color: '#43586f', fontFamily: 'Open Sans', fontSize: 14, marginRight: 14 }}>
                    {server.uptime ? StringUtil.formatSeconds(server.uptime) : '\u00A0'}
                  </div>
                </div>

                <div className='server-type' style={{ marginTop: 10 }}>
                  <img src={serverIcon} alt='server' />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', borderBottom: '1px solid #182026' }}>
                  <div className='ip-address'>{server.awsInstance.type}</div>
                  <div className='ip-address'>{server.awsInstance.availabilityZone}</div>
                  <div className='ip-address' style={{ marginBottom: 15 }}>
                    {server.awsInstance.instanceId}
                  </div>
                </div>

                <div className='widget'>
                  <div className='title'>Mongo</div>
                  <Counter label='State' value={server.stateStr} />
                  <Counter label='Health' value={server.health === 1 ? 'Healthy' : 'Unhealthy'} style={{ color: server.health !== 1 ? '#FF062B' : '#fff' }} />
                  <Counter label='Priority' value={server.priority} />
                </div>

                <div className='widget'>
                  <div className='title'>Redis</div>
                  <Counter
                    label='Health'
                    value={server.redis.connected ? 'Healthy' : 'Unhealthy'}
                    style={{ color: !server.redis.connected ? '#FF062B' : '#fff' }}
                  />
                  <Counter label='Role' value={server.redis.role} />
                  {server.redis.role === 'master' && <Counter label='Slaves' value={server.redis.connected_slaves} />}
                  {server.redis.role === 'slave' && <Counter label='Master Link' value={server.redis.master_link_status} />}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
