import { createActions, handleActions, combineActions } from 'redux-actions';

const initialState = {
  fetching: false,
  error: undefined,
  config: undefined,
  participants: {},
  rooms: {},
  roomList: [],
  startTime: undefined,
  numberOfRooms: undefined,
  numberOfParticipantsPerRoom: undefined,
  eventParticipants: {},
  started: undefined,
};

export const actions = {
  ADD_ROOMS_REQUEST: 'ADD_ROOMS_REQUEST',
  ADD_ROOMS_SUCCESS: 'ADD_ROOMS_SUCCESS',
  ADD_ROOMS_FAILURE: 'ADD_ROOMS_FAILURE',
  START_REQUEST: 'START_REQUEST',
  START_SUCCESS: 'START_SUCCESS',
  START_FAILURE: 'START_FAILURE',
  STOP_REQUEST: 'STOP_REQUEST',
  STOP_SUCCESS: 'STOP_SUCCESS',
  STOP_FAILURE: 'STOP_FAILURE',
  AUTO_ASSIGN_REQUEST: 'AUTO_ASSIGN_REQUEST',
  AUTO_ASSIGN_SUCCESS: 'AUTO_ASSIGN_SUCCESS',
  AUTO_ASSIGN_FAILURE: 'AUTO_ASSIGN_FAILURE',
  SET_EVENT_PARTICIPANTS: 'SET_EVENT_PARTICIPANTS',
  SET_HOST_REQUEST: 'SET_HOST_REQUEST',
  SET_HOST_SUCCESS: 'SET_HOST_SUCCESS',
  SET_HOST_FAILURE: 'SET_HOST_FAILURE',
  ADD_PARTICIPANT_REQUEST: 'ADD_PARTICIPANT_REQUEST',
  ADD_PARTICIPANT_SUCCESS: 'ADD_PARTICIPANT_SUCCESS',
  ADD_PARTICIPANT_FAILURE: 'ADD_PARTICIPANT_FAILURE',
  REMOVE_PARTICIPANT_REQUEST: 'REMOVE_PARTICIPANT_REQUEST',
  REMOVE_PARTICIPANT_SUCCESS: 'REMOVE_PARTICIPANT_SUCCESS',
  REMOVE_PARTICIPANT_FAILURE: 'REMOVE_PARTICIPANT_FAILURE',
  MOVE_PARTICIPANT_REQUEST: 'MOVE_PARTICIPANT_REQUEST',
  MOVE_PARTICIPANT_SUCCESS: 'MOVE_PARTICIPANT_SUCCESS',
  MOVE_PARTICIPANT_FAILURE: 'MOVE_PARTICIPANT_FAILURE',
  GET_BREAKOUT_ROOM_CONFIG_REQUEST: 'GET_BREAKOUT_ROOM_CONFIG_REQUEST',
  GET_BREAKOUT_ROOM_CONFIG_SUCCESS: 'GET_BREAKOUT_ROOM_CONFIG_SUCCESS',
  GET_BREAKOUT_ROOM_CONFIG_FAILURE: 'GET_BREAKOUT_ROOM_CONFIG_FAILURE',
  UPDATE_BREAKOUT_ROOM_CONFIG_REQUEST: 'UPDATE_BREAKOUT_ROOM_CONFIG_REQUEST',
  UPDATE_BREAKOUT_ROOM_CONFIG_SUCCESS: 'UPDATE_BREAKOUT_ROOM_CONFIG_SUCCESS',
  UPDATE_BREAKOUT_ROOM_CONFIG_FAILURE: 'UPDATE_BREAKOUT_ROOM_CONFIG_FAILURE',
};

export const {
  addRoomsRequest,
  addRoomsSuccess,
  addRoomsFailure,
  startRequest,
  startSuccess,
  startFailure,
  stopRequest,
  stopSuccess,
  stopFailure,
  autoAssignRequest,
  autoAssignSuccess,
  autoAssignFailure,
  setEventParticipants,
  setHostRequest,
  setHostSuccess,
  setHostFailure,
  addParticipantRequest,
  addParticipantSuccess,
  addParticipantFailure,
  removeParticipantRequest,
  removeParticipantSuccess,
  removeParticipantFailure,
  moveParticipantRequest,
  moveParticipantSuccess,
  moveParticipantFailure,
  getBreakoutRoomConfigRequest,
  getBreakoutRoomConfigSuccess,
  getBreakoutRoomConfigFailure,
  updateBreakoutRoomConfigRequest,
  updateBreakoutRoomConfigSuccess,
  updateBreakoutRoomConfigFailure,
} = createActions({
  [actions.ADD_ROOMS_REQUEST]: (configId, numRooms, maxRooms, numParticipants) => ({ configId, numRooms, maxRooms, numParticipants }),
  [actions.ADD_ROOMS_SUCCESS]: (data) => ({ data }),
  [actions.ADD_ROOMS_FAILURE]: (error) => ({ error }),
  [actions.START_REQUEST]: (config) => ({ config }),
  [actions.START_SUCCESS]: (data) => ({ data }),
  [actions.START_FAILURE]: (error) => ({ error }),
  [actions.STOP_REQUEST]: (config) => ({ config }),
  [actions.STOP_SUCCESS]: (data) => ({ data }),
  [actions.STOP_FAILURE]: (error) => ({ error }),
  [actions.AUTO_ASSIGN_REQUEST]: (config, rooms) => ({ config, rooms }),
  [actions.AUTO_ASSIGN_SUCCESS]: (data) => ({ data }),
  [actions.AUTO_ASSIGN_FAILURE]: (error) => ({ error }),
  [actions.SET_EVENT_PARTICIPANTS]: (data) => ({ data }),
  [actions.SET_HOST_REQUEST]: (participant, room) => ({ participant, room }),
  [actions.SET_HOST_SUCCESS]: (data) => ({ data }),
  [actions.SET_HOST_FAILURE]: (error) => ({ error }),
  [actions.ADD_PARTICIPANT_REQUEST]: (roomId, participantId, configId, oldRoomId, removeSubStatus) => ({
    roomId,
    participantId,
    configId,
    oldRoomId,
    removeSubStatus,
  }),
  [actions.ADD_PARTICIPANT_SUCCESS]: (data) => ({ data }),
  [actions.ADD_PARTICIPANT_FAILURE]: (error) => ({ error }),
  [actions.REMOVE_PARTICIPANT_REQUEST]: (roomId, participantId, configId) => ({
    roomId,
    participantId,
    configId,
  }),
  [actions.REMOVE_PARTICIPANT_SUCCESS]: (data) => ({ data }),
  [actions.REMOVE_PARTICIPANT_FAILURE]: (error) => ({ error }),
  [actions.MOVE_PARTICIPANT_REQUEST]: (from, to, participantId, configId, removeSubStatus, eventId) => ({
    from,
    to,
    participantId,
    configId,
    removeSubStatus,
    eventId,
  }),
  [actions.MOVE_PARTICIPANT_SUCCESS]: (data) => ({ data }),
  [actions.MOVE_PARTICIPANT_FAILURE]: (error) => ({ error }),
  [actions.GET_BREAKOUT_ROOM_CONFIG_REQUEST]: (event) => ({ event }),
  [actions.GET_BREAKOUT_ROOM_CONFIG_SUCCESS]: (data) => ({ data }),
  [actions.GET_BREAKOUT_ROOM_CONFIG_FAILURE]: (error) => ({ error }),
  [actions.UPDATE_BREAKOUT_ROOM_CONFIG_REQUEST]: (event, config) => ({ event, config }),
  [actions.UPDATE_BREAKOUT_ROOM_CONFIG_SUCCESS]: (data) => ({ data }),
  [actions.UPDATE_BREAKOUT_ROOM_CONFIG_FAILURE]: (error) => ({ error }),
});

const reducer = handleActions(
  {
    [actions.SET_EVENT_PARTICIPANTS]: (state, action) => {
      const eventParticipants = {};
      action.payload.data.forEach((p) => {
        if (p.participant) {
          eventParticipants[p.participant._id] = p.participant;
          eventParticipants[p.participant._id].status = p.status;
        }
      });
      return {
        ...state,
        eventParticipants,
      };
    },
    [actions.START_SUCCESS]: (state, action) => {
      const { startTime } = action.payload.data;
      return {
        ...state,
        started: true,
        startTime,
      };
    },
    [actions.STOP_SUCCESS]: (state) => {
      const participants = {};
      for (const id in state.participants) {
        const a = state.participants[id];
        a.room = undefined;
        a.roomId = undefined;
        participants[id] = { ...a };
      }
      return {
        ...state,
        startTime: undefined,
        roomList: [],
        rooms: {},
        numberOfRooms: undefined,
        numberOfParticipantsPerRoom: undefined,
        started: false,
        participants,
      };
    },
    [actions.AUTO_ASSIGN_SUCCESS]: (state, action) => {
      const { rooms, participants, eventParticipants } = state;
      const { data } = action.payload;
      for (const roomId in data.rooms) {
        const room = rooms[roomId];
        data.rooms[roomId].forEach((p) => {
          participants[p] = { ...eventParticipants[p] };
          participants[p].room = room.index;
          participants[p].roomId = room._id;
        });
        room.participants.concat(data.rooms[roomId]);
      }
      return {
        ...state,
        rooms,
        participants,
      };
    },
    [actions.SET_HOST_SUCCESS]: (state, action) => {
      const { rooms } = state;
      const { participant, room } = action.payload.data;
      if (rooms[room]) {
        rooms[room].host = participant;
      }
      return {
        ...state,
        rooms: { ...rooms },
      };
    },
    [actions.GET_BREAKOUT_ROOM_CONFIG_REQUEST]: (state) => ({
      ...state,
      fetching: true,
      error: null,
    }),
    [combineActions(actions.GET_BREAKOUT_ROOM_CONFIG_SUCCESS, actions.UPDATE_BREAKOUT_ROOM_CONFIG_SUCCESS, actions.ADD_ROOMS_SUCCESS)]: (state, action) => {
      const { data } = action.payload;
      const { numberOfRooms, numberOfParticipantsPerRoom } = data;
      const config = { ...data };
      const rooms = {};
      const roomList = [];
      const participants = {};
      const { startTime } = data;
      const started = !!data.startTime;
      data.rooms.forEach((room) => {
        room.participants.forEach((participant) => {
          let oldRoom = null;
          if (data.techSupportParticipants.length > 0) {
            const tsParticipant = data.techSupportParticipants.find((p) => p.participant === participant._id);
            if (tsParticipant) {
              oldRoom = tsParticipant.oldRoom;
            }
          }
          participants[participant._id] = participant;
          participants[participant._id].room = room.index;
          participants[participant._id].roomId = room._id;
          participants[participant._id].oldRoom = oldRoom;
        });
        room.participants = room.participants.map((p) => p._id);
        rooms[room._id] = room;
        roomList.push(room._id);
      });
      return { ...state, fetching: false, error: null, config, roomList, rooms, participants, numberOfRooms, startTime, started, numberOfParticipantsPerRoom };
    },
    [actions.ADD_PARTICIPANT_SUCCESS]: (state, action) => {
      const { roomId, participantId } = action.payload.data;
      const { rooms, eventParticipants, participants } = state;
      rooms[roomId].participants.push(participantId);

      if (participants[participantId] && participants[participantId].roomId) {
        const { roomId } = participants[participantId];
        const index = rooms[roomId].participants.indexOf(participantId);
        if (index !== -1) {
          rooms[roomId].participants.splice(index, 1);
        }
      }

      participants[participantId] = { ...eventParticipants[participantId] };
      participants[participantId].room = rooms[roomId].index;
      participants[participantId].roomId = roomId;
      return { ...state, fetching: false, error: undefined, rooms, participants };
    },
    [actions.REMOVE_PARTICIPANT_SUCCESS]: (state, action) => {
      const { roomId, participantId } = action.payload.data;
      const { rooms, participants } = state;
      const participantIndex = rooms[roomId].participants.indexOf(participantId);
      rooms[roomId].participants.splice(participantIndex, 1);
      if (rooms[roomId].host === participantId) {
        rooms[roomId].host = undefined;
      }
      delete participants[participantId];
      return { ...state, fetching: false, error: undefined, rooms, participants };
    },
    [actions.MOVE_PARTICIPANT_SUCCESS]: (state, action) => {
      const { from, to, participantId } = action.payload.data;
      const { rooms, participants } = state;
      const participantIndex = rooms[from].participants.indexOf(participantId);
      rooms[from].participants.splice(participantIndex, 1);
      if (rooms[from].host === participantId) {
        rooms[from].host = undefined;
      }
      rooms[to].participants.push(participantId);
      participants[participantId].room = rooms[to].index;
      participants[participantId].roomId = to;
      return { ...state, fetching: false, error: undefined, rooms, participants };
    },
  },
  initialState,
);

export default reducer;
