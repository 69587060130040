import React, { PureComponent } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import SearchBox from '../../common/SearchBox';
import { get } from '../../services/api';
import SocketClient from '../../utils/socket-client';

import PaginatedItems from './PaginatedItems';

class RoleChanges extends PureComponent {
  state = {
    roleChanges: [],
    search: '',
  };

  async componentDidMount() {
    const { activeEvent, activeStudio } = this.props;

    if (activeEvent && activeStudio) {
      const response = await get(`/event/${activeEvent._id}/rolechanges`);
      this.setState({ roleChanges: response.data });

      SocketClient.joinRoom(`${activeEvent._id}:role-changes`);
      SocketClient.on('role-change-update', (data) => {
        const { roleChanges } = this.state;
        const newRoleChanges = [...roleChanges];
        const index = newRoleChanges.findIndex((rc) => rc._id === data._id);
        if (index !== -1) {
          newRoleChanges[index] = data;
        } else {
          newRoleChanges.push(data);
        }
        newRoleChanges.sort(function (a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        this.setState({ roleChanges: newRoleChanges });
      });
    }
  }

  componentWillUnmount() {
    SocketClient.leaveRoom('role-changes');
    SocketClient.off('role-change-update');
  }

  render() {
    const { roleChanges, search } = this.state;
    let displayData = roleChanges.map(({ _id, createdAt, participant, fromRole, toRole, adminAppUser, status }) => {
      return {
        _id,
        timestamp: moment(createdAt).format('MM/DD/YYYY hh:mm:ss A'),
        participant: `${participant.firstName} ${participant.lastName}`,
        fromRole,
        toRole,
        adminAppUser: adminAppUser ? adminAppUser.username : '',
        status: status === 1 ? 'Accepted' : status === 0 ? 'Declined' : '',
      };
    });
    if (search.length > 0) {
      displayData = displayData.filter(({ participant }) => participant.toLowerCase().includes(search.toLowerCase()));
    }

    return (
      <div style={{ padding: 25, height: '90%', overflowY: 'auto' }}>
        <div style={{ display: 'flex', flexDirection: 'row', margin: '6px 8px 7px', alignItems: 'center' }}>
          <SearchBox
            placeholder='Search by participant name'
            value={search}
            onChange={({ target }) => this.setState({ search: target.value })}
            onClear={() => this.setState({ search: '' })}
            showIcon={true}
            containerStyle={{ margin: '0 15px 0 0', width: 320 }}
            inputStyle={{ marginLeft: 0 }}
          />
        </div>
        <table style={{ padding: 25, margin: '0 auto' }}>
          <thead style={{ backgroundColor: 'rgb(45, 64, 81)', color: 'rgb(169, 170, 171)', margin: 0 }}>
            <tr style={{ textAlign: 'left', fontSize: 13 }}>
              <th style={{ padding: 10, textAlign: 'center' }}>TIMESTAMP</th>
              <th style={{ padding: 10, textAlign: 'center' }}>PARTICIPANT</th>
              <th style={{ padding: 10, textAlign: 'center' }}>FROM ROLE</th>
              <th style={{ padding: 10, textAlign: 'center' }}>TO ROLE</th>
              <th style={{ padding: 10, textAlign: 'center' }}>ADMIN USER</th>
              <th style={{ padding: 10, textAlign: 'center' }}>STATUS</th>
            </tr>
          </thead>
          <tbody style={{ margin: 0 }}>{displayData.length > 0 && <PaginatedItems itemsPerPage={15} items={displayData} />}</tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeStudio: state.studio.active,
    activeEvent: state.events.active,
  };
};

export default connect(mapStateToProps)(RoleChanges);
