import { all, takeLatest, call, put, take } from 'redux-saga/effects';

import {
  actions,
  startSuccess,
  startFailure,
  stopSuccess,
  stopFailure,
  addParticipantSuccess,
  addParticipantFailure,
  removeParticipantSuccess,
  removeParticipantFailure,
  moveParticipantFailure,
  moveParticipantSuccess,
  updateBreakoutRoomConfigFailure,
  updateBreakoutRoomConfigSuccess,
  getBreakoutRoomConfigFailure,
  getBreakoutRoomConfigSuccess,
  addRoomsFailure,
  addRoomsSuccess,
  setHostFailure,
  setHostSuccess,
  autoAssignFailure,
  autoAssignSuccess,
} from '../redux/breakoutRoomsRedux';
import { get, post, put as update } from '../services/api';

export function* startRequest(action) {
  try {
    const { config } = action.payload;
    const startTime = new Date();
    const response = yield call(post, '/breakoutRoom/start', {
      config,
      startTime,
    });
    if (response.status === 200) {
      yield put(startSuccess({ startTime }));
    } else {
      yield put(
        startFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(startFailure(error));
  }
}

export function* clearBreakoutRooms(action) {
  try {
    const { config } = action.payload;
    const response = yield call(post, '/breakoutRoom/stop', { config });
    if (response.status === 200) {
      yield put(stopSuccess(response.data));
    } else {
      yield put(
        stopFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(stopFailure(error));
  }
}

export function* addParticipant(action) {
  try {
    const { roomId, participantId, configId, oldRoomId, removeSubStatus } = action.payload;
    const response = yield call(post, `/breakoutRoom/${roomId}/addParticipant`, { uuid: participantId, configId, oldRoomId, removeSubStatus });
    if (response.status === 200) {
      yield put(addParticipantSuccess({ roomId, participantId }));
    } else {
      yield put(
        addParticipantFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(addParticipantFailure(error));
  }
}

export function* removeParticipant(action) {
  try {
    const { roomId, participantId, configId } = action.payload;
    const response = yield call(post, `/breakoutRoom/${roomId}/removeParticipant`, { uuid: participantId, configId });
    if (response.status === 200) {
      yield put(removeParticipantSuccess({ roomId, participantId }));
    } else {
      yield put(
        removeParticipantFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(removeParticipantFailure(error));
  }
}

export function* moveParticipant(action) {
  try {
    const { from, to, participantId, configId, removeSubStatus, eventId } = action.payload;
    const response = yield call(post, `/breakoutRoom/moveParticipant`, {
      from,
      to,
      uuid: participantId,
      configId,
      removeSubStatus,
    });
    if (response.status === 200) {
      yield put(moveParticipantSuccess({ from, to, participantId }));
      const configResponse = yield call(get, `/breakoutRoom/config/${eventId}`);
      if (configResponse.status === 200) {
        yield put(getBreakoutRoomConfigSuccess(configResponse.data));
      }
    } else {
      yield put(
        moveParticipantFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(moveParticipantFailure(error));
  }
}

export function* getConfigRequest(action) {
  try {
    const { event } = action.payload;
    const response = yield call(get, `/breakoutRoom/config/${event}`);
    if (response.status === 200) {
      yield put(getBreakoutRoomConfigSuccess(response.data));
    } else {
      yield put(
        getBreakoutRoomConfigFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(getBreakoutRoomConfigFailure(error));
  }
}

export function* updateConfigRequest(action) {
  try {
    const { event, config } = action.payload;
    const response = yield call(update, `/breakoutRoom/config/${event}`, { config });
    if (response.status === 200) {
      yield put(updateBreakoutRoomConfigSuccess(response.data));
    } else {
      yield put(
        updateBreakoutRoomConfigFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(updateBreakoutRoomConfigFailure(error));
  }
}

export function* addRoomsRequest(action) {
  try {
    const { configId, numRooms, maxRooms } = action.payload;
    const response = yield call(post, `/breakoutRoom/addRooms`, { configId, numRooms, maxRooms });
    if (response.status === 200) {
      yield put(addRoomsSuccess(response.data));
    } else {
      yield put(
        addRoomsFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(addRoomsFailure(error));
  }
}

export function* setHostRequest(action) {
  try {
    const { participant, room } = action.payload;
    const response = yield call(post, `/breakoutRoom/${room}/setHost`, { uuid: participant });
    if (response.status === 200) {
      yield put(setHostSuccess({ participant, room }));
    } else {
      yield put(
        setHostFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(setHostFailure(error));
  }
}

export function* autoAssignRequest(action) {
  try {
    const { config, rooms } = action.payload;
    const response = yield call(post, `/breakoutRoom/autoAssign`, { config, rooms });
    if (response.status === 200) {
      yield put(autoAssignSuccess({ rooms }));
    } else {
      yield put(
        autoAssignFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(autoAssignFailure(error));
  }
}

export default function* () {
  yield all([
    takeLatest([actions.START_REQUEST], startRequest),
    takeLatest([actions.STOP_REQUEST], clearBreakoutRooms),
    takeLatest([actions.ADD_PARTICIPANT_REQUEST], addParticipant),
    takeLatest([actions.REMOVE_PARTICIPANT_REQUEST], removeParticipant),
    takeLatest([actions.MOVE_PARTICIPANT_REQUEST], moveParticipant),
    takeLatest([actions.GET_BREAKOUT_ROOM_CONFIG_REQUEST], getConfigRequest),
    takeLatest([actions.UPDATE_BREAKOUT_ROOM_CONFIG_REQUEST], updateConfigRequest),
    takeLatest([actions.ADD_ROOMS_REQUEST], addRoomsRequest),
    takeLatest([actions.SET_HOST_REQUEST], setHostRequest),
    takeLatest([actions.AUTO_ASSIGN_REQUEST], autoAssignRequest),
  ]);
}
