import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import swal from '@sweetalert/with-react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import Button from '../../common/Button';
import Input from '../../common/Input';
import FileInput from '../../common/FileInput';
import Checkbox from '../../common/Checkbox';
import ColorPickerHex from '../../common/ColorPickerHex';
import RadioButtons from '../../common/RadioButtons';
import Select from '../../common/Select';
import noPrefixIcon from '../../assets/icons/icon-no-prefix.svg';
import lettersIcon from '../../assets/icons/icon-prefix-letters.svg';
import percentagesIcon from '../../assets/icons/icon-percentages.svg';
import numbersIcon from '../../assets/icons/icon-numbers.svg';
import noPrefixGreenIcon from '../../assets/icons/icon-no-prefix-green.svg';
import lettersGreenIcon from '../../assets/icons/icon-prefix-letters-green.svg';
import percentagesGreenIcon from '../../assets/icons/icon-percentages-green.svg';
import numbersGreenIcon from '../../assets/icons/icon-numbers-green.svg';
import bothIcon from '../../assets/icons/icon-both.svg';
import bothGreenIcon from '../../assets/icons/icon-both-green.svg';
import ButtonGroup from '../../common/ButtonGroup';
import { put, post, get } from '../../services/api';
import { uploadFilesRequest } from '../../redux/uploaderRedux';
import { setActiveEvent } from '../../redux/eventsRedux';
import SocketClient from '../../utils/socket-client';
import { PrefixAnswersWith, DisplayResultLabelsAs, PollTypes, PollInputTypes, SpreadsheetDelimiters } from '../../utils/polls';
import Image from '../../common/Image';

import PollPreview from './PollPreview';

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    fontSize: 15,
    padding: '4px 24px',
  },
  control: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginRight: 15,
  },
  label: {
    textAlign: 'left',
    color: '#a9aaab',
    marginBottom: 5,
  },
  textArea: {
    color: '#a9aaab',
    width: '97%',
    height: 130,
    background: 'transparent',
    outline: 'none',
    resize: 'none',
    padding: '5px',
  },
  backgroundImageContainer: {
    width: 160,
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#a9aaab',
    position: 'relative',
    background: '#24313F',
  },
  removeBackgroundImage: {
    background: 'transparent',
    border: 'none',
    outline: 'none',
    width: 20,
    height: 20,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const CHARACTERS_LIMIT = 300;

function PollEditor({ pollId, poll, event, liveEvent, pollingStatus, onUpdatePoll }, ref) {
  const uploadStatus = useSelector((state) => state.uploader.status);
  const uploadResponse = useSelector((state) => state.uploader.responses);
  const activeStudio = useSelector((state) => state.studio.active);
  const activeEvent = useSelector((state) => state.events.active);
  const socketServerUrl = useSelector((state) => state.studio.socketServerUrl);
  const appMode = useSelector((state) => state.ui.appMode);
  const dispatch = useDispatch();

  const _stateRef = useRef(null);

  const [name, setName] = useState('');
  const [pollType, setPollType] = useState(PollTypes.POLL);
  const [inputType, setInputType] = useState(PollInputTypes.RADIO);
  const [spreadsheetDelimiter, setSpreadsheetDelimiter] = useState(SpreadsheetDelimiters.NEWLINE);
  const [data, setData] = useState('');
  const [prefixAnswersWith, setPrefixAnswersWith] = useState(PrefixAnswersWith.NOTHING);
  const [displayResultLabelsAs, setDisplayResultLabelsAs] = useState(DisplayResultLabelsAs.PERCENTAGES);
  const [textColor, setTextColor] = useState('');
  const [bgColor, setBgColor] = useState('');
  const [backgroundImageFile, setBackgroundImageFile] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [backgroundImagePreview, setBackgroundImagePreview] = useState(null);
  const [currentPollResults, setCurrentPollResults] = useState(null);
  const [gatheringPollResponses, setGatheringPollResponses] = useState(null);
  const [characterCount, setCharacterCount] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [correctAnswerIndex, setCorrectAnswerIndex] = useState(null);
  const [isStreamLive, setIsStreamLive] = useState(null);
  const [minAnswers, setMinAnswers] = useState(null);
  const [maxAnswers, setMaxAnswers] = useState(null);
  const [exclusiveAnswers, setExclusiveAnswers] = useState([]);
  const [sortAnswersBySelectionTime, setSortAnswersBySelectionTime] = useState(false);

  useImperativeHandle(ref, () => ({
    updatePoll: _updatePoll,
  }));

  const _updatePoll = async (backgroundImageFileKey) => {
    try {
      const { pollId, name, data, prefixAnswersWith, displayResultLabelsAs, textColor, bgColor } = _stateRef.current;

      const exclusiveAnswersIndexes = exclusiveAnswers.reduce((acc, val, idx) => {
        if (val) {
          acc.push(idx);
        }
        return acc;
      }, []);

      const pollDataLines = data.split('\n');
      pollDataLines.forEach((line) => {
        if (line.length > 300) {
          throw new Error('Please make sure that each line is less than 300 characters.');
        }
      });

      if (pollType === PollTypes.QUESTION && correctAnswerIndex === null) {
        throw new Error('Please select a correct answer.');
      }

      if (inputType === PollInputTypes.CHECKBOX) {
        if (minAnswers === null || maxAnswers === null) {
          throw new Error('Please provide min and max answers.');
        }
        if (minAnswers > maxAnswers) {
          throw new Error('Min answers cannot be greater than max answers.');
        }
        if (minAnswers < 1) {
          throw new Error('Min answers must be at least 1.');
        }
        if (maxAnswers > answers.length) {
          throw new Error('Max answers cannot be greater than the number of choices.');
        }
      }

      const payload = {
        event: event._id,
        type: pollType,
        inputType,
        spreadsheetDelimiter,
        name,
        data,
        prefixAnswersWith,
        displayResultLabelsAs,
        backgroundColor: bgColor,
        correctAnswerIndex: pollType === PollTypes.QUESTION ? correctAnswerIndex : null,
        exclusiveAnswers: exclusiveAnswersIndexes || [],
        minAnswers: minAnswers || null,
        maxAnswers: maxAnswers || null,
        sortAnswersBySelectionTime,
        textColor,
      };
      if (backgroundImageFileKey) {
        payload.backgroundImage = backgroundImageFileKey;
      }
      if (_stateRef.current.removeBackgroundImage === true) {
        _stateRef.current.removeBackgroundImage = false;
        payload.backgroundImage = null;
      }
      await put(`/poll/${pollId}`, payload);
      if (onUpdatePoll) {
        onUpdatePoll(payload);
      }
    } catch (err) {
      swal({
        title: 'Error',
        text: err.message,
      });
    } finally {
      if (backgroundImageFileKey) {
        setBackgroundImagePreview(null);
        setBackgroundImageFile(null);
        setBackgroundImage(backgroundImageFileKey);
      }
    }
  };

  useEffect(() => {
    const _getStreamState = async () => {
      if (event && appMode === 'BROADCAST') {
        const { channelArn } = event.broadcastStream;
        const { data } = await post('/event/getStreamData', { channelArn });
        const streamData = data ? data.stream : null;
        const streamState = streamData && streamData.state ? streamData.state : 'OFFLINE';
        setIsStreamLive(streamState === 'LIVE');
      }
    };
    _getStreamState();
  }, []);

  useEffect(() => {
    if (poll) {
      setName(poll.name);
      setPollType(poll.type);
      setInputType(poll.inputType);
      setSpreadsheetDelimiter(poll.spreadsheetDelimiter);
      setData(poll.data || '');
      setCorrectAnswerIndex(poll.correctAnswerIndex);
      setPrefixAnswersWith(poll.prefixAnswersWith);
      setDisplayResultLabelsAs(poll.displayResultLabelsAs);
      setTextColor(poll.textColor || '');
      setBgColor(poll.backgroundColor || '');
      setBackgroundImage(poll.backgroundImage || '');
      setBackgroundImagePreview(null);
      setBackgroundImageFile(null);
      setCharacterCount(null);
      setSortAnswersBySelectionTime(poll.sortAnswersBySelectionTime || false);
      if (poll.data) {
        const answers = poll.data
          .split('\n')
          .filter((d) => !!d)
          .slice(1);

        const exclusiveAnswers = [];
        answers.forEach((_, idx) => {
          if (poll.exclusiveAnswers && poll.exclusiveAnswers.includes(idx)) {
            exclusiveAnswers[idx] = true;
          } else {
            exclusiveAnswers[idx] = null;
          }
        });
        setExclusiveAnswers(exclusiveAnswers);
      } else {
        setExclusiveAnswers([]);
      }
      setMinAnswers(poll.minAnswers || undefined);
      setMaxAnswers(poll.maxAnswers || undefined);

      _getStatus();
    }
  }, [poll]);

  useEffect(() => {
    if (uploadStatus === 'completed') {
      _updatePoll(uploadResponse[0].fileKey);
    }
  }, [uploadStatus]);

  useEffect(() => {
    _stateRef.current = { ..._stateRef.current, pollId, name, data, prefixAnswersWith, displayResultLabelsAs, textColor, bgColor };
  }, [pollId, name, data, prefixAnswersWith, displayResultLabelsAs, textColor, bgColor]);

  useEffect(() => {
    if (pollingStatus) {
      const { showingResults, gatheringResponses } = pollingStatus;
      setCurrentPollResults(showingResults);
      setGatheringPollResponses(gatheringResponses);
      if (gatheringResponses) {
        _updateActiveEvent(gatheringResponses._id);
      } else {
        _updateActiveEvent(null);
      }
    }
  }, [pollingStatus]);

  const _uploadFile = () => {
    try {
      if (backgroundImageFile) {
        dispatch(uploadFilesRequest([backgroundImageFile], null, event._id, `events/${event._id}/polls/${backgroundImageFile.name}`));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const _onFileChange = async (event) => {
    const { target } = event;
    const file = target.files[0];

    let preview = null;
    try {
      preview = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.toString() || '');
        reader.onerror = (error) => reject(error);
      });
    } catch (err) {
      console.error(err);
    }
    setBackgroundImagePreview(preview);
    setBackgroundImageFile(file);
  };

  const _updateActiveEvent = (activePollId) => {
    const _event = { ...event };
    _event.activePoll = activePollId;
    localStorage.setItem('activeEvent', JSON.stringify(_event));
    dispatch(setActiveEvent(_event));
  };

  const _showPoll = async () => {
    try {
      await post(`/poll/${poll._id}/show`, {});
      _updateActiveEvent(poll._id);
      _getStatus();
    } catch (err) {
      console.error(err);
    }
  };

  const _closePoll = async () => {
    try {
      await post(`/poll/${poll._id}/close`, {});
      _updateActiveEvent(null);
      _getStatus();
    } catch (err) {
      console.error(err);
    }
  };

  const _getStatus = async () => {
    try {
      const resp = await get(`/poll/status?event=${event._id}`);
      if (resp.data) {
        const { showingResults, gatheringResponses } = resp.data;
        setCurrentPollResults(showingResults);
        setGatheringPollResponses(gatheringResponses);
        SocketClient.emitAdminAppSync({
          type: 'polling-status',
          payload: { showingResults, gatheringResponses },
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const _emitPollResultsWindowCommand = (command) => {
    const roomName = `${activeStudio._id}:poll-results-window`;
    SocketClient.emit('poll-results-window-command', { roomName, ...command });
  };

  const _clearResults = async () => {
    const confirm = await swal({
      title: 'Are you sure?',
      text: 'This will clear all poll results. Would you like to proceed?',
      buttons: [
        {
          text: 'Cancel',
          value: false,
          visible: true,
          className: '',
          closeModal: true,
        },
        {
          text: 'Yes',
          value: true,
          visible: true,
          className: 'swal-custom-button--red',
          closeModal: true,
        },
      ],
      dangerMode: true,
    });
    if (confirm) {
      try {
        await post(`/poll/${poll._id}/clearResults`, {});
        _emitPollResultsWindowCommand({ type: 'clear-poll-results', payload: { pollId: poll._id } });
      } catch (err) {
        console.error(err);
      }
    }
  };

  const _displayResults = async () => {
    await post(`/poll/${poll._id}/displayResults`, {});
    _getStatus();
    _emitPollResultsWindowCommand({ type: 'display-poll-results', payload: { pollId: poll._id } });
  };

  const _openResultsWindow = () => {
    window.open(
      `/pollResults?studioId=${activeStudio._id}&socketServerUrl=${socketServerUrl}&eventId=${activeEvent._id}`,
      'Poll Results',
      'width=1280,height=720,toolbar=0,menubar=0,location=0',
    );
  };

  const _removeBackgroundImage = async () => {
    try {
      _stateRef.current.removeBackgroundImage = true;
      setBackgroundImage(null);
      setBackgroundImagePreview(null);
      setBackgroundImageFile(null);
    } catch (err) {
      console.error(err);
    }
  };

  const _save = async () => {
    if (backgroundImageFile) {
      _uploadFile();
    } else {
      _updatePoll();
    }
  };

  const _updateCharacterCount = (event) => {
    const { selectionStart, value } = event.target;
    const currentLine = value.substr(0, selectionStart).split('\n').length;
    const lineCharacterCount = value.split('\n')[currentLine - 1].length;
    setCharacterCount({ line: currentLine, count: lineCharacterCount });
  };

  const _checkCharacterCount = (event) => {
    const allowedKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'Enter', 'Tab'];
    if (characterCount && characterCount.count >= CHARACTERS_LIMIT && !allowedKeys.includes(event.key)) {
      event.stopPropagation();
      event.preventDefault();
      return false;
    }
  };

  useEffect(() => {
    if (data.length > 0) {
      const choices = data
        .split('\n')
        .filter((d) => !!d)
        .slice(1);
      setAnswers(choices.map((p, i) => ({ value: i, label: p })));
    } else {
      setAnswers([]);
    }
  }, [data]);

  const _showLeaderboard = () => {
    window.open(
      `/leaderboard?studioId=${activeStudio._id}&socketServerUrl=${socketServerUrl}&eventId=${activeEvent._id}`,
      'Leaderboard',
      'width=1280,height=720,toolbar=0,menubar=0,location=0',
    );
  };

  const isActivePoll = event && poll && event.activePoll === poll._id;
  const isValidPoll = poll && poll.hasOwnProperty('data');
  const isLiveEvent = event && event._id === liveEvent;

  const showPollButtons = appMode === 'PANDO' ? isLiveEvent : isStreamLive;
  return (
    poll && (
      <>
        <div className='section'>
          <div className='title'>Poll Admin</div>
          {showPollButtons && (
            <div>
              {isActivePoll && <Button type='danger' text='Close' containerStyle={styles.button} onClick={_closePoll} disabled={!isValidPoll} />}
              {!isActivePoll && <Button type='primary' text='Show' containerStyle={styles.button} onClick={_showPoll} disabled={!isValidPoll} />}
              <Button type='secondary' text='Show Leaderboard' containerStyle={styles.button} onClick={_showLeaderboard} />
              <Button type='secondary' text='Open Results Window' containerStyle={styles.button} onClick={_openResultsWindow} disabled={!isValidPoll} />
              <Button type='secondary' text='Display Results' containerStyle={styles.button} onClick={_displayResults} disabled={!isValidPoll} />
              <Button type='danger' text='Clear Results' containerStyle={styles.button} onClick={_clearResults} disabled={!isValidPoll} />
            </div>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Input
            type='text'
            label='Name'
            name='name'
            value={name}
            onChange={(event) => {
              setName(event.target.value);
            }}
            stacked
            containerStyle={{ margin: '0 0 15px 0', width: '25%' }}
          />
          <div className='poll-status'>
            <div style={{ marginBottom: 10 }}>
              Collecting responses for: <span>{gatheringPollResponses ? `${gatheringPollResponses.name}` : 'None'}</span>
            </div>
            <div>
              Results displayed for: <span>{currentPollResults ? `${currentPollResults.name}` : 'None'}</span>
            </div>
          </div>
        </div>
        <div style={{ ...styles.row, marginBottom: 40 }}>
          <div style={{ flex: 0.6 }}>
            <div style={{ ...styles.label }}>Edit Question and Response Options</div>
            <div style={{ height: '100%' }}>
              <textarea
                className='custom-scrollbar'
                value={data}
                onChange={(event) => {
                  setData(event.target.value);
                }}
                style={styles.textArea}
                placeholder={
                  'The first line of input should be the poll question (it is OK if it wraps onto multiple lines)\n\nAny subsequent lines that are not blank should be possible multiple choice answers (it is also OK for these to wrap onto multiple lines)'
                }
                onKeyDown={_checkCharacterCount}
                onKeyUp={_updateCharacterCount}
                onClick={_updateCharacterCount}
              />
              <div
                style={{
                  ...styles.row,
                  justifyContent: 'flex-end',
                  fontSize: 14,
                  height: 15,
                }}
              >
                {characterCount && characterCount.count > 0 && (
                  <small style={{ margin: '0 5px', color: characterCount.count >= CHARACTERS_LIMIT ? 'red' : '#a9aaab' }}>
                    {`Line ${characterCount.line}, ${characterCount.count}/${CHARACTERS_LIMIT} characters`}
                  </small>
                )}
              </div>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ ...styles.row, marginBottom: 30, flexDirection: 'column', alignItems: 'flex-start' }}>
                  <div style={{ ...styles.label, marginBottom: 10 }}>Type</div>
                  <RadioButtons
                    label='Type'
                    onChange={(type) => {
                      const typeValue = parseInt(type, 10);
                      if (typeValue === 0) {
                        setCorrectAnswerIndex(null);
                      } else {
                        setInputType(PollInputTypes.RADIO);
                      }
                      setPollType(typeValue);
                    }}
                    options={[
                      {
                        label: 'Poll',
                        value: PollTypes.POLL,
                      },
                      {
                        label: 'Question',
                        value: PollTypes.QUESTION,
                      },
                    ]}
                    value={pollType}
                    inline
                  />
                </div>
                {pollType === PollTypes.QUESTION && answers.length > 0 && (
                  <div style={{ marginLeft: 15 }}>
                    <div style={{ ...styles.label }}>Correct Answer</div>
                    <div style={{ ...styles.row, padding: '0 15px 30px 0', minWidth: 300, maxWidth: 400 }}>
                      <Select
                        list={answers}
                        listKey='value'
                        listLabel='label'
                        onChange={(opt) => setCorrectAnswerIndex(opt.value)}
                        selected={correctAnswerIndex !== null && answers[correctAnswerIndex] ? answers[correctAnswerIndex].value : null}
                        currentOption={correctAnswerIndex !== null && answers[correctAnswerIndex] ? answers[correctAnswerIndex].label : null}
                        containerStyle={{ width: '100%' }}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {pollType === PollTypes.POLL ? (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ ...styles.row, marginBottom: 30, flexDirection: 'column', alignItems: 'flex-start' }}>
                      <div style={{ ...styles.label, marginBottom: 10 }}>Input Type</div>
                      <RadioButtons
                        label='Input Type'
                        onChange={(subType) => {
                          setInputType(parseInt(subType, 10));
                        }}
                        options={[
                          {
                            label: 'Radio',
                            value: PollInputTypes.RADIO,
                          },
                          {
                            label: 'Checkbox',
                            value: PollInputTypes.CHECKBOX,
                          },
                        ]}
                        value={inputType}
                        inline
                      />
                    </div>
                  </div>
                ) : null}

                {inputType === PollInputTypes.CHECKBOX ? (
                  <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '30px' }}>
                    <div style={{ ...styles.row, marginBottom: 30, flexDirection: 'column', alignItems: 'flex-start' }}>
                      <div style={{ ...styles.label, marginBottom: 10 }}>Spreadsheet Delimiter</div>
                      <RadioButtons
                        label='Spreadsheet Delimiter'
                        onChange={(delimiter) => {
                          setSpreadsheetDelimiter(parseInt(delimiter, 10));
                        }}
                        options={[
                          {
                            label: 'New Line',
                            value: SpreadsheetDelimiters.NEW_LINE,
                          },
                          {
                            label: 'Pipe',
                            value: SpreadsheetDelimiters.PIPE,
                          },
                        ]}
                        value={spreadsheetDelimiter}
                        inline
                      />
                    </div>
                  </div>
                ) : null}
              </div>

              {inputType === PollInputTypes.CHECKBOX ? (
                <>
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 30 }}>
                    <div style={{ ...styles.row, flexDirection: 'column', alignItems: 'flex-start' }}>
                      <div style={{ ...styles.label }}>Min Answers</div>
                      <Input
                        type='number'
                        name='minAnswers'
                        label=''
                        min={1}
                        max={answers.length || 1}
                        step='1'
                        value={minAnswers}
                        onChange={(event) => setMinAnswers(parseInt(event.target.value, 10))}
                        inputStyle={{ width: '60px', marginLeft: 0 }}
                        containerStyle={{ margin: 0 }}
                        stacked
                      />
                    </div>
                    <div style={{ ...styles.row, flexDirection: 'column', alignItems: 'flex-start', marginLeft: 30 }}>
                      <div style={{ ...styles.label }}>Max Answers</div>
                      <Input
                        type='number'
                        name='maxAnswers'
                        label=''
                        min={1}
                        max={answers.length || 1}
                        step='1'
                        value={maxAnswers}
                        onChange={(event) => setMaxAnswers(parseInt(event.target.value, 10))}
                        inputStyle={{ width: '60px', marginLeft: 0 }}
                        containerStyle={{ margin: 0 }}
                        stacked
                      />
                    </div>
                    <div style={{ ...styles.row, flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-end', marginLeft: 30 }}>
                      <div style={{ ...styles.label, marginBottom: 15 }}>Spreadsheet Sorting</div>
                      <Checkbox
                        label='Sort by time of selection'
                        value={sortAnswersBySelectionTime}
                        onChange={(value) => setSortAnswersBySelectionTime(value)}
                      />
                    </div>
                  </div>
                  {answers.length > 0 && (
                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 30 }}>
                      <div>
                        <div style={{ ...styles.label }}>Exclusive Answers</div>
                        <div style={{ ...styles.row, padding: '0 15px 0 0', textAlign: 'left' }}>
                          <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                            {answers.map((answer, idx) => {
                              return (
                                <li>
                                  <Checkbox
                                    key={idx}
                                    label={answer.label}
                                    value={exclusiveAnswers[idx]}
                                    onChange={(value) => {
                                      const newExclusiveAnswers = [...exclusiveAnswers];
                                      newExclusiveAnswers[idx] = value;
                                      setExclusiveAnswers(newExclusiveAnswers);
                                    }}
                                  />
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : null}

              <div style={{ ...styles.row, padding: '15px 15px 30px 0' }}>
                <ButtonGroup
                  label='Prefix answers with'
                  options={[
                    { value: PrefixAnswersWith.NOTHING, label: 'Nothing', icon: noPrefixIcon, selectedIcon: noPrefixGreenIcon },
                    { value: PrefixAnswersWith.LETTERS, label: 'Letters', icon: lettersIcon, selectedIcon: lettersGreenIcon },
                    { value: PrefixAnswersWith.NUMBERS, label: 'Numbers', icon: numbersIcon, selectedIcon: numbersGreenIcon },
                  ]}
                  value={prefixAnswersWith}
                  onChange={(opt) => setPrefixAnswersWith(opt)}
                />
                <ButtonGroup
                  label='Display result labels as'
                  options={[
                    { value: DisplayResultLabelsAs.NUMBERS, label: 'Numbers', icon: numbersIcon, selectedIcon: numbersGreenIcon },
                    { value: DisplayResultLabelsAs.PERCENTAGES, label: 'Percentages', icon: percentagesIcon, selectedIcon: percentagesGreenIcon },
                    { value: DisplayResultLabelsAs.BOTH, label: 'Both', icon: bothIcon, selectedIcon: bothGreenIcon },
                  ]}
                  value={displayResultLabelsAs}
                  onChange={(opt) => setDisplayResultLabelsAs(opt)}
                />
              </div>
              <div className='section'>
                <div className='title'>Poll Results</div>
              </div>
              <div style={{ ...styles.row }}>
                <div style={{ ...styles.control, width: 220 }}>
                  <div style={styles.label}>Polling Background Image</div>
                  <div style={styles.backgroundImageContainer}>
                    {!backgroundImage && !backgroundImageFile && <div>NO IMAGE</div>}
                    {backgroundImage && !backgroundImageFile && <Image source={backgroundImage} alt='' width={160} height={100} />}
                    {backgroundImageFile && <img src={backgroundImagePreview} alt='' width={160} height={100} />}
                  </div>
                  <div style={{ ...styles.row, alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <FileInput
                      name='bgImg'
                      filename={backgroundImageFile ? backgroundImageFile.name : 'Select File'}
                      onChange={_onFileChange}
                      controlStyle={{ padding: 0 }}
                      filenameLabelStyle={
                        backgroundImageFile ? { width: 220 } : { textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: 150, whiteSpace: 'nowrap' }
                      }
                    />
                    {backgroundImage && !backgroundImageFile && (
                      <button type='button' style={styles.removeBackgroundImage} onClick={_removeBackgroundImage}>
                        <FontAwesomeIcon icon={faTrash} color='#a9aaab' style={{ fontSize: 14 }} />
                      </button>
                    )}
                  </div>
                </div>
                <div style={styles.control}>
                  <div style={styles.label}>Background Color</div>
                  <ColorPickerHex onChange={(hexValue) => setBgColor(hexValue)} hexValue={bgColor} />
                </div>
                <div style={styles.control}>
                  <div style={styles.label}>Text Color</div>
                  <ColorPickerHex onChange={(hexValue) => setTextColor(hexValue)} hexValue={textColor} />
                </div>
              </div>
              <div style={{ display: 'flex', marginTop: 15 }}>
                <Button type='primary' text='Save' onClick={_save} containerStyle={{ margin: 0 }} />
              </div>
            </div>
          </div>
          <div style={{ flex: 0.4, color: '#a9aaab' }}>
            <PollPreview data={data} pollType={pollType} inputType={inputType} prefixAnswersWith={prefixAnswersWith} correctAnswerIndex={correctAnswerIndex} />
          </div>
        </div>
      </>
    )
  );
}

export default forwardRef(PollEditor);
