import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { get, setStudioIdHeader } from '../services/api';
import {
  actions,
  disableRefresh,
  disableRoutes,
  disableLiveRoutes,
  disableGoLiveRoute,
  enableGoLiveRoute,
  enableLiveRoutes,
  enableRoutes,
  enableLayoutsRoute,
  disableLayoutsRoute,
  setLoading,
} from '../redux/uiRedux';
import { setActiveEvent } from '../redux/eventsRedux';
import { setActiveStudio } from '../redux/studioRedux';
import { goLiveSuccess } from '../redux/goLiveRedux';

function* applyUpdates(action) {
  const { updates } = action.payload;
  yield all(updates.map((update) => put(update)));
  yield put(disableRefresh());
}

function* loadTopBar() {
  try {
    const storedStudio = localStorage.getItem('activeStudio');
    const storedEvent = localStorage.getItem('activeEvent');

    if (storedStudio) {
      const studio = JSON.parse(storedStudio);
      const studioResponse = yield call(get, `/studio/${studio._id}`);
      if (studioResponse.status === 200 && studioResponse.data) {
        yield call(setStudioIdHeader, studioResponse.data._id);
        yield put(setActiveStudio(studioResponse.data));

        if (storedEvent) {
          const event = JSON.parse(storedEvent);
          const eventResponse = yield call(get, `/event/${event._id}`);
          if (eventResponse.status === 200 && eventResponse.data) {
            yield put(setActiveEvent(eventResponse.data));
          }
        }
      }
    }
  } catch (error) {
  } finally {
    yield call(updateRoutes);
    yield put(setLoading(false));
  }
}

function* updateRoutes() {
  try {
    const getActiveStudio = (state) => state.studio.active;
    const getActiveEvent = (state) => state.events.active;

    const activeStudio = yield select(getActiveStudio);
    const activeEvent = yield select(getActiveEvent);

    if (activeStudio) {
      const studioResponse = yield call(get, `/studio/${activeStudio._id}`);
      let liveEvent;
      if (studioResponse.status === 200 && studioResponse.data) {
        yield call(setStudioIdHeader, studioResponse.data._id);
        liveEvent = studioResponse.data.liveEvent;
      }

      if (activeStudio.dbName !== 'broadcast') {
        const instancesResponse = yield call(get, `/instance?studio=${activeStudio._id}`);
        if (instancesResponse.status === 200 && instancesResponse.data) {
          const serverTypes = instancesResponse.data.map((s) => s.serverType);
          const hasSocketServer = serverTypes.includes('SOCKET_SERVER');
          const hasRtcServer = serverTypes.includes('RTC');
          const hasCompositorServer = serverTypes.includes('COMPOSITOR');

          if (hasSocketServer && hasRtcServer && hasCompositorServer) {
            yield put(enableGoLiveRoute());
          } else {
            yield put(disableGoLiveRoute());
          }
        }
      }

      if ((activeStudio.rows !== 0 && activeStudio.columns !== 0) || activeStudio.dbName === 'broadcast') {
        yield put(enableRoutes());
        if (activeEvent) {
          yield put(enableLayoutsRoute());
          if (liveEvent) {
            if (activeEvent._id.toString() !== liveEvent.toString()) {
              yield put(disableLiveRoutes());
              yield put(disableGoLiveRoute());
            } else {
              yield put(enableGoLiveRoute());
              yield put(enableLiveRoutes());
              yield put(goLiveSuccess());
            }
          } else {
            yield put(disableLiveRoutes());
          }
        } else {
          yield put(disableLayoutsRoute());
          yield put(disableLiveRoutes());
          yield put(disableGoLiveRoute());
        }
      } else {
        yield put(disableRoutes());
      }
    } else {
      yield put(disableRoutes());
    }
  } catch (error) {
    console.log(error);
  }
}

export default function* () {
  yield all([
    takeLatest([actions.APPLY_UPDATES], applyUpdates),
    takeLatest([actions.LOAD_TOP_BAR], loadTopBar),
    takeLatest([actions.UPDATE_ROUTES], updateRoutes),
  ]);
}
